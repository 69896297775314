import React, { useState, useEffect } from "react";
import db from "./Firebase";
import { Button, Spinner } from "react-bootstrap";
import "./Report.css";
import { useNavigate, Link, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "./OnBoardCustomer.css";
import { toast } from "react-toastify";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import "./Report.css";

function TrailOrderReport() {
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    console.log(loggedIn);
    if (loggedIn) {
      // navigate("/");
    } else {
      navigate("/login");
    }
  }, [navigate]);
  const moment = extendMoment(Moment);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isValidRange, setIsValidRange] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [trailOrdersReport, setTrailOrdersReport] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const validateDateRange = (startDate) => {

     if(!startDate) {
          toast.error('Please enter Delivery Date'); 
          return false;
        }
        return true; 
    }    

    const prepareTrailOrdersReport = async () => {
        console.log('start date =>',moment(startDate).format("DD-MM-YYYY"));
      try {
          const querySnapshot = await db.collection('trai_orders')
              .where('delivery_date', '==', moment(startDate).format("DD-MM-YYYY"))
              .get();
    
          const trailOrders = [];
          querySnapshot.forEach((doc) => {
              const transaction = doc.data();
              trailOrders.push(transaction);
          });
    
          console.log(trailOrders[trailOrders.length-1]);
          return trailOrders;
      } catch (error) {
          console.error('Error fetching Trail orders Report:', error);
          return [];
      }
    };

    const handleSearch = async () => {
      
      setErrMsg("");
      const validParams = await validateDateRange(startDate);

      if(validParams) {
        setShowSpinner(true);
        try {
          const trailReport = await prepareTrailOrdersReport();
          setTrailOrdersReport(trailReport);
          setDataLoaded(true);
          setShowSpinner(false);
          
      } catch (error) {
          console.error('Error handling search:', error);
          setErrMsg("Error occurred while fetching wallet transactions.");
          setShowSpinner(false);
      }
      }

    };
    
    const handleReset = () => {
      setFromDate(null);
      setToDate(null);
      setStartDate(null);
      setEndDate(null);
      setTrailOrdersReport([]);
    }

    const SpinnerOverlay = () => (
      <div className="spinner-overlay">
        <div className="spinner"></div>
      </div>
    );

    return (
        <>
        <div className="panel" style={{marginTop:'10px' , marginBottom:'10px'}}>
              <h4>TRAIL ORDER REPORT</h4>
                </div>  
            <div className="datepickers-container">
      <div className="datepicker-container">
        <label className="datepicker-label">Delivery Date:</label>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yyyy" // Format for displaying the date
          className="datepicker-input"
          placeholderText="From Date"
        />
      </div>
      {/* <br/>
      <div className="datepicker-container">
        <label className="datepicker-label">To Date:</label>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat="dd/MM/yyyy" // Format for displaying the date
          className="datepicker-input"
          placeholderText="To date"
        />
      </div> */}
      <Button variant="outline-success" onClick={handleSearch}>Search</Button>
      <Button variant="outline-success" onClick={handleReset} style={{marginLeft:'10px'}}>Reset</Button>
    </div>
            <br/>
            
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {showSpinner && <div className="spinner-container"><SpinnerOverlay/></div>}
              <div class="table-responsive">
                <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Customer Name</th>
                                <th>Customer Contact</th>
                                <th>Hub Name</th>
                                <th>Delivery Executive</th>
                                <th>Location</th>
                                <th>Status</th>
                                <th>Delivery Date</th>
                                <th>Date of Request</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataLoaded && trailOrdersReport.map((trailOrder, index) => (
                                <tr key={index}>
                                    <td>{trailOrder.product_name}</td>
                                    <td>{trailOrder.customer_name}</td>
                                    <td>{trailOrder.customer_phone}</td>
                                    <td>{trailOrder.hub_name}</td>
                                    <td>{trailOrder.delivery_executive}</td>
                                    <td>{trailOrder.location}</td>
                                    <td>{trailOrder.status}</td>
                                    <td>{trailOrder.delivery_date}</td>
                                    <td>{trailOrder.created_date && moment(trailOrder.created_date.toDate().toISOString()).format("DD-MM-YY, h:mm a")}</td>
                                    
                                </tr>
                            ))} 
                        </tbody>
                    </table>
              </div>
                
            </div>
        </>
    );
}

export default TrailOrderReport;
