import React, { useState, useEffect, useSyncExternalStore } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { useNavigate, Link, useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import './OrderSheet'
import ExportTableToExcel from './ExportTableToExcel';
import { Button, Card, Row, Col, Form, Alert } from 'react-bootstrap';
import db from './Firebase';
import { collection, getDocs, query, where, getCountFromServer } from 'firebase/firestore';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
const HubDeliveryReport = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    console.log(loggedIn);
    if (loggedIn) {
      // navigate("/");
    } else {
      navigate("/login");
    }
  }, [navigate]);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [selectedHubName, setSelectedHubName] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [hubDeliveryData, setHubDeliveryData] = useState([]);
  const [hubNames, setHubNames] = useState([]);
  const [productNames, setProductNames] = useState([]);
  const [showNodataFound, setshowNodataFound] = useState(false);
  const [productList, setProductList] = useState([]);
  const [packagingOptions, setPackagingOptions] = useState([]);
  const [selectedPackaging, setSelectedPackaging] = useState();
  const [data, setData] = useState([]);
  const [totalQty, setTotalQty] = useState(0);
  const [fileName, setFileName] = useState("");
  const [selectedReportType, setSelectedReportType] = useState('');
  const [cummulativeHubDeliveryList, setCummulativeHubDeliveryList] = useState([]);
  const [loadData, setLoadData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadCummulativeData, setLoadCummulativeData] = useState(false);
  const reportTypeOptions = [
    { label: "Cummulative", value: "Cummulative" },
    { label: "Hub Wise", value: "Hubwise" }
  ];

  useEffect(() => {
    // Load data logic
  }, [currentPage]);

  
  const exportTableToPDF = () => {
    const doc = new jsPDF();
    // Optional: Add a title or any other text
    doc.text("Cumulative Hub Delivery Report", 20, 20);
    // Prepare table data
    const tableColumn = ["Sr.No", "Product Name", "Category", "Packaging", "Unit Price", "Quantity"];
    if (selectedReportType.value === "Hubwise") {
      tableColumn.splice(1, 0, "Hub Name");
    }
    const tableRows = cummulativeHubDeliveryList.map((delivery, index) => {
      const rowData = [
        index + 1,
        delivery.product_name,
        delivery.category,
        delivery.packaging,
        delivery.unit_price,
        delivery.quantity,
      ];

      if (selectedReportType.value === "Hubwise") {
        rowData.splice(1, 0, delivery.hub_name);
      }

      return rowData;
    });

    // Generate the table
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30, // Start after the title
    });

    // Save the PDF
    doc.save(`${fileName || 'report'}.pdf`);
  };


  const exportTableToCSV = () => {
    // Prepare CSV headers
    const csvColumns = ["Sr.No", "Product Name", "Category", "Packaging", "Unit Price", "Quantity"];
    if (selectedReportType.value === "Hubwise") {
        csvColumns.splice(1, 0, "Hub Name"); // Insert "Hub Name" column if the report type is Hubwise
    }

    // Prepare CSV rows
    const csvRows = cummulativeHubDeliveryList.map((delivery, index) => {
        const rowData = [
            index + 1,
            delivery.product_name,
            delivery.category,
            delivery.packaging,
            delivery.unit_price,
            delivery.quantity,
        ];

        if (selectedReportType.value === "Hubwise") {
            rowData.splice(1, 0, delivery.hub_name); // Insert hub name if the report type is Hubwise
        }

        return rowData;
    });

    // Combine headers and rows into a single CSV content
    const csvContent = [
        csvColumns.join(","), // Join the header row
        ...csvRows.map(row => row.join(",")) // Join each data row
    ].join("\n"); // Join all rows with a newline character

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element for downloading
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${fileName || 'report'}.csv`); // Use the same filename as PDF
    link.style.visibility = 'hidden';

    // Append the link to the body
    document.body.appendChild(link);
    link.click(); // Simulate a click on the link
    document.body.removeChild(link); // Remove the link after download
};

  const moment = require('moment');
  useEffect(() => {
    db.collection("hubs_data").onSnapshot((snapshot) => {
      setHubNames(
        snapshot.docs.map((doc) => ({ label: doc.data().hub_name, value: doc.data().hub_name }))
      );
    });
    db.collection("products_data").onSnapshot((snapshot) => {

      setProductNames(
        snapshot.docs.map((doc) => ({ label: doc.data().productName, value: doc.data().productName }))
      );
      const data = snapshot.docs.map(doc => doc.data());
      if (data.length > 0) {
        setProductList(data);
      }
    });


  }, []);

  const filterProductByName = (selectedProduct) => {
    const filteredList = productList.filter(productDetails => productDetails.productName === selectedProduct);
    if (filteredList) {
      setPackagingOptions(
        filteredList[0].packagingOptions.map((options) => ({ label: options.packaging + ' ' + options.pkgUnit, value: options.packaging + ' ' + options.pkgUnit }))
      )

    }

  };
  useEffect(() => {
    if (selectedProduct) {
      setPackagingOptions([]);
      setSelectedPackaging('');
      filterProductByName(selectedProduct.value);
    }
  }, [selectedProduct]);

  const fetchProductCount = async (deliveryDate, productName, productPackaging) => {
    try {
      const collRef = collection(db, 'order_history');
      const q = query(collRef, where('delivery_date', '==', deliveryDate), where('product_name', '==', productName), where('package_unit', '==', productPackaging))
      const snapshot = await getCountFromServer(q);
      return snapshot.data().count;

    } catch (error) {
      console.error("Error fetching collection size: ", error);
      console.error("Error with Product : ", productName)
      return 0;
    }
  };

  const createFileName = () => {
    const startDateString = moment(startDate).format('DD-MM-YYYY');
    setFileName(`Hub_deliveries_${startDateString}`);
  }

  const calculateCummulativeReport = async () => {
    setLoading(true);
    try {
      const productSnap = await db.collection('products_data').get();
      const productList = productSnap.docs.map(doc => doc.data());
  
      const cumulativePromises = productList.map(async product => {
        const packagingPromises = product.packagingOptions.map(async packagingOption => {
          const ordersSnapshot = await db.collection('order_history')
            .where('delivery_date', '==', moment(startDate).format('YYYY-MM-DD'))
            .where('product_name', '==', product.productName)
            .where('package_unit', '==', `${packagingOption.packaging} ${packagingOption.pkgUnit}`)
            .get();
  
          let totalQuantity = 0;
  
          ordersSnapshot.forEach(doc => {
            const data = doc.data();
            const quantity = data.quantity;
            totalQuantity += quantity;
          });
  
          return {
            product_name: product.productName,
            category: product.category, // Ensure this is included
            packaging: `${packagingOption.packaging} ${packagingOption.pkgUnit}`,
            unit_price: packagingOption.price,
            quantity: totalQuantity
          };
        });
        return Promise.all(packagingPromises);
      });
  
      const cummulativeList = (await Promise.all(cumulativePromises)).flat();
  
      setCummulativeHubDeliveryList(cummulativeList);
      setLoadCummulativeData(true);
      setDataLoaded(true);
      createFileName();
    } catch (error) {
      console.error("Error calculating cumulative report:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const calculateHubWiseReportWithHubName = async () => {
    setLoading(true);
    try {
      const productSnap = await db.collection('products_data').get();
      const productList = productSnap.docs.map(doc => doc.data());

      const cumulativePromises = productList.map(async product => {
        const packagingPromises = product.packagingOptions.map(async packagingOption => {
          const ordersSnapshot = await db.collection('order_history')
            .where('delivery_date', '==', moment(startDate).format('YYYY-MM-DD'))
            .where('product_name', '==', product.productName)
            .where('package_unit', '==', `${packagingOption.packaging} ${packagingOption.pkgUnit}`)
            .where('hub_name', '==', selectedHubName.value)
            .get();

    
          const hubQuantities = {};

          ordersSnapshot.forEach(doc => {
            const data = doc.data();
            const hubName = data.hub_name;
            const quantity = data.quantity;

            if (hubQuantities[hubName]) {
              hubQuantities[hubName] += quantity;
            } else {
              hubQuantities[hubName] = quantity;
            }
          });
    
          return Object.keys(hubQuantities).map(hubName => ({
            product_name: product.productName,
            category: product.category, 
            packaging: `${packagingOption.packaging} ${packagingOption.pkgUnit}`,
            unit_price: packagingOption.price,
            hub_name: hubName,
            quantity: hubQuantities[hubName]
          }));
        });
        return Promise.all(packagingPromises);
      });
      const cummulativeList = (await Promise.all(cumulativePromises)).flat(2);
      setCummulativeHubDeliveryList(cummulativeList);
      setLoadCummulativeData(true);
      console.log(cummulativeList);
      setDataLoaded(true);
      createFileName();
      setLoading(false);
    } catch (error) {
      console.error("Error calculating cumulative report:", error);
    } finally {
      setLoading(false);
    }
  }
  const calculateHubWiseReport = async () => {
    setLoading(true);
    try {
      const productSnap = await db.collection('products_data').get();
      const productList = productSnap.docs.map(doc => doc.data());

      const cumulativePromises = productList.map(async product => {

        const packagingPromises = product.packagingOptions.map(async packagingOption => {
          const ordersSnapshot = await db.collection('order_history')
            .where('delivery_date', '==', moment(startDate).format('YYYY-MM-DD'))
            .where('product_name', '==', product.productName)
            .where('package_unit', '==', `${packagingOption.packaging} ${packagingOption.pkgUnit}`)
            .get();
            const hubQuantities = {};
            ordersSnapshot.forEach(doc => {
            const data = doc.data();
            const hubName = data.hub_name;
            const quantity = data.quantity;
            if (hubQuantities[hubName]) {
              hubQuantities[hubName] += quantity;
            } else {
              hubQuantities[hubName] = quantity;
            }
          });
          return Object.keys(hubQuantities).map(hubName => ({
            product_name: product.productName,
            category: product.category, 
            packaging: `${packagingOption.packaging} ${packagingOption.pkgUnit}`,
            unit_price: packagingOption.price,
            hub_name: hubName,
            quantity: hubQuantities[hubName]
          }));
        });
        return Promise.all(packagingPromises);
      });
      const cummulativeList = (await Promise.all(cumulativePromises)).flat(2);
      setCummulativeHubDeliveryList(cummulativeList);
      setLoadCummulativeData(true);
      setDataLoaded(true);
      createFileName();
      setLoading(false);
    } catch (error) {
      console.error("Error calculating cumulative report:", error);
    } finally {
      setLoading(false);
    }
  }

  const cummulativeReportOnProductSelection = async () => {
    setLoading(true);
    try {
      const ordersSnapshot = await db.collection('order_history')
        .where('delivery_date', '==', moment(startDate).format('YYYY-MM-DD'))
        .where('product_name', '==', selectedProduct.value)
        .where('package_unit', '==', selectedPackaging.value)
        .get();
      const hubQuantities = {};
      ordersSnapshot.forEach(doc => {
        const data = doc.data();
        const hubName = data.hub_name;
        const quantity = data.quantity;
        const unitPrice = data.price;
        const productName = data.product_name;

        if (hubQuantities[productName]) {
          hubQuantities[productName].quantity += quantity;
        } else {
          hubQuantities[productName] = { quantity, unit_price: unitPrice };
        }
      });
      const cummulativeList = Object.keys(hubQuantities).map(productName => ({
        product_name: selectedProduct.label,
        packaging: selectedPackaging.label,
        unit_price: hubQuantities[productName].unit_price,
        //hub_name: hubName,
        quantity: hubQuantities[productName].quantity
      }));

      // cummulativeList = cummulativeList.sort((a, b) => {
      //   if (a.product_name < b.product_name) return -1;
      //   if (a.product_name > b.product_name) return 1;
      //   return 0;
      // });


      setCummulativeHubDeliveryList(cummulativeList);
      setDataLoaded(true);
      setLoading(false);
      createFileName();
    } catch (error) {
      console.error("Error calculating cumulative report:", error);
    } finally {
      setLoading(false);
    }
  }


  const hubwiseReportOnProductSelection = async () => {
    setLoading(true);
    try {
      const ordersSnapshot = await db.collection('order_history')
        .where('delivery_date', '==', moment(startDate).format('YYYY-MM-DD'))
        .where('product_name', '==', selectedProduct.value)
        .where('package_unit', '==', selectedPackaging.value)
        .where('hub_name' , '==' , selectedHubName.value)
        .get();

      const hubQuantities = {};


      ordersSnapshot.forEach(doc => {
        const data = doc.data();
        const hubName = data.hub_name;
        const quantity = data.quantity;
        const unitPrice = data.price;

        if (hubQuantities[hubName]) {
          hubQuantities[hubName].quantity += quantity;
        } else {
          hubQuantities[hubName] = { quantity, unit_price: unitPrice };
        }
      });


      const cummulativeList = Object.keys(hubQuantities).map(hubName => ({
        product_name: selectedProduct.label,
        packaging: selectedPackaging.label,
        unit_price: hubQuantities[hubName].unit_price,
        hub_name: hubName,
        quantity: hubQuantities[hubName].quantity
      }));

      // cummulativeList = cummulativeList.sort((a, b) => {
      //   if (a.product_name < b.product_name) return -1;
      //   if (a.product_name > b.product_name) return 1;
      //   return 0;
      // });


      setCummulativeHubDeliveryList(cummulativeList);
      setDataLoaded(true);
      setLoading(false);
      createFileName();
    } catch (error) {
      console.error("Error calculating cumulative report:", error);
    } finally {
      setLoading(false);
    }
  }




  const handleSearch = async () => {
    if (selectedReportType.value === 'Hubwise') {
      setLoading(true);
      setDataLoaded(false);
      setLoadCummulativeData(false);
      if (selectedProduct) {
        if (selectedPackaging) {
          hubwiseReportOnProductSelection();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            background: '#69aba6',
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });

          Toast.fire({
            icon: 'warning',
            title: 'Please Select Packaging'
          });
          setLoading(false);
          setDataLoaded(false);
        }
      } else {
        if (selectedHubName) {
          calculateHubWiseReportWithHubName();
        } else {
          calculateHubWiseReport();
        }

      }

    } else if (selectedReportType.value === 'Cummulative') {

      if (selectedProduct) {
        if (selectedPackaging) {
          cummulativeReportOnProductSelection();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            background: '#69aba6',
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });

          Toast.fire({
            icon: 'warning',
            title: 'Please Select Packaging'
          });
          setLoading(false);
          setDataLoaded(false);
        }
      } else {
        calculateCummulativeReport();
      }
    }


  }

  const handleDateChange = (date) => {

    setStartDate(date);
  };

  const handleReportTypeChange = (selectedOption) => {
    setSelectedReportType(selectedOption);
    setSelectedProduct('');
    setSelectedPackaging('');
    setSelectedHubName('');
    setCummulativeHubDeliveryList([]);
  };

  const handleReset = () => {
    setStartDate(null);
    setSelectedHubName("");
    setSelectedProduct("");
    setSelectedPackaging("");
    setSelectedReportType("");
    setshowNodataFound(false);
    setHubDeliveryData("");
    setDataLoaded(false);
  };

  console.log();
  
  return (
    <>
      {loading && ( // Render loader when loading state is true
        <div className="loader-overlay">
          <div className="">
            <img style={{
              height: "6rem"
            }} src="images/loader.gif"></img>
          </div>
        </div>
      )}
      <div class="container-scroller">
        <div class="container-fluid">
          <div class="main-panel" style={{ width: '100%' }}>
            <div className='panel' style={{ display: 'flex' }}>
              <span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>HUB DELIVERY REPORT</span>
              <div style={{ marginLeft: '65%' }}>
                {dataLoaded && <ExportTableToExcel
                  tableId="hub_delivery"
                  fileName={fileName}
                />}
              </div>
            
              {dataLoaded && (
                <>
                 <button className='btn btn-success btn-rounded btn-sm' onClick={exportTableToPDF}style={{ marginLeft: '-15%' }} >Export PDF</button>
                
                <button className='btn btn-success btn-rounded btn-sm' onClick={exportTableToCSV}style={{ marginLeft: '-15%' }} >Export CSV</button>
                </>
               
              )}
              
            </div>
            <br />
            <div className="panel">

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '90%' }}>
                <div style={{ marginTop: '10px' }}>
                  <label>Date:</label>
                  <br />
                  <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    className="datepicker-input"
                    placeholderText="Select date"
                  />
                </div>
                <div className='inputPanels'>
                  <label>Report Type</label>
                  <Select
                    options={reportTypeOptions}
                    onChange={handleReportTypeChange}
                    value={selectedReportType}
                    placeholder="Report Type"
                  />
                </div>
                {selectedReportType.value == 'Hubwise' ? <>
                  <div className='inputPanels'>
                    <label>Hub Name:</label>
                    <Select
                      options={hubNames}
                      onChange={value => setSelectedHubName(value)}
                      value={selectedHubName}
                      placeholder="Select Hub Name"
                    />
                  </div>
                </> : <></>
                }
                <div className='inputPanels'>
                  <label>Product:</label>
                  <Select
                    options={productNames}
                    onChange={value => setSelectedProduct(value)}
                    value={selectedProduct}
                    placeholder="Select Product"
                  />
                </div>
                <div className='inputPanels'>
                  <label>Packaging:</label>
                  <Select
                    options={packagingOptions}
                    onChange={value => setSelectedPackaging(value)}
                    value={selectedPackaging}
                    placeholder="Select Packaging"
                  />
                </div>
                <div className='inputPanels' style={{ marginTop: '30px' }}>
                  <Button variant="outline-success"
                    onClick={handleSearch}
                    size="sm"
                  >
                    Search
                  </Button>
                  <Button
                    variant="outline-success"
                    onClick={handleReset}
                    style={{ marginLeft: "10px" }}
                    size="sm"
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </div>
  
            
            <table class="table table-striped" id="hub_delivery">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  {selectedReportType.value == "Hubwise" ? <th>Hub Name</th> : <></>}
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Packaging</th>
                  <th>Unit Price</th>
                  <th>Total Quantity</th>
                </tr>
              </thead>
              <tbody>
                {dataLoaded &&
                  cummulativeHubDeliveryList.map((delivery, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      {selectedReportType.value == "Hubwise" ? <td>{delivery.hub_name}</td> : <></>}
                      <td>{delivery.product_name}</td>                      
                      <td>{delivery.category}</td>
                      <td>{delivery.packaging}</td>
                      <td>{delivery.unit_price}</td>
                      <td>{delivery.quantity}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <Alert show={showNodataFound} variant="success">
              <Alert.Heading>No data found for selected criteria</Alert.Heading>
              <div className="d-flex justify-content-end">
                <Button onClick={() => setshowNodataFound(false)} variant="outline-success">
                  Close
                </Button>
              </div>
            </Alert>
          </div>
        </div>
      </div>
    </>
  );
};

export default HubDeliveryReport;
