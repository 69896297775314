import React, { useEffect } from 'react';
import db from './Firebase';

function TrailOrders() {

    const get = async () => {

        const collectionRef = db.collection("userDetails");
        const snapshot = await collectionRef.get();
        const size = snapshot.size; 
        console.log("size => " , size);
    
    }

  return (
    <div>
       <button onClick={get}>click me</button>
    </div>
  )
}

export default TrailOrders
