import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import Brand from "./Brands";
import Customers from "./Customers";
import Dashbaord from "./Dashboard";
import Footer from "./Footer";
import Header from "./Header";
import HubDist from "./HubDist";
import Location from "./Location";
import Product from "./Product";
import Products from "./Products";
import ProductCategories from "./ProductCtaegories";
import ProfilePage from "./ProfilePage";
import Region from "./Region";
import RoutesData from "./Routes";
import Sidebar from "./Sidebar";
import SidebarAdmin from "./SidebarAdmin";
import Users from "./Users1";
import Marketing from "./Marketing";
import Banner from "./Banner";
import Coupons from "./Coupons";
import Offers from "./Offers";
import Communication from "./Communication";
import Reports from "./Reports";
import UserDetails from "./ManageUserDetails";
import ManageRoles from "./ManageRoles";
import ManageUsersAndRoles from "./ManageUsersAndRoles";
import Tickets from "./Tickets";
import UsersLevel from "./UsersLevel";
import OrderSheet from "./OrderSheet";
import HubDeliveryReport from "./HubDeliveryReport";
import UserProfile from "./UserProfile";
import Login from "./Login";
import { useCookies } from "react-cookie";
import PermissionDenied from "./PermissionDenied";
import SalesReport from "./SalesReport";
import CumulativeSalesReport from "./CumulativeSalesReport";
import LowCreditReport from "./LowCreditReport";
import NewOnboardCustomers from "./NewOnboardCustomers";
import ReturnReport from "./ReturnReport";
import Ordersorting from "./Ordersorting";
import WalletTxnsReport from "./WalletTxnsReport";
import OrderReport from "./OrderReport";
import TrailOrders from "./TrailOrders";
import TrailOrderReport from "./TrailOrderReport";
import OneTimeOrders from "./OnetimeOrders";
import ActivityLogs from "./ActivityLogs";
import SubscriptionReport from "./SubscriptionReport";
import ScheduledOrders from "./ScheduledOrders";
import PayUMoneyPayment from "./Payment";
import EditCustomers from "./EditCustomer";
import PredictiveAnalysis from "./PredictiveAnalysis";
import Lead from "./pages/Lead";
import CafeManagement from "./pages/CafeManagement/CafeManagement";
import CustomerReport from "./components/CustomerReport";
export default function Navigator() { 
    return (
        <Router>
            <Routes>
            <Route path="/" element={<Dashbaord />} />
                <Route path="/region" caseSensitive={false} element={<Region />}  />
                <Route path="/location" caseSensitive={false} element={<Location />}  />
                <Route path="/routes" caseSensitive={false} element={<RoutesData />}  />
                <Route path="/customers" caseSensitive={false} element={<Customers />}  />
                <Route path="/vendors_data" caseSensitive={false} element={<HubDist />}  />
                <Route path="/manageUsersRoles" caseSensitive={false} element={<ManageUsersAndRoles />}  />
                <Route path="/brands" caseSensitive={false} element={<Brand />}  />
                <Route path="/product_categories" caseSensitive={false} element={<ProductCategories />}  />
                <Route path="/product" caseSensitive={false} element={<Product />}  />
                <Route path="/products" caseSensitive={false} element={<Products />}  />
                <Route path="/marketing" caseSensitive={false} element={<Marketing />}  />
                <Route path="/banners" caseSensitive={false} element={<Banner />}  />
                <Route path="/coupons" caseSensitive={false} element={<Coupons />}  />
                <Route path="/offers" caseSensitive={false} element={<Offers />}  />
                <Route path="/tickets" caseSensitive={false} element={<Tickets />}  />
                <Route path="/communication" caseSensitive={false} element={<Communication />}  />
                <Route path="/reports" caseSensitive={false} element={<Reports />}  />
                <Route path="/userdetails" caseSensitive={false} element={<UserDetails />}  />
                <Route path="/profile/:id" caseSensitive={false} element={<ProfilePage />}  />
                <Route path="/sidebaradmin" caseSensitive={false} element={<SidebarAdmin />}  />
                <Route path="/users" caseSensitive={false} element={<Users />}  />
                <Route path="/users_level" caseSensitive={false} element={<UsersLevel />}  />
                <Route path="/orderSheet" caseSensitive={false} element={<OrderSheet />}  />
                <Route path="/ordersorting" caseSensitive={false} element={<Ordersorting />}  />
                <Route path="/lowcreditreport" caseSensitive={false} element={<LowCreditReport />}  />
                <Route path="/newonboardcustomer" caseSensitive={false} element={<NewOnboardCustomers />}  />
                <Route path="/hubdeliveryreport" caseSensitive={false} element={<HubDeliveryReport />}  />
                <Route path="/profile" caseSensitive={false} element={<UserProfile />} />
                <Route path="/return_report" element={<ReturnReport />} />
                <Route path="/permission_denied" element={<PermissionDenied />} />
                <Route path="/login" element={<Login />} />
                <Route path="/salesReport" caseSensitive={false} element={<SalesReport />} />
                <Route path="/cumulativeSalesReport" caseSensitive={false} element={<CumulativeSalesReport />} />
                <Route path="/wallettransactions" caseSensitive={false} element={<WalletTxnsReport />} />                
                <Route path="/orderreport" caseSensitive={false} element={<OrderReport />} />
                <Route path="/trailorders" caseSensitive={false} element={<TrailOrders />} />
                <Route path="/trailorderreport" caseSensitive={false} element={<TrailOrderReport />} />
                <Route path="/onetimeorders" caseSensitive={false} element={<OneTimeOrders />} />
                <Route path="/activitylogs" caseSensitive={false} element={<ActivityLogs />} />
                <Route path="/subscriptionreport" caseSensitive={false} element={<SubscriptionReport />} />
                <Route path="/futureorders" caseSensitive={false} element={<ScheduledOrders />} />
                <Route path="/payment" caseSensitive={false} element={<PayUMoneyPayment />} />
                <Route path="/edit_customers/:id" caseSensitive={false} element={<EditCustomers />} />
                <Route path="/predictiveanalysis" caseSensitive={false} element={<PredictiveAnalysis />} />
                <Route path="/leads/:task_id"  caseSensitive={false} element={<Lead />} />
                <Route path="/cafe-management" caseSensitive={false} element={<CafeManagement />} />
                <Route path="customers_report/" caseSensitive={false} element={<CustomerReport />} />     
            </Routes>
        </Router>
    );
};