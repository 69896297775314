import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import TopPanel from "./TopPanel";
import Footer from "./Footer";

function Brand() {
    const navigate = useNavigate();
    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        console.log(loggedIn);
        if (loggedIn) {
            // navigate("/");
        } else {
            navigate("/login");
        }
    }, [navigate]);
    const [loading, setLoading] = useState(false);
    const [timeOfDay, setTimeOfDay] = useState('');
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const initialFormState = { brand_name: '', brand_thumbnail: '', brand_banner: '', status: '', updated_date: new Date(), created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormState);
    const [thumbnailFile, setThumbnailFile] = useState(null);
    const [bannerFile, setBannerFile] = useState(null);

    const thumbnailInputRef = useRef(null);
    const bannerInputRef = useRef(null);

    const handleThumbnailChange = (e) => {
        setThumbnailFile(e.target.files[0]); // Set the selected file as the thumbnailFile state
    };

    // Function to handle changes in the banner file input
    const handleBannerChange = (e) => {
        setBannerFile(e.target.files[0]); // Set the selected file as the bannerFile state
    };



    useEffect(() => {
        const getCurrentTimeOfDay = () => {
            const currentTime = new Date().getHours();
            if (currentTime < 12) {
                setTimeOfDay('Good Morning');
            } else if (currentTime >= 12 && currentTime < 18) {
                setTimeOfDay('Good Afternoon');
            } else {
                setTimeOfDay('Good Evening');
            }
        };

        getCurrentTimeOfDay();
    }, []);

    const handleChange = (e) => {
        const { id, value, files } = e.target;
        if (id === 'imageThumbnail') {
            setThumbnailFile(files[0]);
        } else if (id === 'imageBanner') {
            setBannerFile(files[0]);
        } else {
            setSubmit({ ...submit, [id]: value })
        }
    }


    const addNew = () => {
        setEdit(false)
        openModal();
        setSubmit({ brand_name: '', brand_thumbnail: '', brand_banner: '', status: '', updated_date: new Date(), created_date: new Date() });
    }

    const reset = () => {
        setSubmit({ brand_name: '', brand_thumbnail: '', brand_banner: '', status: '', updated_date: new Date(), created_date: new Date() })
    }

    useEffect(() => {
        db.collection("brands_data").orderBy("updated_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);

    const changeStatusForm = (data, id) => {
        setEdit(true)
        setEditID(id)
        setSubmit({ id: id, brand_name: data.brand_name, brand_thumbnail: data.brand_thumbnail, brand_banner: data.brand_banner, status: data.status, updated_date: new Date(), created_date: new Date() });
        openModal();
    }

    const openModal = () => {
        // alert("ooo")
        window.modelshow();
    }

    const closeModal = () => {
        window.modalHide();
    }


    const uploadFilesAndSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (!thumbnailFile || !bannerFile) {
                throw new Error("Thumbnail or banner file is missing");
            }

            // Upload thumbnail image
            const thumbnailRef = ref(storage, `brand_thumbnails/${thumbnailFile.name}`);
            const thumbnailUploadTask = uploadBytesResumable(thumbnailRef, thumbnailFile);
            await thumbnailUploadTask;
            const thumbnailDownloadURL = await getDownloadURL(thumbnailRef);

            // Upload banner image
            const bannerRef = ref(storage, `brand_banners/${bannerFile.name}`);
            const bannerUploadTask = uploadBytesResumable(bannerRef, bannerFile);
            await bannerUploadTask;
            const bannerDownloadURL = await getDownloadURL(bannerRef);

            // Update submit object with download URLs
            const updatedSubmit = {
                ...submit,
                brand_thumbnail: thumbnailDownloadURL,
                brand_banner: bannerDownloadURL
            };

            // Check if both thumbnail and banner URLs are available
            if (thumbnailDownloadURL && bannerDownloadURL) {
                // Submit form data to Firestore
                await db.collection("brands_data").add(updatedSubmit);

                // Reset the file state variables
                setThumbnailFile(null);
                setBannerFile(null);
                // Clear the file input fields
                thumbnailInputRef.current.value = '';
                bannerInputRef.current.value = '';
                closeModal();
            } else {
                throw new Error("Thumbnail or banner URL is missing");
            }
        } catch (error) {
            console.error("Error uploading files and submitting form:", error);
            // Handle error appropriately, such as displaying an error message to the user
            Swal.fire('Error', 'Failed to upload files and submit form', 'error');
        } finally {
            setLoading(false); // Set loading state to false when upload completes or encounters an error
        }
    };


    const deleteData = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                db.collection("brands_data").doc(id).delete().then(() => {

                    setSubmit(initialFormState);
                    closeModal();
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    )
                })

            }
        })

    }


    return (
        <>
            <div class="container-scroller">
                <TopPanel />

                <div class="container-fluid page-body-wrapper">
                    <Sidebar />

                    <div class="main-panel">
                        <div class="content-wrapper">

                            <div class="col-lg-12 grid-margin stretch-card">
                                <div class="card">
                                    <div class="card-body">
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <h4 class="card-title">Brand</h4>
                                            <p class="card-description">
                                                <button type="button" class="btn btn-success btn-rounded btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal-2" onClick={() => addNew()}>Add Brands</button>
                                            </p>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Brand Name
                                                        </th>
                                                        <th>
                                                            Brand Thumbnail
                                                        </th>
                                                        <th>
                                                            Brand Banner
                                                        </th>
                                                        <th>
                                                            Action
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.map(({ id, data }, index) => (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    {data.brand_name}
                                                                </td>
                                                                <td>
                                                                    <img src={data.brand_thumbnail}></img>
                                                                </td>
                                                                <td>
                                                                    <img src={data.brand_banner}></img>
                                                                </td>
                                                                <td>
                                                                    <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => changeStatusForm(data, id)} class="btn btn-dark btn-sm"><i class="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i></button>
                                                                    <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} class="btn btn-dark btn-sm" onClick={() => deleteData(id)}><i class="menu-icon mdi mdi-delete" style={{ color: "white" }}></i></button>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="exampleModal-2" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel-2" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel-2">Add Brand</h5>
                                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form class="forms-sample" onSubmit={uploadFilesAndSubmit}>
                                                <div class="form-group">
                                                    <label>Brand Name</label>
                                                    <input type="text" class="form-control" onChange={handleChange} id="brand_name" value={submit.brand_name} required />
                                                </div>
                                                <div class="form-group">
                                                    <label>Brand Thumbnail</label>
                                                    <input type="file" id="thumbnail" ref={thumbnailInputRef} class="form-control" style={{ padding: "10px" }} onChange={handleThumbnailChange} required />
                                                    {edit ? <img src={submit.brand_thumbnail} style={{ height: "6rem", padding: "1rem" }}></img> : <></>}
                                                </div>
                                                <div class="form-group">
                                                    <label>Brand Banner</label>
                                                    <input type="file" id="banner" ref={bannerInputRef} class="form-control" style={{ padding: "10px" }} onChange={handleBannerChange} required />
                                                    {edit ? <img src={submit.brand_banner} style={{ height: "6rem", padding: "1rem" }}></img> : <></>}
                                                </div>
                                                <div class="modal-footer">
                                                    {loading ? <div className="loading">Uploading, please wait...</div> : <button type="submit" value="submit" class="btn btn-success">Submit</button>}
                                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                                </div>
                                            </form>

                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                        <Footer />

                    </div>

                </div>

            </div>
        </>
    )
}

export default Brand
