import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import TopPanel from "./TopPanel";
import Footer from "./Footer";

function Product() {
    const navigate = useNavigate();

    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        console.log(loggedIn);
        if (loggedIn) {
            // navigate("/");
        } else {
            navigate("/login");
        }
    }, [navigate]);
    const [timeOfDay, setTimeOfDay] = useState('');
    const [data, setData] = useState([]);
    const [viewID, setViewID] = useState("");
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const initialPackagingOption = {
        packaging: "",
        price: "",
        pkgUnit: ""
    };

    const initialFormData = {
        image: "",
        quantity: "",
        enableLogistic: false,
        packaging: "",
        pkgUnit: "",
        category: "",
        gst: "",
        productId: "",
        productName: "",
        publishOnApp: false,
        inStock: true,
        productDescription: "",
        brand: "Whyte Farms",
        packagingOptions: [], // Initialize packagingOptions as an empty array
        // packagingOptions: [initialPackagingOption],
        launchDate: new Date(),
        updated_date: new Date(),
        created_date: new Date(),

    };

    const [formData, setFormData] = useState(initialFormData);

    // const [formData, setFormData] = useState({
    //     image: "",
    //     quantity: "",
    //     enableLogistic: false,
    //     packaging: "",
    //     pkgUnit: "",
    //     category: "",
    //     gst: "",
    //     productId: "",
    //     productName: "",
    //     publishOnApp: false,
    //     inStock: true,
    //     productDescription: "",
    //     brand: "Whyte Farms",
    //     packagingOptions: [initialPackagingOption],
    //     launchDate: new Date(),
    //     updated_date: new Date(),
    //     created_date: new Date(),
    // });


    const openModal = () => {
        // alert("ooo")
        window.modelshow();
    }

    const closeModal = () => {
        window.modalHide();
    }



    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        if (type === "checkbox") {
            setFormData(prevState => ({
                ...prevState,
                [name]: checked
            }));
        } else if (type === "file") {
            // File input, handle files separately
            const file = files[0]; // Assuming single file selection
            // You may want to perform additional checks or validation on the file here
            setFormData(prevState => ({
                ...prevState,
                [name]: file
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const handlePackagingOptionChange = (e, index) => {
        const { name, value } = e.target;
        const updatedPackagingOptions = [...formData.packagingOptions];
        updatedPackagingOptions[index][name] = value;
        setFormData({
            ...formData,
            packagingOptions: updatedPackagingOptions
        });
    };



    const handleAddPackagingOption = () => {
        setFormData(prevState => ({
            ...prevState,
            packagingOptions: [...prevState.packagingOptions, { packaging: "", price: "", pkgUnit: "" }]
        }));
    };

    const handleRemovePackagingOption = (index) => {
        setFormData(prevState => ({
            ...prevState,
            packagingOptions: prevState.packagingOptions.filter((_, i) => i !== index)
        }));
    };


    function generateUniqueId() {
        const now = new Date();
        const timestamp = now.getTime();
        const random4Digits = Math.floor(Math.random() * 10000)
            .toString()
            .padStart(4, "0");
        const customerId =
            (timestamp % 10000).toString().padStart(4, "0") + random4Digits;

        return customerId;
    }

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log(formData)
    //     // Add your logic here to submit the form data to Firebase or wherever you want to store it
    //     // For example:
    //     db.collection("products_data").add(formData)
    //         .then(() => {
    //             console.log("Product added successfully!");
    //             // Reset the form after successful submission
    //             setFormData({
    //                 image: "",
    //                 quantity: "",
    //                 enableLogistic: false,
    //                 packaging: "",
    //                 pkgUnit: "",
    //                 category: "",
    //                 gst: "",
    //                 productId: "",
    //                 productName: "",
    //                 publishOnApp: false,
    //                 inStock: true,
    //                 productDescription: "",
    //                 packagingOptions: [initialPackagingOption]
    //             });
    //         })
    //         .catch((error) => {
    //             console.error("Error adding product: ", error);
    //         });
    // };

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log(formData.packagingOptions.length);

        if (formData.packagingOptions.length === 0) {
            const Toast = Swal.mixin({
                toast: true,
                background: '#69aba6',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'warning',
                title: 'Please add packaging option'
            })

        } else {
            if (edit) {
                if (formData.image && typeof formData.image !== "string") {
                    // New image selected, upload it to Firebase Storage
                    const storageRef = ref(storage, `files/${formData.image.name}`);
                    const uploadTask = uploadBytesResumable(storageRef, formData.image);

                    uploadTask.on(
                        "state_changed",
                        snapshot => {
                            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        },
                        error => {
                            console.error("Error uploading image: ", error);
                        },
                        () => {
                            // File uploaded successfully, now obtain the download URL
                            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                // Update product data in Firestore with the new image URL
                                db.collection("products_data").doc(editID).update({
                                    ...formData,
                                    image: downloadURL // Replace image with the download URL of the new image
                                })
                                    .then(() => {
                                        console.log("Product updated successfully!");
                                        // Reset the form after successful submission
                                        setFormData({
                                            image: "",
                                            quantity: "",
                                            enableLogistic: false,
                                            packaging: "",
                                            pkgUnit: "",
                                            category: "",
                                            gst: "",
                                            productId: "",
                                            productName: "",
                                            publishOnApp: false,
                                            inStock: true,
                                            productDescription: "",
                                            brand: "Whyte Farms",
                                            packagingOptions: [initialPackagingOption],
                                            launchDate: new Date(),
                                            updated_date: new Date(),
                                            created_date: new Date(),
                                        });
                                        closeModal()
                                    })
                                    .catch(error => {
                                        console.error("Error updating product: ", error);
                                    });
                            })
                                .catch(error => {
                                    console.error("Error getting download URL: ", error);
                                });
                        }
                    );
                } else {
                    // No new image selected, update product data without changing the image URL
                    db.collection("products_data").doc(editID).update(formData)
                        .then(() => {
                            console.log("Product updated successfully!");
                            // Reset the form after successful submission
                            setFormData({
                                image: "",
                                quantity: "",
                                enableLogistic: false,
                                packaging: "",
                                pkgUnit: "",
                                category: "",
                                gst: "",
                                productId: "",
                                productName: "",
                                publishOnApp: false,
                                inStock: true,
                                productDescription: "",
                                brand: "Whyte Farms",
                                packagingOptions: [initialPackagingOption],
                                launchDate: new Date(),
                                updated_date: new Date(),
                                created_date: new Date(),
                            });
                            closeModal()
                        })
                        .catch(error => {
                            console.error("Error updating product: ", error);
                        });
                }
            }
            else {
                // Upload image file to Firebase Storage if formData.image exists
                if (formData.image) {

                    const storageRef = ref(storage, `files/${formData.image.name}`);
                    const uploadTask = uploadBytesResumable(storageRef, formData.image);

                    uploadTask.on(
                        "state_changed",
                        snapshot => {
                            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        },
                        error => {
                            console.error("Error uploading image: ", error);
                        },
                        () => {
                            // File uploaded successfully, now add form data to Firestore
                            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                // Add form data to Firestore
                                return db.collection("products_data").add({
                                    ...formData,
                                    productId: generateUniqueId(),
                                    image: downloadURL // Replace image with the download URL from Firebase Storage
                                });
                            })
                                .then(() => {
                                    // document.getElementById("image-input").value = "";
                                    // Reset the form after successful submission
                                    setFormData({
                                        image: "",
                                        quantity: "",
                                        enableLogistic: false,
                                        packaging: "",
                                        pkgUnit: "",
                                        category: "",
                                        gst: "",
                                        productId: "",
                                        productName: "",
                                        publishOnApp: false,
                                        inStock: true,
                                        productDescription: "",
                                        brand: "Whyte Farms",
                                        packagingOptions: [initialPackagingOption],
                                        launchDate: new Date(),
                                        updated_date: new Date(),
                                        created_date: new Date(),
                                    });
                                    closeModal()
                                    console.log("Product added successfully!");
                                    // Clear the file input field

                                })
                                .catch(error => {
                                    console.error("Error adding product: ", error);
                                });
                        }
                    );
                } else {
                    // No image to upload, directly add form data to Firestore
                    db.collection("products_data")
                        .add({ ...formData, productId: generateUniqueId() })
                        .then(() => {
                            console.log("Product added successfully!");
                            // Reset the form after successful submission
                            setFormData({
                                image: "",
                                quantity: "",
                                enableLogistic: false,
                                packaging: "",
                                pkgUnit: "",
                                category: "",
                                gst: "",
                                productId: "",
                                productName: "",
                                publishOnApp: false,
                                inStock: true,
                                productDescription: "",
                                brand: "Whyte Farms",
                                packagingOptions: [initialPackagingOption],
                                launchDate: new Date(),
                                updated_date: new Date(),
                                created_date: new Date(),
                            });
                            closeModal()
                        })
                        .catch(error => {
                            console.error("Error adding product: ", error);
                        });
                }
            }
        }




    };




    const addNew = () => {
        setEdit(false);
        // setEdit(false)
        openModal();
        // setSubmit({ parent_category: '', product_category: '', category_order: '', category_image: '', status: '', updated_date: new Date(), created_date: new Date() });
    }

    const editData = (id, data, selectedOption) => {
        console.log(selectedOption)
        setEdit(true)
        setEditID(id)
        setFormData({
            image: data.image,
            quantity: data.quantity,
            enableLogistic: data.enableLogistic,
            packaging: data.packaging,
            pkgUnit: data.pkgUnit,
            category: data.category,
            gst: data.gst,
            productId: data.productId,
            productName: data.productName,
            publishOnApp: data.publishOnApp,
            inStock: data.inStock,
            productDescription: data.productDescription,
            brand: data.brand,
            packagingOptions: selectedOption,
            updated_date: new Date(),
        });
        openModal();
    }


    useEffect(() => {
        const getCurrentTimeOfDay = () => {
            const currentTime = new Date().getHours();
            if (currentTime < 12) {
                setTimeOfDay('Good Morning');
            } else if (currentTime >= 12 && currentTime < 18) {
                setTimeOfDay('Good Afternoon');
            } else {
                setTimeOfDay('Good Evening');
            }
        };

        getCurrentTimeOfDay();
    }, []);

    const [products, setProducts] = useState([]);

    useEffect(() => {
        const unsubscribe = db.collection("products_data").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setProducts(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                    selectedOption: doc.data().packagingOptions[0] // Initialize selected option with the first option
                }))
            );
        });
        console.log(products)

        return () => unsubscribe(); // Unsubscribe from snapshot listener when component unmounts
    }, []);

    const handleOptionChange = (productId, option) => {
        console.log(productId)
        setProducts(prevProducts => prevProducts.map(product =>
            product.id === productId ? { ...product, selectedOption: option } : product
        ));
    };


    const deleteData = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                db.collection("products_data").doc(id).delete().then(() => {
                    console.log('Deleted Successfully');
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    )
                })

            }
        })

    }

    const viewData = (id) => {
        if (viewID === id) {
            setViewID("")
        } else {

            setViewID(id)
        }
    }





    return (
        <>

            <div class="container-scroller">
                <TopPanel />

                <div class="container-fluid page-body-wrapper">
                    <Sidebar />



                    <div class="main-panel">
                        <div class="content-wrapper">

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="home-tab">
                                        <div class="d-sm-flex align-items-center justify-content-between border-bottom">
                                            <ul class="nav nav-tabs" role="tablist">

                                            </ul>
                                            <div>

                                                <div class="btn-wrapper">
                                                    <a href="#" class="btn btn-primary text-white me-0 mr-2" onClick={() => addNew()}><i class="icon-add"></i>Add Products</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 grid-margin stretch-card">
                                <div class="card">
                                    <div class="card-body">
                                        <div className="row">

                                            {products?.map(({ id, data, selectedOption }, index) => (
                                                <>
                                                    <div key={id} className="col-md-4 grid-margin stretch-card">
                                                        <div className="card">

                                                            {viewID === id ?

                                                                <>
                                                                    <div className="card-body">
                                                                        <div className="d-sm-flex flex-row text-start align-items-center">
                                                                            <div className="ms-sm-3 ms-md-0 ms-xl-3 mt-2 mt-sm-0 mt-md-2 mt-xl-0">
                                                                                <p className="text-muted mb-1"><b>Product:</b> {data.productName}</p>
                                                                                <p className="text-muted mb-1"><b>GST:</b> {data.gst}</p>
                                                                                <p className="text-muted mb-1"><b>Brand:</b> {data.brand}</p>
                                                                                <p className="text-muted mb-1"><b>Description:</b> {data.productDescription}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="" style={{ textAlign: "end" }}>
                                                                            <button style={{ backgroundColor: "white", padding: "0.2rem 0.25rem" }} class="btn btn-sm" onClick={() => deleteData(id)}><i class="menu-icon mdi mdi-delete" style={{ color: "red" }}></i></button>
                                                                            <button style={{ backgroundColor: "white", padding: "0.2rem 0.25rem" }} class="btn btn-sm" onClick={() => editData(id, data, data.packagingOptions)}><i class="menu-icon mdi mdi-pencil" style={{ color: "#34b1aa" }}></i></button>
                                                                            <button style={{ backgroundColor: "white", padding: "0.2rem 0.25rem" }} class="btn btn-sm" onClick={() => viewData(id)}><i class="menu-icon mdi mdi-eye-off" style={{ color: "#34b1aa" }}></i></button>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="card-body">
                                                                        <div className="d-sm-flex flex-row text-start align-items-center">
                                                                            <img src={data.image} className="img-lg rounded" alt="profile image" />
                                                                            <div className="ms-sm-3 ms-md-0 ms-xl-3 mt-2 mt-sm-0 mt-md-2 mt-xl-0">
                                                                                <h6 className="mb-0">₹ {selectedOption.price}</h6>
                                                                                <p className="text-muted mb-1">{data.productName}</p>
                                                                                <p className="mb-0 text-success fw-bold">{selectedOption.packaging} {selectedOption.pkgUnit} | {data.category}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="dropdown" style={{ textAlign: "end" }}>
                                                                            <button className="btn btn-light dropdown-toggle toggle-dark btn-xs mb-0 me-0"
                                                                                type="button" id={`dropdownMenuButton_${data.id}`} data-bs-toggle="dropdown"
                                                                                aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "#68bcbc", color: "white", textAlign: "end", height: "24px" }}>Qty</button>
                                                                            <div className="dropdown-menu" aria-labelledby={`dropdownMenuButton_${id}`}>
                                                                                {data.packagingOptions.map((option, index) => (
                                                                                    <a key={index} className="dropdown-item" href="#" onClick={() => handleOptionChange(id, option)}>{option.packaging} {option.pkgUnit}</a>
                                                                                ))}
                                                                            </div>

                                                                            <button style={{ backgroundColor: "white", padding: "0.2rem 0.25rem" }} class="btn btn-sm" onClick={() => viewData(id)}><i class="menu-icon mdi mdi-eye" style={{ color: "#34b1aa" }}></i></button>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }


                                                        </div>
                                                    </div>

                                                </>
                                            ))}
                                            {/* {products.map(product => (
                                                <div key={product.id} className="col-md-4 grid-margin stretch-card">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="d-sm-flex flex-row text-start align-items-center">
                                                                <img src={product.data.image} className="img-lg rounded" alt="profile image" />
                                                                <div className="ms-sm-3 ms-md-0 ms-xl-3 mt-2 mt-sm-0 mt-md-2 mt-xl-0">
                                                                    <h6 className="mb-0">₹ {product.selectedOption.price}</h6>
                                                                    <p className="text-muted mb-1">{product.data.productName}</p>
                                                                    <p className="mb-0 text-success fw-bold">{product.selectedOption.packaging} {product.selectedOption.pkgUnit} | {product.data.category}</p>
                                                                </div>
                                                            </div>
                                                            <div className="dropdown" style={{ textAlign: "end" }}>
                                                                <button className="btn btn-light dropdown-toggle toggle-dark btn-xs mb-0 me-0"
                                                                    type="button" id={`dropdownMenuButton_${product.id}`} data-bs-toggle="dropdown"
                                                                    aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "#68bcbc", color: "white", textAlign: "end", height: "24px" }}>Qty</button>
                                                                <div className="dropdown-menu" aria-labelledby={`dropdownMenuButton_${product.id}`}>
                                                                    {product.data.packagingOptions.map((option, index) => (
                                                                        <a key={index} className="dropdown-item" href="#" onClick={() => handleOptionChange(product.id, option)}>{option.packaging} {option.pkgUnit}</a>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))} */}
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>


                        <div class="modal fade" id="exampleModal-2" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel-2" aria-hidden="true">
                            <div class="modal-dialog modal-lg" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel-2">Add Product</h5>
                                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={handleSubmit}>
                                            <div class="form-group row">
                                                <div class="col">
                                                    <label>Image URL:</label>
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        style={{ padding: "10px" }}
                                                        name="image"
                                                        // Note: value should not be set for file inputs, it's read-only
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div class="col">
                                                    <label>Quantity:</label>
                                                    <input class="form-control" type="number" name="quantity" value={formData.quantity} onChange={handleChange} required />
                                                </div>
                                                <div class="col">
                                                    <label>Product Description:</label>
                                                    <input class="form-control" type="text" name="productDescription" value={formData.productDescription} onChange={handleChange} required />
                                                </div>

                                            </div>

                                            <div class="form-group row">
                                                <div class="col">
                                                    <label>Packaging:</label>
                                                    <input class="form-control" type="text" name="packaging" value={formData.packaging} onChange={handleChange} required />
                                                </div>
                                                <div class="col">
                                                    <label>Package Unit:</label>
                                                    <input class="form-control" type="number" name="pkgUnit" value={formData.pkgUnit} onChange={handleChange} required />
                                                </div>
                                                <div class="col">
                                                    <label>Category:</label>
                                                    <input class="form-control" type="text" name="category" value={formData.category} onChange={handleChange} required />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <div class="col">
                                                    <label>GST:</label>
                                                    <input class="form-control" type="text" name="gst" value={formData.gst} onChange={handleChange} required />
                                                </div>
                                                <div class="col">
                                                    <label>brand:</label>
                                                    <input class="form-control" type="text" name="brand" value={formData.brand} onChange={handleChange} required />
                                                </div>
                                                <div class="col">
                                                    <label>Product Name:</label>
                                                    <input class="form-control" type="text" name="productName" value={formData.productName} onChange={handleChange} required />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col">
                                                    <input
                                                        type="checkbox"
                                                        id="publishOnApp"
                                                        name="publishOnApp"
                                                        checked={formData.publishOnApp} // Bind the checked state to the submitUser object
                                                        onChange={handleChange} // Update the state when the checkbox is toggled

                                                    />
                                                    <label htmlFor="cash_collector" style={{ marginLeft: '0.5rem' }}>Publish On App:</label>
                                                </div>
                                                <div class="col">
                                                    <input
                                                        type="checkbox"
                                                        id="inStock"
                                                        name="inStock"
                                                        checked={formData.inStock} // Bind the checked state to the submitUser object
                                                        onChange={handleChange} // Update the state when the checkbox is toggled
                                                    />
                                                    <label htmlFor="cash_collector" style={{ marginLeft: '0.5rem' }}>In Stock:</label>
                                                </div>
                                                <div class="col">
                                                    <input
                                                        type="checkbox"
                                                        id="enableLogistic"
                                                        name="enableLogistic"
                                                        checked={formData.enableLogistic}// Bind the checked state to the submitUser object
                                                        onChange={handleChange} // Update the state when the checkbox is toggled
                                                    />
                                                    <label htmlFor="cash_collector" style={{ marginLeft: '0.5rem' }}>Enable Logistic:</label>
                                                </div>

                                            </div>


                                            {/* Add other input fields for each property in the formData state */}
                                            {/* Packaging Options */}
                                            <label style={{ marginBottom: "1rem" }}>Packaging Options:   <button type="button" style={{ marginLeft: "2rem", background: "#34b1aa" }} class="btn btn-dark btn-sm" onClick={handleAddPackagingOption}> + Add Packaging Option</button></label>
                                            {formData.packagingOptions.map((option, index) => (
                                                <div class="form-group row" key={index} style={{ textAlign: "end" }}>
                                                    <div class="col">

                                                        <input
                                                            type="text"
                                                            name="packaging"
                                                            value={option.packaging}
                                                            class="form-control"
                                                            onChange={(e) => handlePackagingOptionChange(e, index)}
                                                            placeholder="Packaging"
                                                            required
                                                        />
                                                    </div>
                                                    <div class="col">
                                                        <input
                                                            type="number"
                                                            name="price"
                                                            value={option.price}
                                                            class="form-control"
                                                            onChange={(e) => handlePackagingOptionChange(e, index)}
                                                            placeholder="Price"
                                                            required
                                                        />
                                                    </div>
                                                    <div class="col">
                                                        {/* <input
                                                            type="text"
                                                            name="pkgUnit"
                                                            value={option.pkgUnit}
                                                            class="form-control"
                                                            onChange={(e) => handlePackagingOptionChange(e, index)}
                                                            placeholder="Package Unit"
                                                            required
                                                        /> */}
                                                        <select
                                                            name="pkgUnit"
                                                            value={option.pkgUnit}
                                                            onChange={(e) => handlePackagingOptionChange(e, index)}
                                                            required
                                                            class="form-control"
                                                        >
                                                            <option value="ltr">Liter (ltr)</option>
                                                            <option value="ml">Milliliter (ml)</option>
                                                            <option value="kg">Kilogram (kg)</option>
                                                            <option value="g">Gram (g)</option>
                                                            <option value="pieces">Pieces</option>
                                                        </select>
                                                        <button type="button" class="btn btn-dark btn-sm" style={{ marginTop: "1rem" }} onClick={() => handleRemovePackagingOption(index)}>Remove</button>
                                                    </div>
                                                </div>
                                            ))}


                                            <br />
                                            <div class="" style={{ display: "flex", justifyContent: "end" }}>
                                                <button type="submit" class="btn btn-success btn-sm">{edit ? "Update" : "Submit"}</button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>



                        <Footer />

                    </div>

                </div>

            </div>
        </>
    )
}

export default Product
