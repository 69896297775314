import React, { useState, useEffect } from 'react';
import Moment from "moment";
import { extendMoment } from "moment-range";
import db from './Firebase';
import ExportTableToExcel from './ExportTableToExcel';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function PredictiveAnalysis () {
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    console.log(loggedIn);
    if (loggedIn) {
      // navigate("/");
    } else {
      navigate("/login");
    }
  }, [navigate]);
  const [productNames, setProductNames] = useState([]);
  const [productList, setProductList] = useState([]);
  const moment = extendMoment(Moment);
  const [cummulativeHubDeliveryList, setCummulativeHubDeliveryList] = useState([]);
  const [cummulativeHubDeliveryListCustom , setCummulativeHubDeliveryListCustom] = useState([]);
  // const [loadCummulativeData, setLoadCummulativeData] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  //const [analysisDate, setAnalysisDate] = useState(new Date());
  const [fileName, setFileName] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedPackaging, setSelectedPackaging] = useState();
  const [packagingOptions, setPackagingOptions] = useState([]);
  const [day, setDay] = useState('');
  const [calendarMap, setCalendarMap] = useState(new Map());

    const getProductNames = () => {
        db.collection("products_data").onSnapshot((snapshot) => {
            setProductNames(
              snapshot.docs.map((doc) => ({ label: doc.data().productName, value: doc.data().productName }))
            );
            const data = snapshot.docs.map(doc => doc.data());
            if (data.length > 0) {
              setProductList(data);
            }
          });
    }

    const fetchBulkQty = async () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        const bulk_calendar_snap = await db.collection('bulk_update_quantity')
            .where('delivery_date', '==', moment(tomorrow).format('YYYY-MM-DD'))
            .get();
        
        const calendar_data = bulk_calendar_snap.docs.map(doc => doc.data());
        const newMap = new Map();
        calendar_data.forEach(data => {
            const value = data.quantity;
            newMap.set(data.subscription_id, value);
        })
        console.log("New Map => " , newMap);
        setCalendarMap(newMap);
        return newMap;
    }

    const checkVacationStatus = async () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0,0,0,0);
        const endOfTomorrow =  new Date(tomorrow);
        endOfTomorrow.setHours(23,59,59,999);
        
        try {
            const vacationQuerySnapshot = await db.collection('customers_vacation')
                .where('start_date', '>=', tomorrow)
                .where('end_date' , '<=' , endOfTomorrow)
                .get();

            const vacation_data = vacationQuerySnapshot.docs.map(doc => doc.data());
            const newMap = new Map();
            vacation_data.forEach(data => {
                    newMap.set(data.customer_id, true);
                })
                return newMap;
        } catch (error) {
            console.error('Error fetching vacation data:', error);
        }
    };

    

    const calculatePredictAnalysisQty = async () => {
        setLoading(true);
        let bulk_Quantity_map = new Map();
        let vacation_map = new Map();
        const customSubscriptionList = [];
        const nonCustomSubscriptionList = [];
        bulk_Quantity_map = await fetchBulkQty();
        vacation_map = await checkVacationStatus();
        console.log("vacation map size =>", vacation_map.size);
        
        try {
            // const productSnap = await db.collection('products_data').get();
            // const productList = productSnap.docs.map(doc => doc.data());
            
            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate() + 1);
            const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            const dayOfWeekIndex = tomorrow.getDay();
            const dayOfWeek = weekdays[dayOfWeekIndex];
            console.log("day of week", dayOfWeek);
    
            
            const customSnapshot = await db.collection('subscriptions_data')
                .where(dayOfWeek, '>=', 1)
                .where('subscription_type', '==', 'Custom')
                .where('status', '==', '1')
                .get();
            
            
            customSnapshot.forEach(doc => {
                const subscription = doc.data();
                if (!vacation_map.has(subscription.customer_id)) {
                    if (bulk_Quantity_map.has(subscription.subscription_id)) {
                        subscription.quantity = bulk_Quantity_map.get(subscription.subscription_id);
                    } else {
                        subscription.quantity = subscription[dayOfWeek];
                    }
                    customSubscriptionList.push(subscription);
                }
            });
    
            
            const nonCustomSubSnapshot = await db.collection('subscriptions_data')
                .where('subscription_type', '!=', 'Custom')
                .where('status', '==', '1')
                .where('next_delivery_date', '==', moment(tomorrow).format('YYYY-MM-DD'))
                .get();
            
            let i =0;
            nonCustomSubSnapshot.forEach(doc => {
                const subscription = doc.data();
                if (!vacation_map.has(subscription.customer_id)) {
                    if (bulk_Quantity_map.has(subscription.subscription_id)) {
                        subscription.quantity = bulk_Quantity_map.get(subscription.subscription_id);
                        if(typeof bulk_Quantity_map.get(subscription.subscription_id) == 'string') {
                          console.log(subscription);
                        }
                    }
                    nonCustomSubscriptionList.push(subscription);
                }
            });
    
            
            const combinedSubscriptionList = [...customSubscriptionList, ...nonCustomSubscriptionList];
    
            const productPackagingMap = new Map();

            for (const sub of combinedSubscriptionList) {
              console.log(typeof sub.quantity);
            }
    
            
            combinedSubscriptionList.forEach(subscription => {
                const key = `${subscription.product_name}-${subscription.package_unit}-${subscription.price}`;
                if (productPackagingMap.has(key)) {
                    productPackagingMap.set(key, productPackagingMap.get(key) + subscription.quantity);
                } else {
                    productPackagingMap.set(key, subscription.quantity);
                }
            });
    
            
            const cumulativeList = [];
            for (const [key, quantity] of productPackagingMap.entries()) {
                const [productName, packageUnit, price] = key.split('-');
                cumulativeList.push({
                    product_name: productName,
                    packaging: packageUnit,
                    unit_price: parseFloat(price),
                    quantity: quantity,
                    analysisDate: moment(tomorrow).format('DD-MM-YYYY'),
                    day: dayOfWeek
                });
            }
    
            setCummulativeHubDeliveryList(cumulativeList);
            console.log(cumulativeList);
            setDataLoaded(true);
            createFileName();
    
        } catch (error) {

            console.error("Error calculating cumulative report:", error);
        } finally {
            setLoading(false);
        }
    };
    

    useEffect (()=> {
       calculatePredictAnalysisQty();

    },[]);


    const createFileName = () => {
        const today= new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        const startDateString = moment(tomorrow).format('DD-MM-YYYY');
        setFileName(`Hub_deliveries_${startDateString}`);
      }
      /** export pdf */
      const exportToPDF = () => {
        const doc = new jsPDF();
        const tableColumn = [
          "SR.NO",
          "Date",
          "Day",
          "Product",
          "Packaging",
          "Unit Price",
          "Total Quantity	",
        ];
        const tableRows = cummulativeHubDeliveryList.map((customer ,index) => [
            index + 1,
            customer.analysisDate,
            customer.day,
            customer.product_name,
            customer.packaging,
            customer.unit_price,
            customer.quantity
        ]);
        doc.autoTable({
          head: [tableColumn],
          body: tableRows,
        });
    
        doc.save("PredictiveReport.pdf");
      };


      const exportToCSV = () => {
        const csvRows = [];
        // Add header
        csvRows.push(['Date', 'Day', 'Product', 'Packaging', 'Unit Price', 'Total Quantity'].join(','));

        // Add data
        for (const delivery of cummulativeHubDeliveryList) {
            const row = [
                delivery.analysisDate,
                delivery.day,
                delivery.product_name,
                delivery.packaging,
                delivery.unit_price,
                delivery.quantity
            ].join(',');
            csvRows.push(row);
        }

        // Create a CSV blob and download it
        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', `${fileName}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

  return (
    <>
      {loading && ( // Render loader when loading state is true
        <div className="loader-overlay">
          <div className="">
            <img style={{
              height: "6rem"
            }} src="images/loader.gif" alt=""></img>
          </div>
        </div>
      )}
      <div class="container-scroller">
        <div class="container-fluid">
          <div class="main-panel" style={{ width: '100%' }}>
          <div className='panel' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>Predictive Analysis</span>
            <div style={{ display: 'flex', gap: '10px' }}>
              {dataLoaded && (
                <ExportTableToExcel
                  tableId="predictive_analysis"
                  fileName={fileName}
                />
              )}
              <button className="btn btn-success btn-rounded btn-sm mt-1" onClick={exportToPDF}>Export to PDF</button>
              <button className="btn btn-success btn-rounded btn-sm mt-1" onClick={exportToCSV}>Export to CSV</button>
            </div>
          </div>

            <br />
            {}
            <table class="table table-striped" id="predictive_analysis">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Day</th>
                  <th>Product</th>
                  <th>Packaging</th>
                  <th>Unit Price</th>
                  <th>Total Quantity</th>
                </tr>
              </thead>
              <tbody>
                {dataLoaded &&
                  cummulativeHubDeliveryList.map((delivery, index) => (
                    <tr key={index}>
                      <td>{delivery.analysisDate}</td>
                      <td>{delivery.day}</td>
                      <td>{delivery.product_name}</td>
                      <td>{delivery.packaging}</td>
                      <td>{delivery.unit_price}</td>
                      <td>{delivery.quantity}</td>

                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>




    </>
  )
}

export default PredictiveAnalysis
