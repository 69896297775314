import React, { useState, useEffect } from 'react';
import { create_record, update_record } from '../../helpers';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';

const DispositionForm = ({ setShowForm, existingDispositions, setExistingDispositions, editIndex, setEditIndex, setActivePopup }) => {
  const [name, setName] = useState('');
  const [subdispositions, setSubdispositions] = useState([]);
  const [subdispositionName, setSubdispositionName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editIndex !== null) {
      const disposition = existingDispositions[editIndex];
      setName(disposition.data.name);
      setSubdispositions(disposition.data.subdispositions);
    }
  }, [editIndex, existingDispositions]);

  const addSubdisposition = () => {
    if (subdispositionName.trim() !== '') {
      setSubdispositions([...subdispositions, subdispositionName]);
      setSubdispositionName('');
    }
  };

  const removeSubdisposition = (index) => {
    setSubdispositions(subdispositions.filter((_, subIndex) => subIndex !== index));
  };

  const saveDisposition = () => {
    setLoading(true);
    if (name.trim() !== '') {
      if (editIndex !== null) {
        let update_disp_data = {
          name: name,
          subdispositions: subdispositions
        };
        update_record('dispositions', existingDispositions[editIndex].id, update_disp_data).then((data) => {
          if (data) {
            Swal.fire({
              title: "Disposition Updated",
              text: "The disposition has been updated successfully.",
              icon: 'success',
              confirmButtonText: 'OK'
            });
          } else {
            console.log('Error');
          }
        });
        setEditIndex(null);
      } else {
        let dispo_data = {
          name: name,
          subdispositions: subdispositions
        };
        create_record('dispositions', dispo_data).then((data) => {
          if (data) {
            Swal.fire({
              title: "Disposition Added",
              text: "The disposition has been added successfully.",
              icon: 'success',
              confirmButtonText: 'OK'
            });
            // Handle success
          } else {
            console.log('Error');
          }
        });
      }
      setName('');
      setSubdispositions([]);
      setShowForm(false);
      setActivePopup('');
    }
  };



  return (
    <div className="">
      <div className="">
        <h5 className="">{editIndex !== null ? 'Edit Disposition' : 'Add Disposition'}</h5>
        <div className="form-group">
          <label>Disposition Name:</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Subdisposition Name:</label>
          <input
            type="text"
            className="form-control"
            value={subdispositionName}
            onChange={(e) => setSubdispositionName(e.target.value)}
          />
          <button className="btn btn-secondary mt-2" onClick={addSubdisposition}>Add Subdisposition</button>
        </div>
        <div>
          <h6>Subdispositions:</h6>
          <div className="d-flex flex-wrap mt-2" style={{ gap: "5px" }}>
            {subdispositions.map((sub, subIndex) => (
              <span key={subIndex} className="subdispo_chip">
                {sub}
                <button
                  type="button"
                  className="remove_subdispo"
                  aria-label="Close"
                  onClick={() => removeSubdisposition(subIndex)}
                  
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </span>
            ))}
          </div>
        </div>
        <button className="btn btn-primary mt-3" onClick={saveDisposition} disabled={loading}>
          {loading ? <><Spinner animation="border" size="sm" /> Processing</>:<></>}
          {editIndex !== null ? 'Update Disposition' : 'Save Disposition'}
        </button>
        <button className="btn btn-danger mt-3 ml-2 mx-2" onClick={() => setShowForm(false)}>Cancel</button>
      </div>
    </div>
  );
};

export default DispositionForm;