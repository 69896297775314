import React, { useState, useEffect } from "react";

import DatePicker from 'react-datepicker';
import db from "./Firebase";
import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import './subscripation.css';
import { Button, Alert, Spinner } from "react-bootstrap"; // Import Spinner for loader
import moment from "moment"; // Import moment for date formatting
import Select from "react-select"; // Import Select for multi-select
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const SubscriptionReport = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedHub, setSelectedHub] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedSubscriptionType, setSelectedSubscriptionType] =
    useState(null);
  const [customerNumber, setCustomerNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [data, setData] = useState([]);
  const [hubOptions, setHubOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [showNoDataFound, setShowNoDataFound] = useState(false);
  const [loading, setLoading] = useState(false); 

  const statusOptions = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ];

  const subscriptionTypeOptions = [
    { value: "Everyday", label: "Everyday" },
    { value: "Custom", label: "Custom" },
    { value: "On-Interval", label: "On-Interval" },
    { value: "One Time", label: "One Time" },
  ];

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        // Fetch hubs
        const hubQuerySnapshot = await getDocs(collection(db, "hubs_data"));
        const hubOptions = hubQuerySnapshot.docs.map((doc) => ({
          value: doc.data().hub_name,
          label: doc.data().hub_name,
        }));
        setHubOptions(hubOptions);

        // Fetch products
        const productQuerySnapshot = await getDocs(
          collection(db, "products_data")
        );
        const productOptions = productQuerySnapshot.docs.map((doc) => ({
          value: doc.data().productName,
          label: doc.data().productName,
        }));
        setProductOptions(productOptions);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    if (!loggedIn) {
      navigate("/login");
    }
  }, [navigate]);

  const handleSearch = async () => {
    // Check if endDate is less than startDate
    if (endDate && startDate && endDate < startDate) {
      alert("End date cannot be earlier than start date.");
      return; // Stop the execution if the validation fails
    }

    setLoading(true); // Set loading to true when search starts

    const subscriptionsRef = collection(db, "subscriptions_data");
    let filters = [];

    // Apply filters for the subscription
    if (selectedProduct.length > 0)
      filters.push(
        where(
          "product_name",
          "in",
          selectedProduct.map((p) => p.value)
        )
      );
    if (selectedHub.length > 0)
      filters.push(
        where(
          "hub_name",
          "in",
          selectedHub.map((h) => h.value)
        )
      );
    if (customerNumber)
      filters.push(where("customer_phone", "==", customerNumber));
    if (customerName) filters.push(where("customer_name", "==", customerName));
    if (startDate)
      filters.push(where("start_date", ">=", Timestamp.fromDate(startDate)));
    if (endDate)
      filters.push(where("end_date", "<=", Timestamp.fromDate(endDate)));
    if (selectedStatus) filters.push(where("status", "==", selectedStatus));
    if (selectedSubscriptionType)
      filters.push(where("subscription_type", "==", selectedSubscriptionType));

    try {
      const q = query(subscriptionsRef, ...filters);
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setShowNoDataFound(true);
        setData([]);
        setLoading(false); // Set loading to false after fetching data
        return;
      }

      let subscriptionData = querySnapshot.docs.map((doc) => doc.data());
      setData(subscriptionData);
      setShowNoDataFound(subscriptionData.length === 0);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setCustomerNumber("");
    setCustomerName("");
    setStartDate(null);
    setEndDate(null);
    setSelectedHub([]);
    setSelectedProduct([]);
    setSelectedStatus(null);
    setSelectedSubscriptionType(null);
    setData([]);
  };

  const exportTableToPDF = () => {
    const doc = new jsPDF();
    doc.text("Subscription Report", 20, 20);

    const tableColumn = [
      "Customer Number",
      "Product Name",
      "Hub",
      "Customer Name",
      "Status",
      "Subscription Type",
      "Start Date",
      "End Date",
    ];
    const tableRows = data.map((item, index) => [
      item.customer_phone,
      item.product_name || "N/A",
      item.hub_name || "N/A",
      item.customer_name,
      item.status === "1" ? "Active" : "Inactive",
      item.subscription_type,
      item.start_date
        ? moment(item.start_date.toDate()).format("DD/MM/YYYY")
        : "-",
      item.end_date ? moment(item.end_date.toDate()).format("DD/MM/YYYY") : "-",
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30,
    });

    doc.save("subscription_report.pdf");
  };
  const exportTableToCSV = () => {
    // Prepare CSV headers
    const csvColumns = [
        "Customer Number",
        "Product Name",
        "Hub",
        "Customer Name",
        "Status",
        "Subscription Type",
        "Start Date",
        "End Date",
    ];

    // Prepare CSV rows
    const csvRows = data.map((item, index) => [
        item.customer_phone,
        item.product_name || "N/A",
        item.hub_name || "N/A",
        item.customer_name,
        item.status === "1" ? "Active" : "Inactive",
        item.subscription_type,
        item.start_date ? moment(item.start_date.toDate()).format("DD/MM/YYYY") : "-",
        item.end_date ? moment(item.end_date.toDate()).format("DD/MM/YYYY") : "-",
    ]);

    // Combine headers and rows into a single CSV content
    const csvContent = [
        csvColumns.join(","), // Join the header row
        ...csvRows.map(row => row.join(",")) // Join each data row
    ].join("\n"); // Join all rows with a newline character

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element for downloading
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "subscription_report.csv"); // Set the filename for the CSV
    link.style.visibility = 'hidden';

    // Append the link to the body
    document.body.appendChild(link);
    link.click(); // Simulate a click on the link
    document.body.removeChild(link); // Remove the link after download
};


  const exportTableToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      data.map((item) => ({
        "Customer Number": item.customer_phone,
        "Product Name": item.product_name || "N/A",
        Hub: item.hub_name || "N/A",
        "Customer Name": item.customer_name,
        Status: item.status === "1" ? "Active" : "Inactive",
        "Subscription Type": item.subscription_type,
        "Start Date": item.start_date
          ? moment(item.start_date.toDate()).format("DD/MM/YYYY")
          : "-",
        "End Date": item.end_date
          ? moment(item.end_date.toDate()).format("DD/MM/YYYY")
          : "-",
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Subscriptions");
    XLSX.writeFile(wb, "subscription_report.xlsx");
  };

  return (
    <>
      <div
        className="panel"
        style={{
          padding: "20px",
          backgroundColor: "#f8f9fa",
          borderRadius: "8px",
        }}
      >
        <div
          className="panel"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: "18px",
              color: "#288a84",
              fontWeight: "700",
              marginTop: "12px",
            }}
          >
            SUBSCRIPTION REPORT
          </span>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={exportTableToPDF}
              disabled={loading}
              className="btn btn-success btn-rounded btn-sm"
            >
              Export PDF
            </Button>
            <Button
              onClick={exportTableToExcel}
              disabled={loading}
              className="btn btn-success btn-rounded btn-sm"
            >
              Export Excel
            </Button>
            <Button
              onClick={exportTableToCSV}
              disabled={loading}
              className="btn btn-success btn-rounded btn-sm"
            > Export CSV
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            width: "100%",
          }}
        >
          {/* Customer Number */}
          <div style={{ flex: "1", minWidth: "200px" }}>
            <label>Customer Number:</label>
            <input
              type="text"
              value={customerNumber}
              onChange={(e) => setCustomerNumber(e.target.value)}
              placeholder="Enter customer number"
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ced4da",
              }}
            />
          </div>
          {/* Customer Name */}
          <div style={{ flex: "1", minWidth: "200px" }}>
            <label>Customer Name:</label>
            <input
              type="text"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              placeholder="Enter customer name"
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ced4da",
              }}
            />
          </div>

          {/* Status */}
          <div style={{ flex: "1", minWidth: "200px" }}>
            <label>Status:</label>
            <select
              onChange={(e) => setSelectedStatus(e.target.value)}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ced4da",
              }}
            >
              <option value="">Select status</option>
              {statusOptions.map((status) => (
                <option key={status.value} value={status.value}>
                  {status.label}
                </option>
              ))}
            </select>
          </div>

          {/* Hub */}
          <div style={{ flex: "1", minWidth: "200px" }}>
            <label>Hub:</label>
            <Select
              isMulti
              options={hubOptions}
              onChange={(selected) => setSelectedHub(selected)}
              placeholder="Select hub(s)"
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          {/* Subscription Type */}
          <div style={{ flex: "1", minWidth: "200px" }}>
            <label>Subscription Type:</label>
            <select
              onChange={(e) => setSelectedSubscriptionType(e.target.value)}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ced4da",
              }}
            >
              <option value="">Select subscription type</option>
              {subscriptionTypeOptions.map((subscriptionType) => (
                <option
                  key={subscriptionType.value}
                  value={subscriptionType.value}
                >
                  {subscriptionType.label}
                </option>
              ))}
            </select>
          </div>

          {/* Product */}
          <div style={{ flex: "1", minWidth: "200px" }} >
            <label>Product:</label>
            <Select
              isMulti
              options={productOptions}
              onChange={(selected) => setSelectedProduct(selected)}
              placeholder="Select product(s)"
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
            />
          </div>

          {/* Start Date */}
          <div style={{ flex: "1", minWidth: "200px", }} className="date-fix">
            <label>Start Date:</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              className="form-control"
              placeholderText="Select start date"
            />
          </div>

          {/* End Date */}
          <div style={{ flex: "1", minWidth: "200px" }} className="date-fix">
            <label>End Date:</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              className="form-control"
              placeholderText="Select end date"
             />
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <Button
            variant="outline-success"
            onClick={handleSearch}
            disabled={loading}
            className="justify-content-center align-items-center inputPanels"
          >
            {loading ? <Spinner animation="border" size="sm" /> : "Search"}
          </Button>
          <Button
            variant="outline-success"
            onClick={handleReset}
            style={{ marginLeft: "10px" }}
          >
            Reset
          </Button>
        </div>
      </div>

      <div className="result" style={{ marginTop: "20px" }}>
        {showNoDataFound ? (
          <p>No data found for the given filters.</p>
        ) : (
          <table
            className="table table-striped"
            style={{ width: "100%", marginTop: "20px" }}
          >
            <thead>
              <tr>
                <th>Customer Number</th>
                <th>Product Name</th>
                <th>Hub</th>
                <th>Customer Name</th>
                <th>Status</th>
                <th>Subscription Type</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>
              {data.length ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.customer_phone}</td>
                    <td>{item.product_name || "N/A"}</td>
                    <td>{item.hub_name || "N/A"}</td>
                    <td>{item.customer_name}</td>
                    <td>{item.status === "1" ? "Active" : "Inactive"}</td>
                    <td>{item.subscription_type}</td>
                    <td>
                      {item.start_date
                        ? moment(item.start_date.toDate()).format("DD/MM/YYYY")
                        : "-"}
                    </td>
                    {/* <td>{item.end_date ? moment(item.end_date.toDate()).format('DD/MM/YYYY') : '-'}</td> */}
                    <td>
                      {item.end_date
                        ? (() => {
                            const endDate = item.end_date.toDate();

                            // Convert the date to 'DD/MM/YYYY' format
                            const formattedEndDate =
                              moment(endDate).format("DD/MM/YYYY");
                            const specialDate1 = "31/12/3000";
                            const specialDate2 = "01/01/3000"; // Corresponds to the other check

                            // Check if formattedEndDate matches special dates
                            if (
                              formattedEndDate === specialDate1 ||
                              formattedEndDate === specialDate2
                            ) {
                              return "Until paused";
                            } else {
                              return formattedEndDate;
                            }
                          })()
                        : "-"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    {showNoDataFound
                      ? "No data found for the given filters."
                      : "No data available."}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default SubscriptionReport;
