import React from 'react';
import './ConversastionLogCard.css';

const ConversastionLogCard = ({ data }) => {
  const {
    updated_at,
    customer_email,
    customer_phone,
    followup_required,
    selected_products,
    call_type,
    customer_id,
    created_at,
    res_type,
    tags,
    created_by,
    sub_disposition,
    conversation_notes,
    follow_up_date,
    interaction_type,
    disposition,
    email_subject,
    task_id
  } = data;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    return date.toLocaleString();
  };

  return (
    <div className="c_card">
      <div className="c_card_body">
        <div className='flex_card_body'>
          <p className='card_hearder'>Interaction Type</p>
          <p className='card_value'>{interaction_type}</p>
        </div>

        {interaction_type === 'call' && (
          <div className='flex_card_body'>
            <p className='card_hearder'>Call Type</p>
            <p className='card_value'>{call_type}</p>
          </div>
        )}

        {interaction_type === 'email' && (
          <div className='flex_card_body'>
            <p className='card_hearder'>Email</p>
            <p className='card_value'>{email_subject}</p>
          </div>
        )}
        <div className='flex_card_body'>
          <p className='card_hearder'>Disposition</p>
          <p className='card_value'>{disposition}</p>
        </div>
        <div className='flex_card_body'>
          <p className='card_hearder'>Sub Disposition</p>
          <p className='card_value'>{sub_disposition}</p>
        </div>

        <div className='flex_card_body'>
          <p className='card_hearder'>Created by</p>
          <p className='card_value'>{created_by}</p>
        </div>

        <div className='flex_card_body'>
          <p className='card_hearder'>Follow up required</p>
          <p className='card_value'>{followup_required ? 'Yes' : 'No'}  {followup_required && <p><strong>Follow-up Date:</strong> {follow_up_date}</p>}</p>
          <span style={{
            fontSize:'12px'
          }}>TASK ID: {task_id? <>{task_id}</>:<></>}</span>
        </div>

        <div className='flex_card_body'>
          <p className='card_hearder'>Created AT</p>
          <p className='card_value'>{formatDate(created_at)}</p>
        </div>

      </div>
      <p className='p-2 c_notes'><strong>Conversation Notes:</strong> {conversation_notes}</p>
      <div className='p-2' style={{
        backgroundColor:'#fff'
      }}>
        <p><strong>Products:</strong> {selected_products.map((item,index)=>(
          <span key={index} className='product_chip mx-2'>{item} </span>
        ))}</p>
        <p style={{
          marginTop: '15px'
        }}><strong>Tags:</strong> {tags.map((item,index)=>(
          <span key={index} className='feedback_chip mx-2'>{item} </span>
        ))}</p>
      </div>
    </div>
  );
};

export default ConversastionLogCard;