import React, { useState, useEffect } from "react";
import db from "./Firebase";
import { Button, Spinner } from "react-bootstrap";
import "./Report.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "./OnBoardCustomer.css";
import { toast } from "react-toastify";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import "./Report.css";
import { useNavigate, Link, useParams } from 'react-router-dom';
import { updateEmail } from "firebase/auth";
import ExportTableToExcel from "./ExportTableToExcel";
import { collection, query, where, getDocs, deleteDoc, doc , getCountFromServer , writeBatch , updateDoc} from 'firebase/firestore';

function ActivityLogs() {
    const navigate = useNavigate();

    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        
        if (loggedIn) {
            // navigate("/");
        } else {
            navigate("/login");
        }
    }, [navigate]);
    const moment = extendMoment(Moment);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isValidRange, setIsValidRange] = useState(true);
    const [errMsg, setErrMsg] = useState("");
    const [dataLoaded, setDataLoaded] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [walletTxnsReport, setWalletTxnsReport] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [activityLogs, setActivityLogs] = useState(null);
    const [endDateQuery, setEndDateQuery] = useState(null);
    const [deliveryExecutivesMap, setDeliveryExecutivesMap] = useState(new Map());

    const getDeliveryExecutive = async () => {
        const hub_users_snap = await db.collection('hubs_users_data')
            .where('role', '==', 'Delivery Executive')
            .get();
        const de_data = hub_users_snap.docs.map(doc => doc.data());
        const newMap = new Map();
        de_data.forEach(data => {
            const value = `${data.first_name} ${data.last_name}`;
            newMap.set(data.hub_user_id, value);
        });
        setDeliveryExecutivesMap(newMap);
    };

    useEffect(() => {
        getDeliveryExecutive();
    }, []);


    const validateDateRange = (startDate, endDate) => {

        if (startDate && endDate) {
            if (startDate > endDate) {
                toast.error("From Date should not be greater than To Date")
                return false;
            } else {
                setErrMsg("");
            }
        } else {
            if (!startDate) {
                toast.error('Please enter From Date');
                return false;
            }

            if (!endDate) {
                toast.error('Please enter To Date');
                return false;
            }
        }
        return true;
    };

    const handleStartDateChange = (date) => {

        if (date) {
            const updatedStartDate = new Date(date);
            updatedStartDate.setHours(0, 0, 0, 0); 
            setStartDate(updatedStartDate);
        }
    }

    const handleEndDateChange = (date) => {

        if (date) {
            const updatedEndDate = new Date(date);
            updatedEndDate.setHours(23, 59, 59, 999); // Set the time to 11:59:59 PM
            setEndDateQuery(updatedEndDate);
            setEndDate(updatedEndDate);
        }
    }

    const toUTCDate = (date) => {
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        return utcDate;
    };


    //Export to CSV Function
    const exportToCSV = () => {
        if (!activityLogs.length) {
            toast.error("No data available for export.");
            return;
        }

        const csvRows = [];
        const headers = [
            "S No.",
            "Customer ID",
            "Name",
            "Number",
            "Address",
            "Hub",
            "Delivery Executive",
            "User",
            "Object",
            "Action",
            "Description",
            "Date"
        ];

        csvRows.push(headers.join(",")); // Add header row

        activityLogs.forEach((activity, index) => {
            const row = [
                index + 1,
                activity.customer_id,
                activity.customer_name,
                activity.customer_phone,
                activity.customer_address,
                activity.hub_name,
                deliveryExecutivesMap.get(activity.delivery_exe_id) || '',
                activity.user,
                activity.object,
                activity.action,
                activity.description,
                activity.created_date && moment(activity.created_date.toDate()).format("DD/MM/YY, h:mm a")
            ];
            csvRows.push(row.join(","));
        });

        const csvString = csvRows.join("\n");
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "activity_logs.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const handleSearch = async () => {
        console.log("Start Date => " , startDate);
        setErrMsg("");
        const activityLogs = [];
        setShowSpinner(true);
        
        try {
            const startOfStartDate = new Date(startDate);
            startOfStartDate.setHours(0, 0, 0, 0);
            const endOfEndDate = new Date(endDate);
            endOfEndDate.setHours(23, 59, 59, 999);
    
            const querySnapshot = await db.collection('customer_activities')
                .where('created_date', '>=', startOfStartDate)
                .where('created_date', '<=', endOfEndDate)
                .where('action', '==', "Auto Pause")
                .get();
    
             querySnapshot.forEach(doc => {
                 const activity = doc.data();
                 activityLogs.push(activity);
             });
    
            setDataLoaded(true);
            setShowSpinner(false);
            setActivityLogs(activityLogs);
        } catch (error) {
            console.error('Error handling search:', error);
            setErrMsg("Error occurred while fetching wallet transactions.");
            setShowSpinner(false);
        }
    };

    const handleReset = () => {
        setFromDate(null);
        setToDate(null);
        setStartDate(null);
        setEndDate(null);
        setWalletTxnsReport([]);
    }

    const navi = (id) => {
        const url = `/profile/${id}`;
        const newTab = window.open(url, '_blank');
        newTab.focus();

    }

    const SpinnerOverlay = () => (
        <div className="spinner-overlay">   
            <div className="spinner"></div>
        </div>
    );

    return (
        <>
            <div className="panel" style={{ marginTop: "10px", marginBottom: "10px" }}>
            <span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>ACTIVITY LOG</span>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                  
                    <>
                        {dataLoaded && <ExportTableToExcel tableId="activitylog" fileName="activitylog" />}
                        {dataLoaded && <Button variant="outline-success"  className="btn btn-success btn-rounded btn-sm"  onClick={exportToCSV} style={{ marginLeft: '10px' }}>Export CSV</Button>}
                    </>
                   
                </div>
            </div>
            
            <div className="datepickers-container">
                <div className="datepicker-container">
                    <label className="datepicker-label">From Date:</label>
                    <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy" // Format for displaying the date
                        className="datepicker-input"
                        placeholderText="From Date"
                        maxDate={endDate}
                    />
                </div>
                <br />
                <div className="datepicker-container">
                    <label className="datepicker-label">To Date:</label>
                    <DatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        selectsEnd
                        startDate={startDate}
                        //endDate={endDate}
                        minDate={startDate}
                        dateFormat="dd/MM/yyyy" // Format for displaying the date
                        className="datepicker-input"
                        placeholderText="To date"
                    />
                </div>
                <Button variant="outline-success" onClick={()=>{handleSearch()}} style={{zIndex:1}}>Search</Button>
                <Button variant="outline-success" onClick={handleReset} style={{ marginLeft: '10px' }}>Reset</Button>
            </div>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {showSpinner && <div className="spinner-container"><SpinnerOverlay /></div>}
                <div class="col-md-6 col-xl-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-striped" id="activitylog">
                                    <thead>
                                        <tr>    
                                            <th>S No.</th>
                                            <th>Customer ID</th>
                                            <th>Name</th>
                                            <th>Number</th>
                                            <th>Address</th>
                                            <th>Hub</th>
                                            <th>Delivery Executive</th>
                                            <th>User</th>
                                            <th>Object</th>
                                            <th>Action</th>
                                            <th>Description</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataLoaded && activityLogs.map((activity, index) => (
                                            <tr key={index} onClick={() => navi(activity.customer_id)} style={{ cursor: "pointer" }}>
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    {activity.customer_id}
                                                </td>
                                                <td>
                                                    {activity.customer_name}
                                                </td>
                                                <td>
                                                    {activity.customer_phone}
                                                </td>
                                                <td>{activity.customer_address}</td>
                                                <td>{activity.hub_name}</td>
                                                <td>{deliveryExecutivesMap.get(activity.delivery_exe_id)}</td>
                                                <td>{activity.user}</td>
                                                <td>{activity.object}</td>
                                                <td>{activity.action}</td>
                                                <td>{activity.description}</td>
                                                <td>{activity.created_date && moment(activity.created_date.toDate().toISOString()).format("DD/MM/YY, h:mm a")}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}
export default ActivityLogs;
