import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Select from 'react-select';
import { useCookies } from "react-cookie";
import TopPanel from "./TopPanel";
import Footer from "./Footer";


function HubDist() {
    const navigate = useNavigate();
    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        console.log(loggedIn);
        if (loggedIn) {
            // navigate("/");
        } else {
            navigate("/login");
        }
    }, [navigate]);
    const [cookies] = useCookies(["permissions"]);
    const rolePermissions = cookies.permissions ? cookies.permissions.HubsDist || [] : [];
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [timeOfDay, setTimeOfDay] = useState('');
    const [data, setData] = useState([]);
    const [dataUser, setDataUser] = useState([]);
    const [dataLocation, setDataLocation] = useState([]);
    const [editID, setEditID] = useState("");
    const [editIDUser, setEditIDUser] = useState("");
    const [editIDLocation, setEditIDLocation] = useState("");
    const [edit, setEdit] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const [editLocation, setEditLocation] = useState(false);
    const [showTabs, setShowTabs] = useState(false);
    const [selectedHubName, setSelectedHubName] = useState('');
    const [activeHub, setActiveHub] = useState('');

    const initialFormState = { hub_name: '', address: '', state: '', city: '', mobile_no: '', status: '', updated_date: new Date(), created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormState)

    const initialFormStateUser = { first_name: '', hub_name: activeHub, last_name: '', email: '', role: '', username: '', password: '', confirm_password: '', image: '', phone_no: '', status: '1', cash_collector: false, updated_date: new Date(), created_date: new Date() }
    const [submitUser, setSubmitUser] = useState({ ...initialFormStateUser });

    const initialFormStateLocation = { route: '', location: '', delivery_executive: '', status: '', updated_date: new Date(), created_date: new Date() }
    const [submitLocation, setSubmitLocation] = useState(initialFormStateLocation)

    const [passwordMatch, setPasswordMatch] = useState(true);

    const [errors, setErrors] = useState({})
    const [hubCheck, setHubCheck] = useState([]);
    const [hubOptionsOne, setHubOptionsOne] = useState([]);
    const [hubOptions, setHubOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [selectedMapOption, setSelectedMapOption] = useState(null);

    const [deliveryExecutives, setDeliveryExecutives] = useState([]); // State to store delivery executives
    const [selectedHub, setSelectedHub] = useState(""); // State to store selected hub name
    const [selectedDeliveryExecutive, setSelectedDeliveryExecutive] = useState(""); // State to store selected delivery executive

    const [routeOptions, setRouteOptions] = useState([]);
    const [selectedRouteOptions, setSelectedRouteOptions] = useState("");


    const [userOptions, setUserOptions] = useState([]);
    const [locations, setLocations] = useState([]);
    const [locationsTo, setLocationsTo] = useState([]);
    const [selectedUserFrom, setSelectedUserFrom] = useState(null);
    const [selectedUserTo, setSelectedUserTo] = useState(null);
    const [selectedLocationsFrom, setSelectedLocationsFrom] = useState([]);
    const [selectedLocationsTo, setSelectedLocationsTo] = useState([]);
    const [selectedRoute, setSelectedRoute] = useState('');


    const [userOptionsFrom, setUserOptionsFrom] = useState([]);
    const [userOptionsTo, setUserOptionsTo] = useState([]);
    const [selectedHubFrom, setSelectedHubFrom] = useState(null);
    const [selectedHubTo, setSelectedHubTo] = useState(null);


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [totalPages, setTotalPages] = useState(0);


    const [currentPageL, setCurrentPageL] = useState(1);
    const [itemsPerPageL, setItemsPerPageL] = useState(20);
    const [totalPagesL, setTotalPagesL] = useState(0);

    const [showPassword, setShowPassword] = useState(false);

    const [imagePreview, setImagePreview] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [imageSelected, setImageSelected] = useState(false);

    const fileInputRef = useRef(null);

    const handleHubFromChange = async (selectedOption) => {
        if (selectedOption) {
            setSelectedHubFrom(selectedOption.value);
            try {
                const hubName = selectedOption.value;
                const snapshot = await db.collection("hubs_users_data").where("hub_name", "==", hubName).get();
                const options = snapshot.docs.map(doc => {
                    const { first_name, last_name } = doc.data();
                    return { value: `${first_name} ${last_name}`, label: `${first_name} ${last_name}` };
                });
                setUserOptionsFrom(options);
                setSelectedHubFrom(selectedOption);
                setSelectedUserFrom(null); // Reset selected user
            } catch (error) {
                console.error('Error fetching users for the selected hub:', error);
            }
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPageL(1);  // Reset pagination to the first page
    };
    
    const [userMapID, setUserMapID] = useState({});

    const usermap = (hubname) => {
        setUserMapID({}); // Reset the state before fetching new data
        console.log("Fetching data...");
        db.collection("hubs_users_data").where("hub_name", "==", hubname).onSnapshot((snapshot) => {
            console.log("Snapshot received:", snapshot);
            const userMapIDData = snapshot.docs.map(doc => {
                const data = doc.data();
                console.log(data);
                const { hub_user_id, first_name, last_name } = data;
                console.log("Document data:", data);
                console.log(hub_user_id + '-' + first_name + '-' + last_name);
                const delivery_executive_name = `${first_name} ${last_name}`;
                return { [hub_user_id]: delivery_executive_name };
            });
            console.log("Key-value pairs:", userMapIDData);
            // Set the state with the fetched data
            setUserMapID(Object.assign({}, ...userMapIDData));
        });
    };

    // useEffect hook to log userMapID after it's updated
    // useEffect(() => {
    //     console.log("userMapID updated:", userMapID);
    //     // console.log(userMapID["60"]); // Access the value using the correct key
    // }, [userMapID]);



    const handleHubToChange = async (selectedOption) => {
        if (selectedOption) {
            setSelectedHubTo(selectedOption.value);
            try {
                const hubName = selectedOption.value;
                const snapshot = await db.collection("hubs_users_data").where("hub_name", "==", hubName).get();
                const options = snapshot.docs.map(doc => {
                    const { first_name, last_name } = doc.data();
                    return { value: `${first_name} ${last_name}`, label: `${first_name} ${last_name}` };
                });
                setUserOptionsTo(options);
                setSelectedHubTo(selectedOption);
                setSelectedUserTo(null); // Reset selected user
            } catch (error) {
                console.error('Error fetching users for the selected hub:', error);
            }
        }
    };








    useEffect(() => {
        db.collection("hubs_users_data").where("hub_name", "==", activeHub).onSnapshot((snapshot) => {
            const options = snapshot.docs.map(doc => {
                const { first_name, last_name } = doc.data();
                return { value: `${first_name} ${last_name}`, label: `${first_name} ${last_name}` };
            });
            setUserOptions(options);
        });
    }, []);




    useEffect(() => {
        if (selectedUserFrom) {
            db.collection("hubs_locations_data")
                .where("delivery_executive", "==", selectedUserFrom.value)
                .onSnapshot((snapshot) => {
                    const locationsData = snapshot.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    }));
                    setLocations(locationsData);
                    // Get the route name
                    const routeName = locationsData.length > 0 ? locationsData[0].data.route : '';
                    setSelectedRoute(routeName);


                });

        }
    }, [selectedUserFrom]);


    useEffect(() => {
        if (selectedUserTo) {
            db.collection("hubs_locations_data").where("delivery_executive", "==", selectedUserTo.value)
                .onSnapshot((snapshot) => {
                    const locationsData = snapshot.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    }));
                    setLocationsTo(locationsData);

                });
        }
    }, [selectedUserTo]);

    const addtransferLocation = () => {
        setSelectedUserFrom(null)
        setSelectedUserTo(null)
        setLocations([])
        setLocationsTo([])
        setSelectedLocationsFrom([])
        openModalTL();
    }

    const addtransferLocationHW = () => {
        setSelectedHubFrom(null);
        setSelectedHubTo(null);
        setSelectedUserFrom(null);
        setSelectedUserTo(null);
        setSelectedLocationsFrom([]);
        setSelectedLocationsTo([]);
        setLocations([]);
        setLocationsTo([]);
        openModalTLH();

        // Function to fetch hub names
        const fetchHubNames = async () => {
            try {
                const snapshot = await db.collection('hubs_data').get();
                const hubNames = snapshot.docs.map(doc => doc.data().hub_name);
                const hubCheck = snapshot.docs.map(doc => doc.data().hub_name.toLowerCase());
                const uniqueHubNames = [...new Set(hubNames)];
                const options = uniqueHubNames.map(name => ({ value: name, label: name }));
                setHubOptions(options); // Update hubOptions state here

            } catch (error) {
                console.error('Error fetching hub names:', error);
            }
        };

        // Clear selected hub name and then fetch new hub names
        setSelectedHubName(""); // Clear selected hub name
        fetchHubNames();
    };



    useEffect(() => {
        const fetchHubNames = async () => {
            try {
                const snapshot = await db.collection('hubs_data').get();
                const hubNames = snapshot.docs.map(doc => doc.data().hub_name);
                const hubCheck = snapshot.docs.map(doc => doc.data().hub_name.toLowerCase());
                const uniqueHubNames = [...new Set(hubNames)];
                const options = uniqueHubNames.map(name => ({ value: name, label: name }));
                setHubOptions(options);
                setHubCheck(hubCheck)

            } catch (error) {
                console.error('Error fetching hub names:', error);
            }
        };

        fetchHubNames();
    }, []);


    useEffect(() => {
        const fetchDeliveryExecutives = async () => {
            try {
                if (!selectedHub) {
                    setDeliveryExecutives([]); // Clear the delivery executive options if no hub is selected
                    return;
                }
                const snapshot = await db.collection('hubs_users_data').where('hub_name', '==', selectedHub).get();
                const executiveNames = snapshot.docs.map(doc => ({
                    // Concatenate first name and last name
                    value: doc.data().first_name + ' ' + doc.data().last_name,
                    label: doc.data().first_name + ' ' + doc.data().last_name,
                    user: doc.data()
                }));
                setDeliveryExecutives(executiveNames);
            } catch (error) {
                console.error('Error fetching delivery executives:', error);
            }
        };

        fetchDeliveryExecutives();
    }, [selectedHub]);


    const fetchLocations = async () => {
        try {
            if (!selectedHub) {
                setLocationOptions([]);
                return;
            }
            const snapshot = await db.collection('locations_data').where('hub_name', '==', selectedHub).get();
            const locations = snapshot.docs.map(doc => {
                const data = doc.data();
                return { value: `${data.area}, ${data.subarea}`, label: `${data.area}, ${data.subarea}` };
            });
            setLocationOptions(locations);
            setSubmit({ ...submit, locations: [] }); // Clear selected locations when hub changes
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    useEffect(() => {
        fetchLocations();
    }, [selectedHub]);


    const handleRouteChange = async (selectedOption) => {
        // alert("ok")
        setSelectedRouteOptions(selectedOption ? selectedOption.value : null);
        // Function to fetch location data for a selected route
        try {
            const querySnapshot = await db.collection('routes_data')
                .where('route', '==', selectedOption.value)
                .get();

            const locationValues = [];
            querySnapshot.forEach(doc => {
                const locationArray = doc.data().locations;
                if (locationArray && Array.isArray(locationArray)) {
                    locationArray.forEach(location => {
                        locationValues.push({
                            label: location, // Assuming location is a string, adjust accordingly if it's an object
                            value: location // Assuming location is a string, adjust accordingly if it's an object
                        });
                    });
                }
            });
            setLocationOptions(locationValues);
            setSelectedMapOption(locationValues)


            return locationValues;
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error;
        }



    };

    const handleHubChange = (selectedOption) => {
        setSelectedHubName(selectedOption ? selectedOption.value : null);
        setSelectedHub(selectedOption ? selectedOption.value : null);
        setSelectedMapOption(null);// Clear selected locations when hub changes
        setSelectedDeliveryExecutive(selectedOption ? selectedOption.value : null);
    };

    const [username, setUsername] = useState("");
    const [dePhone, setDePhone] = useState("");
    const [hubUserID, setHubUserID] = useState("");


    // Handler function for delivery executive change
    const handleDeliveryExecutiveChange = (selectedOption) => {
        setSelectedDeliveryExecutive(selectedOption ? selectedOption.value : null);
        // console.log(selectedOption.user.username)
        // alert(selectedOption.user.phone_no)
        setUsername(selectedOption.user.username)
        setDePhone(selectedOption.user.phone_no)
        setHubUserID(selectedOption.user.hub_user_id)
    };



    const handleChange = (e) => {
        const { id, value } = e.target;
        let newValue = value;

        // If the field is phone number, remove non-numeric characters
        if (id === 'mobile_no') {
            newValue = value.replace(/\D/g, '');
        } else {
            newValue = value.replace(/[^\w\s.@/:+\-=]/gi, "");
        }

        // Update the state with the new value
        setSubmit(prevState => ({
            ...prevState,
            [id]: newValue
        }));
    }


    const handleChangeuser = (e) => {
        const { id, value } = e.target;
        let newValue = value;
        // If the field is phone number, remove non-numeric characters
        if (id === 'phone_no') {
            newValue = value.replace(/\D/g, '');
        }

        // Update the state with the new value
        setSubmitUser(prevState => ({
            ...prevState,
            [id]: newValue
        }));

        if (id === 'confirm_password' && submitUser.password !== value) {
            setPasswordMatch(false);
        } else {
            setPasswordMatch(true);
        }
    };



    const handleChangeLocation = (e) => {
        const { id, value } = e.target
        setSubmitLocation({ ...submitLocation, [id]: value })
    }

    const showtabs = (name) => {
        usermap(name);
        setSelectedHub(name)
        fetchLocationHUb(name)
        setSelectedHubName(name)
        setActiveHub(name)
        setHubOptionsOne([{ value: name, label: name }])
        setShowTabs(true)
        db.collection("hubs_users_data").where("hub_name", "==", name).onSnapshot((snapshot) => {
            setDataUser(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );

            const options = snapshot.docs.map(doc => {
                const { first_name, last_name } = doc.data();
                return { value: `${first_name} ${last_name}`, label: `${first_name} ${last_name}` };
            });
            setUserOptions(options);
        });

    }

    const [errorsObj, setErrorsObj] = useState({
        hub_name: false,
        address: false,
        state: false,
        city: false,
        mobile_no: false,
    });



    const addNew = () => {
        const fetchHubNames = async () => {
            try {
                const snapshot = await db.collection('hubs_data').get();
                const hubNames = snapshot.docs.map(doc => doc.data().hub_name);
                const hubCheck = snapshot.docs.map(doc => doc.data().hub_name.toLowerCase());
                const uniqueHubNames = [...new Set(hubNames)];
                const options = uniqueHubNames.map(name => ({ value: name, label: name }));
                setHubOptions(options);
                setHubCheck(hubCheck)

            } catch (error) {
                console.error('Error fetching hub names:', error);
            }
        };

        fetchHubNames();
        // Reset form fields
        setSubmit({
            hub_name: '',
            address: '',
            state: '',
            city: '',
            mobile_no: '',
            status: '',
            updated_date: new Date(),
            created_date: new Date(),
        });

        // Clear any error indicators
        setErrorsObj({
            hub_name: false,
            address: false,
            state: false,
            city: false,
            mobile_no: false,
        });

        // Open the modal
        setEdit(false);
        openModalHub();
    }


    const addNewuser = () => {
        setEditUser(false);
        openModalu();
        setSubmitUser({
            first_name: '',
            last_name: '',
            hub_name: activeHub,
            email: '',
            role: '',
            username: '',
            password: '',
            image: '',
            phone_no: '',
            status: '1',
            cash_collector: false,
            updated_date: new Date(),
            created_date: new Date()
        });
        document.getElementById("image").value = ""; // Clear the input box for the image file
        fileInputRef.current.value = '';
    };

    const fetchRoutes = async () => {
        try {
            if (!selectedHub) {
                setRouteOptions([]);
                return;
            }
            const snapshot = await db.collection('routes_data').where('hub_name', '==', activeHub).get();
            const routes = snapshot.docs.map(doc => ({
                value: doc.data().route,
                label: doc.data().route
            }));
            setRouteOptions(routes);
        } catch (error) {
            console.error('Error fetching routes:', error);
        }
    };

    const addNewLocation = () => {
        setSelectedMapOption(null); // Clear selected locations
        setSelectedHubName(""); // Clear selected hub name
        setSelectedRouteOptions(""); // Clear selected route name
        setSelectedDeliveryExecutive(null); // Clear delivery executive options
        fetchRoutes()
        setEditLocation(false)
        openModalL();
        setSubmitLocation({ route: '', location: '', delivery_executive: '', status: '', updated_date: new Date(), created_date: new Date() });
    }

    const reset = () => {
        setSubmit({ hub_name: '', address: '', state: '', city: '', mobile_no: '', status: '', updated_date: new Date(), created_date: new Date() });
        setSubmitUser({ first_name: '', last_name: '', email: '', role: '', username: '', password: '', image: '', phone_no: '', status: '1', cash_collector: false, updated_date: new Date(), created_date: new Date() });
        setSubmitLocation({ route: '', location: '', delivery_executive: '', status: '', updated_date: new Date(), created_date: new Date() });
    }

    useEffect(() => {
        db.collection("hubs_data").orderBy("updated_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);




    const fetchLocationHUb = (name) => {
        db.collection("hubs_locations_data").where("hub_name", "==", name).onSnapshot((snapshot) => {
            setDataLocation(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );

        });
    }


    useEffect(() => {
        const getCurrentTimeOfDay = () => {
            const currentTime = new Date().getHours();
            if (currentTime < 12) {
                setTimeOfDay('Good Morning');
            } else if (currentTime >= 12 && currentTime < 18) {
                setTimeOfDay('Good Afternoon');
            } else {
                setTimeOfDay('Good Evening');
            }
        };

        getCurrentTimeOfDay();
    }, []);

    const changeStatusForm = (data, id) => {
        setEdit(true)
        setEditID(id)
        setSubmit({ id: id, hub_name: data.hub_name, address: data.address, state: data.state, city: data.city, mobile_no: data.mobile_no, status: data.status, updated_date: new Date(), created_date: new Date() });
        openModalHub();
    }

    const changeStatusFormUser = (data, id) => {
        setEditUser(true)
        setEditIDUser(id)
        setSubmitUser({ id: id, first_name: data.first_name, last_name: data.last_name, email: data.email, role: data.role, username: data.username, password: data.password, confirm_password: data.password, image: data.image, phone_no: data.phone_no, status: '1', cash_collector: data.cash_collector, updated_date: new Date(), created_date: new Date() });
        openModalu();
        if (data.image) {
            setImagePreview(data.image);
            setImageSelected(true);
        } else {
            setImagePreview(null);
            setImageSelected(false);
        }
    }

    const changeStatusFormLocation = (data, id) => {
        setSelectedMapOption([{ value: data.location, label: data.location }]); // Clear selected locations
        // setSelectedHubName(""); // Clear selected hub name
        setSelectedRouteOptions(data.route); // Clear selected route name
        setSelectedDeliveryExecutive(data.delivery_executive); // Clear delivery executive options
        fetchRoutes()
        // setSelectedRouteOptions(data.route)
        setEditLocation(true)
        setEditIDLocation(id)
        setSubmitLocation({ id: id, route: data.route, location: data.location, delivery_executive: data.delivery_executive, updated_date: new Date(), created_date: new Date() });
        openModalloma();
    }


    const openModalHub = () => {
        // alert("ooo")
        window.modelshowHub();
    }

    const closeModalHub = () => {
        window.modalHideHub();
    }

    const openModalu = () => {
        // alert("ooo")
        window.usermodelshow();
    }

    const closeModalu = () => {
        window.usermodalHide();
    }

    const openModalL = () => {
        // alert("ooo")
        window.locationmodelshow();
    }

    const closeModalL = () => {
        window.locationmodalHide();
    }


    const openModalTL = () => {
        // alert("ooo")
        window.locationTmodelshow();
    }

    const closeModalTL = () => {
        window.locationTmodalHide();
    }

    const openModalTLH = () => {
        // alert("ooo")
        window.locationTHmodelshow();
    }

    const closeModalTLH = () => {
        window.locationTHmodalHide();
    }

    const openModalloma = () => {
        // alert("ooo")
        window.locationTHmodelshowloma();
    }

    const closeModalloma = () => {
        window.locationTHmodalloma();
    }




    const states = [
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Andaman and Nicobar Islands',
        'Assam',
        'Bihar',
        'Chhattisgarh',
        'Chandigarh',
        'Dadra and Nagar Haveli',
        'Daman and Diu',
        'Delhi',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jammu and Kashmir',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Lakshadweep',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Puducherry',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttarakhand',
        'Uttar Pradesh',
        'West Bengal',
    ];



    const handleSubmit = (e) => {
        e.preventDefault();
        if (edit) {
            setLoading(true);
            // Perform update operation
            db.collection("hubs_data").doc(editID).update({
                'hub_name': submit.hub_name,
                'address': submit.address,
                'state': submit.state,
                'city': submit.city,
                'mobile_no': submit.mobile_no,
                'status': "1",
                'updated_date': new Date(),
            }).then(() => {

                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    }
                });

                Toast.fire({
                    icon: 'success',
                    title: 'Hub Updated Successfully'
                });
                closeModalHub();
                setLoading(false);

                setSubmit({ hub_name: '', address: '', state: '', city: '', mobile_no: '', status: '', updated_date: new Date(), created_date: new Date() });
            }).catch(error => {
                console.error('Error updating hub:', error);
            });
        } else {
            if (hubCheck.includes(submit.hub_name.toLowerCase())) {
                Swal.fire({
                    icon: 'error',
                    title: 'Hub Name Exists',
                    text: 'The entered hub name already exists in the database. Please choose a different name.',
                });
            } else {
                setLoading(true);
                // Hub name is available, proceed with adding
                db.collection("hubs_data").add({
                    'hub_name': submit.hub_name,
                    'address': submit.address,
                    'state': submit.state,
                    'city': submit.city,
                    'mobile_no': submit.mobile_no,
                    'status': "1",
                    'updated_date': new Date(),
                    'created_date': new Date(),
                }).then(() => {
                    reset();
                    // setshow(true)

                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        }
                    });
                    closeModalHub();
                    setLoading(false);

                    Toast.fire({
                        icon: 'success',
                        title: 'Hub Data Added'
                    });
                    const fetchHubNames = async () => {
                        try {
                            const snapshot = await db.collection('hubs_data').get();
                            const hubNames = snapshot.docs.map(doc => doc.data().hub_name);
                            const hubCheck = snapshot.docs.map(doc => doc.data().hub_name.toLowerCase());
                            const uniqueHubNames = [...new Set(hubNames)];
                            const options = uniqueHubNames.map(name => ({ value: name, label: name }));
                            setHubOptions(options);
                            setHubCheck(hubCheck)

                        } catch (error) {
                            console.error('Error fetching hub names:', error);
                        }
                    };

                    fetchHubNames();
                }).catch(error => {
                    console.error('Error adding hub:', error);
                });
            }

        }

    }

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                setImagePreview(e.target.result);
            };
            reader.readAsDataURL(e.target.files[0]);
            setImageFile(e.target.files[0]);
            setImageSelected(true);
        }
    };


    // Function to check password criteria
    const checkPasswordCriteria = (password) => {
        const capitalRegex = /[A-Z]/;
        const smallRegex = /[a-z]/;
        const numberRegex = /[0-9]/;
        const lengthRegex = /.{8}/;

        return capitalRegex.test(password) &&
            smallRegex.test(password) &&
            numberRegex.test(password) &&
            lengthRegex.test(password);
    };

    // Function to handle password change
    const handlePasswordChange = (e) => {
        const { value } = e.target;
        setSubmitUser(prevState => ({
            ...prevState,
            password: value
        }));
    };

    const removeImage = () => {
        setImagePreview(null);
        setImageFile(null);
        setImageSelected(false);
    };


    function generateHUID() {
        const now = new Date();
        const timestamp = now.getTime(); // Get the timestamp in milliseconds since January 1, 1970
        const random4Digits = Math.floor(Math.random() * 10000).toString().padStart(4, '0'); // Generate a random 4-digit number

        // Take the last 4 digits of the timestamp and concatenate with the random 4-digit number
        const huid = (timestamp % 10000).toString().padStart(4, '0') + random4Digits;

        return huid;
    }


    const checkUsernameExists = async (name) => {
        try {
            const usernameSnapshot = await db.collection("hubs_users_data").where('username', '==', name).get();
            return !usernameSnapshot.empty;
        } catch (error) {
            console.error('Error checking route existence: ', error);
            return false;
        }
    };

    const checkUIDExists = async (uid) => {
        try {
            const usernameSnapshot = await db.collection("hubs_users_data").where('hub_user_id', '==', uid).get();
            return !usernameSnapshot.empty;
        } catch (error) {
            console.error('Error checking route existence: ', error);
            return false;
        }
    };



    const checkPhoneExists = async (phone) => {
        try {
            const phoneSnapshot = await db.collection("hubs_users_data").where('phone_no', '==', phone).get();
            return !phoneSnapshot.empty;
        } catch (error) {
            console.error('Error checking route existence: ', error);
            return false;
        }
    };

    const genereateHubUserId = async () => {
        try {
            const querySnapshot = await db.collection('hubs_users_data').get();
            const total_hubUsers = querySnapshot.docs.map(doc => doc.data());
            const size = total_hubUsers.length;
            return size;

        } catch (error) {
            console.error("Error fetching collection length: ", error);
        }
    }

    const handleSubmituser = async (e) => {
        e.preventDefault();
        //const huid = generateHUID();
        const huid = await genereateHubUserId();

        if (submitUser.phone_no.length != 10) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Phone number should not less than 10 digit',
            });
            return;
        }

        if (submitUser.password != submitUser.confirm_password) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'password error please enter confirm password',
            });
            return;
        }

        if (
            !checkPasswordCriteria(submitUser.password)
        ) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Password must contain at least one capital letter, one small letter, one number, and be at least 8 characters long.',
            });
            // Password doesn't meet the criteria
            // Optionally, you can show an error message to the user
            // alert("");
            return;
        }

        if (editUser) {
            setLoading(true);
            // Update existing user
            if (imageFile) {
                // Update with image
                const storageRef = ref(storage, `files/${imageFile.name}`);
                const uploadTask = uploadBytesResumable(storageRef, imageFile);

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        // Update progress if needed
                    },
                    (error) => {
                        alert(error);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            db.collection("hubs_users_data").doc(editIDUser).update({
                                ...submitUser,
                                image: downloadURL
                            }).then(() => {
                                // Handle success
                            }).catch((error) => {
                                // Handle error
                                console.error("Error updating user with image:", error);
                            });
                        });
                    }
                );
            } else {

                await db.collection("hubs_users_data").doc(editIDUser).update({
                    ...submitUser,
                    image: '',
                });
            }
        } else {
            setLoading(true);

            const usernameExists = await checkUsernameExists(submitUser.username);
            const phoneExists = await checkPhoneExists(submitUser.phone_no);
            const hub_user_id = await checkUIDExists(huid)


            if (usernameExists) {

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Username already exists in the database!',
                });
                setLoading(false);
                return;
            }

            if (phoneExists) {

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Phone no already exists in the database!',
                });
                setLoading(false);
                return;
            }

            if (hub_user_id) {

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Hub User ID already exists in the database!',
                });
                setLoading(false);
                return;
            }
            // Add new user
            if (imageFile) {
                // Add with image
                const storageRef = ref(storage, `files/${imageFile.name}`);
                const uploadTask = uploadBytesResumable(storageRef, imageFile);

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        // Update progress if needed
                    },
                    (error) => {
                        alert(error);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            db.collection("hubs_users_data").add({
                                ...submitUser,
                                image: downloadURL,
                                hub_user_id: huid
                            }).then(() => {
                                // Handle success
                            }).catch((error) => {
                                // Handle error
                                console.error("Error adding user with image:", error);
                            });
                        });
                    }
                );
            } else {
                // Add without image
                await db.collection("hubs_users_data").add({ ...submitUser, hub_user_id: huid });
            }
        }
        // Clear form after submission
        setSubmitUser({ ...initialFormStateUser });
        setImagePreview(null);
        setImageFile(null);
        setImageSelected(false);
        closeModalu();
        setLoading(false);
    };


    const handleSubmitLocation = (e) => {
        e.preventDefault();

        const selectedLocations = selectedMapOption.map(location => location.value);

        // Check if any of the selected locations are already associated with a delivery executive
        const deliveryExecutiveQueryPromises = selectedLocations.map(location => {
            return db.collection("hubs_locations_data")
                .where('location', '==', location)
                .where('delivery_executive', '!=', '')
                .get();
        });

        Promise.all(deliveryExecutiveQueryPromises)
            .then(deliveryExecutiveQuerySnapshots => {
                // Check if any query has results (indicating the delivery executive is already associated with a location)
                const hasExistingLocationsForExecutive = deliveryExecutiveQuerySnapshots.some(querySnapshot => !querySnapshot.empty);
                if (hasExistingLocationsForExecutive) {
                    // Show an alert indicating that the selected delivery executive is already assigned to some of the selected locations
                    Swal.fire({
                        icon: 'warning',
                        title: 'Delivery Executive Already Assigned',
                        text: 'The selected delivery executive is already assigned to some of the selected locations.',
                    });
                } else {
                    setLoading(true);
                    // Proceed to add the locations
                    selectedLocations.forEach((location) => {
                        db.collection("hubs_locations_data").add({
                            'route': selectedRouteOptions,
                            'hub_name': activeHub,
                            'location': location,
                            'delivery_executive': selectedDeliveryExecutive,
                            'username': username,
                            'de_phone': dePhone,
                            'hub_user_id': hubUserID,
                            'status': "1",
                            'updated_date': new Date(),
                            'created_date': new Date(),
                        }).then(() => {
                            reset();
                            setSelectedMapOption(null); // Clear selected locations
                            setSelectedHubName(""); // Clear selected hub name
                            setSelectedRouteOptions(""); // Clear selected route name
                            setSelectedDeliveryExecutive(null); // Clear delivery executive options
                            const Toast = Swal.mixin({
                                toast: true,
                                background: '#69aba6',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            });

                            Toast.fire({
                                icon: 'success',
                                title: 'Location Added'
                            });
                            closeModalL();
                            setLoading(false);
                        }).catch(error => {
                            console.error('Error adding document: ', error);
                        });
                    });
                }
            })
            .catch(error => {
                console.error('Error checking existing locations for the delivery executive: ', error);
            });
    };


    const handleSubmitLocationEdit = (e) => {
        e.preventDefault();
        setLoading(true);

        const selectedLocations = selectedMapOption.map(location => location.value);


        selectedLocations.forEach((location) => {
            db.collection("hubs_locations_data").add({
                'route': selectedRouteOptions,
                'hub_name': activeHub,
                'location': location,
                'delivery_executive': selectedDeliveryExecutive,
                'status': "1",
                'username': username,
                'de_phone': dePhone,
                'hub_user_id': hubUserID,
                'updated_date': new Date(),
            }).then(() => {

                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });

                Toast.fire({
                    icon: 'success',
                    title: 'Location Added'
                });
            }).catch(error => {
                console.error('Error adding document: ', error);
            });

        })
        reset();
        setSelectedMapOption(null); // Clear selected locations
        setSelectedHubName(""); // Clear selected hub name
        setSelectedRouteOptions(""); // Clear selected route name
        setSelectedDeliveryExecutive(null); // Clear delivery executive options
        closeModalloma();
        setLoading(false);
        db.collection("hubs_locations_data").doc(editIDLocation).delete().then(() => {

        })


    }



    const handleSubmitTransferLocation = (e) => {
        e.preventDefault();
        if (selectedLocationsFrom.length === 0 || selectedUserTo === null) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill out all required fields!',
            });
            return;
        }

        // Check if any required field is empty
        if (selectedUserFrom === selectedUserTo) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "You can't transfer location to same user ",
            });
            return;
        }


        const batch = db.batch();

        selectedLocationsFrom.forEach((location) => {
            // Add data to the new user
            const newDataRef = db.collection("hubs_locations_data").doc();
            batch.set(newDataRef, {
                'delivery_executive': selectedUserTo.value,
                'hub_name': activeHub,
                'location': location,
                'route': selectedRoute,
                'username': username,
                'de_phone': dePhone,
                'hub_user_id': hubUserID,
                'status': "1",
                'updated_date': new Date(),
                'created_date': new Date(),
            });
        });

        setLoading(true);

        // Delete data from the old user
        db.collection("hubs_locations_data")
            .where('delivery_executive', '==', selectedUserFrom.value)
            .where('location', 'in', selectedLocationsFrom)
            .where('route', '==', selectedRoute)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    batch.delete(doc.ref);
                });

                // Commit the batch
                return batch.commit();
            })
            .then(() => {
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    }
                });
                Toast.fire({
                    icon: 'success',
                    title: 'Locations Transferred Successfully'
                });
                setSelectedUserFrom(null)
                setSelectedUserTo(null)
                setLocations([])
                setLocationsTo([])
                setSelectedLocationsFrom([])

                closeModalTL();
                setLoading(false);

            })
            .catch((error) => {
                console.error('Error transferring and deleting locations: ', error);
            });

    };

    const deleteData = (id, hubname) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
            className: "p-5"
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                db.collection("hubs_data").doc(id).delete().then(() => {
                    closeModalHub();
                    Swal.fire(
                        'Deleted!',
                        'All the data related to this hub will be deleted.',
                        'success'
                    )
                })
                setLoading(true);
                db.collection("hubs_locations_data")
                    .where('hub_name', '==', hubname)
                    .get()
                    .then((querySnapshot) => {
                        // Iterate through each document in the query results
                        querySnapshot.forEach((doc) => {
                            // Delete the document
                            db.collection("hubs_locations_data")
                                .doc(doc.id)
                                .delete()
                                .then(() => {
                                    console.log("Document successfully deleted!");
                                })
                                .catch((error) => {
                                    console.error("Error removing document: ", error);
                                });
                        });
                    })
                    .then(() => {
                        // Handle success after deleting all documents
                        console.log('All documents related to this hub have been deleted.');

                    })
                    .catch((error) => {
                        console.error("Error getting documents: ", error);
                    });

                db.collection("hubs_users_data")
                    .where('hub_name', '==', hubname)
                    .get()
                    .then((querySnapshot) => {
                        // Iterate through each document in the query results
                        querySnapshot.forEach((doc) => {
                            // Delete the document
                            db.collection("hubs_users_data")
                                .doc(doc.id)
                                .delete()
                                .then(() => {
                                    console.log("Document successfully deleted!");
                                })
                                .catch((error) => {
                                    console.error("Error removing document: ", error);
                                });
                        });
                    })
                    .then(() => {
                        // Handle success after deleting all documents
                        console.log('All documents related to this hub have been deleted.');
                        // closeModalHub();
                        // Swal.fire(
                        //     'Deleted!',
                        //     'All the data related to this hub has been deleted.',
                        //     'success'
                        // );
                    })
                    .catch((error) => {
                        console.error("Error getting documents: ", error);
                    });


                db.collection("locations_data")
                    .where('hub_name', '==', hubname)
                    .get()
                    .then((querySnapshot) => {
                        // Iterate through each document in the query results
                        querySnapshot.forEach((doc) => {
                            // Delete the document
                            db.collection("hubs_users_data")
                                .doc(doc.id)
                                .delete()
                                .then(() => {
                                    console.log("Document successfully deleted!");
                                })
                                .catch((error) => {
                                    console.error("Error removing document: ", error);
                                });
                        });
                    })
                    .then(() => {
                        // Handle success after deleting all documents
                        console.log('All documents related to this hub have been deleted.');

                    })
                    .catch((error) => {
                        console.error("Error getting documents: ", error);
                    });

                db.collection("routes_data")
                    .where('hub_name', '==', hubname)
                    .get()
                    .then((querySnapshot) => {
                        // Iterate through each document in the query results
                        querySnapshot.forEach((doc) => {
                            // Delete the document
                            db.collection("hubs_users_data")
                                .doc(doc.id)
                                .delete()
                                .then(() => {
                                    console.log("Document successfully deleted!");
                                })
                                .catch((error) => {
                                    console.error("Error removing document: ", error);
                                });
                        });
                    })
                    .then(() => {
                        // Handle success after deleting all documents
                        console.log('All documents related to this hub have been deleted.');
                        // closeModalHub();
                        // Swal.fire(
                        //     'Deleted!',
                        //     'All the data related to this hub has been deleted.',
                        //     'success'
                        // );
                    })
                    .catch((error) => {
                        console.error("Error getting documents: ", error);
                    });
                setLoading(false);
            }
        })

    }



    const deleteDatauser = async (id, data) => {
        const hubSnapshot = await db.collection("hubs_locations_data").where("delivery_executive", "==", `${data.first_name} ${data.last_name}`).get();

        // If the user is associated with a hub, show an alert
        if (!hubSnapshot.empty) {
            Swal.fire(
                'Cannot delete!',
                'This user is associated with a hub.',
                'warning'
            );
            return;
        }

        // If the user is not associated with any hub, proceed with deletion
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
            className: "p-5"
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                db.collection("hubs_users_data").doc(id).delete().then(() => {
                    console.log('Deleted Successfully');
                    setSubmit({ hub_name: '', address: '', state: '', city: '', mobile_no: '', status: '', updated_date: new Date(), created_date: new Date() })
                    closeModalu();
                    setLoading(false);
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    )
                })
            }
        });
    }



    const deleteDataLocation = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
            className: "p-5"
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                db.collection("hubs_locations_data").doc(id).delete().then(() => {
                    console.log('Deleted Successfully');
                    setSubmitLocation({ route: '', location: '', delivery_executive: '', status: '', updated_date: new Date(), created_date: new Date() })
                    closeModalL();
                    setLoading(false);
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    )
                })

            }
        })

    }




    const handleLocationFromChange = (e) => {
        const { value, checked } = e.target;
        console.log("Checkbox value:", value);
        console.log("Checkbox checked:", checked);

        setSelectedLocationsFrom(prevLocations => {
            const updatedLocations = checked
                ? [...prevLocations, value]
                : prevLocations.filter(location => location !== value);

            return updatedLocations;
        });
    };





    const handleSubmitTH = (e) => {
        e.preventDefault();



        // Check if any required field is empty
        if (selectedLocationsFrom.length === 0 || selectedUserTo === null) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill out all required fields!',
            });
            return;
        }

        const batch = db.batch();

        selectedLocationsFrom.forEach((location) => {
            // Add data to the new user
            const newDataRef = db.collection("hubs_locations_data").doc();
            batch.set(newDataRef, {
                'delivery_executive': selectedUserTo.value,
                'hub_name': selectedHubTo.value,
                'location': location,
                'route': selectedRoute,
                'username': username,
                'de_phone': dePhone,
                'hub_user_id': hubUserID,
                'status': "1",
                'updated_date': new Date(),
                'created_date': new Date(),
            });
        });
        setLoading(true);
        // Delete data from the old user
        db.collection("hubs_locations_data")
            .where('delivery_executive', '==', selectedUserFrom.value)
            .where('location', 'in', selectedLocationsFrom)
            .where('route', '==', selectedRoute)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    batch.delete(doc.ref);
                });

                // Commit the batch
                return batch.commit();
            })
            .then(() => {
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    }
                });

                Toast.fire({
                    icon: 'success',
                    title: 'Locations Transferred and Deleted Successfully'
                });

                // Reset all state values to initial state
                setSelectedHubFrom(null);
                setSelectedHubTo(null);
                setSelectedUserFrom(null);
                setSelectedUserTo(null);
                setSelectedLocationsFrom([]);
                setSelectedLocationsTo([]);
                setLocations([]);
                setLocationsTo([]);

                closeModalTLH();
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error transferring and deleting locations: ', error);
            });
    };



    const rolePermission = () => {
        const Toast = Swal.mixin({
            toast: true,
            background: '#d7e7e6',
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
        });

        Toast.fire({
            icon: 'error',
            title: 'You are not authorised to do this action'
        });
        // closeModalHub();
        // setLoading(false);
    }




    // Filter data based on search query
    const filteredDataUser = dataUser.filter(({ data }) => {
        // Filter based on search query
        return (
            data.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            data.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            data.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
            data.role.toLowerCase().includes(searchQuery.toLowerCase()) ||
            data.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
            data.phone_no.toLowerCase().includes(searchQuery.toLowerCase()) ||
            data.status.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    // Determine the range of page numbers to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItemsuser = filteredDataUser.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        const total = Math.ceil(dataUser.length / itemsPerPage);
        setTotalPages(total);
    }, [dataUser.length, itemsPerPage]);


    const renderPageButtons = () => {
        const pageButtons = [];
        // Determine the range of page numbers to display
        let startPage = Math.max(1, currentPage - 5);
        let endPage = Math.min(totalPages, startPage + 9);

        // If the total number of pages is less than 10, adjust the endPage
        if (totalPages <= 10) {
            endPage = totalPages;
        } else {
            // If the current page is near the start, display the first 10 pages
            if (currentPage <= 5) {
                startPage = 1;
                endPage = 10;
            }
            // If the current page is near the end, display the last 10 pages
            else if (currentPage >= totalPages - 4) {
                endPage = totalPages;
                startPage = endPage - 9;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageButtons.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button onClick={() => paginate(i)} className="page-link" style={{ color: "black" }}>{i}</button>
                </li>
            );
        }
        return pageButtons;
    };

    const filteredDataLocation = dataLocation.filter(({ data }) => {
        return (
            data.route.toLowerCase().includes(searchQuery.toLowerCase()) ||
            data.location.toLowerCase().includes(searchQuery.toLowerCase()) ||
            userMapID[data.delivery_exe_id].toLowerCase().includes(searchQuery.toLowerCase())  // Ensure delivery_exe_id is mapped correctly
        );
    });
    

    const indexOfLastItemL = currentPageL * itemsPerPageL;
    const indexOfFirstItemL = indexOfLastItemL - itemsPerPageL;
    const currentItems = filteredDataLocation.slice(indexOfFirstItemL, indexOfLastItemL);

    const paginateL = (pageNumber) => setCurrentPageL(pageNumber);

    useEffect(() => {
        const total = Math.ceil(dataLocation.length / itemsPerPageL);
        setTotalPagesL(total);
    }, [dataLocation.length, itemsPerPageL]);

    const renderPageButtonsL = () => {
        const pageButtonsL = [];
        // Determine the range of page numbers to display
        let startPageL = Math.max(1, currentPageL - 5);
        let endPageL = Math.min(totalPagesL, startPageL + 9);

        // If the total number of pages is less than 10, adjust the endPage
        if (totalPagesL <= 10) {
            endPageL = totalPagesL;
        } else {
            // If the current page is near the start, display the first 10 pages
            if (currentPageL <= 5) {
                startPageL = 1;
                endPageL = 10;
            }
            // If the current page is near the end, display the last 10 pages
            else if (currentPageL >= totalPagesL - 4) {
                endPageL = totalPagesL;
                startPageL = endPageL - 9;
            }
        }

        for (let i = startPageL; i <= endPageL; i++) {
            pageButtonsL.push(
                <li key={i} className={`page-item ${currentPageL === i ? 'active' : ''}`}>
                    <button onClick={() => paginateL(i)} className="page-link" style={{ color: "black" }}>{i}</button>
                </li>
            );
        }
        return pageButtonsL;
    };



    return (
        <>
            {loading && ( // Render loader when loading state is true
                <div className="loader-overlay">
                    <div className="">
                        <img style={{
                            height: "6rem"
                        }} src="images/loader.gif"></img>
                    </div>
                </div>
            )}
            <div class="container-scroller">
                <TopPanel />

                <div class="container-fluid page-body-wrapper">

                    <Sidebar />

                    <div class="main-panel">
                        <div class="content-wrapper">

                            {showTabs ?
                                <>
                                    <div class="col-md-6 col-xl-12 grid-margin stretch-card">
                                        <div class="card">
                                            <div class="card-body">
                                                <div style={{ display: "flex", justifyContent: "space-between", }}>
                                                    <span onClick={() => setShowTabs(false)} style={{ fontWeight: "600", fontSize: "14px", cursor: "pointer" }}><img width="20" height="20" src="https://img.icons8.com/flat-round/64/circled-left.png" alt="circled-left" />    Go Back</span>
                                                    <h4 class="card-title"><span style={{ color: "#34b1aa" }}> {activeHub} </span> : Hubs Data</h4>
                                                </div>
                                                <div class="home-tab">
                                                    <div class="align-items-center justify-content-between border-bottom" style={{ background: '#4a54ba', borderRadius: '15px' }}>
                                                        <ul class="nav nav-tabs" role="tablist">
                                                            <li class="nav-item" style={{ marginLeft: '10px' }}>
                                                            </li>
                                                            <li class="nav-item" style={{ marginLeft: '10px' }}>
                                                                <a class="nav-link ps-0" id="home-tab" data-bs-toggle="tab" href="#overview" role="tab"
                                                                    aria-controls="overview" aria-selected="true" onClick={() => setSearchQuery("")}>Locations</a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a class="nav-link" id="profile-tab" data-bs-toggle="tab" href="#audiences" role="tab"
                                                                    aria-selected="false" onClick={() => setSearchQuery("")}>Users</a>
                                                            </li>

                                                            <li class="nav-item">
                                                                {/* <input
                                                                    style={{
                                                                        border: "1px solid grey",
                                                                        padding: "0px 4px 0px 1rem;",
                                                                        borderRadius: "1rem",
                                                                        marginTop: "3px",
                                                                        marginLeft: "1rem",
                                                                        paddingLeft: "1rem",
                                                                        height: "32px",
                                                                        paddingBottom: "0px"
                                                                    }}
                                                                    type="text"
                                                                    placeholder="Search here"
                                                                    value={searchQuery}
                                                                    onChange={handleSearchChange}
                                                                /> */}

                                                        <input
                                                         style={{
                                                            border: "1px solid grey",
                                                            padding: "0px 4px 0px 1rem;",
                                                            borderRadius: "1rem",
                                                            marginTop: "3px",
                                                            marginLeft: "1rem",
                                                            paddingLeft: "1rem",
                                                            height: "32px",
                                                            paddingBottom: "0px"
                                                        }}
                                                            type="text"
                                                            value={searchQuery}
                                                            onChange={handleSearchChange}
                                                            placeholder="Search..."
                                                        />
                                                            </li>

                                                           



                                                        </ul>
                                                    </div>
                                                    <div class="tab-content tab-content-basic">

                                                        <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="home-tab">
                                                            <p class="card-description">
                                                                {rolePermissions.includes('transfer_location_hub_wise') ? <><button type="button" class="btn btn-success btn-rounded btn-sm" style={{ color: "white" }} onClick={() => addtransferLocationHW()}>Transfer Location-Hub Wise</button></> : <><button type="button" class="btn btn-success btn-rounded btn-sm" style={{ color: "white" }} onClick={() => rolePermission()}>Transfer Location-Hub Wise</button></>}
                                                                {rolePermissions.includes('transfer_location') ? <><button type="button" class="btn btn-success btn-rounded btn-sm" style={{ color: "white" }} onClick={() => addtransferLocation()}>Transfer Location</button></> : <><button type="button" class="btn btn-success btn-rounded btn-sm" style={{ color: "white" }} onClick={() => rolePermission()}>Transfer Location</button></>}
                                                                {rolePermissions.includes('add_route') ? <><button type="button" class="btn btn-success btn-rounded btn-sm" style={{ color: "white" }} onClick={() => addNewLocation()}>Add Route</button></> : <><button type="button" class="btn btn-success btn-rounded btn-sm" style={{ color: "white" }} onClick={() => rolePermission()}>Add Route</button></>}

                                                            </p>
                                                            <div class="">

                                                                <div class="table-responsive">
                                                                    <table class="table table-striped">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    Route
                                                                                </th>
                                                                                <th>
                                                                                    Location
                                                                                </th>
                                                                                <th>
                                                                                    Delivery Executive
                                                                                </th>
                                                                                <th>
                                                                                    Action
                                                                                </th>

                                                                            </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                            {currentItems.map(({ id, data }, index) => {
                                                                                const itemIndex = indexOfFirstItem + index + 1;
                                                                                return (
                                                                                    <tr key={id}>

                                                                                        <td>
                                                                                            {data.route}
                                                                                        </td>
                                                                                        <td>
                                                                                            {data.location}
                                                                                        </td>
                                                                                        <td>
                                                                                            {userMapID[data.delivery_exe_id]}
                                                                                        </td>

                                                                                        <td>
                                                                                            {rolePermissions.includes('update_location') ? <><button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => changeStatusFormLocation(data, id)} class="btn btn-dark btn-sm"><i class="menu-icon mdi mdi-map-marker" style={{ color: "white" }}></i></button></> : <><button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => rolePermission()} class="btn btn-dark btn-sm"><i class="menu-icon mdi mdi-map-marker" style={{ color: "white" }}></i></button></>}
                                                                                            {rolePermissions.includes('edit') ? <><button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} class="btn btn-dark btn-sm" onClick={() => deleteDataLocation(id)}><i class="menu-icon mdi mdi-delete" style={{ color: "white" }}></i></button></> : <><button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} class="btn btn-dark btn-sm" onClick={() => rolePermission()}><i class="menu-icon mdi mdi-delete" style={{ color: "white" }}></i></button></>}

                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })}

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <ul className="pagination pt-4">
                                                                    <li className="page-item">
                                                                        <button onClick={() => paginateL(currentPageL - 1)} className="page-link" disabled={currentPageL === 1}>Previous</button>
                                                                    </li>
                                                                    {renderPageButtonsL()}
                                                                    <li className="page-item">
                                                                        <button onClick={() => paginateL(currentPageL + 1)} className="page-link" disabled={currentPageL === totalPagesL}>Next</button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade" id="audiences" role="tabpanel" aria-labelledby="profile-tab">
                                                            <p class="card-description">
                                                                {rolePermissions.includes('add_hub_users') ? <><button type="button" class="btn btn-success btn-rounded btn-sm" style={{ color: "white" }} onClick={() => addNewuser()}>Add User</button></> : <><button type="button" class="btn btn-success btn-rounded btn-sm" style={{ color: "white" }} onClick={() => rolePermission()}>Add User</button></>}


                                                            </p>
                                                            <div class="media">

                                                                <div class="table-responsive">
                                                                    <table class="table table-striped">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    Hub User Id
                                                                                </th>
                                                                                <th>
                                                                                    First Name
                                                                                </th>
                                                                                <th>
                                                                                    Last Name
                                                                                </th>
                                                                                <th>
                                                                                    Email
                                                                                </th>
                                                                                <th>
                                                                                    Role
                                                                                </th>
                                                                                <th>
                                                                                    Username
                                                                                </th>
                                                                                <th>
                                                                                    Phone Number
                                                                                </th>

                                                                                <th>
                                                                                    Action
                                                                                </th>

                                                                            </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                            {currentItemsuser.map(({ id, data }, index) => {
                                                                                const itemIndex = indexOfFirstItem + index + 1;
                                                                                return (
                                                                                    <tr key={id}>
                                                                                        <td>
                                                                                            {data.hub_user_id}
                                                                                        </td>
                                                                                        <td>
                                                                                            {data.first_name}
                                                                                        </td>
                                                                                        <td>
                                                                                            {data.last_name}
                                                                                        </td>
                                                                                        <td>
                                                                                            {data.email}
                                                                                        </td>
                                                                                        <td>
                                                                                            {data.role}
                                                                                        </td>
                                                                                        <td>
                                                                                            {data.username}
                                                                                        </td>
                                                                                        <td>
                                                                                            {data.phone_no}
                                                                                        </td>


                                                                                        <td>
                                                                                            {rolePermissions.includes('edit_hub_users') ? <> <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => changeStatusFormUser(data, id)} class="btn btn-dark btn-sm"><i class="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i></button></> : <> <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => rolePermission()} class="btn btn-dark btn-sm"><i class="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i></button></>}
                                                                                            {rolePermissions.includes('delete_hub_users') ? <><button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} class="btn btn-dark btn-sm" onClick={() => deleteDatauser(id, data)}><i class="menu-icon mdi mdi-delete" style={{ color: "white" }}></i></button></> : <><button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} class="btn btn-dark btn-sm" onClick={() => rolePermission()}><i class="menu-icon mdi mdi-delete" style={{ color: "white" }}></i></button></>}

                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })}

                                                                        </tbody>
                                                                    </table>

                                                                </div>

                                                            </div>
                                                            <ul className="pagination">
                                                                <li className="page-item">

                                                                    <button onClick={() => paginate(currentPage - 1)} className="page-link" disabled={currentPage === 1}>Previous</button>
                                                                </li>
                                                                {renderPageButtons()}
                                                                <li className="page-item">
                                                                    <button onClick={() => paginate(currentPage + 1)} className="page-link" disabled={currentPage === totalPages}>Next</button>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div class="col-lg-12 grid-margin stretch-card">
                                        <div class="card">
                                            <div class="card-body">
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <h4 class="card-title">HUBS</h4>
                                                    <p class="card-description">
                                                        {rolePermissions.includes('add') ? <>  <button type="button" class="btn btn-success btn-rounded btn-sm" onClick={() => addNew()}>Add Hub/Distributor</button></> : <>  <button type="button" class="btn btn-success btn-rounded btn-sm" onClick={() => rolePermission()}>Add Hub/Distributor</button></>}

                                                    </p>
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    Hub Name
                                                                </th>
                                                                <th>
                                                                    Address
                                                                </th>
                                                                <th>
                                                                    State
                                                                </th>
                                                                <th>
                                                                    City
                                                                </th>
                                                                <th>
                                                                    Mobile No
                                                                </th>
                                                                <th style={{ textAlign: "center" }}>
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data?.map(({ id, data }, index) => (
                                                                <tr key={id} className="hover-highlight">
                                                                    <td>
                                                                        {data.hub_name}
                                                                    </td>
                                                                    <td>
                                                                        {data.address}
                                                                    </td>
                                                                    <td>
                                                                        {data.state}
                                                                    </td>
                                                                    <td>
                                                                        {data.city}
                                                                    </td>
                                                                    <td>
                                                                        {data.mobile_no}
                                                                    </td>
                                                                    <td>
                                                                        {rolePermissions.includes('edit') ? <><button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => changeStatusForm(data, id)} class="btn btn-dark btn-sm"><i class="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i></button></> : <>  <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => rolePermission()} class="btn btn-dark btn-sm"><i class="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i></button></>}
                                                                        {rolePermissions.includes('delete') ? <><button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} class="btn btn-dark btn-sm" onClick={() => deleteData(id, data.hub_name)}><i class="menu-icon mdi mdi-delete" style={{ color: "white" }}></i></button></> : <><button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} class="btn btn-dark btn-sm" onClick={() => rolePermission()}><i class="menu-icon mdi mdi-delete" style={{ color: "white" }}></i></button></>}
                                                                        {rolePermissions.includes('edit') ? <><button style={{ padding: "0.2rem 0.85rem" }} class="btn btn-dark btn-sm" onClick={() => showtabs(data.hub_name)}><i class="menu-icon mdi mdi-eye" style={{ color: "white" }}></i></button></> : <><button style={{ padding: "0.2rem 0.85rem" }} class="btn btn-dark btn-sm" onClick={() => rolePermission()}><i class="menu-icon mdi mdi-eye" style={{ color: "white" }}></i></button></>}

                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }


                            <div class="modal hub fade" id="exampleModal-2" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">Add Hub</h5>
                                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form class="myForm" onSubmit={handleSubmit}>
                                                <div class="form-group row">
                                                    <div class="col">
                                                        <label>Hub Name:</label>
                                                        <input className={`form-control ${errors.hub_name ? 'is-invalid' : ''}`} type="text" onChange={handleChange} id="hub_name" value={submit.hub_name} required autoComplete="off" />
                                                    </div>
                                                    <div class="col">
                                                        <label>Address</label>
                                                        <input className={`form-control ${errors.address ? 'is-invalid' : ''}`} type="text" onChange={handleChange} id="address" value={submit.address} required autoComplete="off" />
                                                    </div>
                                                    <div class="col">
                                                        <label>State</label>
                                                        <select className={`form-control ${errors.state ? 'is-invalid' : ''}`} onChange={handleChange} id="state" value={submit.state} required>
                                                            <option value="">Select State</option>
                                                            {states.map((state, index) => (
                                                                <option key={index} value={state}>{state}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <div class="col">
                                                        <label>City</label>
                                                        <input className={`form-control ${errors.city ? 'is-invalid' : ''}`} type="text" onChange={handleChange} id="city" value={submit.city} required autoComplete="off" />
                                                    </div>
                                                    <div class="col">
                                                        <label>Mobile No</label>
                                                        <input class="form-control" type="tel" pattern="[0-9]*" onChange={handleChange} id="mobile_no" value={submit.mobile_no} maxLength={10} required autoComplete="off" />

                                                    </div>


                                                </div>

                                                <div class="modal-footer">
                                                    <button type="submit" value="submit" class="btn btn-success">Submit</button>
                                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div class="modal um fade" id="exampleModal-2" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <div> <h5 class="modal-title" id="exampleModalLabel">Add User</h5>
                                            </div>
                                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form class="myForm" onSubmit={handleSubmituser}>
                                                <div class="form-group row">
                                                    <div class="col">
                                                        <label>Upload Photo</label>


                                                        {imagePreview ?
                                                            <>
                                                                <div style={{}}>
                                                                    <img src={imagePreview} alt="Preview" style={{ height: "50px", marginTop: "10px", display: "block" }} />
                                                                    <span type="span" onClick={removeImage} className="" style={{ cursor: "pointer", fontSize: "16px", fontWeight: "600" }}>Remove</span>
                                                                </div>
                                                            </>
                                                            : <input type="file" id="image" className="form-control" style={{
                                                                height: "34px",
                                                                padding: "6px 10px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} onChange={handleImageChange} ref={fileInputRef} />}




                                                    </div>

                                                    <div class="col">
                                                        <label>First Name:</label>
                                                        <input class="form-control" type="text" onChange={handleChangeuser} id="first_name" value={submitUser.first_name} required autoComplete="off" />
                                                    </div>
                                                    <div class="col">
                                                        <label>Last Name</label>
                                                        <input class="form-control" type="text" onChange={handleChangeuser} id="last_name" value={submitUser.last_name} required autoComplete="off" />
                                                    </div>

                                                </div>

                                                <div class="form-group row">
                                                    <div class="col">
                                                        <label>Username</label>
                                                        <input class="form-control" type="text" onChange={handleChangeuser} id="username" value={submitUser.username} required autoComplete="off" />
                                                    </div>
                                                    <div className="col">
                                                        <label>Password</label>
                                                        <input
                                                            className="form-control"
                                                            type={showPassword ? "text" : "password"}
                                                            onChange={handlePasswordChange}
                                                            id="password"
                                                            value={submitUser.password}
                                                            required
                                                            autoComplete="off"
                                                        />
                                                    </div>

                                                    <div className="col position-relative">
                                                        <label>Confirm Password</label>
                                                        <div className="input-group">
                                                            <input className="form-control" type={showPassword ? "text" : "password"} onChange={handleChangeuser} id="confirm_password" value={submitUser.confirm_password} autoComplete="off" />
                                                            <span style={{ padding: "4px", borderColor: "lightgray" }} className="btn btn-outline-secondary" type="button" onClick={() => setShowPassword(!showPassword)}>
                                                                <i className={`bi ${showPassword ? "mdi mdi-eye" : "mdi mdi-eye-off"}`}></i>
                                                            </span>
                                                        </div>
                                                        {!passwordMatch && <span style={{ color: 'red' }}>Passwords do not match</span>}
                                                    </div>


                                                </div>
                                                <div class="form-group row">
                                                    <div class="col">
                                                        <label>Email</label>
                                                        <input class="form-control" type="email" onChange={handleChangeuser} id="email" value={submitUser.email} autoComplete="off" />
                                                    </div>

                                                    <div class="col">
                                                        <label>Phone Number</label>
                                                        <input class="form-control" type="tel" pattern="[0-9]*" onChange={handleChangeuser} id="phone_no" value={submitUser.phone_no} maxLength={10} required autoComplete="off" />
                                                    </div>




                                                    <div class="col">
                                                        <label>Select Role</label>
                                                        <select class="form-select" onChange={handleChangeuser} id="role" value={submitUser.role}>
                                                            <option>Select Role</option>
                                                            <option value="Vendor Admin">Vendor Admin</option>
                                                            <option value="Delivery Executive">Delivery Executive</option>
                                                            <option value="Delivery Admin">Delivery Admin</option>
                                                            <option value="Cash Collector">Cash Collector</option>
                                                        </select>
                                                    </div>

                                                </div>

                                                <div class="form-group row">
                                                    <div class="col">
                                                        <input
                                                            type="checkbox"
                                                            id="cash_collector"
                                                            checked={submitUser.cash_collector} // Bind the checked state to the submitUser object
                                                            onChange={(e) => setSubmitUser({ ...submitUser, cash_collector: e.target.checked })} // Update the state when the checkbox is toggled
                                                        />
                                                        <label htmlFor="cash_collector" style={{ marginLeft: '0.5rem' }}>Cash Collector</label>
                                                    </div>
                                                </div>


                                                <div class="modal-footer">
                                                    <button type="submit" value="submit" class="btn btn-success">Submit</button>
                                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div class="modal lm fade" id="exampleModal-2" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">Add Route</h5>
                                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form class="myForm" onSubmit={handleSubmitLocation}>
                                                <div class="form-group row">


                                                    <div class="col">
                                                        <label>Route Name:</label>

                                                        <Select
                                                            options={routeOptions}
                                                            onChange={handleRouteChange}
                                                            value={selectedRouteOptions ? routeOptions.find(option => option.value === selectedRouteOptions) : null}
                                                            placeholder="Select Route Name"
                                                        />

                                                    </div>


                                                    <div class="col">
                                                        <label>Delivery Executive Names</label>

                                                        <Select
                                                            options={deliveryExecutives}
                                                            onChange={handleDeliveryExecutiveChange}
                                                            value={selectedDeliveryExecutive ? deliveryExecutives.find(option => option.value === selectedDeliveryExecutive) : null}
                                                            placeholder="Select Delivery Executive"
                                                        />

                                                    </div>
                                                </div>

                                                <div class="form-group row">

                                                    <div class="col">
                                                        <label for="exampleSelectGender">Locations</label>

                                                        <Select
                                                            value={selectedMapOption}
                                                            onChange={setSelectedMapOption}
                                                            options={locationOptions}
                                                            isMulti
                                                        />
                                                    </div>
                                                </div>

                                                <div class="modal-footer">
                                                    <button type="submit" value="submit" class="btn btn-success">Submit</button>
                                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div class="modal tl fade" id="exampleModal-2" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel-2" aria-hidden="true">
                                <div class="modal-dialog modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel-2">Transfer Location</h5>
                                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form class="forms-sample" onSubmit={handleSubmitTransferLocation}>
                                                <div class="form-group row">
                                                    <div class="col">
                                                        <label>Transfer From</label>
                                                        <Select
                                                            options={userOptions} handleSubmitLocation
                                                            value={selectedUserFrom}
                                                            onChange={(selectedOption) => setSelectedUserFrom(selectedOption)}
                                                        />
                                                    </div>
                                                    <div class="col">
                                                        <label >Transfer To</label>
                                                        <Select
                                                            options={userOptions}
                                                            value={selectedUserTo}
                                                            onChange={(selectedOption) => setSelectedUserTo(selectedOption)}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="form-group row">
                                                    <div class="col" style={{ paddingLeft: "2rem" }}>
                                                        <label>Locations From</label>
                                                        {locations.map(location => (
                                                            <div className="form-check" key={location.id}>
                                                                <input className="form-check-input" type="checkbox" value={location.data.location} id={location.id} onChange={handleLocationFromChange} />
                                                                <label className="form-check-label" htmlFor={location.id}>
                                                                    {location.data.location}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <div class="col">
                                                        <label>Locations To</label>
                                                        {locationsTo.map(location => (
                                                            <div className="form-check" key={location.id}>
                                                                <label className="form-check-label" htmlFor={location.id}>
                                                                    {location.data.location}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div class="modal-footer">
                                                    <button type="submit" value="submit" class="btn btn-success">Submit</button>
                                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal tlh fade" id="exampleModal-2" tabIndex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel-2" aria-hidden="true">
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel-2">Transfer Location Hub Wise</h5>
                                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form className="forms-sample" onSubmit={handleSubmitTH}>
                                                <div className="form-group row">
                                                    <div className="col">
                                                        <label>Transfer From hub</label>
                                                        <Select
                                                            options={hubOptionsOne}
                                                            onChange={handleHubFromChange}
                                                            placeholder="Select Hub Name"
                                                            value={selectedHubFrom}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <label>Transfer To hub</label>

                                                        <Select
                                                            options={hubOptions}
                                                            onChange={handleHubToChange}
                                                            placeholder="Select Hub Name"
                                                            value={selectedHubTo} // Add the value prop here
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col">
                                                        <label>Transfer From</label>
                                                        <Select
                                                            options={userOptionsFrom}
                                                            value={selectedUserFrom}
                                                            onChange={(selectedOption) => setSelectedUserFrom(selectedOption)}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <label >Transfer To</label>
                                                        <Select
                                                            options={userOptionsTo}
                                                            value={selectedUserTo}
                                                            onChange={(selectedOption) => setSelectedUserTo(selectedOption)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col" style={{ paddingLeft: "2rem" }}>
                                                        <label>Locations From</label>
                                                        {locations.map(location => (
                                                            <div className="form-check" key={location.id}>
                                                                <input className="form-check-input" type="checkbox" value={location.data.location} id={location.id} onChange={handleLocationFromChange} />
                                                                <label className="form-check-label" htmlFor={location.id}>
                                                                    {location.data.location}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="col">
                                                        <label>Locations To</label>
                                                        {locationsTo.map(location => (
                                                            <div className="form-check" key={location.id}>
                                                                <label className="form-check-label" htmlFor={location.id}>
                                                                    {location.data.location}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="submit" value="submit" className="btn btn-success">Submit</button>
                                                    <button type="button" className="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="modal loma fade" id="exampleModal-2" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">Update Location</h5>
                                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form class="myForm" onSubmit={handleSubmitLocationEdit}>
                                                <div class="form-group row">


                                                    <div class="col">
                                                        <label>Route Name:</label>

                                                        <Select
                                                            options={routeOptions}
                                                            onChange={handleRouteChange}
                                                            value={selectedRouteOptions ? routeOptions.find(option => option.value === selectedRouteOptions) : null}
                                                            placeholder="Select Route Name"
                                                        />

                                                    </div>


                                                    <div class="col">
                                                        <label>Delivery Executive Names</label>

                                                        <Select
                                                            options={deliveryExecutives}
                                                            onChange={handleDeliveryExecutiveChange}
                                                            value={selectedDeliveryExecutive ? deliveryExecutives.find(option => option.value === selectedDeliveryExecutive) : null}
                                                            placeholder="Select Delivery Executive"
                                                        />

                                                    </div>
                                                </div>

                                                <div class="form-group row">

                                                    <div class="col">
                                                        <label for="exampleSelectGender">Location</label>

                                                        <Select
                                                            value={selectedMapOption}
                                                            onChange={setSelectedMapOption}
                                                            options={locationOptions}
                                                            isMulti
                                                        />
                                                    </div>
                                                </div>

                                                <div class="modal-footer">
                                                    <button type="submit" value="submit" class="btn btn-success">Submit</button>
                                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>




                        </div>

                        <Footer />

                    </div>

                </div>

            </div>
        </>
    )
}

export default HubDist
