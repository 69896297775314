import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Alert } from "react-bootstrap";
import { collection, getDocs, query, where, Timestamp } from "firebase/firestore";
import db from "../Firebase";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "react-datepicker/dist/react-datepicker.css";

function CustomerReport() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  /* Export table to CSV */
  const exportTableToCSV = () => {
    const csvColumns = ["Sr.No", "Hub", "Customer Name", "Customer's Contact", "Wallet Amount", "Credit Limit", "Alternate Number", "Registered On", "Account Status"];
    const csvRows = customers.map((customer, index) => {
      const rowData = [
        index + 1,
        customer.hub_name || "Hub Not Added",
        customer.customer_name || "No data",
        customer.customer_phone || "No data",
        customer.wallet_balance || "0",
        customer.credit_limit || "0",
        customer.alt_phone || "Not Added",
        customer.registered_date && customer.registered_date.seconds
          ? new Date(customer.registered_date.seconds * 1000).toLocaleDateString()
          : "N/A",
        customer.status === 1 ? "Active" : "Inactive"
      ];
      return rowData;
    });
    const csvContent = [
      csvColumns.join(","), 
      ...csvRows.map(row => row.join(",")) 
    ].join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `customer_report.csv`); 
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click(); 
    document.body.removeChild(link); 
  };

  /* Export table to PDF */
  const exportTableToPDF = () => {
    const doc = new jsPDF();
    doc.text("Customer Report", 20, 10);
    doc.autoTable({ html: "#hub_delivery" });
    doc.save("customer_report.pdf");
  };

  /* Export table to Excel */
  const exportTableToEXL = () => {
    const table = document.getElementById("hub_delivery");
    const worksheet = XLSX.utils.table_to_sheet(table);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "CustomerReport");
    XLSX.writeFile(workbook, "customer_report.xlsx");
  };

  /* Reset search form */
  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setCustomers([]);
    setDataLoaded(false);
    setErrorMessage("");
  };

  // Search customers based on date range
  const handleSearch = async () => {
    setErrorMessage("");
    if (!startDate || !endDate) {
      setErrorMessage("Please select both start and end dates.");
      return;
    }
    const TWO_MONTHS_IN_MILLISECONDS = 60 * 24 * 60 * 60 * 1000;
    const timeDiff = endDate - startDate;
    if (timeDiff > TWO_MONTHS_IN_MILLISECONDS) {
      setErrorMessage("Please select a date range within two months.");
      return;
    }

    setLoading(true);
    try {
      const startTimestamp = Timestamp.fromDate(startDate);
      const endTimestamp = Timestamp.fromDate(endDate);

      const q = query(
        collection(db, "customers_data"),
        where("registered_date", ">=", startTimestamp),
        where("registered_date", "<=", endTimestamp)
      );

      const querySnapshot = await getDocs(q);
      const customerData = querySnapshot.docs.map(doc => doc.data());
      console.log("------------data", customerData);

      setCustomers(customerData);
      setDataLoaded(true);

      if (customerData.length === 0) {
        setErrorMessage("No data found within the selected date range.");
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
      setErrorMessage("Error fetching customer data.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div>
            <img
              style={{ height: "6rem" }}
              src="images/loader.gif"
              alt="Loading..."
            />
          </div>
        </div>
      )}
      <div className="container-scroller">
        <div className="container-fluid">
          <div className="main-panel" style={{ width: "100%" }}>
            <div className="panel" style={{ display: "flex" }}>
              <span
                style={{
                  fontSize: "18px",
                  color: "#288a84",
                  fontWeight: "700",
                  marginTop: "12px",
                }}
              >
                CUSTOMER REPORT
              </span>
              <div style={{ marginLeft: "65%" }}>
                {dataLoaded && (
                  <Button onClick={exportTableToEXL} className="btn btn-success btn-rounded btn-sm">
                    Export Excel
                  </Button>
                )}
              </div>
              {dataLoaded && (
                <>
                  <button
                    className="btn btn-success btn-rounded btn-sm"
                    onClick={exportTableToPDF}
                    style={{ marginLeft: "-15%" }}
                  >
                    Export PDF
                  </button>
                </>
              )}
              {dataLoaded && (
                <>
                  <button
                    className="btn btn-success btn-rounded btn-sm"
                    onClick={exportTableToCSV}
                    style={{ marginLeft: "-15%" }}
                  >
                    Export CSV
                  </button>
                </>
              )}
            </div>
            <br />
            <div className="panel">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "90%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label>Start Date:</label>
                  <br />
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="From reg"
                    className="form-control"
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <label>End Date:</label>
                  <br />
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="To reg"
                    className="form-control"
                  />
                </div>
                <div className="inputPanels" style={{ marginTop: "30px" }}>
                  <Button variant="outline-success" onClick={handleSearch} size="sm">
                    Search
                  </Button>
                  <Button
                    variant="outline-success"
                    onClick={handleReset}
                    style={{ marginLeft: "10px" }}
                    size="sm"
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </div>

            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

            {/* Display Total Count */}
            {dataLoaded && (
              <div style={{ marginBottom: "10px",marginTop: "10px" }}>
                Total Customers: {customers.length}
              </div>
            )}

<div style={{ overflowY: "auto", maxHeight: "600px",  }}>
              <table className="table table-striped" id="hub_delivery">
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Hub</th>
                    <th>Customer Name</th>
                    <th>Customer's Contact</th>
                    <th>Customer's Address</th>
                    <th>Wallet Amount</th>
                    <th>Credit Limit</th>
                    <th>Alternate Number</th>
                    <th>Registered On</th>
                    <th>Account Status</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.length > 0 ? (
                    customers.map((customer, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{customer.hub_name || "Hub Not Added"}</td>
                        <td>{customer.customer_name || "No data"}</td>
                        <td>{customer.customer_phone || "No data"}</td>
                        <td>{customer.customer_address || "No data"}</td>
                        <td>{customer.wallet_balance || "0"}</td>
                        <td>{customer.credit_limit || "0"}</td>
                        <td>{customer.alt_phone || "Not Added"}</td>
                        <td>
                          {customer.registered_date && customer.registered_date.seconds
                            ? new Date(customer.registered_date.seconds * 1000).toLocaleDateString()
                            : "N/A"}
                        </td>
                        <td>{customer.status == 1 ? "Active" : "Inactive"}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CustomerReport;
