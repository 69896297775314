export const ROLES = ["Admin","Customer Care Agent"]
export const CONVERSASTION_LOGS_REQUIRED_FIELD = ["interaction_type","conversation_notes","disposition","sub_disposition","tags","followup_required"]
export const FOLLOW_REQUIRED_FIELD = ["follow_up_date","assigned_to"]
export const INTERACTION_TYPE_REQUIRED_FIELDS = {
  
    call: ["call_type"],
    email: ["email_subject"],
}

export const TASK_TYPE = Object.freeze({
    FOLLOW_UP: "FOLLOW_UP",
    ONBOARD: "ONBOARD",
})

export const TASK_STATUS = Object.freeze({
    PENDING: "PENDING",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
})


export const API_URL = "https://api.whytefarms.com/api/v1"
export const API_KEY = "TKcxfwjLElCOLKgDZCCZkAUaeaDhLjuyVlbDkIcYSiCILFfNBT"