import React from 'react';

function Footer() {

    return <footer class="footer">
        <div class="d-sm-flex justify-content-center justify-content-sm-between">

            <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Copyright © 2024. All rights
                reserved.</span>
        </div>
    </footer>
};

export default Footer;