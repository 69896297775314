import db from "./Firebase";
import moment from 'moment';

export const getUserInfo = () => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    const userId = localStorage.getItem("userId");
    const username = localStorage.getItem("username");
    const loggedIn_user = localStorage.getItem("loggedIn_user");
    

    return { loggedIn, userId, username , loggedIn_user };
};

export function calculateChanges(old_data, submit) {
    let changes = {};

    for (let key in submit) {
        if (submit.hasOwnProperty(key) && key !== 'created_date' && key !== 'updated_date') {
            if (key === 'due_date') {
                let oldDueDate, newDueDate;
                console.log(old_data[key], submit[key]);
                try {
                    oldDueDate = moment(old_data[key].toDate().toISOString()).format("YYYY-MM-DD");
                } catch (e) {
                    oldDueDate = moment(old_data[key]).format("YYYY-MM-DD");
                }

                try {
                    newDueDate = moment(submit[key].toDate().toISOString()).format("YYYY-MM-DD");
                } catch (e) {
                    newDueDate = moment(submit[key]).format("YYYY-MM-DD");
                }

                if (oldDueDate !== newDueDate) {
                    changes[key] = {
                        old: oldDueDate,
                        new: newDueDate
                    };
                }
            } else if (submit[key] !== old_data[key]) {
                changes[key] = {
                    old: old_data[key],
                    new: submit[key]
                };
            }
        }
    }

    return changes;
}

export async function  reconsileWallet(customer_id, wallet_data) {
    let transaction_id = prompt("Please Enter the last correct transaction id", "");

    if (transaction_id === null || transaction_id === "") {
        alert("Please enter the correct transaction id");
        return;
    }
    let wrong_transactions = [];
    let final_balance = null;
    for (let i = 0; i < wallet_data.length; i++) {
        if (wallet_data[i].data.txn_id !== transaction_id) {
            wrong_transactions.push(wallet_data[i]);
        }else{
            final_balance = Number(wallet_data[i].data.current_wallet_balance);
            break;
        }
    }
    wrong_transactions.reverse();

    for (let i = 0; i < wrong_transactions.length; i++) {
        console.log("The wrong transaction", wrong_transactions[i].data.type);
        if(wrong_transactions[i].data.type.toLowerCase() === "credit"){
            final_balance += Number(wrong_transactions[i].data.amount);

        }else if(wrong_transactions[i].data.type.toLowerCase() === "debit"){
            final_balance -= Number(wrong_transactions[i].data.amount);
        }
        console.log("The final balance",final_balance);
        db.collection("wallet_history").doc(wrong_transactions[i].id).update({
            current_wallet_balance: final_balance
        });

    }
    let customerDataSnapshot = await db.collection("customers_data").where("customer_id", "==", customer_id).get();
    const customerData = customerDataSnapshot.docs[0];
    await db.collection("customers_data").doc(customerData.id).update({
        wallet_balance: final_balance
    });
    
}

export function generateRandomId() {
    const now = new Date();
    const timestamp = now.getTime(); // Get the timestamp in milliseconds since January 1, 1970
    const random4Digits = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0"); 

    const customerId =
      (timestamp % 10000).toString().padStart(4, "0") + random4Digits;

    return customerId;
  }

