import React, { useState, useEffect } from "react";
import db from "./Firebase";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./OnBoardCustomer.css";
import { toast } from "react-toastify";
import Moment from "moment";
import { extendMoment } from "moment-range";
import Alert from "react-bootstrap/Alert";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Select from "react-select";
import "./Report.css";
import "./OrderSheet.css";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExportTableToExcel from "./ExportTableToExcel";
import { collection, getDocs, query, where, getCountFromServer } from 'firebase/firestore';

function OneTimeOrders() {
    const navigate = useNavigate();

    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        console.log(loggedIn);
        if (loggedIn) {
            // navigate("/");
        } else {
            navigate("/login");
        }
    }, [navigate]);
    const moment = extendMoment(Moment);
    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [dataLoaded, setDataLoaded] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [orderReports, setOrderReports] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [show, setShow] = useState(false);
    const [selectedHub, setSelectedHub] = useState(null);
    const [deliveryExecutiveNames, setDeliveryExecutiveNames] = useState([]);
    const [hubNames, setHubNames] = useState([]);
    const [selectedDeliveryExecutive, setSelectedDeliveryExecutive] = useState();
    const [selectedOrderStatus, setSelectedOrderStatus] = useState();
    const [productList, setProductList] = useState([]);
    const [totalQtyOrder, setTotalQtyOrder] = useState(0);
    const [orderSheet, setOrdersheet] = useState({});
    const [orderDataList, setOrderDataList] = useState([]);
    const [productSummary, setProductSummary] = useState([]);
    const [productListQty, setProductListQty] = useState([]);
    const [deliveryExecutivesMap, setDeliveryExecutivesMap] = useState(new Map());
    const orderStatusOptions = [
        { label: "New", value: "0" },
        { label: "Cancelled", value: "2" },
        { label: "Delivered", value: "1" },
    ];
    // const firstDeliveryOptions = [
    //     { label: "One Time Order", value: "One Time Order" }
    // ];
    const [selectedFirstDelivery, setSelectedFirstDelivery] = useState('');

    const getDeliveryExecutive = async () => {
        const hub_users_snap = await db.collection('hubs_users_data')
            .where('role', '==', 'Delivery Executive')
            .get();
        const de_data = hub_users_snap.docs.map(doc => doc.data());
        const newMap = new Map();
        de_data.forEach(data => {
            const value = `${data.first_name} ${data.last_name}`;
            newMap.set(data.hub_user_id, value);

            //deliveryExecutivesMap.set(details.hub_user_id , `${details.first_name} ${details.last_name}`);
        })

        setDeliveryExecutivesMap(newMap);
    }
    useEffect(() => {

        getDeliveryExecutive();

    }, []);

    const handleSearch = async () => {
        setDataLoaded(false);
        setShowSpinner(true);
    
        const formattedDate = moment(deliveryDate).format('YYYY-MM-DD');
        console.log("Selected date => ", formattedDate);
    
        // Default conditions for fetching orders
        const conditions = [where('order_type', '==', 'OT')];
    
        if (selectedHub) {
            conditions.push(where('hub_name', '==', selectedHub.value));
        }
        if (selectedDeliveryExecutive) {
            conditions.push(where('delivery_exe_id', '==', selectedDeliveryExecutive.value));
        }
        if (selectedOrderStatus) {
            conditions.push(where('status', '==', selectedOrderStatus.value));
        }
        if (deliveryDate) {
            conditions.push(where('delivery_date', '==', formattedDate));
        }
    
        try {
            // Fetch orders based on default conditions
            const q = query(collection(db, 'order_history'), ...conditions);
            const querySnapshot = await getDocs(q);
    
            const orderReports = [];
            querySnapshot.forEach((doc) => {
                orderReports.push({ id: doc.id, ...doc.data() });
            });
    
            let filteredOrders = orderReports;
    
            if (selectedFirstDelivery && selectedFirstDelivery.value === 'First Time Order') {
                const firstTimeOrders = await Promise.all(
                    orderReports.map(async (order) => {
                        const collRef = collection(db, 'order_history');
    
                        // Check if it's a one-time order
                        const oneTimeOrderQ = query(
                            collRef,
                            where('customer_id', '==', order.customer_id),
                            where('order_type', '==', 'OT')
                        );
                        const oneTimeOrderSnapshot = await getCountFromServer(oneTimeOrderQ);
                        const isOneTimeOrder = oneTimeOrderSnapshot.data().count === 1;
    
                        if (isOneTimeOrder) {
                            // Check if it's the first time order
                            const firstTimeOrderQ = query(
                                collRef,
                                where('customer_id', '==', order.customer_id),
                                where('delivery_date', '<', order.delivery_date),
                                where('product_name', '==', order.product_name)
                            );
                            const firstTimeOrderSnapshot = await getCountFromServer(firstTimeOrderQ);
                            const isFirstTimeOrder = firstTimeOrderSnapshot.data().count === 0;
    
                            if (isFirstTimeOrder) {
                                // Verify the product is not ordered in the past before the current order date
                                const pastOrdersQ = query(
                                    collRef,
                                    where('customer_id', '==', order.customer_id),
                                    where('delivery_date', '<', formattedDate),
                                    where('product_name', '==', order.product_name)
                                );
                                const pastOrdersSnapshot = await getCountFromServer(pastOrdersQ);
                                const hasPastOrders = pastOrdersSnapshot.data().count > 0;
    
                                if (!hasPastOrders) {
                                    // Check the created_date
                                    if (!order.created_date) {
                                        console.error('Missing created_date:', order);
                                        return null; // Skip this order if created_date is missing
                                    }
    
                                    // Convert Firestore Timestamp to Date
                                    const createdDate = order.created_date.toDate ? order.created_date.toDate() : new Date(order.created_date.seconds * 1000);
    
                                    // Format the date
                                    const formattedCreatedDate = moment(createdDate).format('YYYY-MM-DD');
    
                                    if (!moment(formattedCreatedDate, 'YYYY-MM-DD', true).isValid()) {
                                        console.error('Invalid created_date:', createdDate);
                                        return null; // Skip this order if created_date is invalid
                                    }
    
                                    const pastCreatedDatesQ = query(
                                        collRef,
                                        where('customer_id', '==', order.customer_id),
                                        where('created_date', '<', formattedCreatedDate),
                                        where('product_name', '==', order.product_name)
                                    );
                                    const pastCreatedDatesSnapshot = await getCountFromServer(pastCreatedDatesQ);
                                    const hasPastCreatedDates = pastCreatedDatesSnapshot.data().count > 0;
    
                                    if (!hasPastCreatedDates) {
                                        return { ...order, isFirstTimeOrder: true };
                                    }
                                }
                            }
                        }
                        return null;
                    })
                );
                filteredOrders = firstTimeOrders.filter(order => order !== null);
            }
    
            console.log("Filtered Orders =>", filteredOrders);
            setOrderReports(filteredOrders);
        } catch (error) {
            console.error("Error fetching documents: ", error);
        } finally {
            setDataLoaded(true);
            setShowSpinner(false);
        }
    };
    
    
    
    
    
    
    
    
    

    
    
    const handleReset = () => {
        setDeliveryDate(null);
        setSelectedHub("");
        setSelectedDeliveryExecutive("");
        setOrderReports([]);

    };

    const SpinnerOverlay = () => (
        <div className="spinner-overlay">
            <div className="spinner"></div>
        </div>
    );

    // Determine the range of page numbers to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    //const currentItems = OnboardedCustomer.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const renderPageButtons = () => {

        const pageButtons = [];
        // Determine the range of page numbers to display
        let startPage = Math.max(1, currentPage - 5);
        let endPage = Math.min(totalPages, startPage + 9);

        // If the total number of pages is less than 10, adjust the endPage
        if (totalPages <= 10) {
            endPage = totalPages;
        } else {
            // If the current page is near the start, display the first 10 pages
            if (currentPage <= 5) {
                startPage = 1;
                endPage = 10;
            }
            // If the current page is near the end, display the last 10 pages
            else if (currentPage >= totalPages - 4) {
                endPage = totalPages;
                startPage = endPage - 9;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageButtons.push(
                <li
                    key={i}
                    className={`page-item ${currentPage === i ? "active" : ""}`}
                >
                    <button
                        onClick={() => paginate(i)}
                        className="page-link"
                        style={{ color: "black" }}
                    >
                        {i}
                    </button>
                </li>
            );
        }

        return pageButtons;
    };

    useEffect(() => {
        db.collection("hubs_data").onSnapshot((snapshot) => {
            setHubNames(
                snapshot.docs.map((doc) => ({
                    label: doc.data().hub_name,
                    value: doc.data().hub_name,
                }))
            );
        });
    }, []);

    useEffect(() => {
        const fetchDeliveryExeList = async () => {

            try {
                if (!selectedHub) {
                    setDeliveryExecutiveNames([]);
                    return;
                }
                const snapshot = await db
                    .collection("hubs_users_data")
                    .where("hub_name", "==", selectedHub.value)
                    .get();
                const de = snapshot.docs.map((doc) => {
                    const data = doc.data();
                    return {

                        value: `${data.hub_user_id}`,
                        label: `${data.first_name} ${data.last_name}`,
                    };
                });
                setDeliveryExecutiveNames(de);
            } catch (error) {
                console.error("Error fetching delivery executive:", error);
            }
        };

        fetchDeliveryExeList();
    }, [selectedHub]);



    const handleHubChange = async (selectedOption) => {

        setSelectedHub(selectedOption);
    };

    const handleDEchange = async (selectedOption) => {
        setSelectedDeliveryExecutive(selectedOption);
    };

    const handleOrderStatusChange = async (selectedOption) => {
        setSelectedOrderStatus(selectedOption);
    }


    return (
        <>
            {showSpinner && ( // Render loader when loading state is true
                <div className="loader-overlay">
                    <div className="">
                        <img style={{
                            height: "6rem"
                        }} src="images/loader.gif" alt="loader"></img>
                    </div>
                </div>
            )}
            <div class="container-scroller">
                <div class="container-fluid">
                    <div class="main-panel" style={{ width: '100%' }}>
                        <div className="panel" style={{ marginTop: "10px", marginBottom: "10px" }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>One Time Deliveries</span>
                                <div>
                                    {dataLoaded &&
                                        // <button className="btn btn-success btn-rounded btn-sm">Export</button>
                                        <ExportTableToExcel
                                            tableId="one_time_orders_table"
                                            fileName="one_time_orders"
                                        />
                                        }
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '90%' }}>
                                <div style={{ marginTop: "7px" }}>
                                    <label style={{ marginBottom: '5px' }}>Delivery Date</label>
                                    <br />
                                    <DatePicker
                                        selected={deliveryDate}
                                        onChange={(date) => setDeliveryDate(date)}
                                        dateFormat="dd/MM/yyyy" // Format for displaying the date
                                        className="datepicker-input"
                                        placeholderText="Date"
                                    />
                                </div>

                                <div>
                                    <label style={{ marginBottom: '10px' }}>Hubs</label>
                                    <Select
                                        options={hubNames}
                                        onChange={handleHubChange}
                                        value={selectedHub}
                                        placeholder="Hub Name"
                                        required
                                    />
                                </div>
                        <div>
                            <label style={{ marginBottom: '10px' }}>Delivery Executive</label>
                            <Select
                                options={deliveryExecutiveNames}
                                onChange={handleDEchange}
                                value={selectedDeliveryExecutive}
                                placeholder="Delivery Executive"
                                required
                            />
                        </div>
                        <div>
                            <label style={{ marginBottom: '10px' }}>Order Status</label>
                            <Select
                                options={orderStatusOptions}
                                onChange={handleOrderStatusChange}
                                value={selectedOrderStatus}
                                placeholder="Order Status"
                                required
                            />
                        </div>
                        
                        <div>
                            <label style={{ marginBottom: '10px' }}>Delivery Type</label>
                            <Select
                                options={[
                                    { value: 'One Time Order', label: 'One Time Order' },
                                    { value: 'First Time Order', label: 'First Time Order' },
                                ]}
                                onChange={setSelectedFirstDelivery}
                                value={selectedFirstDelivery}
                                placeholder="Select Order Type"
                            />
                        </div>
                        
                        <div style={{ marginTop: '25px' }}>
                            <Button variant="outline-success" onClick={handleSearch} size='sm'>
                                Search
                            </Button>
                            <Button
                                variant="outline-success"
                                onClick={handleReset}
                                style={{ marginLeft: "10px" }}
                                size='sm'
                            >
                                Reset
                            </Button>
                        </div>
                    </div>

                <br />
            </div>


            <div className="table-responsive">
    <table className="table table-striped" id="one_time_orders_table">
        <thead>
            <tr>
                <th>Order Id</th>
                <th>Product Name</th>
                <th>Hub</th>
                <th>Delivery Executive</th>
                <th>Customer Name & Phone</th>
                <th>Customer Address</th>
                <th>Order Status</th>
                <th>Order Amount</th>
                <th>Delivery Date</th>
                <th>Delivery Time</th>
            </tr>
        </thead>
        <tbody>
            {dataLoaded && orderReports.map((order, index) => (
                <tr
                    key={index}
                    style={{
                        backgroundColor: order.isFirstTimeOrder ? 'lightgreen' : 'white'
                    }}
                >
                    <td>{order.order_id}</td>
                    <td className="wrap-text customer-name-phone">{order.product_name}</td>
                    <td>{order.hub_name}</td>
                    <td>{deliveryExecutivesMap.get(order.delivery_exe_id)}</td>
                    <td className="wrap-text customer-name-phone">{`${order.customer_name} / ${order.customer_phone}`}</td>
                    <td className="wrap-text customer-name-phone" style={{ maxWidth: '400px' }}>{order.location}</td>
                    <td>
                        {order.status === "0" && <div className="badge badge-pill badge-outline-info" style={{ marginRight: "1rem" }}>New</div>}
                        {order.status === "1" && <div className="badge badge-pill badge-outline-success" style={{ marginRight: "1rem" }}>Delivered</div>}
                        {order.status === "2" && <div className="badge badge-pill badge-outline-danger" style={{ marginRight: "1rem" }}>Cancelled</div>}
                    </td>
                    <td>{parseInt(order.total_amount)}</td>
                    <td>{moment(order.delivery_date).format("DD-MM-YYYY")}</td>
                    <td>{moment(order.delivery_time, 'HH:mm:ss').format('hh:mm A')}</td>
                </tr>
            ))}
        </tbody>
    </table>
</div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default OneTimeOrders;
