import React, { useState } from 'react';
//import './ScheduledOrders.css'; // Import custom CSS file

const ScheduledOrders = () => {
  const [nextDeliveryDate, setNextDeliveryDate] = useState('');
  const [intervalDays, setIntervalDays] = useState('');
  const [futureDate, setFutureDate] = useState('');
  const [isScheduled, setIsScheduled] = useState(false);

  const handleNextDeliveryDateChange = (e) => {
    setNextDeliveryDate(e.target.value);
  };

  const handleIntervalDaysChange = (e) => {
    setIntervalDays(e.target.value);
  };

  const handleFutureDateChange = (e) => {
    setFutureDate(e.target.value);
  };

  const calculateScheduledDates = () => {
    const deliveryDate = new Date(nextDeliveryDate);
    const interval = parseInt(intervalDays);
    const futureCheckDate = new Date(futureDate);

    if (isNaN(interval) || !deliveryDate || !futureCheckDate) {
      setIsScheduled(false);
      return;
    }

    let currentDate = new Date(deliveryDate);
    while (currentDate <= futureCheckDate) {
      if (currentDate.getTime() === futureCheckDate.getTime()) {
        setIsScheduled(true);
        return;
      }
      currentDate.setDate(currentDate.getDate() + interval);
    }

    setIsScheduled(false);
  };

  return (
    <div className="container">
      <h1>Check Scheduled Order</h1>
      <form>
        <div className="form-group">
          <label>Next Delivery Date</label>
          <input
            type="date"
            value={nextDeliveryDate}
            onChange={handleNextDeliveryDateChange}
          />
        </div>
        <div className="form-group">
          <label>Interval Days</label>
          <input
            type="number"
            value={intervalDays}
            onChange={handleIntervalDaysChange}
          />
        </div>
        <div className="form-group">
          <label>Future Date to Check</label>
          <input
            type="date"
            value={futureDate}
            onChange={handleFutureDateChange}
          />
        </div>
        <button type="button" onClick={calculateScheduledDates}>
          Check
        </button>
      </form>
      {futureDate && (
        <div className="result">
          {isScheduled ? (
            <p>There is a scheduled order on {futureDate}.</p>
          ) : (
            <p>There is no scheduled order on {futureDate}.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ScheduledOrders;
