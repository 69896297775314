import React, { useState } from 'react';
import axios from 'axios';

const PayUMoneyPayment = () => {
    const [paymentUrl, setPaymentUrl] = useState('');

    const createPaymentLink = async () => {
        try {
            // Make API request to your backend to generate payment link
            const response = await axios.post('/api/create-payment', {
                // Pass any required parameters like amount, product details, etc.
                amount: 100,
                product: 'Example Product',
                // Add other required parameters like merchant ID, salt, key
                merchantId: '5968137',
                key: 'pCVpYPUj',
                salt: 'M21EDZ10Hx',
            });

            // Once you get the payment link, set it in state
            setPaymentUrl(response.data.paymentLink);
        } catch (error) {
            console.error('Error creating payment link:', error);
        }
    };

    return (
        <div>
            <button onClick={createPaymentLink}>Create Payment Link</button>
            {paymentUrl && <a href={paymentUrl} target="_blank" rel="noopener noreferrer">Proceed to Payment</a>}
        </div>
    );
};

export default PayUMoneyPayment;
