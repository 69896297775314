import React, { useState, useEffect } from "react";
import TopPanel from "../../TopPanel";
import Sidebar from "../../Sidebar";
import Footer from "../../Footer";
import db from "../../Firebase";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import CreateCafeModal from "./AddCafeForm";
import PlaceOrderModal from "./PlaceOrderForm";
import Swal from 'sweetalert2'
import ViewCafeOrders from "./CafeOrderHistory";

function CafeManagement() {
  const [cookies] = useCookies(["permissions"]);
  const navigate = useNavigate();
    useEffect(() => {
        if (!cookies.permissions) {
          localStorage.clear();
          navigate("/login");
        }
    }, [navigate, cookies.permissions]);
  const rolePermissions = cookies.permissions ? cookies.permissions.cafeData || [] : [];
  const Toast = Swal.mixin({
    toast: true,
    background: '#69aba6',
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  const initialFormState = {
    type: "",
    cafe_name: "",
    delivery_hub: "",
    contact_person_name: "",
    username: "",
    password: ""
  };
  const [formData, setFormData] = useState({ ...initialFormState });
  const loggedInUser = localStorage.getItem("loggedIn_user")
  const [loading, setLoading] = useState(false);  
  const [docId, setDocId] = useState("");
  const [edit, setEdit] = useState(false);
  const [cafeData, setCafeData] = useState([]); 
  const [rowData, setRowData] = useState({})

  const notAuthorized = () => {
    const Toast = Swal.mixin({
        toast: true,
        background: '#d7e7e6',
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    Toast.fire({
        icon: 'error',
        title: 'You are not authorised to do this action'
    });
}

  const addCafe = () => {
      window.modelshowCafe()
  }

  const editCafe = (data, id) => {
    if (rolePermissions.includes('edit')){
      setEdit(true)
      setDocId(id)
      setFormData({ id: id, cafe_name: data.cafe_name, delivery_hub: data.delivery_hub, type: data.type, contact_person_name: data.contact_person_name, username: data.user_name, password: data.password });
      addCafe();
    } else {
      notAuthorized()
    }
  }

  const handlePlaceOrderClick = () => {
    window.modelshowPlaceOrder()
  }

  const placeOrder = (data, id) => {
    setDocId(id)
    setFormData({ id: id, cafe_name: data.cafe_name, delivery_hub: data.delivery_hub, type: data.type, contact_person_name: data.contact_person_name });
    handlePlaceOrderClick();
  }

  const viewOrders = (data, id) => {
    setDocId(id)
    setRowData({ id: id, cafe_name: data.cafe_name, delivery_hub: data.delivery_hub, type: data.type, contact_person_name: data.contact_person_name });
    window.modelshowCafeOrders()
  }

  const fetchCafeDetails = async () => {
    try {
      const snapshot = await db.collection("cafe_master").get()
      const cafes = snapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      }));
      setCafeData(cafes);
    } catch (error) {
      console.error("Error fetchind cafe details", error)
    }
  }

  useEffect(() => {
    fetchCafeDetails()
  }, [])

  const deleteCafe = (id) => {
    if (rolePermissions.includes('delete')){
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        className: "p-5"
      }).then((result) => {
        if(result.isConfirmed) {
          setLoading(true)
          db.collection("cafe_master").doc(id).delete().then(() => {
            window.modelhideCafe();
            Toast.fire({
              icon: 'success',
              title: 'Cafe Deleted'
            });
            fetchCafeDetails();
            setLoading(false)
          })
        }
      })
    } else {
      notAuthorized()
    }
  }

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="">
            <img
              alt="loader"
              style={{
                height: "6rem",
              }}
              src="images/loader.gif"
            ></img>
          </div>
        </div>
      )}
      <div class="container-scroller">
        <TopPanel />
        <div class="container-fluid page-body-wrapper">
          <Sidebar />
          <div class="main-panel">
            <div class="content-wrapper">
              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class='card-body'>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <h4 class="card-title">Cafe / E-commerce Management</h4>
                      <p class="card-description">
                        <button 
                          type="button" 
                          class="btn btn-success btn-rounded btn-sm" 
                          onClick={() => {
                            if (rolePermissions.includes('add')) {
                              addCafe();
                              setEdit(false)
                              setDocId('')
                            } else {
                              notAuthorized()
                            }
                          }}
                        >
                          Add Cafe/ E-Commerce Platform
                        </button>
                      </p>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-striped">
                          <thead>
                              <tr>
                                  <th style={{ textAlign: "center" }}>Name</th>
                                  <th style={{ textAlign: "center" }}>Delivery Hub</th>
                                  <th style={{ textAlign: "center" }}>Type</th>
                                  <th style={{ textAlign: "center" }}>Place Order</th>
                                  <th style={{ textAlign: "center" }}>Order History</th>
                                  <th style={{ textAlign: "center" }}>Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              {cafeData?.map(({id, data}) => (
                                  <tr key={id} className="hover-highlight">
                                      <td style={{ textAlign: "center" }}>{data.cafe_name}</td>
                                      <td style={{ textAlign: "center" }}>{data.delivery_hub}</td>
                                      <td style={{ textAlign: "center" }}>{data.type}</td>
                                      <td style={{ textAlign: "center" }}>
                                        <button
                                          class="btn btn-dark btn-md"
                                          style={{ padding: "0.2rem 0.85rem" }}
                                          onClick={() => placeOrder(data, id)}
                                        >
                                          Place Order
                                        </button>
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        <button
                                          class="btn btn-dark btn-md"
                                          style={{ padding: "0.2rem 0.85rem" }}
                                          onClick={() => viewOrders(data, id)}
                                        >
                                          View Orders
                                        </button>
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        <button
                                          class="btn btn-dark btn-sm"
                                          style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }}
                                          onClick={() => editCafe(data, id)}
                                        >
                                          <i class="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i>
                                        </button>
                                        <button
                                          class="btn btn-dark btn-sm"
                                          style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }}
                                          onClick={() => deleteCafe(id, data.cafe_name)}
                                        >
                                          <i class="menu-icon mdi mdi-delete" style={{ color: "white" }}></i>
                                        </button>
                                      </td>
                                  </tr>
                              ))}
                          </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal cafe fade" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-md" role="document">
                  <div class="modal-content">
                    <CreateCafeModal formData={formData} setFormData={setFormData} initialFormState={initialFormState} loggedInUser={loggedInUser} 
                    edit={edit} docId={docId} fetchCafeDetails={fetchCafeDetails} />
                  </div>  
                </div>
              </div>
              <div class="modal order fade" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-md" role="document">
                  <div class="modal-content">
                    <PlaceOrderModal orderFormData={formData}/>
                  </div>  
                </div>
              </div>
              <div class="modal oh fade" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content">
                    <ViewCafeOrders rowData={rowData}/>
                  </div>  
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default CafeManagement;
