import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const ExportWallet = ({ tableId, fileName , buttonText}) => {
  return (
    <div >
      {/* Button to trigger export */}
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="btn btn-primary text-white me-0 mr-2"
        //className="btn btn-success btn-rounded btn-sm"
        //className="export-excel-button"
        table={tableId}
        filename={fileName}
        sheet="Sheet"
        buttonText={buttonText}
      />
    </div>
  );
};

export default ExportWallet;
