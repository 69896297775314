import React, { useState, useRef, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Form } from 'react-bootstrap';
import './ProductModal.css';

function ProductModal({ show, handleClose, initialData, updateData, editProduct }) {

    const [submitProduct, setSubmitProduct] = useState(initialData);
    const fileInputRef = useRef(null);
    const [errMsgName, setErrMsgName] = useState("");
    const [errMsgDescription, setErrMsgDescription] = useState("");
    const [errMsgCategory, setErrMsgCategory] = useState("");
    const [errMsgBrand, setErrMsgBrand] = useState("");
    const [errMsgPrice, setErrMsgPrice] = useState("");
    const [errMsgPkg, setErrMsgPkg] = useState("");
    const [errMsgGst, setErrMsgGst] = useState("");
    const [errMsgLaunchDate, setErrMsgLaunchDate] = useState(false);
    const [unitError, setUnitError] = useState("");

    const handleAddPackagingOption = () => {
        setSubmitProduct(prevProduct => ({
            ...prevProduct,
            packagingOptions: [...prevProduct.packagingOptions, { packaging: '', price: '' }]
        }));
    };


    useEffect(() => {
        setSubmitProduct(initialData);
    }, [initialData]);


    const handleChange = (e) => {
        const { name, value } = e.target;

        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
        const positiveWholeNumber = /^\d+$/;

        if (name === 'productName') {

            if (specialCharRegex.test(value)) {

                setErrMsgName('Product name cannot contain special characters');
            } else {
                setErrMsgName("");
            }

        } else if (name === 'category') {
            if (value === '') {

                setErrMsgCategory("Please select the category");
            }
        } else if (name === 'brand') {
            if (value === '') {

                setErrMsgBrand("Please select the brand");
            }
        } else if (name === 'price') {
            if (value === '') {
                setErrMsgPrice("Please enter price");
            } else if (!positiveWholeNumber.test(value)) {
                setErrMsgPrice("Price must be a positive number");
            } else if (isNaN(parseFloat(value))) {

                setErrMsgPrice("Price must be number");
            } else {
                const formattedPrice = parseFloat(value).toFixed(2);
                setErrMsgPrice("");
                setSubmitProduct(prevState => ({
                    ...prevState,
                    [name]: formattedPrice
                }))
                setErrMsgPrice("");
            }
        } else if (name === 'pkgUnit') {
            setUnitError(value ? '' : 'Please select the unit.');
        } else if (name === 'packaging') {
            if (!positiveWholeNumber.test(value)) {

                setErrMsgPkg("Please enter a number");
            } else {
                setErrMsgPkg("");
            }
        } else if (name === 'gst') {
            if (!positiveWholeNumber.test(value)) {

                setErrMsgGst("Please enter a number");
            } else {
                setErrMsgGst("");
            }
        } else if (name === 'launch_date') {
            if (value === '') {
                setErrMsgLaunchDate("Please select a launch date");
            } else {
                setErrMsgLaunchDate("");
            }
        } else {
            setErrMsgName("")
            setErrMsgDescription("");
            setErrMsgCategory("");
            setErrMsgBrand("");
            setErrMsgPrice("");
            setErrMsgPkg("");
            setUnitError("");
            setErrMsgGst("");

        }
        
        setSubmitProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleDate = (date) => {

        if (date) {
            setSubmitProduct(prevState => ({
                ...prevState,
                'launchDate': date
            }))
            setErrMsgLaunchDate('');
        } else {
            setErrMsgLaunchDate('Please select a valid date.');
        }
    };

    const handleImageChange = (e) => {
        
        if (e.target.files && e.target.files[0]) {
            const newImageName = `${e.target.files[0].name}_${Date.now()}`;
            const storageRef = ref(storage, `products_images/${newImageName}`);

            uploadBytes(storageRef, e.target.files[0]).then(data => {
                
                getDownloadURL(data.ref).then(val => {
                    

                    setSubmitProduct(prevState => ({
                        ...prevState,
                        'image': val
                    }));
                })
            })
        }
    };

    const handleLogistic = () => {
        
        setSubmitProduct(prevState => ({
            ...prevState,
            'enableLogistic': !submitProduct.enableLogistic
        }));
        
    }


    const handleAppPublish = () => {
        
        setSubmitProduct(prevState => ({
            ...prevState,
            'publishOnApp': !submitProduct.publishOnApp
        }));
        
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        updateData(submitProduct);
        handleClose();

    };

    const handlePackagingChange = (index, e) => {
        const { name, value } = e.target;
        const updatedPackagingOptions = [...submitProduct.packagingOptions];
        updatedPackagingOptions[index][name] = value;
        setSubmitProduct(prevProduct => ({
            ...prevProduct,
            packagingOptions: updatedPackagingOptions
        }));
    };

    const handleDeleteInput = (index) => {
        
        const updatedPackagingOptions = [...submitProduct.packagingOptions];
        updatedPackagingOptions.splice(index, 1);
        
        setSubmitProduct(prevProduct => ({
            ...prevProduct,
            packagingOptions: updatedPackagingOptions
        }));
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form class="myForm" onSubmit={handleSubmit}>
                        <div className="panel-group">
                            <div className="panel">
                                <h2>General Information</h2>
                                <input
                                    type="text"
                                    placeholder="Product Name"
                                    name="productName"
                                    value={submitProduct.productName}
                                    onChange={handleChange}
                                    required
                                    autoComplete="off"
                                />
                                {errMsgName && (
                                    <span style={{ color: 'red', fontSize: '12px', display: 'block', marginBottom: '10px' }}>
                                        {errMsgName}
                                    </span>
                                )}

                                <input
                                    type="text"
                                    name="productDescription"
                                    value={submitProduct.productDescription}
                                    placeholder="Product Description"
                                    onChange={handleChange}
                                    required
                                    autoComplete="off"
                                />
                                {errMsgDescription && (
                                    <span style={{ color: 'red', fontSize: '12px', display: 'block', marginBottom: '10px' }}>
                                        {errMsgDescription}
                                    </span>
                                )}
                            </div>
                            <div className="panel">
                                {submitProduct.image ?
                                    <>
                                        <div style={{}}>
                                            <img src={submitProduct.image} alt="Preview" style={{ height: "50px", marginTop: "10px", display: "block" }} />

                                            <input type="file" accept="image/*" id="image" className="form-control" style={{
                                                height: "34px",
                                                padding: "6px 10px",
                                                boxSizing: "border-box",
                                                border: "2px solid #ccc",
                                                borderRadius: "4px",
                                                backgroundColor: "#f8f8f8",
                                                fontSize: "16px",
                                                resize: "none"
                                            }} onChange={handleImageChange} ref={fileInputRef} />
                                        </div>
                                    </>
                                    : <input type="file" accept="image/*" id="image" className="form-control" style={{
                                        height: "34px",
                                        padding: "6px 10px",
                                        boxSizing: "border-box",
                                        border: "2px solid #ccc",
                                        borderRadius: "4px",
                                        backgroundColor: "#f8f8f8",
                                        fontSize: "16px",
                                        resize: "none"
                                    }} onChange={handleImageChange} ref={fileInputRef} />}
                            </div>
                            <div className="panel">
                                <h2>Category</h2>
                                <select name="category" value={submitProduct.category} onChange={handleChange} required>
                                    <option value={""}>Select Category</option>
                                    <option value="Dairy">Dairy</option>
                                    <option value="Breads">Breads</option>
                                </select>
                                {errMsgCategory && (
                                    <span style={{ color: 'red', fontSize: '12px', display: 'block', marginBottom: '10px' }}>
                                        {errMsgCategory}
                                    </span>
                                )}
                            </div>
                            <div className="panel">
                                <h2>Brand</h2>
                                <select name="brand" value={submitProduct.brand} onChange={handleChange}>
                                    <option value="">Select Brand</option>
                                    <option value="Whyte Farms" selected>Whyte Farms</option>
                                </select>
                                {errMsgBrand && (
                                    <span style={{ color: 'red', fontSize: '12px', display: 'block', marginBottom: '10px' }}>
                                        {errMsgBrand}
                                    </span>
                                )}
                            </div>
                            <div className="panel" >
                                <div className="container">

                                    {
                                        submitProduct.packagingOptions.map((option, index) => {
                                            return (
                                                <div key={index}>
                                                    <h2>Packaging</h2>
                                                    <input
                                                        type="text"
                                                        placeholder="Packaging"
                                                        name="packaging"
                                                        value={option.packaging}
                                                        onChange={(e) => handlePackagingChange(index, e)}
                                                        required
                                                    />
                                                    {errMsgPkg && (
                                                        <span style={{ color: 'red', fontSize: '12px', display: 'block', marginBottom: '10px' }}>
                                                            {errMsgPkg}
                                                        </span>
                                                    )}
                                                    <select
                                                        name="pkgUnit"
                                                        value={option.pkgUnit}
                                                        onChange={(e) => handlePackagingChange(index, e)}
                                                        required
                                                    >
                                                        <option value="">Select Unit</option>
                                                        <option value="ltr">Liter (ltr)</option>
                                                        <option value="ml">Milliliter (ml)</option>
                                                        <option value="kg">Kilogram (kg)</option>
                                                        <option value="g">Gram (g)</option>
                                                        <option value="pieces">Pieces</option>
                                                    </select>
                                                    {unitError && (
                                                        <span style={{ color: 'red', fontSize: '12px', display: 'block', marginBottom: '10px' }}>
                                                            {unitError}
                                                        </span>
                                                    )}
                                                    <br />
                                                    <h2>Price</h2>
                                                    <input
                                                        type="text"
                                                        placeholder="price"
                                                        name="price"
                                                        value={option.price}
                                                        onChange={(e) => handlePackagingChange(index, e)}
                                                        required
                                                    />
                                                    {errMsgPrice && (
                                                        <span style={{ color: 'red', fontSize: '12px', display: 'block', marginBottom: '10px' }}>
                                                            {errMsgPrice}
                                                        </span>
                                                    )}
                                                    {submitProduct.packagingOptions.length > 1 && (
                                                        <Button onClick={() => handleDeleteInput(index)}>Remove Packaging</Button>
                                                    )}

                                                </div>

                                            )
                                        })}

                                    <hr />
                                    <Button variant="primary" onClick={handleAddPackagingOption}>
                                        Add More Packaging
                                    </Button>

                                </div>



                            </div>



                            <div className="panel">
                                <h2>Tax Information</h2>
                                <input
                                    type="text"
                                    name="gst"
                                    value={submitProduct.gst}
                                    placeholder="GST"
                                    onChange={handleChange}
                                    required
                                />
                                <span className="percentage">%</span>
                                {errMsgGst && (
                                    <span style={{ color: 'red', fontSize: '12px', display: 'block', marginBottom: '10px' }}>
                                        {errMsgGst}
                                    </span>
                                )}
                            </div>
                            <div className="panel">
                                <h2>Launch Date</h2>
                                <DatePicker
                                    name="launch_date"
                                    selected={submitProduct.launchDate}
                                    onChange={handleDate}
                                    dateFormat="dd/MM/yyyy" 
                                    placeholderText="Select a launch date" 
                                />

                                {errMsgLaunchDate && (
                                    <span style={{ color: 'red', fontSize: '12px', display: 'block', marginBottom: '10px' }}>
                                        {errMsgLaunchDate}
                                    </span>
                                )}
                                {/* {submitProduct.launchDate && <p>Product launch date will be : {formatDate(submitProduct.launchDate)}</p>} */}
                            </div>
                            <div className="panel">
                                <Form>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Form.Switch
                                            id="toggle-reverseLogistic"
                                            label="Enable Reverse Logistic"
                                            checked={submitProduct.enableLogistic}
                                            onChange={handleLogistic}
                                        />

                                    </div>
                                    <Form.Switch
                                        id="toggle-enableOnApp"
                                        label="Enable on app"
                                        checked={submitProduct.publishOnApp}
                                        onChange={handleAppPublish}
                                    />
                                </Form>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ProductModal;