import React, { useEffect, useState } from "react";
import db from "../../Firebase";

const ViewCafeOrders = ({ rowData }) => {
  const [loading, setLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");

  const fetchOrderList = async (date) => {
    setLoading(true);
    try {
      let query = db.collection("order_history").where("order_type", "==", rowData.type);
      if (date) {
        query = query.where("delivery_date", "==", date);
      }
      const snapshot = await query.get();
      const orders = snapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      }));
      setOrderList(orders);
    } catch (error) {
      console.error("Error fetching orders: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (rowData){
        fetchOrderList();
    }
  }, [rowData]);

  return (
    <>
        {loading && (
        <div className="loader-overlay">
          <div className="">
            <img
              alt="loader"
              style={{
                height: "6rem",
              }}
              src="images/loader.gif"
            ></img>
          </div>
        </div>
      )}
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
        Order History: {rowData.cafe_name}
        </h5>
        <div style={{display:"flex", flexDirection: "row", alignItems: "center", gap: "10px"}}>
            <label>Select date</label>{"   "}
            <input 
            type="date"
            onKeyDown={(e) => e.preventDefault()}
            autoComplete="off"
            onChange={(e) => setSelectedDate(e.target.value)}
            /> 
            {" "}
            <button
                class="btn btn-primary btn-md"
                style={{ padding: "0.2rem 0.85rem" }}
                onClick={() => fetchOrderList(selectedDate)} 
            >
                Search
            </button>
        </div>    
        <button
          type="button"
          class="close"
          aria-label="Close"
          data-bs-dismiss="modal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Product Name</th>
                <th style={{ textAlign: "center" }}>Quantity</th>
                <th style={{ textAlign: "center" }}>Package Unit</th>
                <th style={{ textAlign: "center" }}>Total Amount</th>
                <th style={{ textAlign: "center" }}>Delivery Hub</th>
              </tr>
            </thead>
            <tbody>
              {orderList?.map(({ id, data }) => (
                <tr key={id} className="hover-highlight">
                  <td style={{ textAlign: "center" }}>{data.product_name}</td>
                  <td style={{ textAlign: "center" }}>{data.quantity}</td>
                  <td style={{ textAlign: "center" }}>{data.package_unit}</td>
                  <td style={{ textAlign: "center" }}>{data.total_amount}</td>
                  <td style={{ textAlign: "center" }}>{data.hub_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ViewCafeOrders;
