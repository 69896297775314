import React, { useState, useEffect, useRef } from "react";
import { useNavigate} from 'react-router-dom';
import Sidebar from "./Sidebar";
import Swal from 'sweetalert2'
import db from "./Firebase";
import TopPanel from "./TopPanel";
import DatePicker from "react-datepicker";
import { storage } from './Firebase';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Select from 'react-select';
import { useCookies } from "react-cookie";
import Footer from "./Footer";


function Tickets() {
    const navigate = useNavigate();

    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        console.log(loggedIn);
        if (loggedIn) {
            // navigate("/");
        } else {
            navigate("/login");
        }
    }, [navigate]);
    const [cookies] = useCookies(["permissions"]);
    const rolePermissions = cookies.permissions ? cookies.permissions.Tickets || [] : [];
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [viewID, setViewID] = useState("");
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);


    const [selectedcustomerData, setSelectedcustomerData] = useState("");
    const [selectedownerData, setSelectedownerData] = useState("");
    const [customerData, setcustomerData] = useState([]);
    const [ownerData, setOwnerData] = useState([]);
    const [dueDate, setDueDate] = useState(new Date());
    const [hubNames, setHubNames] = useState();

    useEffect(() => {
        db.collection("tickets").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);


    useEffect(() => {
        const unsubscribe = db.collection("customers_data").onSnapshot((snapshot) => {
            setcustomerData(
                snapshot.docs.map((doc) => ({
                    value: doc.data().customer_name,
                    label: doc.data().customer_name
                }))
            );
        });

        return () => unsubscribe();
    }, []);


    useEffect(() => {
        const unsubscribe = db.collection("users").onSnapshot((snapshot) => {
            setOwnerData(
                snapshot.docs.map((doc) => ({
                    value: `${doc.data().first_name}  ${doc.data().last_name}  (${doc.data().role})`,
                    label: `${doc.data().first_name}  ${doc.data().last_name}  (${doc.data().role})`
                }))
            );
        });

        return () => unsubscribe();
    }, []);

    const initialFormData = {
        ticket_id: '',
        customer_id: '',
        customer_name: '',
        hub_name: '',
        category: "",
        status: "",
        priority: "",
        due_date: "",
        owner: "",
        description: "",
        visible_on: "",
        updated_date: new Date(),
        created_date: new Date(),

    };

    const [formData, setFormData] = useState(initialFormData);




    const handleCustomerChange = async (selectedOption) => {
        setSelectedcustomerData(selectedOption ? selectedOption.value : null);

        try {
            const querySnapshot = await db.collection('customers_data')
                .where('customer_name', '==', selectedOption.value)
                .get();

            querySnapshot.forEach(doc => {
                const data = doc.data();
                formData.customer_id = data.customer_id;
                formData.customer_name = data.customer_name;
                formData.hub_name = data.hub_name;
            });
            console.log("Customer Data:", formData);

        } catch (error) {
            console.error("Error fetching data:", error);
            throw error;
        }
    };


    const handleOwnerChange = async (selectedOption) => {
        setSelectedownerData(selectedOption ? selectedOption.value : null);

    };


    const openModal = () => {
        // alert("ooo")
        window.modelshow();
    }

    const closeModal = () => {
        window.modalHide();
    }

    useEffect(() => {
        db.collection("hubs_data").onSnapshot((snapshot) => {
            setHubNames(
                snapshot.docs.map((doc) => doc.data().hub_name)
            );
        });
    }, []);


    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        if (type === "checkbox") {
            setFormData(prevState => ({
                ...prevState,
                [name]: checked
            }));
        } else if (type === "file") {
            const file = files[0];
            setFormData(prevState => ({
                ...prevState,
                [name]: file
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    function generateTicketId() {
        const now = new Date();
        const timestamp = now.getTime(); // Get the timestamp in milliseconds since January 1, 1970
        const random4Digits = Math.floor(Math.random() * 10000).toString().padStart(4, '0'); // Generate a random 4-digit number

        // Take the last 4 digits of the timestamp and concatenate with the random 4-digit number
        const ticketId = (timestamp % 10000).toString().padStart(4, '0') + random4Digits;

        return ticketId;
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        const ticketID = generateTicketId();

        if (edit) {

            db.collection("tickets").doc(editID).update({
                ...formData, due_date: dueDate, owner: selectedownerData, updated_date: new Date()
            }).then(() => {
                console.log('Updated Successfully');
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Ticket Updated Successfully'
                })
                closeModal();
                setLoading(false);
            })
            // setLoading(false);
        } else {
            db.collection("tickets").add({
                ...formData, ticket_id: ticketID, due_date: dueDate, owner: selectedownerData, updated_date: new Date(),
                'created_date': new Date(),
            }).then(() => {
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                closeModal();
                setLoading(false);


                Toast.fire({
                    icon: 'success',
                    title: 'Ticket  Added'
                })
            })
        }



    };



    const addNew = () => {
        setEdit(false);
        setFormData({
            ticket_id: '',
            customer_id: '',
            customer_name: '',
            hub_name: '',
            category: "",
            status: "",
            priority: "",
            due_date: "",
            owner: "",
            description: "",
            visible_on: "",
            updated_date: new Date(),
            created_date: new Date(),

        });
        openModal()
    }

    const editData = (id, data) => {
        setEdit(true)
        setEditID(id)
        setDueDate(moment(data.due_date.toDate().toISOString()).format("YYYY-MM-DD"))

        setSelectedownerData(data.owner)
        setSelectedcustomerData(data.customer_name)
        setFormData({
            ticket_id: data.ticket_id,
            customer_id: data.customer_id,
            hub_name: data.hub_name,
            customer_name: data.customer_name,
            category: data.category,
            status: data.status,
            priority: data.priority,
            due_date: data.due_date,
            owner: data.owner,
            description: data.description,
            visible_on: data.visible_on,
            updated_date: new Date(), //
        });


        openModal();
    };






    const deleteData = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true); // Start loading indicator
                db.collection("banners").doc(id).delete()
                    .then(() => {
                        console.log('Banner Deleted Successfully');
                        // Optionally, you can perform additional actions after deletion
                        // For example, updating state, displaying a confirmation message, etc.
                        setLoading(false); // Stop loading indicator
                        Swal.fire(
                            'Deleted!',
                            'Banner has been deleted.',
                            'success'
                        );
                    })
                    .catch((error) => {
                        console.error('Error deleting banner:', error);
                        // Optionally, you can handle errors, display an error message, etc.
                        setLoading(false); // Stop loading indicator
                        Swal.fire(
                            'Error!',
                            'An error occurred while deleting banner.',
                            'error'
                        );
                    });
            }
        });
    };


    const rolePermission = () => {
        const Toast = Swal.mixin({
            toast: true,
            background: '#d7e7e6',
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
        });

        Toast.fire({
            icon: 'error',
            title: 'You are not authorised to do this action'
        });

    }




    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <div className="">
                        <img style={{
                            height: "6rem"
                        }} src="images/loader.gif" alt=""></img>
                    </div>
                </div>
            )}
            <div class="container-scroller">
                <TopPanel />

                <div class="container-fluid page-body-wrapper">


                    <Sidebar />

                    <div class="main-panel">
                        <div class="content-wrapper">

                            <div class="col-lg-12 grid-margin stretch-card">
                                <div class="card">
                                    <div class="card-body">
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <h4 class="card-title">Tickets</h4>
                                            <p class="card-description">
                                                {rolePermissions.includes('write') ? <> <button type="button" class="btn btn-success btn-rounded btn-sm" onClick={() => addNew()}>Add Ticket</button></> : <> <button type="button" class="btn btn-success btn-rounded btn-sm" onClick={() => rolePermission()}>Add Ticket</button></>}

                                            </p>
                                        </div>

                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>

                                                        <th>
                                                            Ticket ID
                                                        </th>
                                                        <th>
                                                            Customer ID
                                                        </th>
                                                        <th>
                                                            Customer Name
                                                        </th>

                                                        <th>
                                                            Hub Name
                                                        </th>

                                                        <th>
                                                            Created On
                                                        </th>
                                                        <th>
                                                            Priority
                                                        </th>
                                                        <th>
                                                            Owner
                                                        </th>
                                                        <th>
                                                            Due Date
                                                        </th>
                                                        <th>
                                                            Description
                                                        </th>
                                                        <th>
                                                            Action
                                                        </th>

                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    {data.map(({ id, data }, index) => {
                                                        const dueDate = data.due_date && typeof data.due_date.toDate === 'function'
                                                            ? moment(data.due_date.toDate()).format("YYYY-MM-DD")
                                                            : data.due_date;
                                                        return (
                                                            <tr key={id}>
                                                                <td>{data.ticket_id}</td>
                                                                <td>{data.customer_id}</td>
                                                                <td>{data.customer_name}</td>
                                                                <td>{data.hub_name}</td>
                                                                <td>{moment(data.created_date.toDate()).format("YYYY-MM-DD")}</td>
                                                                <td>{data.priority}</td>
                                                                <td>{data.owner}</td>
                                                                <td>{dueDate}</td>
                                                                <td>{data.description}</td>
                                                                <td>
                                                                    {rolePermissions.includes('edit') ? <>
                                                                        <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => editData(id, data)} className="btn btn-dark btn-sm">
                                                                            <i className="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i>
                                                                        </button></> : <>
                                                                        <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => rolePermission()} className="btn btn-dark btn-sm">
                                                                            <i className="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i>
                                                                        </button></>}


                                                                    {rolePermissions.includes('delete') ? <>
                                                                        <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} className="btn btn-dark btn-sm" onClick={() => deleteData(id)}>
                                                                            <i className="menu-icon mdi mdi-delete" style={{ color: "white" }}></i>
                                                                        </button>
                                                                    </> : <>
                                                                        <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} className="btn btn-dark btn-sm" onClick={() => rolePermission()}>
                                                                            <i className="menu-icon mdi mdi-delete" style={{ color: "white" }}></i>
                                                                        </button></>}


                                                                </td>
                                                            </tr>
                                                        );
                                                    })}

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div class="modal fade" id="exampleModal-2" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel-2" aria-hidden="true">
                            <div class="modal-dialog modal-lg" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel-2">{edit ? "Update Ticket" : "Add Ticket"}</h5>
                                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={handleSubmit}>
                                            <div class="form-group row">
                                                <div class="col">
                                                    <label>Customer</label>
                                                    <Select
                                                        options={customerData}
                                                        onChange={handleCustomerChange}
                                                        value={selectedcustomerData ? customerData.find(option => option.value === selectedcustomerData) : null}
                                                        placeholder="Select Customer Name"
                                                    />
                                                </div>
                                                <div class="col">
                                                    <label>Category:</label>
                                                    <select
                                                        name="category"
                                                        value={formData.category}
                                                        onChange={handleChange}
                                                        style={{ color: "black" }}
                                                        required
                                                        class="form-control"
                                                    >
                                                        <option value="">Select Category</option>
                                                        <option value="Support">Support</option>
                                                        <option value="Feedback">Feedback</option>
                                                        <option value="Complaint">Complaint</option>
                                                    </select>
                                                </div>

                                                <div class="col">
                                                    <label>Status:</label>
                                                    <select
                                                        name="status"
                                                        value={formData.status}
                                                        onChange={handleChange}
                                                        style={{ color: "black" }}
                                                        required
                                                        class="form-control"
                                                    >
                                                        <option value="">Select Status</option>
                                                        <option value="Open">Open</option>
                                                        <option value="Close">Close</option>
                                                        <option value="In Process">In Process</option>
                                                        <option value="Not Started">Not Started</option>
                                                    </select>
                                                </div>

                                            </div>

                                            <div class="form-group row">
                                                <div class="col">
                                                    <label>Owner</label>
                                                    <Select
                                                        options={ownerData}
                                                        onChange={handleOwnerChange}
                                                        value={selectedownerData ? ownerData.find(option => option.value === selectedownerData) : null}
                                                        placeholder="Select Owner Name"
                                                    />
                                                </div>

                                                <div class="col">
                                                    <label>Priority:</label>
                                                    <select
                                                        name="priority"
                                                        value={formData.priority}
                                                        onChange={handleChange}
                                                        style={{ color: "black" }}
                                                        required
                                                        class="form-control"
                                                    >
                                                        <option value="">Select Priority</option>
                                                        <option value="Low">Low</option>
                                                        <option value="Medium">Medium</option>
                                                        <option value="High">High</option>
                                                    </select>
                                                </div>

                                                <div class="col">
                                                    <label>Visible On:</label>
                                                    <select
                                                        name="visible_on"
                                                        value={formData.visible_on}
                                                        onChange={handleChange}
                                                        style={{ color: "black" }}
                                                        required
                                                        class="form-control"
                                                    >
                                                        <option value="">Select Visibility</option>
                                                        <option value="Internal">Internal</option>
                                                        <option value="Internal & External">Internal & External</option>
                                                    </select>
                                                </div>

                                            </div>
                                            <div class="form-group row">
                                                <div class="col">
                                                    <label>Due Date:</label>
                                                    <p>
                                                        <DatePicker
                                                            selected={dueDate}
                                                            onChange={(date) => setDueDate(date)}
                                                            placeholderText="Start Due date"
                                                            className="form-control"
                                                            dateFormat="MMMM d, yyyy"
                                                        />
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="form-group row">

                                                <div class="col">
                                                    <label>Description:</label>
                                                    <textarea rows="2" cols="30" style={{ height: "5rem" }} class="form-control" type="text" name="description" value={formData.description} onChange={handleChange} required />
                                                </div>
                                            </div>


                                            <div class="" style={{ display: "flex", justifyContent: "end" }}>
                                                <button type="submit" class="btn btn-success btn-sm">{edit ? "Update" : "Submit"}</button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <Footer />

                    </div>

                </div>

            </div>


        </>
    )
}

export default Tickets

