import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import UserDetails from './ManageUserDetails';
import ManageUsersAndRoles from './ManageUsersAndRoles';
import { useCookies } from "react-cookie";

function TopPanel() {
    const [cookies, setCookie, removeCookie] = useCookies(["permissions"]);
    const userRole = cookies.permissions?.roleData?.user_role;
    const rolePermissions = cookies.permissions ? cookies.permissions.Menu || [] : [];
    const navigate = useNavigate();
    const [timeOfDay, setTimeOfDay] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const getCurrentTimeOfDay = () => {
            const currentTime = new Date().getHours();
            if (currentTime < 12) {
                setTimeOfDay('Good Morning');
            } else if (currentTime >= 12 && currentTime < 18) {
                setTimeOfDay('Good Afternoon');
            } else {
                setTimeOfDay('Good Evening');
            }
        };

        getCurrentTimeOfDay();
    }, []);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleLogout = () => {
        removeCookie("permissions");
        localStorage.removeItem("loggedIn");
        navigate("/login");
    };


    return (
        <>
            <nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
                <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                    <div class="me-3">
                        <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-bs-toggle="minimize">
                            <span class="icon-menu"></span>
                        </button>
                    </div>
                    <div>
                        <Link class="navbar-brand brand-logo" to="/">
                            <img src="https://www.whytefarms.com/img/logo-icon.png" alt="logo" />


                        </Link>
                        <Link class="navbar-brand brand-logo-mini" to="/">
                            <img src="/images/logo-mini.svg" alt="logo" />
                        </Link>
                    </div>
                </div>
                <div class="navbar-menu-wrapper d-flex align-items-top">
                    <ul class="navbar-nav">
                        <li class="nav-item font-weight-semibold d-none d-lg-block ms-0">
                            <h1 class="welcome-text">{timeOfDay}, <span class="text-black fw-bold">{userRole}</span></h1>
                        </li>
                    </ul>
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item dropdown">
                            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
                                aria-labelledby="notificationDropdown">
                                <a class="dropdown-item py-3 border-bottom">
                                    <p class="mb-0 font-weight-medium float-left">You have 4 new notifications </p>
                                    <span class="badge badge-pill badge-primary float-right">View all</span>
                                </a>
                                <a class="dropdown-item preview-item py-3">
                                    <div class="preview-thumbnail">
                                        <i class="mdi mdi-alert m-auto text-primary"></i>
                                    </div>
                                    <div class="preview-item-content">
                                        <h6 class="preview-subject fw-normal text-dark mb-1">Application Update</h6>
                                        <p class="fw-light small-text mb-0"> Just now </p>
                                    </div>
                                </a>
                                <a class="dropdown-item preview-item py-3">
                                    <div class="preview-thumbnail">
                                        <i class="mdi mdi-settings m-auto text-primary"></i>
                                    </div>
                                    <div class="preview-item-content">
                                        <h6 class="preview-subject fw-normal text-dark mb-1">Settings</h6>
                                        <p class="fw-light small-text mb-0"> Private message </p>
                                    </div>
                                </a>
                                <a class="dropdown-item preview-item py-3">
                                    <div class="preview-thumbnail">
                                        <i class="mdi mdi-airballoon m-auto text-primary"></i>
                                    </div>
                                    <div class="preview-item-content">
                                        <h6 class="preview-subject fw-normal text-dark mb-1">New user registration</h6>
                                        <p class="fw-light small-text mb-0"> 2 days ago </p>
                                    </div>
                                </a>
                            </div>

                        </li>
                        <li class="nav-item dropdown d-none d-lg-block user-dropdown">
                            <a class="nav-link" id="UserDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <img class="img-xs rounded-circle" src="https://static-00.iconduck.com/assets.00/user-icon-2048x2048-ihoxz4vq.png" alt="Profile image" /> </a>
                            <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
                                <div class="dropdown-header text-center">
                                    <img class="img-xs rounded-circle" src="https://static-00.iconduck.com/assets.00/user-icon-2048x2048-ihoxz4vq.png" alt="Profile image" />
                                    <p class="mb-1 mt-3 font-weight-semibold">{userRole}</p>
                                    {/* <p class="fw-light text-muted mb-0">admin@gmail.com</p> */}
                                </div>
                                {rolePermissions.includes('profile') ? <>
                                    <Link class="dropdown-item" to="/profile"><i class="dropdown-item-icon mdi mdi-account-outline text-primary me-2"></i>
                                        Profile <span class="badge badge-pill badge-danger"></span></Link>
                                </> : <></>}

                                {rolePermissions.includes('manageUsersRoles') ? <>
                                    <Link class="dropdown-item" to="/users"><i class="dropdown-item-icon mdi mdi-message-text-outline text-primary me-2" ></i>
                                        Manage Users & Roles</Link>
                                </> : <></>}

                                {/* <a class="dropdown-item"><i class="dropdown-item-icon mdi mdi-settings text-primary me-2"></i>
                                    Settings</a> */}
                                {/* <a class="dropdown-item"><i class="dropdown-item-icon mdi mdi-help-circle-outline text-primary me-2"></i>
                                    Help</a> */}
                                <a class="dropdown-item" onClick={handleLogout}><i class="dropdown-item-icon mdi mdi-power text-primary me-2"></i>LogOut</a>
                            </div>
                        </li>
                    </ul>
                    <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
                        data-bs-toggle="offcanvas">
                        <span class="mdi mdi-menu"></span>
                    </button>
                </div>
            </nav>
        </>
    )
}

export default TopPanel
