import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Swal from 'sweetalert2'
import db from "./Firebase";
import TopPanel from "./TopPanel";
import DatePicker from "react-datepicker";
import { storage } from './Firebase';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useCookies } from "react-cookie";
import Footer from "./Footer";

function Banner() {
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        console.log(loggedIn);
        if (loggedIn) {
            // navigate("/");
            setLoading(false)
        } else {
            navigate("/login");
            setLoading(false)
        }
    }, [navigate]);
    const [cookies] = useCookies(["permissions"]);
    const rolePermissions = cookies.permissions ? cookies.permissions.Banner || [] : [];
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hubNames, setHubNames] = useState([]);
    const fileInputRef = useRef(null);


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());


    useEffect(() => {
        const unsubscribe = db.collection("banners").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

        return () => unsubscribe(); // Unsubscribe from snapshot listener when component unmounts
    }, []);

    const initialFormData = {
        image: "",
        hub_name: "",
        click_action_type: "",
        navigation_type: "",
        start_date_time: "",
        end_date_time: "",
        banner_order: "",
        platform: "",
        status: "",
        updated_date: new Date(),
        created_date: new Date(),

    };

    const [formData, setFormData] = useState(initialFormData);


    const openModal = () => {
        // alert("ooo")
        window.modelshow();
    }

    const closeModal = () => {
        window.modalHide();
    }

    useEffect(() => {
        db.collection("hubs_data").onSnapshot((snapshot) => {
            setHubNames(
                snapshot.docs.map((doc) => doc.data().hub_name)
            );
        });
    }, []);


    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        if (type === "checkbox") {
            setFormData(prevState => ({
                ...prevState,
                [name]: checked
            }));
        } else if (type === "file") {
            // File input, handle files separately
            const file = files[0]; // Assuming single file selection
            // You may want to perform additional checks or validation on the file here
            setFormData(prevState => ({
                ...prevState,
                [name]: file
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        if (edit) {
            if (formData.image && typeof formData.image !== "string") {
                // New image selected, upload it to Firebase Storage
                const storageRef = ref(storage, `banner/${formData.image.name}`);
                const uploadTask = uploadBytesResumable(storageRef, formData.image);

                uploadTask.on(
                    "state_changed",
                    snapshot => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    },
                    error => {
                        console.error("Error uploading image: ", error);
                    },
                    () => {
                        // File uploaded successfully, now obtain the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // Update Banner data in Firestore with the new image URL
                            db.collection("banners").doc(editID).update({
                                ...formData,
                                start_date_time: startDate,
                                end_date_time: endDate,
                                image: downloadURL // Replace image with the download URL of the new image
                            })
                                .then(() => {

                                    // Reset the form after successful submission
                                    setFormData({
                                        image: "",
                                        hub_name: "",
                                        click_action_type: "",
                                        navigation_type: "",
                                        start_date_time: "",
                                        end_date_time: "",
                                        banner_order: "",
                                        platform: "",
                                        status: "",
                                        updated_date: new Date(),
                                        created_date: new Date(),
                                    });
                                    closeModal()
                                })
                                .catch(error => {
                                    console.error("Error updating Banner: ", error);
                                });
                        })
                            .catch(error => {
                                console.error("Error getting download URL: ", error);
                            });
                    }
                );
            } else {
                // No new image selected, update Banner data without changing the image URL
                db.collection("banners").doc(editID).update({
                    ...formData, start_date_time: startDate,
                    end_date_time: endDate,
                })
                    .then(() => {

                        // Reset the form after successful submission
                        setFormData({
                            image: "",
                            hub_name: "",
                            click_action_type: "",
                            navigation_type: "",
                            start_date_time: "",
                            end_date_time: "",
                            banner_order: "",
                            platform: "",
                            status: "",
                            updated_date: new Date(),
                            created_date: new Date(),
                        });
                        closeModal()
                    })
                    .catch(error => {
                        console.error("Error updating product: ", error);
                    });
            }
        }
        else {
            // Upload image file to Firebase Storage if formData.image exists
            if (formData.image) {

                const storageRef = ref(storage, `banner/${formData.image.name}`);
                const uploadTask = uploadBytesResumable(storageRef, formData.image);

                uploadTask.on(
                    "state_changed",
                    snapshot => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    },
                    error => {
                        console.error("Error uploading image: ", error);
                    },
                    () => {
                        // File uploaded successfully, now add form data to Firestore
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // Add form data to Firestore
                            return db.collection("banners").add({
                                ...formData,
                                start_date_time: startDate,
                                end_date_time: endDate,
                                image: downloadURL // Replace image with the download URL from Firebase Storage
                            });
                        })
                            .then(() => {
                                // document.getElementById("image-input").value = "";
                                // Reset the form after successful submission
                                setFormData({
                                    image: "",
                                    hub_name: "",
                                    click_action_type: "",
                                    navigation_type: "",
                                    start_date_time: "",
                                    end_date_time: "",
                                    banner_order: "",
                                    platform: "",
                                    status: "",
                                    updated_date: new Date(),
                                    created_date: new Date(),
                                });
                                closeModal()

                                // Clear the file input field

                            })
                            .catch(error => {
                                console.error("Error adding Banner: ", error);
                            });
                    }
                );
            } else {
                // No image to upload, directly add form data to Firestore
                db.collection("banners")
                    .add({
                        ...formData, start_date_time: startDate,
                        end_date_time: endDate,
                    })
                    .then(() => {

                        // Reset the form after successful submission
                        setFormData({
                            image: "",
                            hub_name: "",
                            click_action_type: "",
                            navigation_type: "",
                            start_date_time: "",
                            end_date_time: "",
                            banner_order: "",
                            platform: "",
                            status: "",
                            updated_date: new Date(),
                            created_date: new Date(),
                        });
                        closeModal()
                    })
                    .catch(error => {
                        console.error("Error adding Banner: ", error);
                    });
            }
        }





    };






    const addNew = () => {
        fileInputRef.current.value = '';
        setEdit(false);
        // setEdit(false)
        openModal();
    }

    const editData = (id, data) => {
        setEdit(true)
        setEditID(id)

        setFormData({
            image: data.image,
            hub_name: data.hub_name,
            click_action_type: data.click_action_type,
            navigation_type: data.navigation_type,
            start_date_time: data.start_date_time.toDate(),
            end_date_time: data.end_date_time.toDate(),
            banner_order: data.banner_order,
            platform: data.platform,
            status: data.status,
            updated_date: new Date(),
        });
        openModal();
    };






    const deleteData = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true); // Start loading indicator
                db.collection("banners").doc(id).delete()
                    .then(() => {


                        setLoading(false);
                        Swal.fire(
                            'Deleted!',
                            'Banner has been deleted.',
                            'success'
                        );
                    })
                    .catch((error) => {
                        console.error('Error deleting banner:', error);

                        setLoading(false);
                        Swal.fire(
                            'Error!',
                            'An error occurred while deleting banner.',
                            'error'
                        );
                    });
            }
        });
    };


    const rolePermission = () => {
        const Toast = Swal.mixin({
            toast: true,
            background: '#d7e7e6',
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
        });

        Toast.fire({
            icon: 'error',
            title: 'You are not authorised to do this action'
        });

    }





    return (
        <>
            {loading && ( // Render loader when loading state is true
                <div className="loader-overlay">
                    <div className="">
                        <img style={{
                            height: "6rem"
                        }} src="images/loader.gif"></img>
                    </div>
                </div>
            )}
            <div class="container-scroller">
                <TopPanel />

                <div class="container-fluid page-body-wrapper">


                    <Sidebar />

                    <div class="main-panel">
                        <div class="content-wrapper">

                            <div class="col-lg-12 grid-margin stretch-card">
                                <div class="card">
                                    <div class="card-body">
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <h4 class="card-title">Banners</h4>
                                            <p class="card-description">
                                                {rolePermissions.includes('write') ? <><button type="button" class="btn btn-success btn-rounded btn-sm" onClick={() => addNew()}>Add Banner</button></> : <><button type="button" class="btn btn-success btn-rounded btn-sm" onClick={() => rolePermission()}>Add Banner</button></>}
                                            </p>

                                        </div>

                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>

                                                        <th>
                                                            Image
                                                        </th>
                                                        <th>
                                                            Hub Name
                                                        </th>
                                                        <th>
                                                            Start Date Time
                                                        </th>

                                                        <th>
                                                            End Date Time
                                                        </th>
                                                        <th>
                                                            Status
                                                        </th>
                                                        <th>
                                                            Action
                                                        </th>

                                                    </tr>

                                                </thead>
                                                <tbody>

                                                    {data.map(({ id, data }, index) => {
                                                        return (
                                                            <tr key={id}>
                                                                <td><img src={data.image} style={{ height: "5rem", width: "8rem", borderRadius: "10%" }}></img></td>
                                                                <td>{data.hub_name}</td>
                                                                <td>{moment(data.start_date_time.toDate()).format("YYYY-MM-DD, HH:mm:ss")}</td>
                                                                <td>{moment(data.end_date_time.toDate().toISOString()).format("YYYY-MM-DD, HH:mm:ss")}</td>
                                                                <td>{data.status}</td>
                                                                <td>
                                                                    {rolePermissions.includes('write') ? <>
                                                                        <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => editData(id, data)} className="btn btn-dark btn-sm">
                                                                            <i className="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i>
                                                                        </button>
                                                                    </> : <>
                                                                        <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => rolePermission()} className="btn btn-dark btn-sm">
                                                                            <i className="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i>
                                                                        </button>
                                                                    </>}

                                                                    {rolePermissions.includes('delete') ? <>
                                                                        <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} className="btn btn-dark btn-sm" onClick={() => deleteData(id)}>
                                                                            <i className="menu-icon mdi mdi-delete" style={{ color: "white" }}></i>
                                                                        </button>
                                                                    </> : <>
                                                                        <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} className="btn btn-dark btn-sm" onClick={() => rolePermission()}>
                                                                            <i className="menu-icon mdi mdi-delete" style={{ color: "white" }}></i>
                                                                        </button>
                                                                    </>}

                                                                </td>
                                                            </tr>
                                                        );
                                                    })}

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div class="modal fade" id="exampleModal-2" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel-2" aria-hidden="true">
                            <div class="modal-dialog modal-lg" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel-2">Add Product</h5>
                                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={handleSubmit}>
                                            <div class="form-group row">
                                                <div class="col">
                                                    <label>Select Banner Image:</label>
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        style={{ padding: "10px" }}
                                                        name="image"
                                                        ref={fileInputRef}
                                                        // Note: value should not be set for file inputs, it's read-only
                                                        onChange={handleChange}

                                                    />
                                                </div>
                                                <div class="col">
                                                    <label>Hub Name</label>
                                                    <select class="form-select" onChange={handleChange} id="hub_name" name="hub_name" value={formData.hub_name} required>
                                                        <option>Select Hub Name</option>
                                                        {hubNames.map((HubName) => (
                                                            <option key={HubName} value={HubName}>
                                                                {HubName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div class="col">
                                                    <label>Click Action Type:</label>
                                                    <select
                                                        name="click_action_type"
                                                        value={formData.click_action_type}
                                                        onChange={handleChange}
                                                        required
                                                        class="form-control"
                                                    >
                                                        <option value="">Select Click Action Type</option>
                                                        <option value="In-app Section">In-app Section</option>
                                                        <option value="Internal Web View">Internal Web View</option>
                                                        <option value="External Web View">External Web View</option>
                                                    </select>
                                                </div>

                                            </div>

                                            <div class="form-group row">
                                                <div class="col">
                                                    <label>Navigation Type:</label>
                                                    <select
                                                        name="navigation_type"
                                                        value={formData.navigation_type}
                                                        onChange={handleChange}
                                                        required
                                                        class="form-control"
                                                    >
                                                        <option value="">Select Navigation Type</option>
                                                        <option value="Product">Product</option>
                                                    </select>
                                                </div>
                                                <div class="col">
                                                    <label>Start Date Time:</label>
                                                    <p>
                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={(date) => setStartDate(date)}
                                                            showTimeSelect
                                                            placeholderText="Start Date Time"
                                                            className="form-control"
                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                        />
                                                    </p>
                                                </div>
                                                <div class="col">
                                                    <label>End Date Time:</label>
                                                    <p>
                                                        <DatePicker
                                                            selected={endDate}
                                                            onChange={(date) => setEndDate(date)}
                                                            showTimeSelect
                                                            placeholderText="End Date Time"
                                                            className="form-control"
                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                        />
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <div class="col">
                                                    <label>Banner Order:</label>
                                                    <input class="form-control" type="text" name="banner_order" value={formData.banner_order} onChange={handleChange} required />
                                                </div>
                                                <div class="col">
                                                    <label>Platform:</label>
                                                    <select
                                                        name="platform"
                                                        value={formData.platform}
                                                        onChange={handleChange}
                                                        required
                                                        class="form-control"
                                                    >
                                                        <option value="">Select Platform</option>
                                                        <option value="Mobile App">Mobile App</option>
                                                        <option value="Web App">Web App</option>
                                                        <option value="Both">Both</option>
                                                    </select>
                                                </div>
                                                <div class="col">
                                                    <label>Status:</label>
                                                    <select
                                                        name="status"
                                                        value={formData.status}
                                                        onChange={handleChange}
                                                        required
                                                        class="form-control"
                                                    >
                                                        <option value="">Select Status</option>
                                                        <option value="Active"> Active</option>
                                                        <option value="Inactive"> Inactive</option>
                                                    </select>
                                                </div>
                                            </div>


                                            <div class="" style={{ display: "flex", justifyContent: "end" }}>
                                                <button type="submit" class="btn btn-success btn-sm">{edit ? "Update" : "Submit"}</button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <Footer />

                    </div>

                </div>

            </div>


        </>
    )
}

export default Banner

