import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Swal from 'sweetalert2'
import db from "./Firebase";
import TopPanel from "./TopPanel";
import Footer from "./Footer";

function Region() {
    const navigate = useNavigate();

    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        console.log(loggedIn);
        if (loggedIn) {
            // navigate("/");
        } else {
            navigate("/login");
        }
    }, [navigate]);
    const [timeOfDay, setTimeOfDay] = useState('');
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const initialFormState = { region: '', city: '', visible_on: '', status: '', updated_date: new Date(), created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormState)

    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const addNew = () => {
        setEdit(false)
        openModal();
        setSubmit({ region: '', city: '', visible_on: '', status: '', updated_date: new Date(), created_date: new Date() });
    }

    const reset = () => {
        setSubmit({ region: '', city: '', visible_on: '', status: '', updated_date: new Date(), created_date: new Date() })
    }

    useEffect(() => {
        db.collection("regions_data").orderBy("updated_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);

    useEffect(() => {
        const getCurrentTimeOfDay = () => {
            const currentTime = new Date().getHours();
            if (currentTime < 12) {
                setTimeOfDay('Good Morning');
            } else if (currentTime >= 12 && currentTime < 18) {
                setTimeOfDay('Good Afternoon');
            } else {
                setTimeOfDay('Good Evening');
            }
        };

        getCurrentTimeOfDay();
    }, []);

    const changeStatusForm = (data, id) => {
        setEdit(true)
        setEditID(id)
        setSubmit({ id: id, region: data.region, city: data.city, visible_on: data.visible_on, status: data.status, updated_date: new Date(), created_date: new Date() });
        openModal();
    }

    const openModal = () => {
        // alert("ooo")
        window.modelshow();
    }

    const closeModal = () => {
        window.modalHide();
    }


    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(submit)

        if (edit) {

            db.collection("regions_data").doc(editID).update({
                'region': submit.region,
                'city': submit.city,
                'visible_on': submit.visible_on,
                'status': "1",
                'updated_date': new Date(),
            }).then(() => {
                console.log('Updated Successfully');
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Region Updated Successfully'
                })
                closeModal();

                setSubmit({ region: '', city: '', visible_on: '', status: '', updated_date: new Date(), created_date: new Date() })
            })

        } else {
            db.collection("regions_data").add({
                'region': submit.region,
                'city': submit.city,
                'visible_on': submit.visible_on,
                'status': "1",
                'updated_date': new Date(),
            }).then(() => {
                reset();
                // setshow(true)
                console.log('region Added Successfully');
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                closeModal();


                Toast.fire({
                    icon: 'success',
                    title: 'Region Added'
                })
            })
        }



    }

    const deleteData = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                db.collection("regions_data").doc(id).delete().then(() => {
                    console.log('Deleted Successfully');
                    setSubmit({ region: '', city: '', visible_on: '', status: '', updated_date: new Date(), created_date: new Date() })
                    closeModal();
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    )
                })

            }
        })

    }

    return (
        <>
            <div class="container-scroller">
                <TopPanel />

                <div class="container-fluid page-body-wrapper">

                    <div id="right-sidebar" class="settings-panel">
                        <i class="settings-close ti-close"></i>
                        <ul class="nav nav-tabs border-top" id="setting-panel" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="todo-tab" data-bs-toggle="tab" href="#todo-section" role="tab"
                                    aria-controls="todo-section" aria-expanded="true">TO DO LIST</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="chats-tab" data-bs-toggle="tab" href="#chats-section" role="tab"
                                    aria-controls="chats-section">CHATS</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="setting-content">
                            <div class="tab-pane fade show active scroll-wrapper" id="todo-section" role="tabpanel"
                                aria-labelledby="todo-section">
                                <div class="add-items d-flex px-3 mb-0">
                                    <form class="form w-100">
                                        <div class="form-group d-flex">
                                            <input type="text" class="form-control todo-list-input" placeholder="Add To-do" />
                                            <button type="submit" class="add btn btn-primary todo-list-add-btn" id="add-task">Add</button>
                                        </div>
                                    </form>
                                </div>
                                <div class="list-wrapper px-3">
                                    <ul class="d-flex flex-column-reverse todo-list">
                                        <li>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input class="checkbox" type="checkbox" />
                                                    Team review meeting at 3.00 PM
                                                </label>
                                            </div>
                                            <i class="remove ti-close"></i>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input class="checkbox" type="checkbox" />
                                                    Prepare for presentation
                                                </label>
                                            </div>
                                            <i class="remove ti-close"></i>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input class="checkbox" type="checkbox" />
                                                    Resolve all the low priority tickets due today
                                                </label>
                                            </div>
                                            <i class="remove ti-close"></i>
                                        </li>
                                        <li class="completed">
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input class="checkbox" type="checkbox" checked />
                                                    Schedule meeting for next week
                                                </label>
                                            </div>
                                            <i class="remove ti-close"></i>
                                        </li>
                                        <li class="completed">
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input class="checkbox" type="checkbox" checked />
                                                    Project review
                                                </label>
                                            </div>
                                            <i class="remove ti-close"></i>
                                        </li>
                                    </ul>
                                </div>
                                <h4 class="px-3 text-muted mt-5 fw-light mb-0">Events</h4>
                                <div class="events pt-4 px-3">
                                    <div class="wrapper d-flex mb-2">
                                        <i class="ti-control-record text-primary me-2"></i>
                                        <span>Feb 11 2018</span>
                                    </div>
                                    <p class="mb-0 font-weight-thin text-gray">Creating component page build a js</p>
                                    <p class="text-gray mb-0">The total number of sessions</p>
                                </div>
                                <div class="events pt-4 px-3">
                                    <div class="wrapper d-flex mb-2">
                                        <i class="ti-control-record text-primary me-2"></i>
                                        <span>Feb 7 2018</span>
                                    </div>
                                    <p class="mb-0 font-weight-thin text-gray">Meeting with Alisa</p>
                                    <p class="text-gray mb-0 ">Call Sarah Graves</p>
                                </div>
                            </div>
                            {/* <!-- To do section tab ends --> */}
                            <div class="tab-pane fade" id="chats-section" role="tabpanel" aria-labelledby="chats-section">
                                <div class="d-flex align-items-center justify-content-between border-bottom">
                                    <p class="settings-heading border-top-0 mb-3 pl-3 pt-0 border-bottom-0 pb-0">Friends</p>
                                    <small class="settings-heading border-top-0 mb-3 pt-0 border-bottom-0 pb-0 pr-3 fw-normal">See All</small>
                                </div>
                                <ul class="chat-list">
                                    <li class="list active">
                                        <div class="profile"><img src="images/faces/face1.jpg" alt="image" /><span class="online"></span></div>
                                        <div class="info">
                                            <p>Thomas Douglas</p>
                                            <p>Available</p>
                                        </div>
                                        <small class="text-muted my-auto">19 min</small>
                                    </li>
                                    <li class="list">
                                        <div class="profile"><img src="images/faces/face2.jpg" alt="image" /><span class="offline"></span></div>
                                        <div class="info">
                                            <div class="wrapper d-flex">
                                                <p>Catherine</p>
                                            </div>
                                            <p>Away</p>
                                        </div>
                                        <div class="badge badge-success badge-pill my-auto mx-2">4</div>
                                        <small class="text-muted my-auto">23 min</small>
                                    </li>
                                    <li class="list">
                                        <div class="profile"><img src="images/faces/face3.jpg" alt="image" /><span class="online"></span></div>
                                        <div class="info">
                                            <p>Daniel Russell</p>
                                            <p>Available</p>
                                        </div>
                                        <small class="text-muted my-auto">14 min</small>
                                    </li>
                                    <li class="list">
                                        <div class="profile"><img src="images/faces/face4.jpg" alt="image" /><span class="offline"></span></div>
                                        <div class="info">
                                            <p>James Richardson</p>
                                            <p>Away</p>
                                        </div>
                                        <small class="text-muted my-auto">2 min</small>
                                    </li>
                                    <li class="list">
                                        <div class="profile"><img src="images/faces/face5.jpg" alt="image" /><span class="online"></span></div>
                                        <div class="info">
                                            <p>Madeline Kennedy</p>
                                            <p>Available</p>
                                        </div>
                                        <small class="text-muted my-auto">5 min</small>
                                    </li>
                                    <li class="list">
                                        <div class="profile"><img src="images/faces/face6.jpg" alt="image" /><span class="online"></span></div>
                                        <div class="info">
                                            <p>Sarah Graves</p>
                                            <p>Available</p>
                                        </div>
                                        <small class="text-muted my-auto">47 min</small>
                                    </li>
                                </ul>
                            </div>
                            {/* <!-- chat tab ends --> */}
                        </div>
                    </div>
                    {/* <!-- partial -->
      <!-- partial:partials/_sidebar.html --> */}

                    {/* <nav class="sidebar sidebar-offcanvas" id="sidebar">
                        <ul class="nav">
                            <li class="nav-item">
                                <Link class="nav-link" to="/">
                                    <i class="mdi mdi-grid-large menu-icon"></i>
                                    <span class="menu-title">Dashboard</span>
                                </Link>
                            </li>
                            <li class="nav-item nav-category">Menu</li>
                            <li class="nav-item">
                                <Link class="nav-link" to="/vendors_data">
                                    <i class="menu-icon mdi mdi-account-multiple"></i>
                                    <span class="menu-title">Hubs/Distributors</span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" to="/customers">
                                    <i class="menu-icon mdi mdi-file-document"></i>
                                    <span class="menu-title">Customers</span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="collapse" href="#locations" aria-expanded="false"
                                    aria-controls="locations">
                                    <i class="menu-icon mdi mdi-map-marker"></i>
                                    <span class="menu-title">Locations</span>
                                    <i class="menu-arrow"></i>
                                </a>
                                <div class="collapse" id="locations">
                                    <ul class="nav flex-column sub-menu">
                                        <li class="nav-item"> <Link class="nav-link" to="/region">Regions</Link></li>
                                        <li class="nav-item"> <Link class="nav-link" to="/location">Locations</Link></li>
                                        <li class="nav-item"> <Link class="nav-link" to="/routes">Routes</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="collapse" href="#products" aria-expanded="false"
                                    aria-controls="products">
                                    <i class="menu-icon mdi mdi-cart-arrow-down"></i>
                                    <span class="menu-title">Products</span>
                                    <i class="menu-arrow"></i>
                                </a>
                                <div class="collapse" id="products">
                                    <ul class="nav flex-column sub-menu">
                                        <li class="nav-item"> <Link class="nav-link" to="/brands">Brand</Link></li>
                                        <li class="nav-item"> <Link class="nav-link" to="/product_categories">Product Categories</Link>
                                        </li>
                                        <li class="nav-item"> <Link class="nav-link" to="/product">Product</Link></li>
                                        <li class="nav-item"> <a class="nav-link" href="#">Banner</a></li>
                                        <li class="nav-item"> <a class="nav-link" href="#">Product Package
                                            Sorting</a></li>
                                        <li class="nav-item"> <a class="nav-link" href="#">Stock Estimation</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="collapse" href="#offer" aria-expanded="false" aria-controls="offer">
                                    <i class="menu-icon mdi mdi-tag"></i>
                                    <span class="menu-title">Coupons & Offers </span>
                                    <i class="menu-arrow"></i>
                                </a>
                                <div class="collapse" id="offer">
                                    <ul class="nav flex-column sub-menu">
                                        <li class="nav-item"> <a class="nav-link" href="#">Promo Codes</a></li>
                                        <li class="nav-item"> <a class="nav-link" href="#">Offers</a></li>
                                    </ul>
                                </div>
                            </li>


                            <li class="nav-item">
                                <a class="nav-link" href="#">
                                    <i class="menu-icon mdi mdi-chart-line"></i>
                                    <span class="menu-title">Reports</span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" href="#">
                                    <i class="menu-icon mdi mdi-wechat"></i>
                                    <span class="menu-title">COMMUNICATIONS</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">
                                    <i class="menu-icon mdi mdi-ticket-percent"></i>
                                    <span class="menu-title">Tickets</span>
                                </a>
                            </li>

                        </ul>
                    </nav> */}
                    <Sidebar />

                    <div class="main-panel">
                        <div class="content-wrapper">

                            <div class="col-lg-12 grid-margin stretch-card">
                                <div class="card">
                                    <div class="card-body">
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <h4 class="card-title">REGIONS</h4>
                                            {/* <button type="button" class="btn btn-primary btn-sm d-flex align-items-center"
                data-bs-toggle="modal" data-bs-target="#exampleModal-2">Click for demo<i
                    class="ti-arrow-circle-right ms-1"></i></button> */}
                                            <p class="card-description">
                                                <code><button type="button" class="btn btn-success btn-rounded btn-sm" onClick={() => addNew()}> Add Regions</button></code>
                                            </p>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Region
                                                        </th>
                                                        <th>
                                                            City
                                                        </th>
                                                        <th>
                                                            Visible On
                                                        </th>
                                                        <th>
                                                            Action
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.map(({ id, data }, index) => (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    {data.region}
                                                                </td>
                                                                <td>
                                                                    {data.city}
                                                                </td>
                                                                <td>
                                                                    {data.visible_on}
                                                                </td>
                                                                <td>
                                                                    <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => changeStatusForm(data, id)} class="btn btn-dark btn-sm"><i class="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i></button>
                                                                    <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} class="btn btn-dark btn-sm" onClick={() => deleteData(id)}><i class="menu-icon mdi mdi-delete" style={{ color: "white" }}></i></button>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))}




                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="exampleModal-2" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel-2" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel-2">Add Region</h5>
                                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form class="forms-sample" onSubmit={handleSubmit}>
                                                <div class="form-group">
                                                    <label>Region Name</label>
                                                    <input type="text" class="form-control" onChange={handleChange} id="region" value={submit.region} required />
                                                </div>
                                                <div class="form-group">
                                                    <label >City</label>
                                                    <input type="text" class="form-control" onChange={handleChange} id="city" value={submit.city} required />
                                                </div>

                                                <div class="form-group">
                                                    <label >Visible On</label>
                                                    <select class="form-select" onChange={handleChange} id="visible_on" value={submit.visible_on}>
                                                        <option>Select Visible On</option>
                                                        <option value="Internal & External">Internal & External</option>
                                                        <option value="Internal">Internal</option>
                                                    </select>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="submit" value="submit" class="btn btn-success">Submit</button>
                                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <Footer />

                    </div>

                </div>

            </div>
        </>
    )
}

export default Region
