import React, { useState, useEffect, } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';

function SidebarAdmin() {
    return (
        <>
            <nav class="sidebar sidebar-offcanvas" id="sidebar">
                <ul class="nav">
                    <li class="nav-item">
                        <Link class="nav-link" to="/">
                            <i class="mdi mdi-grid-large menu-icon"></i>
                            <span class="menu-title">Dashboard</span>
                        </Link>
                    </li>
                    <li class="nav-item nav-category">Menu</li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/vendors_data">
                            <i class="menu-icon mdi mdi-account-multiple"></i>
                            <span class="menu-title">Hubs/Distributors</span>
                        </Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/customers">
                            <i class="menu-icon mdi mdi-file-document"></i>
                            <span class="menu-title">Customers</span>
                        </Link>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="collapse" href="#locations" aria-expanded="false"
                            aria-controls="locations">
                            <i class="menu-icon mdi mdi-map-marker"></i>
                            <span class="menu-title">Locations</span>
                            <i class="menu-arrow"></i>
                        </a>
                        <div class="collapse" id="locations">
                            <ul class="nav flex-column sub-menu">
                                <li class="nav-item"> <Link class="nav-link" to="/location">Locations</Link></li>
                                <li class="nav-item"> <Link class="nav-link" to="/routes">Routes</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="collapse" href="#products" aria-expanded="false"
                            aria-controls="products">
                            <i class="menu-icon mdi mdi-cart-arrow-down"></i>
                            <span class="menu-title">Products</span>
                            <i class="menu-arrow"></i>
                        </a>
                        <div class="collapse" id="products">
                            <ul class="nav flex-column sub-menu">
                                <li class="nav-item"> <Link class="nav-link" to="/brands">Brand</Link></li>
                                <li class="nav-item"> <Link class="nav-link" to="/product_categories">Product Categories</Link>
                                </li>
                                <li class="nav-item"> <Link class="nav-link" to="/product">Product</Link></li>
                                <li class="nav-item"> <a class="nav-link" href="#">Banner</a></li>
                                <li class="nav-item"> <a class="nav-link" href="#">Product Package
                                    Sorting</a></li>
                                <li class="nav-item"> <a class="nav-link" href="#">Stock Estimation</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="collapse" href="#offer" aria-expanded="false" aria-controls="offer">
                            <i class="menu-icon mdi mdi-tag"></i>
                            <span class="menu-title">Coupons & Offers </span>
                            <i class="menu-arrow"></i>
                        </a>
                        <div class="collapse" id="offer">
                            <ul class="nav flex-column sub-menu">
                                <li class="nav-item"> <a class="nav-link" href="#">Promo Codes</a></li>
                                <li class="nav-item"> <a class="nav-link" href="#">Offers</a></li>
                            </ul>
                        </div>
                    </li>


                    <li class="nav-item">
                        <a class="nav-link" href="#">
                            <i class="menu-icon mdi mdi-chart-line"></i>
                            <span class="menu-title">Reports</span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#">
                            <i class="menu-icon mdi mdi-wechat"></i>
                            <span class="menu-title">COMMUNICATIONS</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">
                            <i class="menu-icon mdi mdi-ticket-percent"></i>
                            <span class="menu-title">Tickets</span>
                        </a>
                    </li>

                </ul>
            </nav>
        </>
    );
};

export default SidebarAdmin;