import React, { useState, useEffect } from "react";
import TopPanel from "./TopPanel";
import Sidebar from "./Sidebar";
import "react-toastify/dist/ReactToastify.css";
import ManageRoles from "./ManageRoles";
import ManageUserDetails from "./ManageUserDetails";
import Users from "./Users1";
import { useNavigate, Link, useParams } from 'react-router-dom';

function ManageUsersAndRoles() {
    const navigate = useNavigate();

    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        console.log(loggedIn);
        if (loggedIn) {
            // navigate("/");
        } else {
            navigate("/login");
        }
    }, [navigate]);
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <div class="container-scroller">
            <TopPanel />
            <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <div class="main-panel">
                    <div class="content-wrapper">
                        <div class="col-lg-12 grid-margin stretch-card">
                            <div class="card">
                                <div class="card-body">
                                    <div class="home-tab">
                                        <div class="d-sm-flex align-items-center justify-content-between border-bottom">
                                            <ul class="nav nav-tabs" role="tablist">
                                                <li class="nav-item">
                                                    <a class="nav-link active ps-0"
                                                        id="home-tab"
                                                        data-bs-toggle="tab"
                                                        href="#overview"
                                                        role="tab"
                                                        aria-controls="overview"
                                                        aria-selected="true"
                                                        onClick={() => setSearchQuery("")}
                                                    >
                                                        User Details
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        class="nav-link"
                                                        id="manage-roles-tab"
                                                        data-bs-toggle="tab"
                                                        href="#manageRoles"
                                                        role="tab"
                                                        aria-selected="false"
                                                        onClick={() => setSearchQuery("")}
                                                    >
                                                        Manage Roles
                                                    </a>
                                                </li>

                                                <li class="nav-item">
                                                    <input
                                                        style={{
                                                            border: "1px solid grey",
                                                            padding: "0px 4px 0px 1rem;",
                                                            borderRadius: "1rem",
                                                            marginTop: "3px",
                                                            marginLeft: "1rem",
                                                            paddingLeft: "1rem",
                                                            height: "32px",
                                                            paddingBottom: "0px"
                                                        }}
                                                        type="text"
                                                        placeholder="Search here"
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                    />
                                                </li>


                                            </ul>
                                        </div>
                                        <div class="tab-content tab-content-basic">
                                            <div
                                                class="tab-pane fade show active"
                                                id="overview"
                                                role="tabpanel"
                                                aria-labelledby="home-tab"
                                            >
                                                <p class="card-description">

                                                    <Users></Users>
                                                </p>
                                            </div>
                                            <div
                                                class="tab-pane fade"
                                                id="manageRoles"
                                                role="tabpanel"
                                                aria-labelledby="manage-roles-tab"
                                            >
                                                <p class="card-description">
                                                    <ManageRoles></ManageRoles>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManageUsersAndRoles;
