import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import TopPanel from "./TopPanel";
import './Report.css';
import LowCreditReport from "./LowCreditReport";
import { Card } from 'react-bootstrap';
import NewOnboardCustomers from "./NewOnboardCustomers";
import WalletTxnsReport from "./WalletTxnsReport";
import { useCookies } from "react-cookie";
import Swal from 'sweetalert2'
import { useNavigate, Link, useParams } from 'react-router-dom';

function Reports() {
  const [cookies] = useCookies(["permissions"]);
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    console.log(loggedIn);
    if (loggedIn) {
      // navigate("/");
    } else {
      navigate("/login");
    }
  }, [navigate]);
  const rolePermissions = cookies.permissions ? cookies.permissions.Reports || [] : [];
  const [isVisible, setIsVisible] = useState(true);
  const [showLowCreditReport, setShowLowCreditReport] = useState(false);
  const [showWalletTxns, setShowWalletTxns] = useState(false);
  const [showReports, setShowReports] = useState(true);
  const [showOnboardReport, setShowOnboardReport] = useState(false);
  console.log(rolePermissions)

  const handleBack = () => {
    setShowLowCreditReport(false);
    setShowOnboardReport(false);
    setShowWalletTxns(false);
    setShowReports(true);

  }

  const clickLowCreditReport = () => {
    const url = '/lowcreditreport';
    navigate(url);
    // const newTab = window.open(url, '_blank');
    // newTab.focus();
  }

  const clickWalletTransactions = () => {
    const url = '/wallettransactions';
    navigate(url);
    // const newTab = window.open(url, '_blank');
    // newTab.focus();
  }



  const loadOnBoardReport = () => {
    const url = '/newonboardcustomer';
    navigate(url);
    // const newTab = window.open(url, '_blank');
    // newTab.focus();

  }

  const navigateToOrderSheet = () => {
    const url = '/orderSheet';
    navigate(url);
    // const newTab = window.open(url, '_blank');
    // newTab.focus();
    //navigate(`/profile/${contact}`);
  }

  const navigateToHubDeliveryReport = () => {
    const url = '/hubdeliveryreport';
    navigate(url);
    // const newTab = window.open(url, '_blank');
    // newTab.focus();
  }

  const navigateToSubscripationReport = () => {
    try {
      const url = '/subscriptionreport';
      console.log('Navigating to:', url);
      navigate(url);
    } catch (error) {
      console.error('Error navigating to Subscription Report:', error);
    }
  };
  
  const navigateToPredictiveAnalysis = () => {
    const url = '/predictiveanalysis';
    navigate(url);
    // const newTab = window.open(url, '_blank');
    // newTab.focus();
  }

  const navigateToSalesReport = () => {
    const url = '/salesReport';
    navigate(url);
    // const newTab = window.open(url, '_blank');
    // newTab.focus();
  }

  const navigateToCumulativeSalesReport = () => {
    const url = '/cumulativeSalesReport';
    navigate(url);
    // const newTab = window.open(url, '_blank');
    // newTab.focus();
  }

  const navigateToOrderSorting = () => {
    const url = '/ordersorting';
    navigate(url);
    // const newTab = window.open(url, '_blank');
    // newTab.focus();
  }

  const navigateToActivitylogs = () => {
    const url = '/activitylogs'
    navigate(url);
    // const newTab = window.open(url, '_blank');
    // newTab.focus();
  }


  const rolePermission = () => {
    const Toast = Swal.mixin({
      toast: true,
      background: '#d7e7e6',
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });

    Toast.fire({
      icon: 'error',
      title: 'You are not authorised to do this action'
    });

  }


  return (
    <>

      <div class="container-scroller">
        <TopPanel />
        <div class="container-fluid page-body-wrapper">
          <Sidebar />
          <div class="main-panel">
            <div class="content-wrapper">
              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card" style={{ background: '#4a54ba' }}>
                  <div class="card-body">
                    {showReports && <div>
                      <h4 class="card-title" style={{ color: '#fff', fontWeight: '700' }}>Reports</h4>

                      <Card>
                        <Card.Body>
                          <Card.Title>Customer Reports</Card.Title>
                          <Card.Text>
                            <br />
                          </Card.Text>
                          {rolePermissions.includes('lowcreditreport') ? <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "
                              onClick={clickLowCreditReport}
                            >
                              Low Credit Reports
                            </button></> : <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "
                              onClick={() => rolePermission()}
                            >
                              Low Credit Reports
                            </button>
                          </>}

                          {'  '}

                          {rolePermissions.includes('newonboardcustomer') ? <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "

                              onClick={loadOnBoardReport}
                            >
                              Onboard Report
                            </button>
                          </> : <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "

                              onClick={() => rolePermission()}
                            >
                              Onboard Report
                            </button>
                          </>}
                          {'  '}
                          {/* <button
                                  type="button"
                                  class="btn btn-success btn-rounded btn-sm disabled"
                                  style={{ color: "white" }}
                                  //onClick={handleManageRoles}
                                >
                                  Order / Feedback Report
                                </button>{'   '} */}

                        </Card.Body>
                      </Card>
                      <br />

                      <Card>
                        <Card.Body>
                          <Card.Title>Predictive</Card.Title>
                          <Card.Text>
                            <br />
                          </Card.Text>
                          {rolePermissions.includes('predictive_analysis') ? <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm"
                              style={{ color: "white" }}
                              onClick={navigateToPredictiveAnalysis}
                            >
                              Predictive Analysis
                            </button>
                          </> : <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "

                              onClick={() => rolePermission()}
                            >
                              Predictive Analysis
                            </button>
                          </>}

                          {'  '}
                        </Card.Body>
                      </Card>
                      <br />
                      <Card>
                        <Card.Body>
                          <Card.Title>Operations</Card.Title>
                          <Card.Text>
                            <br />
                          </Card.Text>
                          {rolePermissions.includes('hubdeliveryreport') ? <><button
                            type="button"
                            class="btn btn-success btn-rounded btn-sm  "

                            onClick={navigateToHubDeliveryReport}
                          >
                            Hub Deliveries
                          </button> </> : <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "

                              onClick={() => rolePermission()}
                            >
                              Hub Deliveries
                            </button></>}
                          {'  '}

                          {rolePermissions.includes('subscriptionreport') ? 
  <button
    type="button"
    class="btn btn-success btn-rounded btn-sm"
    onClick={navigateToSubscripationReport}
  >
    Subscription Report
  </button> 
: 
  <button
    type="button"
    class="btn btn-success btn-rounded btn-sm"
    onClick={() => rolePermission()}
  >
    Subscription Report
  </button>
}

                           
                            {rolePermissions.includes('orderSheet') ? <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "

                              onClick={navigateToOrderSheet}
                            >
                              Order Sheet
                            </button></> : <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "

                              onClick={() => rolePermission()}
                            >
                              Order Sheet
                            </button>
                          </>}
                          {'   '}
                          {rolePermissions.includes('ordersorting') ? <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "

                              onClick={navigateToOrderSorting}
                            >
                              Order Sorting
                            </button></> : <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "

                              onClick={() => rolePermission()}
                            >
                              Order Sorting
                            </button>
                          </>}
                        </Card.Body>
                      </Card>
                      <br />
                      <Card>
                        <Card.Body>
                          <Card.Title>Finance</Card.Title>
                          <Card.Text>
                            <br />
                          </Card.Text>
                          {rolePermissions.includes('cumulative_sales') ? <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "

                              onClick={navigateToCumulativeSalesReport}
                            >
                              Cumulative Sales
                            </button>
                          </> : <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "

                              onClick={() => rolePermission()}
                            >
                              Cumulative Sales
                            </button>
                          </>}
                          {'  '}

                          {rolePermissions.includes('customers_sales') ? <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "

                              onClick={navigateToSalesReport}
                            >
                              Customer Sales (Sales Report)
                            </button></> : <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "

                              onClick={() => rolePermission()}
                            >
                              Customer Sales (Sales Report)
                            </button>
                          </>}
                          {'   '}
                          {rolePermissions.includes('wallet_transaction') ? <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "

                              onClick={clickWalletTransactions}
                            >
                              Wallet Transactions
                            </button></> : <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "

                              onClick={() => rolePermission()}
                            >
                              Wallet Transactions
                            </button>
                          </>}
                          {'   '}

                        </Card.Body>
                      </Card>
                      <br />
                      <Card>
                        <Card.Body>
                          <Card.Title>Logs</Card.Title>
                          <Card.Text>
                            <br />
                          </Card.Text>
                          {rolePermissions.includes('activity_log') ? <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "
                              style={{ background: '#84bf93' }}
                              onClick={navigateToActivitylogs}
                            >
                              Activity Logs
                            </button>
                          </> : <>
                            <button
                              type="button"
                              class="btn btn-success btn-rounded btn-sm  "

                              onClick={() => rolePermission()}
                            >
                              Activity Logs
                            </button>
                          </>}

                        </Card.Body>
                      </Card>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reports;
