import React from 'react'

function Marketing() {
  return (
    <div>
      Marketing
    </div>
  )
}

export default Marketing
