import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Swal from 'sweetalert2'
import db from "./Firebase";
import TopPanel from "./TopPanel";
import DatePicker from "react-datepicker";
import { storage } from './Firebase';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Footer from "./Footer";
import { getUserInfo } from "./Utility";

function UserProfile() {
    const navigate = useNavigate();

    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        console.log(loggedIn);
        if (loggedIn) {
            // navigate("/");
        } else {
            navigate("/login");
        }
    }, [navigate]);
    //const { userId } = useParams();
    const fileInputRef = useRef(null);
    const { loggedIn, userId, username , loggedIn_user} = getUserInfo();


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const initialFormData = {
        user_id: "",
        first_name: "",
        last_name: "",
        email: "",
        role: "",
        username: "",
        password: "",
        phone_no: "",
        status: true,
        user_image: null,
        created_date: new Date(),
        updated_date: new Date(),

    };

    const [formData, setFormData] = useState(initialFormData);


    

    useEffect(() => {
        // Fetch user data from the database based on user ID
        const fetchUserData = async () => {
            try {
                
                console.log("user id => " , userId);
                const querySnapshot = await db.collection('users').where("user_id", "==", userId).get();
                if (!querySnapshot.empty) {
                    querySnapshot.forEach(doc => {
                        const userData = doc.data();
                        setFormData(userData);
                        console.log(userData);
                    });
                } else {
                    console.log("No documents found!");
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [userId]);

    if (!loggedIn) {
        return <div>Please log in.</div>;
    }

    const fetchUserData = async () => {
        try {
            const querySnapshot = await db.collection('users').where("user_id", "==", userId).get();
            if (!querySnapshot.empty) {
                // Since where() returns a collection, iterate through each document in the collection
                querySnapshot.forEach(doc => {
                    const userData = doc.data();
                    setFormData(userData);
                    console.log("form data in profile",userData);
                });
            } else {
                console.log("No documents found!");
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };


    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        if (type === "checkbox") {
            setFormData(prevState => ({
                ...prevState,
                [name]: checked
            }));
        } else if (type === "file") {
            const file = files[0];
            setFormData(prevState => ({
                ...prevState,
                [name]: file
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Check if a file is selected
            if (formData.user_image) {
                const storageRef = ref(storage, `users/${formData.user_image.name}`);
                const uploadTask = uploadBytesResumable(storageRef, formData.user_image);

                uploadTask.on(
                    "state_changed",
                    snapshot => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    },
                    error => {
                        console.error("Error uploading image: ", error);
                    },
                    () => {
                        // File uploaded successfully, now obtain the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                            db.collection("users").doc("Qwnwy7vF9qUJbHA6KhlK").update({
                                ...formData,
                                user_image: downloadURL // Replace image with the download URL of the new image
                            })
                                .then(() => {
                                    console.log("User updated successfully!");
                                    fileInputRef.current.value = '';
                                    fetchUserData();

                                })
                                .catch(error => {
                                    console.error("Error updating user: ", error);
                                });
                        })
                            .catch(error => {
                                console.error("Error getting download URL: ", error);
                            });
                    }
                );
            } else {
                await db.collection('users').doc("Qwnwy7vF9qUJbHA6KhlK").update(formData);
            }
            console.log("User data updated successfully!");
            const Toast = Swal.mixin({
                toast: true,
                background: '#69aba6',
                position: 'top-end',
                showConfirmButton: false,
                timer: 6000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: `User Profile Updated Successfully`
            })
            // Optionally, you can show a success message here
        } catch (error) {
            console.error("Error updating user data:", error);
            console.log("User data updated successfully!");
            const Toast = Swal.mixin({
                toast: true,
                background: '#69aba6',
                position: 'top-end',
                showConfirmButton: false,
                timer: 6000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'failure',
                title: `User Profile Updated Unsuccessful`
            })
            // Optionally, you can show an error message here
        }
    };




    return (
        <>

            <div class="container-scroller">
                <TopPanel />

                <div class="container-fluid page-body-wrapper">

                    <Sidebar />

                    <div class="main-panel">

                        <div class="content-wrapper">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <div class="text-center pb-4">
                                                        <img 
                                                            alt="profile"
                                                            src={formData.user_image ? formData.user_image : 'https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg'}
                                                            class="img-lg rounded-circle mb-3" style={{ width: "120px", height: "120px", marginTop: "3rem" }} />
                                                        <div class="mb-3">
                                                            <h3>{formData.first_name} {formData.last_name}</h3>

                                                        </div>
                                                        <p class="w-75 mx-auto mb-3" style={{ fontSize: "14px", fontWeight: "400" }}>User Role : {formData.role}</p>
                                                        <div class="d-flex justify-content-center">
                                                            {/* <button class="btn btn-success" style={{ height: "40px" }}>Change Password</button> */}
                                                        </div>
                                                    </div>



                                                </div>
                                                <div class="col-lg-8">

                                                    <div class="profile-feed">
                                                        <div class="align-items-start" style={{ padding: "1.5rem 0" }}>
                                                            <form class="forms-sample" onSubmit={handleSubmit}>
                                                                <div class="form-group row">
                                                                    <div class="col">
                                                                        <label>Update Profile Image:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            type="file"
                                                                            style={{ padding: "10px" }}
                                                                            name="user_image"
                                                                            ref={fileInputRef}
                                                                            onChange={handleChange}

                                                                        />
                                                                    </div>
                                                                    <div class="col">
                                                                        <label>User Name:</label>
                                                                        <input class="form-control" type="text" name="username" value={formData.username} onChange={handleChange} required disabled />
                                                                    </div>


                                                                </div>

                                                                <div class="form-group row">
                                                                    <div class="col">
                                                                        <label>First Name:</label>
                                                                        <input class="form-control" type="text" name="first_name" value={formData.first_name} onChange={handleChange} required />
                                                                    </div>
                                                                    <div class="col">
                                                                        <label>Last Name:</label>
                                                                        <input class="form-control" type="text" name="last_name" value={formData.last_name} onChange={handleChange} required />
                                                                    </div>

                                                                </div>

                                                                <div class="form-group row">
                                                                    <div class="col">
                                                                        <label>Email ID:</label>
                                                                        <input class="form-control" type="text" name="email" value={formData.email} onChange={handleChange} required />
                                                                    </div>
                                                                    <div class="col">
                                                                        <label>Mobile No:</label>
                                                                        <input class="form-control" type="text" name="phone_no" value={formData.phone_no} onChange={handleChange} required />
                                                                    </div>

                                                                </div>


                                                                <div class="" style={{ display: "flex", justifyContent: "end" }}>
                                                                    <button type="submit" class="btn btn-success btn-sm">Update Profile</button>
                                                                </div>
                                                            </form>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer />

                    </div>

                </div>

            </div>

        </>

    );
}

export default UserProfile;
