import React from 'react'

function Coupons() {
  return (
    <div>
      Coupons
    </div>
  )
}

export default Coupons
