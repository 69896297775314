import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Swal from 'sweetalert2'
import db from "./Firebase";
import Select from 'react-select';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from './Firebase';
import { useCookies } from "react-cookie";
import TopPanel from "./TopPanel";
import Footer from "./Footer";
import './profiilePage.css';
import { getUserInfo,calculateChanges } from "./Utility";
import { collection, getDocs, query, getCountFromServer } from 'firebase/firestore';



function EditCustomers() {
    const [cookies] = useCookies(["permissions"]);
    let params = useParams();
    const {  loggedIn_user } = getUserInfo();
    const rolePermissions = cookies.permissions ? cookies.permissions.Customers || [] : [];
    const navigate = useNavigate();
    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        console.log(loggedIn);
        if (loggedIn) {
            // navigate("/");
        } else {
            navigate("/login");
        }
    }, [navigate]);
    const moment = extendMoment(Moment);
    const [timeOfDay, setTimeOfDay] = useState('');
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const initialFormState = { customer_id: '', customer_image: null, customer_type: false, customer_name: '', customer_category: '', hub_name: '', customer_phone: '', alt_phone: '', customer_email: '', dob: '', anniversary_date: '', customer_address: '', flat_villa_no: '', floor: '', landmark: '', location: '', pincode: '', gender: '', plateform: '', delivery_exe_id: '', wallet_balance: 0, credit_limit: 0, status: '', source: '', updated_date: new Date(), created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormState)

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);

    const [hubNames, setHubNames] = useState([]);
    const [selectedHub, setSelectedHub] = useState(null);
    const [locationOptions, setLocationOptions] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [sourceCategory, setSourceCategory] = useState("");
    const [deliveryExecutiveID, setDeliveryExecutiveID] = useState("");
    const [nod, setNod] = useState("");

    // const [searchQuery, setSearchQuery] = useState('');
    const [customers, setCustomers] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [collectionSize, setCollectionSize] = useState(null);
    const [oldData, setOldData] = useState();
    // const [loading, setLoading] = useState(false);
    // const moment = extendMoment(Moment);
    // const [totalPages, setTotalPages] = useState(0);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [itemsPerPage, setItemsPerPage] = useState(10);

    useEffect(() => {
        fetchInitialData();
        fetchCollectionSize();
    }, []);


    const fetchCollectionSize = async () => {
        try {
            const coll = collection(db, 'customers_data');
            const snapshot = await getCountFromServer(query(coll));
            setCollectionSize(snapshot.data().count);
        } catch (error) {
            console.error("Error fetching collection size: ", error);
        }
    };

    const fetchInitialData = async () => {
        setLoading(true);
        const snapshot = await db.collection("customers_data")
            .orderBy("updated_date", "desc")
            .limit(20)
            .get();

        const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
        const customerData = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
        }));
        setCustomers(customerData);
        setLastVisible(lastVisibleDoc);
        setLoading(false);
    };

    const fetchMoreData = async () => {
        if (lastVisible) {
            setLoading(true);
            const snapshot = await db.collection("customers_data")
                .orderBy("updated_date", "desc")
                .startAfter(lastVisible)
                .limit(20)
                .get();

            const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
            const newCustomerData = snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));
            setCustomers((prevCustomers) => [...prevCustomers, ...newCustomerData]);
            setLastVisible(lastVisibleDoc);
            setLoading(false);
        }
    };


    const fetchData = async (searchTerm = '') => {

        setLoading(true);
        let query = db.collection("customers_data").orderBy("updated_date", "desc");

        if (searchTerm) {
            const nameQuery = db.collection("customers_data")
                .orderBy("customer_name")
                .startAt(searchTerm)
                .endAt(searchTerm + '\uf8ff')
                .limit(20)
                .get();

            const phoneQuery = db.collection("customers_data")
                .orderBy("updated_date", "desc")
                .where("customer_phone", "==", searchTerm)
                .limit(20)
                .get();

            const idQuery = db.collection("customers_data")
                .orderBy("updated_date", "desc")
                .where("customer_id", "==", searchTerm)
                .limit(20)
                .get();

            // Wait for all queries to complete
            const [nameSnapshot, phoneSnapshot, idSnapshot] = await Promise.all([nameQuery, phoneQuery, idQuery]);

            // Merge the results
            const customerData = [
                ...nameSnapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })),
                ...phoneSnapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })),
                ...idSnapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))
            ];

            setCustomers(customerData);
        } else {
            // If no search term, fetch initial data
            const snapshot = await query.limit(20).get();
            const customerData = snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));
            setCustomers(customerData);
        }

        setLoading(false);
    };


    const handleSearch = () => {
        setCustomers([]);
        setLastVisible(null);
        fetchData(searchQuery);
    };

    const handleKeyPress = (event) => {

        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    useEffect(() => {
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, []);

    // Determine the range of page numbers to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = customers.slice(indexOfFirstItem, indexOfLastItem);


    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        const total = Math.ceil(customers.length / itemsPerPage);
        setTotalPages(total);
    }, [customers.length, itemsPerPage]);

    const renderPageButtons = () => {
        const pageButtons = [];
        // Determine the range of page numbers to display
        let startPage = Math.max(1, currentPage - 5);
        let endPage = Math.min(totalPages, startPage + 9);

        // If the total number of pages is less than 10, adjust the endPage
        if (totalPages <= 10) {
            endPage = totalPages;
        } else {
            // If the current page is near the start, display the first 10 pages
            if (currentPage <= 5) {
                startPage = 1;
                endPage = 10;
            }
            // If the current page is near the end, display the last 10 pages
            else if (currentPage >= totalPages - 4) {
                endPage = totalPages;
                startPage = endPage - 9;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageButtons.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button onClick={() => paginate(i)} className="page-link" style={{ color: "black" }}>{i}</button>
                </li>
            );
        }
        return pageButtons;
    };

    const categoryList = [
        { label: "Lead", value: "Lead" },
        { label: "One Time", value: "One Time" },
        { label: "Subscribed", value: "Subscribed" },
        { label: "Unqualified", value: "Unqualified" },
    ];


    const sourceList = [
        { label: "Website", value: "Website" },
        { label: "Friends", value: "Friends" },
        { label: "Newspaper", value: "Newspaper" },
        { label: "Ads", value: "Ads" },
    ];

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // const handleSearch = () => {
    //     // Perform search based on startDate and endDate
    // };

    const fileInputRef = useRef(null);

    useEffect(() => {
        db.collection("hubs_data").onSnapshot((snapshot) => {
            setHubNames(
                snapshot.docs.map((doc) => ({ label: doc.data().hub_name, value: doc.data().hub_name }))
            );
        });
    }, []);

    useEffect(() => {
        fetchLocations();
    }, [selectedHub]);

    const fetchLocations = async () => {
        try {
            if (!selectedHub) {
                setLocationOptions([]);
                return;
            }
            const snapshot = await db.collection('locations_data').where('hub_name', '==', selectedHub.value).get();
            const locations = snapshot.docs.map(doc => {
                const data = doc.data();
                return { value: `${data.area}, ${data.subarea}`, label: `${data.area}, ${data.subarea}` };
            });
            setLocationOptions(locations);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    const handleCategoryChange = (selectedOption) => {
        setSelectedCategory(selectedOption);
        setSubmit({ ...submit, customer_category: selectedOption.value });
    };

    const handleSourceChange = (selectedOption) => {
        setSourceCategory(selectedOption);
        setSubmit({ ...submit, source: selectedOption.value });
    };


    const handleHubChange = (selectedOption) => {
        setSelectedHub(selectedOption);
        setSelectedLocation([]);

        setSubmit({ ...submit, hub_name: selectedOption.value });
    };

    const handleLocationChange = (selectedOption) => {
        setSelectedLocation(selectedOption);
        setSubmit({ ...submit, location: selectedOption.value });
        // Perform query to search for delivery executive name based on selected location
        db.collection("hubs_locations_data")
            .where("location", "==", selectedOption.value)
            .where("hub_name" , "==" , selectedHub.value)
            .get()
            .then((querySnapshot) => {

                if (!querySnapshot.empty) {
                    const data = querySnapshot.docs[0].data();

                    // const deliveryExecutiveName1 = data.delivery_executive;
                    const did = data.delivery_exe_id;
                    // alert(data.delivery_executive)
                    //alert(data.delivery_exe_id)
                    setDeliveryExecutiveID(did)
                    setNod("found")
                } else {
                    console.log("No matching location found in hubs_locations_data collection.");
                    // setDeliveryExecutiveName("notfound")
                    setDeliveryExecutiveID("notfound")
                }
            })
            .catch((error) => {
                console.error("Error searching for location:", error);
            });
    };



    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleDateRange = () => {

        const startDateTimestamp = startDate ? startDate.getTime() : 0;
        const endDateTimestamp = endDate ? endDate.getTime() : new Date().getTime();

        db.collection("customers_data")
            .where("created_date", ">=", new Date(startDateTimestamp))
            .where("created_date", "<=", new Date(endDateTimestamp))
            .orderBy("created_date", "desc")
            .onSnapshot((snapshot) => {
                setData(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                );
            });
    };

    const handleReset = () => {
        setStartDate(null);
        setEndDate(null);
        fetchInitialData();
        // db.collection("customers_data").orderBy("updated_date", "desc").onSnapshot((snapshot) => {
        //     setData(
        //         snapshot.docs.map((doc) => ({
        //             id: doc.id,
        //             data: doc.data(),
        //         }))
        //     );
        // });
    };

    const navi = (name) => {
        // const url = `/profile/${name}`;
        // const newTab = window.open(url, '_blank');
        // newTab.focus();
        navigate(`/profile/${name}`);
    }


    // const handleChange = (e) => {
    //     const { id, value } = e.target
    //     setSubmit({ ...submit, [id]: value.replace(/[^\w\s.@/:+\-=]/gi, "") })
    // }
    const handleChange = (e) => {
        const { id, name, value, type, checked, files } = e.target;
        let newValue = type === 'checkbox' ? checked : value;

        if (type === 'radio') {
            // For radio buttons, directly set the value
            newValue = value;
        } else if (id === 'customer_phone' || id === 'pincode' || id === 'alt_phone') {
            // Allow only numbers in customer_phone and pincode fields
            newValue = value.replace(/\D/g, '');
        } else if (type === "file") {
            // File input, handle files separately
            const file = files[0]; // Assuming single file selection
            // You may want to perform additional checks or validation on the file here
            newValue = file
            // setSubmit(prevState => ({
            //     ...prevState,
            //     customer_image
            //         : file
            // }));
        } else {
            // Allow only alphanumeric characters and specific symbols in other fields
            newValue = value.replace(/[^\w\s.@/:+\-=]/gi, "");
        }

        setSubmit(prevState => ({
            ...prevState,
            [name]: newValue
        }));
    };



    const addNew = () => {
        fileInputRef.current.value = '';
        setSelectedHub("");
        setSelectedLocation([]);
        setSelectedCategory("");
        setEdit(false);
        openModal();
        // setDeliveryExecutiveName("")
        setDeliveryExecutiveID("")
        setNod("")
        setSubmit({ customer_id: '', customer_image: null, customer_type: false, customer_name: '', customer_category: '', hub_name: '', customer_phone: '', alt_phone: '', customer_email: '', dob: '', anniversary_date: '', customer_address: '', flat_villa_no: '', floor: '', landmark: '', location: '', pincode: '', gender: '', plateform: '', delivery_exe_id: '', wallet_balance: 0, credit_limit: 0, status: '', source: '', updated_date: new Date(), created_date: new Date() });
    }

    const reset = () => {
        setSubmit({ customer_id: '', customer_image: null, customer_type: false, customer_name: '', customer_category: '', hub_name: '', customer_phone: '', alt_phone: '', customer_email: '', dob: '', anniversary_date: '', customer_address: '', flat_villa_no: '', floor: '', landmark: '', location: '', pincode: '', gender: '', plateform: '', delivery_exe_id: '', wallet_balance: 0, credit_limit: 0, status: '', source: '', updated_date: new Date(), created_date: new Date() })
    }

    // useEffect(() => {
    //     db.collection("customers_data").orderBy("updated_date", "desc").onSnapshot((snapshot) => {
    //         setData(
    //             snapshot.docs.map((doc) => ({
    //                 id: doc.id,
    //                 data: doc.data(),
    //             }))
    //         );
    //     });

    // }, []);

    // useEffect(() => {
    //     db.collection("customers_data").orderBy("updated_date", "desc").onSnapshot((snapshot) => {
    //         const sortedData = snapshot.docs.map((doc) => ({
    //             id: doc.id,
    //             data: doc.data(),
    //         })).sort((a, b) => {
    //             const idA = parseInt(a.data.customer_id, 10);
    //             const idB = parseInt(b.data.customer_id, 10);
    //             return idB - idA;
    //         });

    //         setData(sortedData);
    //     });
    // }, []);


    const filterData = (cat) => {
        if (cat === "All") {
            db.collection("customers_data").orderBy("updated_date", "desc").onSnapshot((snapshot) => {
                setData(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                );
            });
        } else {
            db.collection("customers_data").where("customer_category", "==", cat).onSnapshot((snapshot) => {
                setData(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                );
            });
        }

    }

    const changeStatusForm = (event, data, id) => {
        event.stopPropagation();
        setSelectedHub({ value: data.hub_name, label: data.hub_name });

        setSelectedLocation([{ value: data.location, label: data.location }]);
        setSelectedCategory({ value: data.customer_category, label: data.customer_category })
        // setDeliveryExecutiveName(data.delivery_executive === "" ? "notfound" : data.delivery_executive)
        setDeliveryExecutiveID(data.delivery_exe_id === "" ? "notfound" : data.delivery_exe_id)
        setNod("found")
        setEdit(true)
        setEditID(id)
        setSubmit({ id: id, customer_id: data.customer_id, customer_image: data.customer_image, customer_type: data.customer_type, customer_name: data.customer_name, customer_category: data.customer_category, hub_name: data.hub_name, customer_phone: data.customer_phone, alt_phone: data.alt_phone, customer_email: data.customer_email, dob: moment(data.dob).format("YYYY-MM-DD"), anniversary_date: moment(data.anniversary_date.toDate().toISOString()).format("YYYY-MM-DD"), customer_address: data.customer_address, flat_villa_no: data.flat_villa_no, floor: data.floor, landmark: data.landmark, location: data.location, pincode: data.pincode, gender: data.gender, plateform: data.plateform, delivery_exe_id: data.delivery_exe_id, wallet_balance: data.wallet_balance, credit_limit: data.credit_limit, status: data.status, source: data.source, updated_date: new Date(), created_date: new Date() });
        openModal();
    }

    const openModal = () => {
        // alert("ooo")
        window.modelshow();
    }

    const closeModal = () => {
        window.modalHide();
    }

    // function generateCustomerId() {
    //     const now = new Date();
    //     const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    //     const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Month (padded with zero if necessary)
    //     const day = now.getDate().toString().padStart(2, '0'); // Day (padded with zero if necessary)
    //     const hours = now.getHours().toString().padStart(2, '0'); // Hours (padded with zero if necessary)
    //     const minutes = now.getMinutes().toString().padStart(2, '0'); // Minutes (padded with zero if necessary)
    //     const seconds = now.getSeconds().toString().padStart(2, '0'); // Seconds (padded with zero if necessary)
    //     const milliseconds = now.getMilliseconds().toString().padStart(3, '0'); // Milliseconds (padded with zero if necessary)

    //     // Concatenate the components to form the customer ID
    //     const customerId = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;

    //     return customerId;
    // }
    function generateCustomerId() {
        const now = new Date();
        const timestamp = now.getTime(); // Get the timestamp in milliseconds since January 1, 1970
        const random4Digits = Math.floor(Math.random() * 10000).toString().padStart(4, '0'); // Generate a random 4-digit number

        // Take the last 4 digits of the timestamp and concatenate with the random 4-digit number
        const customerId = (timestamp % 10000).toString().padStart(4, '0') + random4Digits;

        return customerId;
    }

    const checkPhoneExists = async (phone) => {
        try {
            const phoneSnapshot = await db.collection("customers_data").where('customer_phone', '==', phone).get();
            return !phoneSnapshot.empty;
        } catch (error) {
            console.error('Error checking route existence: ', error);
            return false;
        }
    };


    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const snapshot = await db.collection('customers_data')
                    .where('customer_id', '==', params.id)
                    .get();
                if (!snapshot.empty) {
                    const doc = snapshot.docs[0];
                    const data = doc.data();

                    console.log(data)

                    setSubmit({
                        id: doc.id,
                        customer_id: data.customer_id,
                        customer_image: data.customer_image,
                        customer_type: data.customer_type,
                        customer_name: data.customer_name,
                        customer_category: data.customer_category,
                        hub_name: data.hub_name,
                        customer_phone: data.customer_phone,
                        alt_phone: data.alt_phone,
                        customer_email: data.customer_email,
                        dob: data.dob === "" ? moment(new Date()).format('YYYY-MM-DD') : moment(data.dob).format('YYYY-MM-DD'),
                        anniversary_date: moment(data.anniversary_date.toDate().toISOString()).format('YYYY-MM-DD'),
                        customer_address: data.customer_address,
                        flat_villa_no: data.flat_villa_no,
                        floor: data.floor,
                        landmark: data.landmark,
                        location: data.location,
                        pincode: data.pincode,
                        gender: data.gender,
                        platform: data.platform,
                        delivery_exe_id: data.delivery_exe_id,
                        wallet_balance: data.wallet_balance,
                        credit_limit: data.credit_limit,
                        status: data.status,
                        source: data.source,
                        updated_date: new Date(),
                        created_date: new Date()
                    });
                    setOldData({
                        id: doc.id,
                        customer_id: data.customer_id,
                        customer_image: data.customer_image,
                        customer_type: data.customer_type,
                        customer_name: data.customer_name,
                        customer_category: data.customer_category,
                        hub_name: data.hub_name,
                        customer_phone: data.customer_phone,
                        alt_phone: data.alt_phone,
                        customer_email: data.customer_email,
                        dob: data.dob === "" ? moment(new Date()).format('YYYY-MM-DD') : moment(data.dob).format('YYYY-MM-DD'),
                        anniversary_date: moment(data.anniversary_date.toDate().toISOString()).format('YYYY-MM-DD'),
                        customer_address: data.customer_address,
                        flat_villa_no: data.flat_villa_no,
                        floor: data.floor,
                        landmark: data.landmark,
                        location: data.location,
                        pincode: data.pincode,
                        gender: data.gender,
                        platform: data.platform,
                        delivery_exe_id: data.delivery_exe_id,
                        wallet_balance: data.wallet_balance,
                        credit_limit: data.credit_limit,
                        status: data.status,
                        source: data.source,
                        updated_date: new Date(),
                        created_date: new Date()
                    });

                    // Set additional states
                    setSelectedHub({ value: data.hub_name, label: data.hub_name });
                    setSelectedLocation([{ value: data.location, label: data.location }]);
                    setSelectedCategory({ value: data.customer_category, label: data.customer_category });
                    setSourceCategory({ value: data.source, label: data.source });
                    setDeliveryExecutiveID(data.delivery_exe_id || 'notfound');
                    setNod('found');
                    setEdit(true);
                    setEditID(doc.id);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching customer data: ', error);
                setLoading(false);
            }
        };

        fetchInitialData();
        openModal();
    }, [params.id]);

    const addActivity = (object, action, description) => {
        db.collection("customer_activities").add({
            'customer_id': data.data && data.data.customer_id,
            'customer_name': data.data && data.data.customer_name,
            'customer_phone': data.data && data.data.customer_phone,
            'customer_address': data.data && data.data.customer_address,
            'hub_name': data.data && data.data.hub_name,
            'delivery_exe_id': data.data & data.data.delivery_exe_id,
            'user': 'Server',
            'object': object,
            'action': action,
            'description': description,
            //'description': description,  
            'platform' : "CRM",
            'date': new Date(),
            'created_date': new Date(),
        }).then(() => {
            console.log('added activiteies');
        })
    }




    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(submit)
        const customerId = generateCustomerId();

        if (submit.customer_phone.length != 10) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Phone number should not less than 10 digit',
            });
            return;
        }

        if (edit) {

            setLoading(true);

            if (submit.customer_image && typeof submit.customer_image !== "string") {
                const storageRef = ref(storage, `users/${submit.customer_image.name}`);
                const uploadTask = uploadBytesResumable(storageRef, submit.customer_image);

                uploadTask.on(
                    "state_changed",
                    snapshot => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    },
                    error => {
                        console.error("Error uploading image: ", error);
                    },
                    () => {
                        // File uploaded successfully, now obtain the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                            db.collection("customers_data").doc(editID).update({
                                'customer_id': submit.customer_id,
                                'customer_image': downloadURL,
                                'customer_type': submit.customer_type,
                                'customer_name': submit.customer_name,
                                'customer_category': submit.customer_category === "" ? "Lead" : submit.customer_category,
                                'hub_name': submit.hub_name,
                                'customer_phone': submit.customer_phone,
                                'alt_phone': submit.alt_phone,
                                'customer_email': submit.customer_email,
                                // 'dob': submit.dob === "" ? new Date() : new Date(submit.dob),
                                'anniversary_date': submit.anniversary_date === "" ? new Date() : new Date(submit.anniversary_date),
                                'customer_address': submit.customer_address,
                                'flat_villa_no': submit.flat_villa_no,
                                'floor': submit.floor,
                                'landmark': submit.landmark,
                                'location': submit.location,
                                'pincode': submit.pincode,
                                'gender': submit.gender,
                                'platform': "Website",
                                'delivery_exe_id': deliveryExecutiveID,
                                'wallet_balance': submit.wallet_balance,
                                'credit_limit': submit.credit_limit,
                                'source': submit.source,
                                'status': submit.status,
                                'updated_date': new Date(),
                                //'created_date': new Date(),
                                'referral_code': "",
                                'latitude': "",
                                'longitude': "",
                                // 'registered_date': new Date(),
                            }).then(() => {
                        
                                console.log('Updated Successfully',customerId,"submit data is",submit);
                                let changes = calculateChanges(oldData, submit);
                                db.collection("customer_activities").add({
                                    'customer_id': submit.customer_id,
                                    'customer_name': submit.customer_name,  
                                    'customer_phone': submit.customer_phone,
                                    'customer_address': submit.customer_address,
                                    'hub_name': submit.hub_name,
                                    'delivery_exe_id': deliveryExecutiveID,
                                    'user': 'Server',
                                    'object': "Edit Customer",
                                    'action': "Edit Customer",
                                    'description': `Customer details has been updated from CRM by ${loggedIn_user}`,
                                    //'description': description,
                                    'platform' : "CRM",   
                                    'date': new Date(),
                                    'created_date': new Date(),
                                    "changes":changes
                                });
                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: '#69aba6',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })

                                Toast.fire({
                                    icon: 'success',
                                    title: 'Customer Updated Successfully'
                                })
                                navigate(`/profile/${params.id}`);
                                closeModal();
                                setLoading(false);
                                setSelectedHub(null);
                                setSelectedLocation([]);
                                setSubmit({ customer_id: '', customer_image: "", customer_type: false, customer_name: '', customer_category: '', hub_name: '', customer_phone: '', alt_phone: '', customer_email: '', dob: '', anniversary_date: '', customer_address: '', flat_villa_no: '', floor: '', landmark: '', location: '', pincode: '', gender: '', plateform: '', delivery_exe_id: '', wallet_balance: 0, credit_limit: 0, status: '', source: '', updated_date: new Date(), created_date: new Date() })
                            })
                        })
                            .catch(error => {
                                console.error("Error getting download URL: ", error);
                            });
                    }
                );
            } else {
                db.collection("customers_data").doc(editID).update({
                    'customer_id': submit.customer_id,
                    'customer_image': submit.customer_image,
                    'customer_type': submit.customer_type,
                    'customer_name': submit.customer_name,
                    'customer_category': submit.customer_category === "" ? "Lead" : submit.customer_category,
                    'hub_name': submit.hub_name,
                    'customer_phone': submit.customer_phone,
                    'alt_phone': submit.alt_phone,
                    'customer_email': submit.customer_email,
                    // 'dob': submit.dob === "" ? new Date() : new Date(submit.dob),
                    'anniversary_date': submit.anniversary_date === "" ? new Date() : new Date(submit.anniversary_date),
                    'customer_address': submit.customer_address,
                    'flat_villa_no': submit.flat_villa_no,
                    'floor': submit.floor,
                    'landmark': submit.landmark,
                    'location': submit.location,
                    'pincode': submit.pincode,
                    'gender': submit.gender,
                    'platform': "Website",
                    'delivery_exe_id': deliveryExecutiveID,
                    'wallet_balance': submit.wallet_balance,
                    'credit_limit': submit.credit_limit,
                    'status': submit.status,
                    'source': submit.source,
                    'updated_date': new Date(),
                    //'created_date': new Date(),
                    'referral_code': "",
                    'latitude': "",
                    'longitude': "",
                    //'registered_date': new Date(),

                }).then(() => {
                    console.log('Updated Successfully',customerId,"submit data is",submit);
                    let changes = calculateChanges(oldData, submit);
                    db.collection("customer_activities").add({
                        'customer_id': submit.customer_id,
                        'customer_name': submit.customer_name,
                        'customer_phone': submit.customer_phone,
                        'customer_address': submit.customer_address,
                        'hub_name': submit.hub_name,
                        'delivery_exe_id': deliveryExecutiveID,
                        'user': 'Server',
                        'object': "Edit Customer",
                        'action': "Edit Customer",
                        'description': `Customer details has been updated from CRM by ${loggedIn_user}`,
                        'changes': changes,
                        //'description': description,
                        'platform' : "CRM",   
                        'date': new Date(),
                        'created_date': new Date()
                    });
                    console.log('Activity Added Successfully');
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    Toast.fire({
                        icon: 'success',
                        title: 'Customer Updated Successfully'
                    })
                    navigate(`/profile/${params.id}`);
                    closeModal();
                    setLoading(false);
                    setSelectedHub(null);
                    setSelectedLocation([]);
                    setSubmit({ customer_id: '', customer_image: '', customer_type: false, customer_name: '', customer_category: '', hub_name: '', customer_phone: '', alt_phone: '', customer_email: '', dob: '', anniversary_date: '', customer_address: '', flat_villa_no: '', floor: '', landmark: '', location: '', pincode: '', gender: '', plateform: '', delivery_exe_id: '', wallet_balance: 0, credit_limit: 0, status: '', source: '', updated_date: new Date(), created_date: new Date() })
                })
            }



        } else {
            setLoading(true);
            const phoneExists = await checkPhoneExists(submit.customer_phone);
            if (phoneExists) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Phone no already exists in the database!',
                });
                setLoading(false);
                return;
            }


            if (submit.customer_image) {
                const storageRef = ref(storage, `users/${submit.customer_image.name}`);
                const uploadTask = uploadBytesResumable(storageRef, submit.customer_image);

                uploadTask.on(
                    "state_changed",
                    snapshot => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    },
                    error => {
                        console.error("Error uploading image: ", error);
                    },
                    () => {
                        // File uploaded successfully, now add form data to Firestore
                        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                            // Add form data to Firestore
                            await db.collection("customers_data").add({
                                'customer_id': customerId,
                                'customer_image': downloadURL,
                                'customer_type': submit.customer_type,
                                'customer_name': submit.customer_name,
                                'customer_category': submit.customer_category === "" ? "Lead" : submit.customer_category,
                                'hub_name': submit.hub_name,
                                'customer_phone': submit.customer_phone,
                                'alt_phone': submit.alt_phone,
                                'customer_email': submit.customer_email,
                                // 'dob': submit.dob === "" ? new Date() : new Date(submit.dob),
                                'anniversary_date': submit.anniversary_date === "" ? new Date() : new Date(submit.anniversary_date),
                                'customer_address': submit.customer_address,
                                'flat_villa_no': submit.flat_villa_no,
                                'floor': submit.floor,
                                'landmark': submit.landmark,
                                'location': submit.location,
                                'pincode': submit.pincode,
                                'gender': submit.gender,
                                'plateform': "Website",
                                'delivery_exe_id': deliveryExecutiveID,
                                'wallet_balance': 0,
                                'credit_limit': 110,
                                'status': "1",
                                'source': submit.source,
                                'updated_date': new Date(),
                                'created_date': new Date(),
                                'referral_code': "",
                                'latitude': "",
                                'longitude': "",
                                'registered_date': new Date(),
                            });
                            reset();


                        })
                            .then(() => {
                                // setshow(true)
                                console.log('Customer Added Successfully');
                                db.collection("customer_activities").add({
                                    'customer_id': customerId,
                                    'customer_name': submit.customer_name,
                                    'customer_phone': submit.customer_phone,
                                    'customer_address': submit.customer_address,
                                    'hub_name': submit.hub_name,
                                    'delivery_exe_id': deliveryExecutiveID,
                                    'user': 'Server',
                                    'object': "Add Customer",
                                    'action': "Add Customer",
                                    'description': `Customer has been created from CRM by ${loggedIn_user}`,
                                    //'description': description,  
                                    'platform' : "CRM", 
                                    'date': new Date(),
                                    'created_date': new Date()
                                });
                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: '#69aba6',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast_1) => {
                                        toast_1.addEventListener('mouseenter', Swal.stopTimer);
                                        toast_1.addEventListener('mouseleave', Swal.resumeTimer);
                                    }
                                });
                                navigate(`/profile/${params.id}`);
                                closeModal();
                                setLoading(false);
                                // addActivity("Add Customer" , "Add Customer" , `Customer has been created by ${data.data && data.user}`);
                                setSubmit({ customer_id: '', customer_type: false, customer_name: '', customer_category: '', hub_name: '', customer_phone: '', alt_phone: '', customer_email: '', dob: '', anniversary_date: '', customer_address: '', flat_villa_no: '', floor: '', landmark: '', location: '', pincode: '', gender: '', plateform: '', delivery_exe_id: '', wallet_balance: 0, credit_limit: 0, status: '', source: '', updated_date: new Date(), created_date: new Date() });
                                setSelectedHub(null);
                                setSelectedLocation([]);
                                // Clear the file input field

                            })
                            .catch(error => {
                                console.error("Error adding user: ", error);
                            });
                    }
                );
            } else {
                db.collection("customers_data").add({
                    'customer_id': customerId,
                    'customer_type': submit.customer_type,
                    'customer_image': '',
                    'customer_name': submit.customer_name,
                    'customer_category': submit.customer_category === "" ? "Lead" : submit.customer_category,
                    'hub_name': submit.hub_name,
                    'customer_phone': submit.customer_phone,
                    'alt_phone': submit.alt_phone,
                    'customer_email': submit.customer_email,
                    // 'dob': submit.dob === "" ? new Date() : new Date(submit.dob),
                    'anniversary_date': submit.anniversary_date === "" ? new Date() : new Date(submit.anniversary_date),
                    'customer_address': submit.customer_address,
                    'flat_villa_no': submit.flat_villa_no,
                    'floor': submit.floor,
                    'landmark': submit.landmark,
                    'location': submit.location,
                    'pincode': submit.pincode,
                    'gender': submit.gender,
                    'plateform': "Website",
                    'delivery_exe_id': deliveryExecutiveID,
                    'wallet_balance': 0,
                    'credit_limit': 110,
                    'status': "1",
                    'source': submit.source,
                    'updated_date': new Date(),
                    'created_date': new Date(),
                    'referral_code': "",
                    'latitude': "",
                    'longitude': "",
                    'registered_date': new Date(),
                }).then(() => {
                    reset();
                    // setshow(true)
                    console.log('Customer Added Successfully');
                    db.collection("customer_activities").add({
                        'customer_id': customerId,
                        'customer_name': submit.customer_name,
                        'customer_phone': submit.customer_phone,
                        'customer_address': submit.customer_address,
                        'hub_name': submit.hub_name,
                        'delivery_exe_id': deliveryExecutiveID,
                        'user': 'Server',
                        'object': "Edit Customer",
                        'action': "Edit Customer",
                        'description': `Customer details has been updated from CRM by ${loggedIn_user}`,
                        //'description': description,
                        'platform' : "CRM",   
                        'date': new Date(),
                        'created_date': new Date()
                    });
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    navigate(`/profile/${params.id}`);
                    closeModal();
                    setLoading(false);
                    //addActivity("Add Customer" , "Add Customer" , `Customer has been created by ${data.data && data.user}`);
                    setSubmit({ customer_id: '', customer_type: false, customer_name: '', customer_category: '', hub_name: '', customer_phone: '', alt_phone: '', customer_email: '', dob: '', anniversary_date: '', customer_address: '', flat_villa_no: '', floor: '', landmark: '', location: '', pincode: '', gender: '', plateform: '', delivery_exe_id: '', wallet_balance: 0, credit_limit: 0, status: '', source: '', updated_date: new Date(), created_date: new Date() })
                    setSelectedHub(null);
                    setSelectedLocation([]);
                    Toast.fire({
                        icon: 'success',
                        title: 'Customer Added'
                    })
                })
            }

        }


    }

    const deleteData = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                db.collection("customers_data").doc(id).delete().then(() => {
                    console.log('Deleted Successfully');
                    setSubmit({ customer_id: '', customer_type: false, customer_name: '', customer_category: '', hub_name: '', customer_phone: '', customer_email: '', dob: '', anniversary_date: '', customer_address: '', flat_villa_no: '', floor: '', landmark: '', location: '', pincode: '', gender: '', plateform: '', delivery_exe_id: '', wallet_balance: 0, credit_limit: 0, status: '', source: '', updated_date: new Date(), created_date: new Date() })
                    closeModal();
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    )
                })

            }
        })

    }


    useEffect(() => {
        const getCurrentTimeOfDay = () => {
            const currentTime = new Date().getHours();
            if (currentTime < 12) {
                setTimeOfDay('Good Morning');
            } else if (currentTime >= 12 && currentTime < 18) {
                setTimeOfDay('Good Afternoon');
            } else {
                setTimeOfDay('Good Evening');
            }
        };

        getCurrentTimeOfDay();
    }, []);

    // Filter data based on search query
    // const filteredUserData = data.filter(({ data }) => {
    //     // Check if data is defined
    //     if (!data) {
    //         return false;
    //     }

    //     // Filter based on search query
    //     return (
    //         (data.customer_id && data.customer_id.toLowerCase().includes(searchQuery.toLowerCase())) ||
    //         (data.customer_name && data.customer_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
    //         (data.customer_category && data.customer_category.toLowerCase().includes(searchQuery.toLowerCase())) ||
    //         (data.hub_name && data.hub_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
    //         (data.customer_phone && data.customer_phone.toLowerCase().includes(searchQuery.toLowerCase())) ||
    //         (data.customer_email && data.customer_email.toLowerCase().includes(searchQuery.toLowerCase())) ||
    //         (data.customer_address && data.customer_address.toLowerCase().includes(searchQuery.toLowerCase())) ||
    //         (data.flat_villa_no && data.flat_villa_no.toLowerCase().includes(searchQuery.toLowerCase())) ||
    //         (data.floor && data.floor.toLowerCase().includes(searchQuery.toLowerCase())) ||
    //         (data.landmark && data.landmark.toLowerCase().includes(searchQuery.toLowerCase())) ||
    //         (data.location && data.location.toLowerCase().includes(searchQuery.toLowerCase())) ||
    //         (data.pincode && data.pincode.toLowerCase().includes(searchQuery.toLowerCase())) ||
    //         (data.gender && data.gender.toLowerCase().includes(searchQuery.toLowerCase()))
    //     );
    // });


    // Determine the range of page numbers to display
    // const indexOfLastItem = currentPage * itemsPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems = filteredUserData.slice(indexOfFirstItem, indexOfLastItem);


    // const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // useEffect(() => {
    //     const total = Math.ceil(data.length / itemsPerPage);
    //     setTotalPages(total);
    // }, [data.length, itemsPerPage]);


    // const renderPageButtons = () => {
    //     const pageButtons = [];
    //     // Determine the range of page numbers to display
    //     let startPage = Math.max(1, currentPage - 5);
    //     let endPage = Math.min(totalPages, startPage + 9);

    //     // If the total number of pages is less than 10, adjust the endPage
    //     if (totalPages <= 10) {
    //         endPage = totalPages;
    //     } else {
    //         // If the current page is near the start, display the first 10 pages
    //         if (currentPage <= 5) {
    //             startPage = 1;
    //             endPage = 10;
    //         }
    //         // If the current page is near the end, display the last 10 pages
    //         else if (currentPage >= totalPages - 4) {
    //             endPage = totalPages;
    //             startPage = endPage - 9;
    //         }
    //     }

    //     for (let i = startPage; i <= endPage; i++) {
    //         pageButtons.push(
    //             <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
    //                 <button onClick={() => paginate(i)} className="page-link" style={{ color: "black" }}>{i}</button>
    //             </li>
    //         );
    //     }
    //     return pageButtons;
    // };

    const rolePermission = () => {
        const Toast = Swal.mixin({
            toast: true,
            background: '#d7e7e6',
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
        });

        Toast.fire({
            icon: 'error',
            title: 'You are not authorised to do this action'
        });

    }



    return (
        <>
            {loading && ( // Render loader when loading state is true
                <div className="loader-overlay">
                    <div className="">
                        <img style={{
                            height: "6rem"
                        }} src="../images/loader.gif"></img>
                    </div>
                </div>
            )}
            <div class="container-scroller">

                <TopPanel />

                <div class="container-fluid page-body-wrapper">
                    <Sidebar />

                    <div class="main-panel">
                        <div class="content-wrapper">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="home-tab">
                                        <div class="d-sm-flex align-items-center justify-content-between border-bottom">
                                            <ul class="nav nav-tabs" role="tablist">

                                            </ul>
                                            <div>

                                                <div class="btn-wrapper">
                                                    {rolePermissions.includes('add') ? <><a href="#" class="btn btn-primary text-white me-0 mr-2" onClick={() => addNew()}><i class="icon-add"></i>Add Customer</a></> : <><a href="#" class="btn btn-primary text-white me-0 mr-2" onClick={() => rolePermission()}><i class="icon-add"></i>Add Customer</a></>}

                                                    <a href="#" class="btn btn-primary text-white me-0" style={{ marginLeft: "1rem" }}><i class="icon-download"></i>Export Data</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row" style={{ marginTop: "1rem", }}>
                                <div class="col-md-4 grid-margin">
                                    <div class="card d-flex align-items-start" style={{ background: '#84bf93' }}>
                                        <div class="card-body" >
                                            <div class="d-flex flex-row align-items-start">
                                                <i class="mdi mdi-account-check icon-lg" style={{ color: '#fff' }}></i>
                                                <div class="ms-3">
                                                    <h6 class="text-user" style={{ fontSize: "22px", fontWeight: "800" }}>- </h6>
                                                    <p class="mt-2 text-muted card-text custom-text">New Registrations</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 grid-margin">
                                    <div class="card d-flex align-items-start" style={{ background: '#4a54ba' }}>
                                        <div class="card-body">
                                            <div class="d-flex flex-row align-items-start">
                                                <i class="mdi mdi-account-multiple-outline icon-lg" style={{ color: '#fff' }}></i>
                                                {/* <i class="mdi mdi-account-multiple-outline menu-icon"></i> */}
                                                <div class="ms-3">
                                                    <h6 class="text-users" style={{ fontSize: "22px", fontWeight: "800", color: "#fff" }}>{collectionSize} </h6>
                                                    <p class="mt-2 text-muted card-text custom-text" style={{ fontSize: "15px", }}>Total Customers</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 grid-margin">
                                    <div class="card d-flex align-items-start" style={{ background: '#4a54ba' }}>
                                        <div class="card-body">
                                            <div class="d-flex flex-row align-items-start">
                                                <i class="mdi mdi-account-search icon-lg" style={{ color: '#fff' }}></i>
                                                <div class="ms-3" style={{ display: 'flex' }}>
                                                    <input type="search" class="form-control" onChange={(e) => setSearchQuery(e.target.value)} value={searchQuery} placeholder="Search Customer here" title="Search here" />
                                                    {/* <a href="#" class="btn btn-xs text-white me-0" style={{ backgroundColor: "#83bf91", padding: "5px" }} onClick={handleSearch}><i class="icon-reset"></i>Search Customer</a> */}
                                                    <a href="#" class="btn btn-xs btn-primary text-white me-0" onClick={handleSearch} style={{ marginLeft: '10px', background: 'rgb(132, 191, 147)' }}><i class="icon-search"></i></a>
                                                    {/* <p class="mt-2 text-muted card-text custom-text" onClick={handleSearch} style={{ fontSize: "15px", }}>Search Customer</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 grid-margin stretch-card">
                                <div class="card">
                                    <div class="card-body">
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <h4 class="card-title">Customers</h4>
                                            <p class="card-description">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    selectsStart
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    dateFormat="DD/MM/YYYY"
                                                    placeholderText="From reg"
                                                    className="form-control"
                                                />
                                                <span className="" style={{ width: "1rem" }}> TO  </span>
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={(date) => setEndDate(date)}
                                                    selectsEnd
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    minDate={startDate}
                                                    dateFormat="DD/MM/YYYY"
                                                    placeholderText="To reg"
                                                    className="form-control"
                                                />
                                            </p>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="home-tab">
                                                <div class="d-sm-flex align-items-center justify-content-between border-bottom">
                                                    <ul class="nav nav-tabs" role="tablist">
                                                        <li class="nav-item">
                                                            <a class="nav-link active ps-0" id="home-tab" data-bs-toggle="tab" href="#overview" role="tab"
                                                                aria-controls="overview" aria-selected="true" onClick={() => filterData("All")}>All</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link" id="profile-tab" data-bs-toggle="tab" href="#audiences" role="tab"
                                                                aria-selected="false" onClick={() => filterData("One Time")}>One time</a>
                                                        </li>
                                                        {/* <li class="nav-item">
                                                            <a class="nav-link" id="contact-tab" data-bs-toggle="tab" href="#demographics" role="tab"
                                                                aria-selected="false" onClick={() => filterData("Trail")}>Trail</a>
                                                        </li> */}
                                                        <li class="nav-item">
                                                            <a class="nav-link" id="contact-tab" data-bs-toggle="tab" href="#demographics" role="tab"
                                                                aria-selected="false" onClick={() => filterData("Subscription")}>Subscribed</a>
                                                        </li>
                                                        {/* <li class="nav-item">
                                                            <a class="nav-link" id="contact-tab" data-bs-toggle="tab" href="#demographics" role="tab"
                                                                aria-selected="false" onClick={() => filterData("Unqualified")}>Unqualified</a>
                                                        </li> */}
                                                        <li class="nav-item">
                                                            <a class="nav-link" id="contact-tab" data-bs-toggle="tab" href="#demographics" role="tab"
                                                                aria-selected="false" onClick={() => filterData("Lead")}>Lead</a>
                                                        </li>
                                                        {/* <li class="nav-item dropdown">
                                                            <a class="nav-link dropdown-bordered dropdown-toggle dropdown-toggle-split" id="messageDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false"> Customer Class </a>
                                                            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0" aria-labelledby="messageDropdown">

                                                                <a class="dropdown-item preview-item">
                                                                    <div class="preview-item-content flex-grow py-2">
                                                                        <p class="preview-subject ellipsis font-weight-medium text-dark">C-Copper</p>
                                                                    </div>
                                                                </a>
                                                                <a class="dropdown-item preview-item">
                                                                    <div class="preview-item-content flex-grow py-2">
                                                                        <p class="preview-subject ellipsis font-weight-medium text-dark">G-Gold</p>
                                                                    </div>
                                                                </a>
                                                                <a class="dropdown-item preview-item">
                                                                    <div class="preview-item-content flex-grow py-2">
                                                                        <p class="preview-subject ellipsis font-weight-medium text-dark">New Lead</p>
                                                                    </div>
                                                                </a>
                                                                <a class="dropdown-item preview-item">
                                                                    <div class="preview-item-content flex-grow py-2">
                                                                        <p class="preview-subject ellipsis font-weight-medium text-dark">P-Platinum</p>
                                                                    </div>
                                                                </a>
                                                                <a class="dropdown-item preview-item">
                                                                    <div class="preview-item-content flex-grow py-2">
                                                                        <p class="preview-subject ellipsis font-weight-medium text-dark">S-Silver</p>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </li> */}



                                                    </ul>
                                                    <div>

                                                        <div class="btn-wrapper">
                                                            <a href="#" class="btn btn-primary text-white me-0" onClick={handleDateRange}><i class="icon-search"></i>Search</a>
                                                            <a href="#" class="btn btn-primary text-white me-0" onClick={handleReset}><i class="icon-reset"></i>reset</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            ID
                                                        </th>
                                                        <th>
                                                            Hub
                                                        </th>
                                                        <th>
                                                            Customer Name
                                                        </th>
                                                        <th>
                                                            Customer's Contact
                                                        </th>
                                                        <th>
                                                            Wallet Amount
                                                        </th>
                                                        <th>
                                                            Credit Limit
                                                        </th>

                                                        <th>
                                                            Alternate Number
                                                        </th>

                                                        <th>
                                                            Registered On
                                                        </th>
                                                        <th>
                                                            Account Status
                                                        </th>

                                                        <th>
                                                            Action
                                                        </th>

                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    {currentItems.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="10" style={{ textAlign: "center" }}>No data found</td>
                                                        </tr>
                                                    ) : (
                                                        currentItems.map(({ id, data }, index) => {
                                                            const itemIndex = indexOfFirstItem + index + 1;
                                                            return (
                                                                //
                                                                <tr key={id} onClick={() => rolePermissions.includes('view') ? navi(data.customer_id) : rolePermission()} style={{ cursor: "pointer" }}>

                                                                    <td>
                                                                        {data.customer_id}
                                                                    </td>
                                                                    <td>
                                                                        {data.hub_name}
                                                                    </td>
                                                                    <td>
                                                                        <p>{data.customer_name}</p>

                                                                    </td>
                                                                    <td>
                                                                        {data.customer_phone}
                                                                    </td>
                                                                    <td>
                                                                        ₹ {data.wallet_balance}
                                                                    </td>
                                                                    <td>
                                                                        ₹ {data.credit_limit}
                                                                    </td>
                                                                    <td>
                                                                        {data.alt_phone}
                                                                    </td>
                                                                    <td>
                                                                        {moment(data.created_date.toDate().toISOString()).format("DD/MM/YY, h:mm a")}
                                                                    </td>
                                                                    <td>
                                                                        {data.status === "1" ? "Active" : "Inactive"}
                                                                    </td>
                                                                    <td>
                                                                        {/* <Link to={`/profile/${data.customer_phone}`} style={{ color: "black" }}><button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} class="btn btn-dark btn-sm"><i class="menu-icon mdi mdi-eye" style={{ color: "white" }}></i></button></Link> */}

                                                                        <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={(e) => changeStatusForm(e, data, id)} class="btn btn-dark btn-sm"><i class="menu-icon mdi mdi-pencil" style={{ color: "white" }}></i></button>
                                                                    </td>
                                                                </tr>



                                                            );
                                                        })
                                                    )}
                                                </tbody>

                                            </table>
                                        </div>
                                        {/* <button onClick={fetchMoreData} disabled={loading}>Load More</button> */}
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <button onClick={() => paginate(currentPage - 1)} className="page-link" disabled={currentPage === 1}>Previous</button>
                                            </li>
                                            {renderPageButtons()}
                                            <li className="page-item">
                                                <button onClick={() => paginate(currentPage + 1)} className="page-link" disabled={currentPage === totalPages}>Next</button>
                                            </li>
                                            <li className="page-item">
                                                <button onClick={fetchMoreData} disabled={loading} className="page-link" >Load More</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <Footer />

                    </div>

                </div>

            </div>

            <div class="modal fade" id="exampleModal-2" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel-2" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel-2">Add Customer</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form class="forms-sample" onSubmit={handleSubmit}>
                                <div class="form-group row">
                                    <div class="col">
                                        <label>User Image:</label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ padding: "10px" }}
                                            name="customer_image"
                                            // Note: value should not be set for file inputs, it's read-only
                                            onChange={handleChange}

                                        />
                                    </div>
                                    <div class="col">
                                        <label >Customer Category</label>
                                        <Select
                                            options={categoryList}
                                            onChange={handleCategoryChange}
                                            value={selectedCategory}
                                            placeholder="Select Category"
                                        />

                                    </div>
                                    <div class="col">
                                        <label >Hubs *</label>
                                        <Select
                                            options={hubNames}
                                            onChange={handleHubChange}
                                            value={selectedHub}
                                            placeholder="Select Hub Name"
                                            required
                                        />

                                    </div>

                                </div>
                                <div class="form-group row">
                                    <div class="col">
                                        <label for="exampleSelectGender">Customer Phone No *</label>
                                        <input class="form-control" type="tel" pattern="[0-9]*" onChange={handleChange} id="customer_phone" name="customer_phone" value={submit.customer_phone} maxLength={10} required autoComplete="off" />

                                    </div>
                                    <div class="col">
                                        <label for="exampleSelectGender">Alternate Phone No *</label>
                                        <input class="form-control" type="tel" pattern="[0-9]*" onChange={handleChange} id="alt_phone" name="alt_phone" value={submit.alt_phone} maxLength={10} autoComplete="off" />

                                    </div>

                                    <div class="col">
                                        <label for="exampleSelectGender">Customer Name *</label>
                                        <input type="text" class="form-control" onChange={handleChange} id="customer_name" name="customer_name" value={submit.customer_name} required autoComplete="off" />

                                    </div>

                                </div>
                                <div class="form-group row">
                                    <div class="col">
                                        <label for="exampleSelectGender">Customer Email</label>
                                        <input type="email" class="form-control" onChange={handleChange} id="customer_email" name="customer_email" value={submit.customer_email} autoComplete="off" />

                                    </div>
                                    <div class="col">
                                        <label for="exampleSelectGender">DOB</label>
                                        <input type="date" style={{
                                            // width: "17rem",
                                            height: "35px",
                                            padding: "12px 20px",
                                            boxSizing: "border-box",
                                            // border: "2px solid #ccc",
                                            borderRadius: "4px",
                                            // backgroundColor: "#f8f8f8",
                                            // fontSize: "16px",
                                            resize: "none"
                                        }} id="dob" name="dob" onChange={handleChange} value={submit.dob} class="form-control" />

                                    </div>
                                    <div class="col">
                                        <label >Anniversary Date</label>
                                        <input type="date" style={{
                                            height: "35px",
                                            padding: "12px 20px",
                                            boxSizing: "border-box",
                                            borderRadius: "4px",
                                            resize: "none"
                                        }} id="anniversary_date" name="anniversary_date" onChange={handleChange} value={submit.anniversary_date} class="form-control" />
                                    </div>


                                </div>
                                <div class="form-group row">
                                    <div class="col">
                                        <label >Customer Address *</label>
                                        <input type="text" class="form-control" onChange={handleChange} id="customer_address" name="customer_address" value={submit.customer_address} required autoComplete="off" />
                                    </div>
                                    <div class="col">
                                        <label >Flat/villa No</label>
                                        <input type="text" class="form-control" onChange={handleChange} id="flat_villa_no" name="flat_villa_no" value={submit.flat_villa_no} autoComplete="off" />
                                    </div>
                                    <div class="col">
                                        <label >Floor</label>
                                        <input type="text" class="form-control" onChange={handleChange} id="floor" name="floor" value={submit.floor} autoComplete="off" />

                                    </div>


                                </div>

                                <div class="form-group row">
                                    <div class="col">
                                        <label >Landmark</label>
                                        <input type="text" class="form-control" onChange={handleChange} id="landmark" name="landmark" value={submit.landmark} autoComplete="off" />
                                    </div>
                                    <div class="col">
                                        <label >Location *</label>
                                        <Select
                                            options={locationOptions}
                                            onChange={handleLocationChange}
                                            value={selectedLocation}
                                            placeholder="Select Location"
                                            required
                                        />
                                        <p style={{ color: "red" }}>{deliveryExecutiveID === "notfound" ? <>**No Delivery agent found for this location...</> : <></>} </p>
                                    </div>

                                    <div class="col">
                                        <label >Pincode</label>
                                        <input class="form-control" type="tel" pattern="[0-9]*" onChange={handleChange} id="pincode" name="pincode" value={submit.pincode} maxLength={6} autoComplete="off" />

                                    </div>


                                </div>

                                <div class="form-group row">




                                    <div className="col">
                                        <div style={{ display: 'inline-block', marginRight: '10px' }}>
                                            <label>Gender *</label>
                                        </div>
                                        <br />
                                        <div style={{ display: 'inline-block' }}>
                                            <input type="hidden" id="genderHidden" name="gender" required />
                                            <label>
                                                <input
                                                    type="radio"
                                                    id="genderMale"
                                                    value="male"
                                                    name="gender"
                                                    checked={submit.gender === "male"}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <span style={{ marginRight: '10px', marginLeft: "10px" }}>Male</span>
                                            </label>
                                            <label style={{ marginRight: '10px' }}>
                                                <input
                                                    type="radio"
                                                    id="genderFemale"
                                                    value="female"
                                                    name="gender"
                                                    checked={submit.gender === "female"}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <span style={{ marginRight: '10px', marginLeft: "10px" }}>Female</span>
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    id="genderOther"
                                                    value="other"
                                                    name="gender"
                                                    checked={submit.gender === "other"}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <span style={{ marginRight: '10px', marginLeft: "10px" }}>Other</span>
                                            </label>
                                        </div>

                                    </div>




                                    <div class="col">
                                        <label >Customer Type</label>
                                        <br />
                                        <input
                                            type="checkbox"
                                            id="customer_type"
                                            name="customer_type"
                                            checked={submit.customer_type} // Bind the checked state to the submitUser object
                                            onChange={(e) => setSubmit({ ...submit, customer_type: e.target.checked })} // Update the state when the checkbox is toggled
                                        />
                                        <label htmlFor="customer_type" style={{ marginLeft: '0.5rem' }}>Corporate</label>
                                    </div>

                                    <div class="col">
                                        <label >Source</label>
                                        <Select
                                            options={sourceList}
                                            onChange={handleSourceChange}
                                            value={sourceCategory}
                                            placeholder="Select Category"
                                        />

                                    </div>
                                </div>

                                <div class="modal-footer">
                                    {rolePermissions.includes('edit') ? <>{nod === "" ? <></> : <><button type="submit" value="submit" class="btn btn-success">Submit</button></>} </> : <>{nod === "" ? <></> : <><button onClick={() => rolePermission()} class="btn btn-success">Submit</button></>}</>}

                                    <button type="button" class="btn btn-info" data-bs-dismiss="modal">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditCustomers
