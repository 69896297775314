import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyAajKa8xz-RZGuhyhCDeGHl_Sli61-OeUk",
  authDomain: "whytefarmsweb.firebaseapp.com",
  databaseURL: "https://whytefarmsweb-default-rtdb.firebaseio.com",
  projectId: "whytefarmsweb",
  storageBucket: "whytefarmsweb.appspot.com",
  messagingSenderId: "149750199117",
  appId: "1:149750199117:web:f6769b178428ffc4937d04",
  measurementId: "G-N7D8RFQ38Y"
};
  
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
var auth = getAuth(firebaseApp);
const provider = new GoogleAuthProvider();
export const storage = getStorage(firebaseApp);
export { provider, auth, firebase };

export default db;

// stage connection string

// const firebaseConfig = {
//   apiKey: "AIzaSyDAgVMqLFEnoGPgrFv9G5JTpUbKSITe_Jc",
// authDomain: "whyte-farms.firebaseapp.com",
// databaseURL: "https://whyte-farms-default-rtdb.firebaseio.com",
// projectId: "whyte-farms",
// storageBucket: "whyte-farms.appspot.com",
// messagingSenderId: "111460680826",
// appId: "1:111460680826:web:1fdca394931abb136867c3",
// measurementId: "G-7C00GER1FF"
// };


// prod connection string

// const firebaseConfig = {
//   apiKey: "AIzaSyAajKa8xz-RZGuhyhCDeGHl_Sli61-OeUk",
//   authDomain: "whytefarmsweb.firebaseapp.com",
//   databaseURL: "https://whytefarmsweb-default-rtdb.firebaseio.com",
//   projectId: "whytefarmsweb",
//   storageBucket: "whytefarmsweb.appspot.com",
//   messagingSenderId: "149750199117",
//   appId: "1:149750199117:web:f6769b178428ffc4937d04",
//   measurementId: "G-N7D8RFQ38Y"
// };