import React, { useState, useEffect } from "react";
import db from "./Firebase";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./OnBoardCustomer.css";
import { toast } from "react-toastify";
import Moment from "moment";
import { extendMoment } from "moment-range";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import "./Report.css";
import "./OrderSheet.css";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import { getFirestore, collection, query, where, getDocs , orderBy } from "firebase/firestore";

function OrderSheet() {
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    console.log(loggedIn);
    if (loggedIn) {
      // navigate("/");
    } else {
      navigate("/login");
    }
  }, [navigate]);
  const moment = extendMoment(Moment);
  const [fromDate, setFromDate] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [orderReports, setOrderReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [show, setShow] = useState(false);
  const [selectedHub, setSelectedHub] = useState(null);
  const [deliveryExecutiveNames, setDeliveryExecutiveNames] = useState([]);
  const [hubNames, setHubNames] = useState([]);
  const [selectedDeliveryExecutive, setSelectedDeliveryExecutive] = useState();
  const [productList, setProductList] = useState([]);
  const [totalQtyOrder, setTotalQtyOrder] = useState(0);
  const [orderSheet, setOrdersheet] = useState({});
  const [orderDataList, setOrderDataList] = useState([]);
  const [productSummary, setProductSummary] = useState([]);
  const [productListQty, setProductListQty] = useState([]);
  const [groupedRecords, setGroupedRecords] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [combinedList , setCombinedList] = useState([]);
  const [loading , setLoading] = useState(false);
  const [totalQuantity , setTotalQuantity] = useState(0);
  const [customerWalletMap , setCustomerWalletMap] = useState(new Map());
  const [disabledSearch , setDisabledSearch] = useState(false);

  const generatePDF = async () => {
    const doc = new jsPDF();
    const orderDate = moment(fromDate.toISOString()).format("DD-MM-YYYY");
    const fullFilename = `OrderSheet_${orderDate}`;

    const headers = [
        ['Customer Name & Contact', 
        'Customer Address',
        'Product', 
        'Packaging',
        'Quantity',
        'Wallet',
        'Location']
    ];
    const product_header = [
        ['Product Name', 'Packaging', 'Quantity']
    ];

    const dEdetails_header = [
        ['Delivery Executive Name', 'Order Date', 'Total Quantity']
    ];
    const col_width_DE = [60, 40, 30];
    const columnWidths = [30, 30, 30, 22, 20, 25]; // Adjust column widths as needed
    const col_width_product = [80, 25, 20];

    let grandTotal = 0;
    productList.forEach(product => {
        grandTotal += product.totalQuantity;
    });

    const createOrderRows = (orders) => {
        return orders
            .sort((a, b) => a.location.localeCompare(b.location))
            .map(order => ([
                `${order.customer_name} / ${order.customer_phone}`,
                order.delivering_to,
                order.product_name,
                order.package_unit,
                order.quantity,
                customerWalletMap.get(order.customer_id),
                order.location
            ]));
    };

    const createProductRows = (orders) => {
        const productMap = new Map();
        orders.forEach(order => {
            const key = `${order.product_name}-${order.package_unit}`;
            if (productMap.has(key)) {
                productMap.set(key, productMap.get(key) + order.quantity);
            } else {
                productMap.set(key, order.quantity);
            }
        });
        return Array.from(productMap.entries()).map(([key, quantity]) => {
            const [productName, packaging] = key.split('-');
            return [productName, packaging, quantity];
        });
    };

    const addTableWithPageBreaks = (tableConfig) => {
        doc.autoTable({
            ...tableConfig,
            startY: doc.previousAutoTable ? doc.previousAutoTable.finalY + 10 : tableConfig.startY,
            styles: { overflow: 'linebreak' },
            columnStyles: tableConfig.columnStyles || {},
        });
    };

    let yOffset = 10;

    deliveryExecutiveNames.forEach(dE => {
        const filteredOrders = orderDataList.filter(order => order.delivery_exe_id === dE.value);
        const rows_dE = [
            [dE.label, moment(fromDate.toISOString()).format("DD-MM-YYYY"), filteredOrders.reduce((acc, order) => acc + order.quantity, 0)]
        ];
        const orderRows = createOrderRows(filteredOrders);
        const productRows = createProductRows(filteredOrders);

        addTableWithPageBreaks({
            startY: yOffset,
            head: dEdetails_header,
            headStyles: {
                fillColor: [74, 84, 186],
                textColor: [255, 255, 255]
            },
            bodyStyles: {
                textColor: [0, 0, 0]
            },
            body: rows_dE,
            theme: 'grid',
            columnStyles: {
                0: { cellWidth: col_width_DE[0] }, 
                1: { cellWidth: col_width_DE[1] },
                2: { cellWidth: col_width_DE[2] }
            },
            rowPageBreak: 'avoid'
        });

        yOffset = doc.previousAutoTable.finalY + 10;

        addTableWithPageBreaks({
            startY: yOffset,
            head: product_header,
            headStyles: {
                fillColor: [74, 84, 186],
                textColor: [255, 255, 255]
            },
            bodyStyles: {
                textColor: [0, 0, 0]
            },
            body: productRows,
            theme: 'grid',
            columnStyles: {
                0: { cellWidth: col_width_product[0] },
                1: { cellWidth: col_width_product[1] },
                2: { cellWidth: col_width_product[2] }
            },
            rowPageBreak: 'avoid'
        });

        yOffset = doc.previousAutoTable.finalY + 10;

        addTableWithPageBreaks({
            startY: yOffset,
            head: headers,
            headStyles: {
                fillColor: [74, 84, 186],
                textColor: [255, 255, 255]
            },
            bodyStyles: {
                textColor: [0, 0, 0]
            },
            body: orderRows,
            theme: 'grid',
            columnStyles: {
                0: { cellWidth: columnWidths[0] },
                1: { cellWidth: columnWidths[1] },
                2: { cellWidth: columnWidths[2] },
                3: { cellWidth: columnWidths[3] },
                4: { cellWidth: columnWidths[4] },
                5: { cellWidth: columnWidths[5] },
                6: { cellWidth: columnWidths[6] }
            },
            rowPageBreak: 'avoid'
        });

        yOffset = doc.previousAutoTable.finalY + 10;
    });

    // Save PDF
    doc.save(fullFilename);
};

//   const generatePDF = async () => {
//     const doc = new jsPDF();
//     const orderDate = moment(fromDate.toISOString()).format("DD-MM-YYYY");
//     const fullFilename = `OrderSheet_${orderDate}`;

//     const headers = [
//         ['Customer Name & Contact', 
//         'Customer Address',
//         'Product', 
//         'Packaging',
//         'Quantity',
//         'Wallet',
//         'Location']
//     ];
//     const product_header = [
//         ['Product Name', 'Packaging', 'Quantity']
//     ];

//     const dEdetails_header = [
//         ['Delivery Executive Name', 'Order Date', 'Total Quantity']
//     ];
//     const col_width_DE = [60, 40, 30];
//     //const columnWidths = [40, 40, 30, 22, 20, 25]; // Adjust column widths as needed
//     const columnWidths = [40, 40, 30, 22, 20, 25, 25];
//     const col_width_product = [80, 25, 20];

//     let grandTotal = 0;
//     productList.forEach(product => {
//         grandTotal += product.totalQuantity;
//     });
//     setTotalQuantity(grandTotal);

//     const createOrderRows = (orders) => {
//         return orders
//             .sort((a, b) => a.location.localeCompare(b.location))
//             .map(order => ([
//                 `${order.customer_name} / ${order.customer_phone}`,
//                 order.delivering_to,
//                 order.product_name,
//                 order.package_unit,
//                 order.quantity,
//                 customerWalletMap.get(order.customer_id),
//                 order.location
//             ]));
//     };

//     const createProductRows = (orders) => {
//         const productMap = new Map();
//         orders.forEach(order => {
//             const key = `${order.product_name}-${order.package_unit}`;
//             if (productMap.has(key)) {
//                 productMap.set(key, productMap.get(key) + order.quantity);
//             } else {
//                 productMap.set(key, order.quantity);
//             }
//         });
//         return Array.from(productMap.entries()).map(([key, quantity]) => {
//             const [productName, packaging] = key.split('-');
//             return [productName, packaging, quantity];
//         });
//     };

//     const addTableWithPageBreaks = (tableConfig) => {
//         doc.autoTable({
//           ...tableConfig,
//           startY: doc.previousAutoTable ? doc.previousAutoTable.finalY + 10 : tableConfig.startY
//       });        
//     };

//     let yOffset = 10;

//     deliveryExecutiveNames.forEach(dE => {
//         const filteredOrders = orderDataList.filter(order => order.delivery_exe_id === dE.value);
//         const rows_dE = [
//             [dE.label, moment(fromDate.toISOString()).format("DD-MM-YYYY"), filteredOrders.reduce((acc, order) => acc + order.quantity, 0)]
//         ];
//         const orderRows = createOrderRows(filteredOrders);
//         const productRows = createProductRows(filteredOrders);

//         addTableWithPageBreaks({
//             startY: yOffset,
//             head: dEdetails_header,
//             headStyles: {
//                 fillColor: [74, 84, 186],
//                 textColor: [255, 255, 255]
//             },
//             bodyStyles: {
//                 textColor: [0, 0, 0]
//             },
//             body: rows_dE,
//             theme: 'grid',
//             columnStyles: {
//                 0: { cellWidth: col_width_DE[0] }, 
//                 1: { cellWidth: col_width_DE[1] },
//                 2: { cellWidth: col_width_DE[2] }
//             },
//             rowPageBreak: 'avoid'
//         });

//         yOffset = doc.previousAutoTable.finalY + 10;

//         addTableWithPageBreaks({
//             startY: yOffset,
//             head: product_header,
//             headStyles: {
//                 fillColor: [74, 84, 186],
//                 textColor: [255, 255, 255]
//             },
//             bodyStyles: {
//                 textColor: [0, 0, 0]
//             },
//             body: productRows,
//             theme: 'grid',
//             columnStyles: {
//                 0: { cellWidth: col_width_product[0] }, // Adjust column widths as needed
//                 1: { cellWidth: col_width_product[1] },
//                 2: { cellWidth: col_width_product[2] }
//             },
//             rowPageBreak: 'avoid'
//         });

//         yOffset = doc.previousAutoTable.finalY + 10;

//         addTableWithPageBreaks({
//             startY: yOffset,
//             head: headers,
//             headStyles: {
//                 fillColor: [74, 84, 186],
//                 textColor: [255, 255, 255]
//             },
//             bodyStyles: {
//                 textColor: [0, 0, 0]
//             },
//             body: orderRows,
//             theme: 'grid',
//             columnStyles: {
//                 0: { cellWidth: columnWidths[0] }, // Adjust column widths as needed
//                 1: { cellWidth: columnWidths[1] },
//                 2: { cellWidth: columnWidths[2] },
//                 3: { cellWidth: columnWidths[3] },
//                 4: { cellWidth: columnWidths[4] },
//                 5: { cellWidth: columnWidths[5] }
//             },
//             rowPageBreak: 'avoid'
//         });

//         yOffset = doc.previousAutoTable.finalY + 10;
//     });

//     // Save PDF
//     doc.save(fullFilename);
// };

  const handleFromDateChange = (date) => {
    setShow(false);
    setFromDate(date);
  };

  const validateParams = () => {

    let errMsg = "";
    if (!fromDate) {
      errMsg = "Please enter the Date";
      return errMsg;
    } else if (!selectedHub) {
      errMsg = "Please enter the Hub Name";
      return errMsg;
    } else if (!selectedDeliveryExecutive) {
      errMsg = "Please enter the Delivery Executive";
      return errMsg;
    }
  };

  const getCustomersWalletBalance = async() => {
    const customerMap = new Map();
    const querySnapshot_sub = await db.collection('order_history').where('delivery_date' , '==' , moment(fromDate).format('YYYY-MM-DD'))
    .get();
    const customerId_set = new Set();
    querySnapshot_sub.forEach(doc => {
      const order = doc.data();
      customerId_set.add(order.customer_id);
    })
    console.log("set => ",customerId_set);
    if(customerId_set.size>0) {
      customerId_set.forEach(async (customer_id) => {
        const wallet_snap = await db.collection('customers_data').where('customer_id' , '==' , customer_id).get();
        wallet_snap.forEach(doc => {
          customerMap.set(doc.data().customer_id , doc.data().wallet_balance)
        })
      })
      setCustomerWalletMap(customerMap);
      console.log(customerMap);
      setDisabledSearch(false);
      
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    await getCustomersWalletBalance();
    //customerBalanceMap = await getCustomerBalance(moment(fromDate).format('YYYY-MM-DD'));
    const customer_list = [];
    let customer = {};
    let querySnapshot;
    if(selectedHub && !selectedDeliveryExecutive) {

      querySnapshot = await db.collection("order_history")
      .where("delivery_date", "==", moment(fromDate).format('YYYY-MM-DD'))
      .where("hub_name", "==", selectedHub.value)
      .orderBy('location', 'asc')
      .get();  
    
    }else if(selectedDeliveryExecutive && selectedHub) {

      querySnapshot = await db.collection("order_history")
      .where("delivery_date", "==", moment(fromDate).format('YYYY-MM-DD'))
      .where("hub_name", "==", selectedHub.value)
      .where("delivery_exe_id", "==", selectedDeliveryExecutive.value.trim())
      .orderBy('location', 'asc')
      .get();
    
    }else {

      // querySnapshot = await db.collection("order_history")
      // .where("delivery_date", "==", moment(fromDate).format('YYYY-MM-DD'))
      // .get();
      const Toast = Swal.mixin({
        toast: true,
        background: '#69aba6',
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    
    
    setLoading(false);
    Toast.fire({
        icon: 'warning',
        title: 'Please select Hub Name'
    });
    return;
    
    }
      

    const ordersList = querySnapshot.docs.map(doc => doc.data());
    setOrderDataList(ordersList);
    console.log("Order data list :",orderDataList.length);

    setCustomerDetails(ordersList);

        const productsMap = {};
        querySnapshot.forEach(doc => {
            const data = doc.data();
            const productName = data.product_name;
            const packaging = data.package_unit;
            const unitPrice = data.price;
            const quantity = data.quantity;

            if (!productsMap[productName]) {
                productsMap[productName] = {
                    productName: productName,
                    packaging: packaging,
                    unitPrice: unitPrice,
                    totalQuantity: 0
                };
            }

            productsMap[productName].totalQuantity += quantity;
        });

        
        const productListArr = Object.values(productsMap);
        setProductList(productListArr);

    setDataLoaded(true);
    setLoading(false);
  }

  const handleReset = () => {
    setFromDate(null);
    setSelectedHub("");
    setSelectedDeliveryExecutive("");
    setShow(false);
    setOrderReports([]);
    setProductList([]);
  };

  const SpinnerOverlay = () => (
    <div className="spinner-overlay">
      <div className="spinner"></div>
    </div>
  );

  // Determine the range of page numbers to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //const currentItems = OnboardedCustomer.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderPageButtons = () => {

    const pageButtons = [];
    // Determine the range of page numbers to display
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, startPage + 9);

    // If the total number of pages is less than 10, adjust the endPage
    if (totalPages <= 10) {
      endPage = totalPages;
    } else {
      // If the current page is near the start, display the first 10 pages
      if (currentPage <= 5) {
        startPage = 1;
        endPage = 10;
      }
      // If the current page is near the end, display the last 10 pages
      else if (currentPage >= totalPages - 4) {
        endPage = totalPages;
        startPage = endPage - 9;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button
            onClick={() => paginate(i)}
            className="page-link"
            style={{ color: "black" }}
          >
            {i}
          </button>
        </li>
      );
    }

    return pageButtons;
  };

  useEffect(() => {
    db.collection("hubs_data").onSnapshot((snapshot) => {
      setHubNames(
        snapshot.docs.map((doc) => ({
          label: doc.data().hub_name,
          value: doc.data().hub_name,
        }))
      );
    });    
  }, []);

  useEffect(() => {
    const fetchDeliveryExeList = async () => {

      try {
        if (!selectedHub) {
          setDeliveryExecutiveNames([]);
          return;
        }
        const snapshot = await db
          .collection("hubs_users_data")
          .where("hub_name", "==", selectedHub.value)
          .get();
        const de = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            value: `${data.hub_user_id}`,
            label: `${data.first_name} ${data.last_name}`,
          };
        });
        setDeliveryExecutiveNames(de);
      } catch (error) {
        console.error("Error fetching delivery executive:", error);
      }
    };

    fetchDeliveryExeList();
  }, [selectedHub]);



  const handleHubChange = async (selectedOption) => {
    setSelectedHub(selectedOption);
  };

  const handleDEchange = async (selectedOption) => {
    setSelectedDeliveryExecutive(selectedOption);
  };


  return (
    <>
    {loading && ( // Render loader when loading state is true
                    <div className="loader-overlay">
                        <div className="">
                            <img style={{
                                height: "6rem"
                            }} src="images/loader.gif"></img>
                        </div>
                    </div>
                )}
      <div class="container-scroller">
        <div class="container-fluid">
          <div class="main-panel" style={{ width: '100%' }}>
            <div className="panel" style={{ marginTop: "10px", marginBottom: "10px" }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>ORDER SHEET</span>
                <div>
                  {dataLoaded &&
                    <button className="btn btn-success btn-rounded btn-sm" onClick={generatePDF}>Generate PDF</button>
                  }
                </div>
              </div>
            </div>

            <div className="panel datepickers-container">
              <div className="datepicker-container" style={{ marginTop: "10px" }}>
                <label className="datepicker-label">Date:</label>
                <DatePicker
                  selected={fromDate}
                  onChange={handleFromDateChange}
                  dateFormat="dd/MM/yyyy" // Format for displaying the date
                  className="datepicker-input"
                  placeholderText="Select date"
                />
              </div>
              <div className="dropdown-container">
                <label>Hubs *</label>
              </div>
              <div className="dropdown-container">
                <Select
                  options={hubNames}
                  onChange={handleHubChange}
                  value={selectedHub}
                  placeholder="Select Hub Name"
                  required
                />
              </div>
              <div className="dropdown-container">
                <label>Delivery Executive *</label>
              </div>
              <div className="dropdown-container">
                <Select
                  options={deliveryExecutiveNames}
                  onChange={handleDEchange}
                  value={selectedDeliveryExecutive}
                  placeholder="Select Delivery Executive"
                  required
                />
              </div>
              <div>
                <Button variant="outline-success" onClick={handleSearch} size='sm' disabled={disabledSearch}>
                  Search
                </Button>
                <Button
                  variant="outline-success"
                  onClick={handleReset}
                  style={{ marginLeft: "10px" }}
                  size='sm'
                >
                  Reset
                </Button>
              </div>
            </div>

            <br />
            <br />
            <div class="home-tab" style={{ marginLeft: "10px" }}>
              <div class="d-sm-flex align-items-center justify-content-between border-bottom">
                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item" style={{color:'#83bf91'}}>
                    <a
                      class="nav-link active ps-0"
                      id="home-tab"
                      data-bs-toggle="tab"
                      href="#overview"
                      role="tab"
                      aria-controls="overview"
                      aria-selected="true"
                      style={{color:'#83bf91'}}
                    >
                      Customer List Report
                    </a>
                  </li>
                  <li class="nav-item" style={{color:'#83bf91'}}>
                    <a
                      class="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      href="#audiences"
                      role="tab"
                      aria-selected="false"
                      style={{color:'#83bf91'}}
                    >
                      Product List Report
                    </a>
                  </li>
                  <input
                    style={{
                      border: "1px solid grey",
                      padding: "0px 4px 0px 1rem;",
                      borderRadius: "1rem",
                      marginTop: "3px",
                      marginLeft: "7px",
                      marginBottom: "1rem",
                      paddingLeft: "1rem",
                      height: "32px",
                      paddingBottom: "0px",
                    }}
                    type="text"
                    placeholder="Search here"


                  />
                </ul>
              </div>
              <div class="tab-content tab-content-basic">
                <div
                  class="tab-pane fade show active"
                  id="overview"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  {dataLoaded &&
                    <div class="col-md-12 grid-margin grid-margin-md-0 stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h4 class="card-title">Delivery Date : {moment(fromDate).format("DD-MM-YYYY")}</h4>
                          <div class="">
                            <div class="tab-pane" role="tabpanel" aria-labelledby="contact-tab">
                              {customerDetails.map((customer,index) => (
                                <div className="card mb-2" key={index}>

                                  <div className="card-body">
                                    <div class="row">
                                      <div class="col-lg-2">
                                        <address>
                                          <p class="fw-bold"> Customer Name: </p>
                                          <p>  {customer.customer_name}
                                          </p>
                                        </address>


                                      </div>
                                      <div class="col-lg-2">
                                        <address>
                                          <p class="fw-bold"> Customer Phone :</p>
                                          <p>
                                            {customer.customer_phone}
                                          </p>
                                        </address>


                                      </div>
                                      <div class="col-lg-2">
                                        <address>
                                          <p class="fw-bold"> Customer Address: </p>
                                          <p>
                                            {customer.delivering_to}
                                          </p>
                                        </address>



                                      </div>
                                      <div class="col-lg-2">
                                        <address>
                                          <p class="fw-bold"> Wallet Balance: </p>
                                          <p>
                                            {customerWalletMap.get(customer.customer_id)}
                                          </p>
                                        </address>


                                      </div>
                                      <div class="col-lg-2">
                                        {/* <address>
                                          <p class="fw-bold"> Delivery Preference </p>
                                          <p>
                                            {customer.delivery_mode}
                                          </p>
                                        </address> */}
                                      </div>
                                      <div class="col-lg-2">
                                        {/* <address>
                                          <p class="fw-bold"> Order ID </p>
                                          <p>
                                            {groupedRecords[customerId][0].order_id}
                                          </p>
                                        </address> */}
                                      </div>
                                    </div>
                                    <br />
                                    <div class="" style={{ display: "flex", justifyContent: "space-between" }}>
                                      <div style={{ backgroundColor: "", display: "flex" }}>
                                      </div>
                                      <div>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="table-responsive">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th className="pt-1">Product Name</th>
                                          <th className="pt-1">Packaging</th>
                                          <th className="pt-1">Quantity</th>
                                          <th className="pt-1">Unit Price</th>
                                          <th className="pt-1">Total Amount</th>
                                          {/* <th className="pt-1">Order Id</th>
                                          <th className="pt-1">customer id</th> */}

                                        </tr>
                                      </thead>
                                      <tbody>
                                      
                                        {(orderDataList.filter(orders => orders.customer_id == customer.customer_id)).map((filteredOrders , index) => (
                                          //{orderListData.filter(order => order.data.order_id === uniqueOrder.data.order_id).map
                                          //(record => (
                                          <tr key={index}>

                                            <td>{filteredOrders.product_name}</td>
                                            <td>{filteredOrders.package_unit}</td>
                                            <td>{filteredOrders.quantity}</td>
                                            <td>{filteredOrders.price}</td>
                                            <td>{filteredOrders.total_amount}</td>
                                            {/* <td>{filteredOrders.order_id}</td>
                                            <td>{filteredOrders.customer_id}</td> */}
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              ))}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  <div class="media">
                    <div class="card-body">
                      <div className="grid-container" id="dataPanel">

                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="audiences"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div class="media">
                    <div class="card-body">
                      {productList && <div class="table-responsive">
                        <table class="table table-striped" id="productTable">
                          <thead>
                            <tr>
                              <th>Sr No.</th>
                              <th>Product Name</th>
                              <th>packaging</th>
                              <th>Product Price</th>
                              <th>Quantity</th>
                            </tr>
                          </thead>
                          <tbody>

                            {productList.map((product, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{product.productName}</td>
                                  <td>{product.packaging}</td>
                                  <td>{product.unitPrice}</td>
                                  <td>{product.totalQuantity}</td>
                                </tr>
                              )

                            })

                            }
                          </tbody>
                        </table>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alert show={show} variant="success">
        <Alert.Heading>No data found for selected criteria</Alert.Heading>
        <div className="d-flex justify-content-end">
          <Button onClick={() => setShow(false)} variant="outline-success">
            Close
          </Button>
        </div>
      </Alert>

    </>
  );
}

export default OrderSheet;
