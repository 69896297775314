import db from "./Firebase";

export const create_record = async (collection, data) => {
    try {
        data['created_at'] = new Date();
        await db.collection(collection).add(data);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const update_record = async (collection, id, data) => {
    try {
        data['updated_at'] = new Date()
        await db.collection(collection).doc(id).update(data);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const fetch_records = async(collections, query,require_sorting=false,orderByField = 'created_at', orderDirection = 'desc') => {
    let data = [];
    try {
        let collectionRef = db.collection(collections);
        if (query.length > 0) {
            query.forEach(q => {
                collectionRef = collectionRef.where(q.key, q.operator, q.value);
            });
        }
        if(require_sorting){
            collectionRef = collectionRef.orderBy(orderByField, orderDirection);
        }
        const snapshot = await collectionRef.get();
        snapshot.forEach(doc => {
            data.push({id: doc.id, data: doc.data()});
        });
        return data;
    }
    catch (error) {
        console.log(error);
        return data;
    }
}

export const delete_record = async (collection, id) => {
    try {
        await db.collection(collection).doc(id).delete();
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const fetch_all_records = async (collection) => {
    let data = [];
    try {
        const snapshot = await db.collection(collection).get();
        snapshot.forEach(doc => {
            data.push({id: doc.id, data: doc.data()});
        });
        return data;
    } catch (error) {
        console.log(error);
        return data;
    }
}


export const generate_random_id = (length = 6) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    const charactersLength = characters.length;
  
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
  
    return result;
  };

  export const fetch_records_with_pagination = async (collections, query,lastDoc=null,require_sorting = false, orderByField = 'created_at', orderDirection = 'desc', page = 1, limit = 50) => {
    let data = [];
    let totalCount = 0;
    let totalPages = 0;
    let currentPage = page;

    console.log("here")
    try {
        let collectionRef = db.collection(collections);

       
        if (query.length > 0) {
            query.forEach(q => {
                collectionRef = collectionRef.where(q.key, q.operator, q.value);
            });
        }

        const totalSnapshot = await collectionRef.get();
        totalCount = totalSnapshot.size;

        totalPages = Math.ceil(totalCount / limit);

        if (require_sorting) {
            collectionRef = collectionRef.orderBy(orderByField, orderDirection);
        }
        
        // if (page > 1) {
        //     collectionRef = collectionRef.startAt(limit * (page-1));
        // }
        // if (lastDoc) {
        //     collectionRef = collectionRef.startAfter(lastDoc);
        // }


        const snapshot = await collectionRef.get();

        console.log("snapshot", snapshot)
        snapshot.forEach(doc => {
            data.push({ id: doc.id, data: doc.data() });
        });
        console.log("data at the helper", data)
        const lastVisible = snapshot.docs[snapshot.docs.length - 1];

        return {
            data,
            totalCount,
            currentPage,
            totalPages,
            lastVisible
        };
    } catch (error) {
        console.log(error);
        return {
            data,
            totalCount,
            currentPage,
            totalPages
        };
    }
}