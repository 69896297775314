import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import TopPanel from "./TopPanel";
import db from "./Firebase";
import Footer from "./Footer";
import { useNavigate, Link, useParams } from 'react-router-dom';


function UsersLevel() {
    const navigate = useNavigate();

    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        console.log(loggedIn);
        if (loggedIn) {
            // navigate("/");
        } else {
            navigate("/login");
        }
    }, [navigate]);
    const [loading, setLoading] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedOptions, setSelectedOptions] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [permissionsExist, setPermissionsExist] = useState(false);
    const [roles, setRoles] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);

    const initialroleData = {
        role_type: "",
        user_role: "",
        updated_date: new Date(),
        created_date: new Date(),

    };

    const [roleData, setRoleData] = useState(initialroleData);

    useEffect(() => {
        const fetchRoles = async () => {
            setLoading(true);
            try {
                const rolesSnapshot = await db.collection('user_permissions').get();
                const rolesData = rolesSnapshot.docs.map(doc => doc.id);
                setRoles(rolesData);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
            setLoading(false);
        };

        fetchRoles();
    }, []);

    useEffect(() => {
        const fetchMenuPermissions = async () => {
            setLoading(true);
            try {
                const menuPermissionsSnapshot = await db.collection('user_permissions').doc(selectedRole).get();
                const menuPermissionsData = menuPermissionsSnapshot.data();
                if (menuPermissionsData) {
                    setSelectedOptions(menuPermissionsData);
                    setPermissionsExist(true);
                    // console.log(menuPermissionsData);
                } else {
                    setSelectedOptions({});
                    setPermissionsExist(false);
                }
            } catch (error) {
                console.error('Error fetching menu permissions:', error);
            }
            setLoading(false);

        };

        if (selectedRole) {
            fetchMenuPermissions();
        }
    }, [selectedRole]);


    const handleRoleChange = (event) => {
        const newRole = event.target.value;
        setSelectedRole(newRole);
        // Reset selectedOptions to clear previous data
        setSelectedOptions({});
        setRoleData({
            role_type: "",
            user_role: "",
            updated_date: new Date(),
            created_date: new Date(),
        })

    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setRoleData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setSelectedRole('')
        setSelectedOptions({});
    };

    const handleMenuChange = (category, option, checked) => {
        setSelectedOptions((prevOptions) => {
            const categoryOptions = prevOptions[category] || [];
            let updatedOptions;

            if (checked) {
                updatedOptions = {
                    ...prevOptions,
                    [category]: [...categoryOptions, option]
                };
            } else {
                updatedOptions = {
                    ...prevOptions,
                    [category]: categoryOptions.filter((item) => item !== option)
                };
            }

            return updatedOptions;
        });
    };



    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitting(true);
        try {

            await db.collection('user_permissions').doc(roleData.user_role).set({ ...selectedOptions, roleData });
            console.log('Menu permissions saved successfully!');
        } catch (error) {
            console.error('Error saving menu permissions:', error);
        }
        setSubmitting(false);
    };

    const handleUpdatePermissions = async () => {
        setSubmitting(true);
        try {
            await db.collection('user_permissions').doc(selectedRole).update({ ...selectedOptions });
            console.log('Menu permissions updated successfully!');
        } catch (error) {
            console.error('Error updating menu permissions:', error);
        }
        setSubmitting(false);
    };

    const SpinnerOverlay = () => (
        <div className="spinner-overlay">
            <div className="spinner"></div>
        </div>
    );



    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <div className="">
                        <img style={{ height: "6rem" }} src="images/loader.gif" alt="Loader"></img>
                    </div>
                </div>
            )}

            {submitting && (
                <div className="loader-overlay">
                    <div className="">
                        <img style={{ height: "6rem" }} src="images/loader.gif" alt="Loader"></img>
                    </div>
                </div>
            )}
            <div className="container-scroller">
                <TopPanel />

                <div className="container-fluid page-body-wrapper">
                    <Sidebar />

                    <div class="main-panel">
                        <div class="content-wrapper">


                            <div className="col-md-12 grid-margin stretch-card">
                                <div className="card">

                                    <div className="card-body">
                                        <div>

                                        </div>
                                        <div class="form-group row">
                                            <div class="col">
                                                <label htmlFor="roleDropdown">Select Role To Update:</label>
                                                <select id="roleDropdown" class="form-control" value={selectedRole} onChange={handleRoleChange}>
                                                    <option value="">Select Role</option>
                                                    {roles.map((role, index) => (
                                                        <option key={index} value={role}>{role}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div class="col">
                                                {selectedRole === "" ? <></> : <><button type="button" class="btn btn-success" onClick={handleUpdatePermissions} style={{ marginTop: "1.6rem", height: "2.5rem" }} disabled={submitting}>Update Permissions</button></>}
                                            </div>
                                        </div>

                                        <form class="myForm" onSubmit={handleSubmit}>
                                            <div class="form-group row">

                                                <div class="col">
                                                    <label>Role Type</label>
                                                    <select class="form-control" id="role_type" name="role_type" value={roleData.role_type} onChange={handleChange} required>
                                                        <option value="">Select Role Type</option>
                                                        <option value="Global">Global</option>
                                                        <option value="Hub Level">Hub Level</option>
                                                    </select>
                                                </div>
                                                <div class="col">
                                                    <label>User Role:</label>
                                                    <input class="form-control" type="text" id="user_role" name="user_role" value={roleData.user_role} onChange={handleChange} required autoComplete="off" />
                                                </div>
                                                <div class="col">

                                                    {selectedRole != "" ? <></> : <><button type="submit" value="submit" class="btn btn-success" style={{ marginTop: "1.6rem", height: "2.5rem" }}>Add Role</button></>}


                                                </div>
                                            </div>
                                        </form>


                                    </div>

                                </div>
                            </div>



                            <div class="col-lg-12 grid-margin stretch-card">
                                <div class="">
                                    <div class="card-body">
                                        <div className="row">
                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div className="card" style={{ height: "14rem", overflow: "auto" }}>
                                                    <div style={{ display: "flex", justifyContent: "center" }}><span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>Menu Module</span></div>
                                                    <div className="card-body">
                                                        <div className="d-sm-flex flex-row text-start align-items-center">
                                                            <div className="ms-sm-3 ms-md-0 ms-xl-3 mt-2 mt-sm-0 mt-md-2 mt-xl-0 form-group" style={{ marginBottom: "0rem" }} >

                                                                <div className="col">
                                                                    <input type="checkbox" id="Dashboard" name="dashboard" checked={selectedOptions['Menu']?.includes('')} onChange={(e) => handleMenuChange("Menu", "", e.target.checked)} />
                                                                    <label htmlFor="Dashboard" style={{ marginLeft: '0.5rem' }}>Dashboard</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="hubs" name="hubs" checked={selectedOptions['Menu']?.includes('vendors_data')} onChange={(e) => handleMenuChange("Menu", "vendors_data", e.target.checked)} />
                                                                    <label htmlFor="hubs" style={{ marginLeft: '0.5rem' }}>Hubs/Dist</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="location" name="location" checked={selectedOptions['Menu']?.includes('location')} onChange={(e) => handleMenuChange("Menu", "location", e.target.checked)} />
                                                                    <label htmlFor="location" style={{ marginLeft: '0.5rem' }}>Location</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="routes" name="routes" checked={selectedOptions['Menu']?.includes('routes')} onChange={(e) => handleMenuChange("Menu", "routes", e.target.checked)} />
                                                                    <label htmlFor="Routes" style={{ marginLeft: '0.5rem' }}>Routes</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="customers" name="customers" checked={selectedOptions['Menu']?.includes('customers')} onChange={(e) => handleMenuChange("Menu", "customers", e.target.checked)} />
                                                                    <label htmlFor="customers" style={{ marginLeft: '0.5rem' }}>Customers</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="profile" name="profile" checked={selectedOptions['Customers']?.includes("profile/:id")} onChange={(e) => handleMenuChange("Customers", "profile/:id", e.target.checked)} />
                                                                    <label htmlFor="profile" style={{ marginLeft: '0.5rem' }}>View Customer Profile</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="banners" name="banners" checked={selectedOptions['Menu']?.includes('banners')} onChange={(e) => handleMenuChange("Menu", "banners", e.target.checked)} />
                                                                    <label htmlFor="Banner" style={{ marginLeft: '0.5rem' }}>Banner</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="coupons" name="coupons" checked={selectedOptions['Menu']?.includes('coupons')} onChange={(e) => handleMenuChange("Menu", "coupons", e.target.checked)} />
                                                                    <label htmlFor="Coupons" style={{ marginLeft: '0.5rem' }}>Coupons</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="offers" name="offers" checked={selectedOptions['Menu']?.includes('offers')} onChange={(e) => handleMenuChange("Menu", "offers", e.target.checked)} />
                                                                    <label htmlFor="Offers" style={{ marginLeft: '0.5rem' }}>Offers</label>
                                                                </div>

                                                                <div class="col">
                                                                    <input type="checkbox" id="products" name="products" checked={selectedOptions['Menu']?.includes('products')} onChange={(e) => handleMenuChange("Menu", "products", e.target.checked)} />
                                                                    <label htmlFor="products" style={{ marginLeft: '0.5rem' }}>Products</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="profile" name="profile" checked={selectedOptions['Menu']?.includes('profile')} onChange={(e) => handleMenuChange("Menu", "profile", e.target.checked)} />
                                                                    <label htmlFor="profile" style={{ marginLeft: '0.5rem' }}>Profile</label>
                                                                </div>

                                                                <div class="col">
                                                                    <input type="checkbox" id="communication" name="communication" checked={selectedOptions['Menu']?.includes('communication')} onChange={(e) => handleMenuChange("Menu", "communication", e.target.checked)} />
                                                                    <label htmlFor="Communication" style={{ marginLeft: '0.5rem' }}>Communication</label>
                                                                </div>


                                                                <div class="col">
                                                                    <input type="checkbox" id="manageUsersRoles" name="manageUsersRoles" checked={selectedOptions['Menu']?.includes('manageUsersRoles')} onChange={(e) => handleMenuChange("Menu", "manageUsersRoles", e.target.checked)} />
                                                                    <label htmlFor="Manage Users Roles" style={{ marginLeft: '0.5rem' }}>Manage User Roles</label>
                                                                </div>

                                                                <div class="col">
                                                                    <input type="checkbox" id="reports" name="reports" checked={selectedOptions['Menu']?.includes('reports')} onChange={(e) => handleMenuChange("Menu", "reports", e.target.checked)} />
                                                                    <label htmlFor="Manage Users Roles" style={{ marginLeft: '0.5rem' }}>Reports</label>
                                                                </div>

                                                                <div class="col">
                                                                    <input type="checkbox" id="tickets" name="tickets" checked={selectedOptions['Menu']?.includes('tickets')} onChange={(e) => handleMenuChange("Menu", "tickets", e.target.checked)} />
                                                                    <label htmlFor="Manage Users Roles" style={{ marginLeft: '0.5rem' }}>Tickets</label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div className="card" style={{ height: "14rem", overflow: "auto" }}>
                                                    <div style={{ display: "flex", justifyContent: "center" }}><span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>Hubs & distributor</span></div>
                                                    <div className="card-body">
                                                        <div className="d-sm-flex flex-row text-start align-items-center">
                                                            <div className="ms-sm-3 ms-md-0 ms-xl-3 mt-2 mt-sm-0 mt-md-2 mt-xl-0 form-group" style={{ marginBottom: "0rem" }} >
                                                                <div class="col">
                                                                    <input type="checkbox" id="add" name="add" checked={selectedOptions['HubsDist']?.includes('add')} onChange={(e) => handleMenuChange("HubsDist", "add", e.target.checked)} />
                                                                    <label htmlFor="View" style={{ marginLeft: '0.5rem' }}>Add</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="edit" name="edit" checked={selectedOptions['HubsDist']?.includes('edit')} onChange={(e) => handleMenuChange("HubsDist", "edit", e.target.checked)} />
                                                                    <label htmlFor="Edit" style={{ marginLeft: '0.5rem' }}>Edit</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="delete" name="delete" checked={selectedOptions['HubsDist']?.includes('delete')} onChange={(e) => handleMenuChange("HubsDist", "delete", e.target.checked)} />
                                                                    <label htmlFor="Delete" style={{ marginLeft: '0.5rem' }}>Delete</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="tde" name="tde" checked={selectedOptions['HubsDist']?.includes('transfer_location_hub_wise')} onChange={(e) => handleMenuChange("HubsDist", "transfer_location_hub_wise", e.target.checked)} />
                                                                    <label htmlFor="tde" style={{ marginLeft: '0.5rem' }}>Transfer Loc. Hub Wise</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="tde" name="tde" checked={selectedOptions['HubsDist']?.includes('transfer_location')} onChange={(e) => handleMenuChange("HubsDist", "transfer_location", e.target.checked)} />
                                                                    <label htmlFor="tde" style={{ marginLeft: '0.5rem' }}>Transfer Location</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="tde" name="tde" checked={selectedOptions['HubsDist']?.includes('add_route')} onChange={(e) => handleMenuChange("HubsDist", "add_route", e.target.checked)} />
                                                                    <label htmlFor="tde" style={{ marginLeft: '0.5rem' }}>Add Route</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="tde" name="tde" checked={selectedOptions['HubsDist']?.includes('update_location')} onChange={(e) => handleMenuChange("HubsDist", "update_location", e.target.checked)} />
                                                                    <label htmlFor="tde" style={{ marginLeft: '0.5rem' }}>Update Location</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="arth" name="arth" checked={selectedOptions['HubsDist']?.includes('add_route_to_hub')} onChange={(e) => handleMenuChange("HubsDist", "add_route_to_hub", e.target.checked)} />
                                                                    <label htmlFor="arth" style={{ marginLeft: '0.5rem' }}>Add Route to Hub</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="ahu" name="ahu" checked={selectedOptions['HubsDist']?.includes('add_hub_users')} onChange={(e) => handleMenuChange("HubsDist", "add_hub_users", e.target.checked)} />
                                                                    <label htmlFor="ahu" style={{ marginLeft: '0.5rem' }}>Add Hub Users</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="ehu" name="ehu" checked={selectedOptions['HubsDist']?.includes('edit_hub_users')} onChange={(e) => handleMenuChange("HubsDist", "edit_hub_users", e.target.checked)} />
                                                                    <label htmlFor="ehu" style={{ marginLeft: '0.5rem' }}>Edit Hub Users</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="dhu" name="dhu" checked={selectedOptions['HubsDist']?.includes('delete_hub_users')} onChange={(e) => handleMenuChange("HubsDist", "delete_hub_users", e.target.checked)} />
                                                                    <label htmlFor="dhu" style={{ marginLeft: '0.5rem' }}>Delete Hub Users</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div className="card" style={{ height: "14rem", overflow: "auto" }}>
                                                    <div style={{ display: "flex", justifyContent: "center" }}><span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>Customers</span></div>
                                                    <div className="card-body">
                                                        <div className="d-sm-flex flex-row text-start align-items-center">
                                                            <div className="ms-sm-3 ms-md-0 ms-xl-3 mt-2 mt-sm-0 mt-md-2 mt-xl-0 form-group" style={{ marginBottom: "0rem" }} >
                                                                <div class="col">
                                                                    <input type="checkbox" id="add" name="add" checked={selectedOptions['Customers']?.includes('add')} onChange={(e) => handleMenuChange("Customers", "add", e.target.checked)} />
                                                                    <label htmlFor="add" style={{ marginLeft: '0.5rem' }}>Add Customer</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="edit" name="edit" checked={selectedOptions['Customers']?.includes('edit')} onChange={(e) => handleMenuChange("Customers", "edit", e.target.checked)} />
                                                                    <label htmlFor="edit" style={{ marginLeft: '0.5rem' }}>Edit Customer</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="view" name="view" checked={selectedOptions['Customers']?.includes('view')} onChange={(e) => handleMenuChange("Customers", "view", e.target.checked)} />
                                                                    <label htmlFor="view" style={{ marginLeft: '0.5rem' }}>View Customer Details</label>
                                                                </div>

                                                                <div class="col">
                                                                    <input type="checkbox" id="profile" name="profile" checked={selectedOptions['Customers']?.includes("profile/:id")} onChange={(e) => handleMenuChange("Customers", "profile/:id", e.target.checked)} />
                                                                    <label htmlFor="profile" style={{ marginLeft: '0.5rem' }}>View Customer Profile</label>
                                                                </div>

                                                                <div class="col">
                                                                    <input type="checkbox" id="add_subscription" name="add_subscription" checked={selectedOptions['Customers']?.includes('add_subscription')} onChange={(e) => handleMenuChange("Customers", "add_subscription", e.target.checked)} />
                                                                    <label htmlFor="add_subscription" style={{ marginLeft: '0.5rem' }}>Add Subscription</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="update_subescription" name="update_subescription" checked={selectedOptions['Customers']?.includes('update_subescription')} onChange={(e) => handleMenuChange("Customers", "update_subescription", e.target.checked)} />
                                                                    <label htmlFor="update_subescription" style={{ marginLeft: '0.5rem' }}>Update Subscription</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="wallet_transaction" name="wallet_transaction" checked={selectedOptions['Customers']?.includes('wallet_transaction')} onChange={(e) => handleMenuChange("Customers", "wallet_transaction", e.target.checked)} />
                                                                    <label htmlFor="wallet_transaction" style={{ marginLeft: '0.5rem' }}>Wallet Transaction</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="credit_limit_transaction" name="credit_limit_transaction" checked={selectedOptions['Customers']?.includes('credit_limit_transaction')} onChange={(e) => handleMenuChange("Customers", "credit_limit_transaction", e.target.checked)} />
                                                                    <label htmlFor="credit_limit_transaction" style={{ marginLeft: '0.5rem' }}>Credit Limit Transaction</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="add_ticket" name="add_ticket" checked={selectedOptions['Customers']?.includes('add_ticket')} onChange={(e) => handleMenuChange("Customers", "add_ticket", e.target.checked)} />
                                                                    <label htmlFor="add_ticket" style={{ marginLeft: '0.5rem' }}>Add ticket</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="update_ticket" name="update_ticket" checked={selectedOptions['Customers']?.includes('update_ticket')} onChange={(e) => handleMenuChange("Customers", "update_ticket", e.target.checked)} />
                                                                    <label htmlFor="update_ticket" style={{ marginLeft: '0.5rem' }}>Update Ticket</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="add_vacation" name="add_vacation" checked={selectedOptions['Customers']?.includes('add_vacation')} onChange={(e) => handleMenuChange("Customers", "add_vacation", e.target.checked)} />
                                                                    <label htmlFor="add_vacation" style={{ marginLeft: '0.5rem' }}>Add vacation</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="update_vacation" name="update_vacation" checked={selectedOptions['Customers']?.includes('update_vacation')} onChange={(e) => handleMenuChange("Customers", "update_vacation", e.target.checked)} />
                                                                    <label htmlFor="update_vacation" style={{ marginLeft: '0.5rem' }}>Update Vacation</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="delivery_prefrence" name="delivery_prefrence" checked={selectedOptions['Customers']?.includes('delivery_prefrence')} onChange={(e) => handleMenuChange("Customers", "delivery_prefrence", e.target.checked)} />
                                                                    <label htmlFor="delivery_prefrence" style={{ marginLeft: '0.5rem' }}>Delivery Preference</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="cash_collection" name="cash_collection" checked={selectedOptions['Customers']?.includes('cash_collection')} onChange={(e) => handleMenuChange("Customers", "cash_collection", e.target.checked)} />
                                                                    <label htmlFor="cash_collection" style={{ marginLeft: '0.5rem' }}>Cash Collection</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div className="card" style={{ height: "14rem", overflow: "auto" }}>
                                                    <div style={{ display: "flex", justifyContent: "center" }}><span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>Location</span></div>
                                                    <div className="card-body">
                                                        <div className="d-sm-flex flex-row text-start align-items-center">
                                                            <div className="ms-sm-3 ms-md-0 ms-xl-3 mt-2 mt-sm-0 mt-md-2 mt-xl-0 form-group" style={{ marginBottom: "0rem" }} >
                                                                <div class="col">
                                                                    <input type="checkbox" id="read" name="read" checked={selectedOptions['Location']?.includes('read')} onChange={(e) => handleMenuChange("Location", "read", e.target.checked)} />
                                                                    <label htmlFor="read" style={{ marginLeft: '0.5rem' }}>Read</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="add" name="add" checked={selectedOptions['Location']?.includes('add')} onChange={(e) => handleMenuChange("Location", "add", e.target.checked)} />
                                                                    <label htmlFor="add" style={{ marginLeft: '0.5rem' }}>Add</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="write" name="write" checked={selectedOptions['Location']?.includes('write')} onChange={(e) => handleMenuChange("Location", "write", e.target.checked)} />
                                                                    <label htmlFor="write" style={{ marginLeft: '0.5rem' }}>Write</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="delete" name="delete" checked={selectedOptions['Location']?.includes('delete')} onChange={(e) => handleMenuChange("Location", "delete", e.target.checked)} />
                                                                    <label htmlFor="delete" style={{ marginLeft: '0.5rem' }}>Delete</label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div className="card" style={{ height: "14rem", overflow: "auto" }}>
                                                    <div style={{ display: "flex", justifyContent: "center" }}><span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>Routes</span></div>
                                                    <div className="card-body">
                                                        <div className="d-sm-flex flex-row text-start align-items-center">
                                                            <div className="ms-sm-3 ms-md-0 ms-xl-3 mt-2 mt-sm-0 mt-md-2 mt-xl-0 form-group" style={{ marginBottom: "0rem" }} >
                                                                <div class="col">
                                                                    <input type="checkbox" id="read" name="read" checked={selectedOptions['Routes']?.includes('read')} onChange={(e) => handleMenuChange("Routes", "read", e.target.checked)} />
                                                                    <label htmlFor="read" style={{ marginLeft: '0.5rem' }}>Read</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="add" name="add" checked={selectedOptions['Routes']?.includes('add')} onChange={(e) => handleMenuChange("Routes", "add", e.target.checked)} />
                                                                    <label htmlFor="add" style={{ marginLeft: '0.5rem' }}>Add</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="write" name="write" checked={selectedOptions['Routes']?.includes('write')} onChange={(e) => handleMenuChange("Routes", "write", e.target.checked)} />
                                                                    <label htmlFor="write" style={{ marginLeft: '0.5rem' }}>Write</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="delete" name="delete" checked={selectedOptions['Routes']?.includes('delete')} onChange={(e) => handleMenuChange("Routes", "delete", e.target.checked)} />
                                                                    <label htmlFor="delete" style={{ marginLeft: '0.5rem' }}>Delete</label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div className="card" style={{ height: "14rem", overflow: "auto" }}>
                                                    <div style={{ display: "flex", justifyContent: "center" }}><span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>Banner</span></div>
                                                    <div className="card-body">
                                                        <div className="d-sm-flex flex-row text-start align-items-center">
                                                            <div className="ms-sm-3 ms-md-0 ms-xl-3 mt-2 mt-sm-0 mt-md-2 mt-xl-0 form-group" style={{ marginBottom: "0rem" }} >
                                                                <div class="col">
                                                                    <input type="checkbox" id="read" name="read" checked={selectedOptions['Banner']?.includes('read')} onChange={(e) => handleMenuChange("Banner", "read", e.target.checked)} />
                                                                    <label htmlFor="read" style={{ marginLeft: '0.5rem' }}>Read</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="write" name="write" checked={selectedOptions['Banner']?.includes('write')} onChange={(e) => handleMenuChange("Banner", "write", e.target.checked)} />
                                                                    <label htmlFor="write" style={{ marginLeft: '0.5rem' }}>Write</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="delete" name="delete" checked={selectedOptions['Banner']?.includes('delete')} onChange={(e) => handleMenuChange("Banner", "delete", e.target.checked)} />
                                                                    <label htmlFor="delete" style={{ marginLeft: '0.5rem' }}>Delete</label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div className="card" style={{ height: "14rem", overflow: "auto" }}>
                                                    <div style={{ display: "flex", justifyContent: "center" }}><span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>Coupons</span></div>
                                                    <div className="card-body">
                                                        <div className="d-sm-flex flex-row text-start align-items-center">
                                                            <div className="ms-sm-3 ms-md-0 ms-xl-3 mt-2 mt-sm-0 mt-md-2 mt-xl-0 form-group" style={{ marginBottom: "0rem" }} >
                                                                <div class="col">
                                                                    <input type="checkbox" id="read" name="read" checked={selectedOptions['Coupons']?.includes('read')} onChange={(e) => handleMenuChange("Coupons", "read", e.target.checked)} />
                                                                    <label htmlFor="read" style={{ marginLeft: '0.5rem' }}>Read</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="read" name="read" checked={selectedOptions['Coupons']?.includes('write')} onChange={(e) => handleMenuChange("Coupons", "write", e.target.checked)} />
                                                                    <label htmlFor="read" style={{ marginLeft: '0.5rem' }}>Write</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="delete" name="delete" checked={selectedOptions['Coupons']?.includes('delete')} onChange={(e) => handleMenuChange("Coupons", "delete", e.target.checked)} />
                                                                    <label htmlFor="delete" style={{ marginLeft: '0.5rem' }}>Delete</label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div className="card" style={{ height: "14rem", overflow: "auto" }}>
                                                    <div style={{ display: "flex", justifyContent: "center" }}><span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>Offer</span></div>
                                                    <div className="card-body">
                                                        <div className="d-sm-flex flex-row text-start align-items-center">
                                                            <div className="ms-sm-3 ms-md-0 ms-xl-3 mt-2 mt-sm-0 mt-md-2 mt-xl-0 form-group" style={{ marginBottom: "0rem" }} >
                                                                <div class="col">
                                                                    <input type="checkbox" id="read" name="read" checked={selectedOptions['Offer']?.includes('read')} onChange={(e) => handleMenuChange("Offer", "read", e.target.checked)} />
                                                                    <label htmlFor="read" style={{ marginLeft: '0.5rem' }}>Read</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="write" name="write" checked={selectedOptions['Offer']?.includes('write')} onChange={(e) => handleMenuChange("Offer", "write", e.target.checked)} />
                                                                    <label htmlFor="write" style={{ marginLeft: '0.5rem' }}>Write</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="delete" name="delete" checked={selectedOptions['Offer']?.includes('delete')} onChange={(e) => handleMenuChange("Offer", "delete", e.target.checked)} />
                                                                    <label htmlFor="delete" style={{ marginLeft: '0.5rem' }}>Delete</label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div className="card" style={{ height: "14rem", overflow: "auto" }}>
                                                    <div style={{ display: "flex", justifyContent: "center" }}><span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>Communication</span></div>
                                                    <div className="card-body">
                                                        <div className="d-sm-flex flex-row text-start align-items-center">
                                                            <div className="ms-sm-3 ms-md-0 ms-xl-3 mt-2 mt-sm-0 mt-md-2 mt-xl-0 form-group" style={{ marginBottom: "0rem" }} >
                                                                <div class="col">
                                                                    <input type="checkbox" id="read" name="read" checked={selectedOptions['Communication']?.includes('read')} onChange={(e) => handleMenuChange("Communication", "read", e.target.checked)} />
                                                                    <label htmlFor="read" style={{ marginLeft: '0.5rem' }}>Read</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="write" name="write" checked={selectedOptions['Communication']?.includes('write')} onChange={(e) => handleMenuChange("Communication", "write", e.target.checked)} />
                                                                    <label htmlFor="write" style={{ marginLeft: '0.5rem' }}>Write</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="delete" name="delete" checked={selectedOptions['Communication']?.includes('delete')} onChange={(e) => handleMenuChange("Communication", "delete", e.target.checked)} />
                                                                    <label htmlFor="delete" style={{ marginLeft: '0.5rem' }}>Delete</label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div className="card" style={{ height: "14rem", overflow: "auto" }}>
                                                    <div style={{ display: "flex", justifyContent: "center" }}><span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>Products</span></div>
                                                    <div className="card-body">
                                                        <div className="d-sm-flex flex-row text-start align-items-center">
                                                            <div className="ms-sm-3 ms-md-0 ms-xl-3 mt-2 mt-sm-0 mt-md-2 mt-xl-0 form-group" style={{ marginBottom: "0rem" }} >
                                                                <div class="col">
                                                                    <input type="checkbox" id="read" name="read" checked={selectedOptions['Products']?.includes('read')} onChange={(e) => handleMenuChange("Products", "read", e.target.checked)} />
                                                                    <label htmlFor="read" style={{ marginLeft: '0.5rem' }}>Read</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="write" name="write" checked={selectedOptions['Products']?.includes('write')} onChange={(e) => handleMenuChange("Products", "write", e.target.checked)} />
                                                                    <label htmlFor="write" style={{ marginLeft: '0.5rem' }}>Write</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="delete" name="delete" checked={selectedOptions['Products']?.includes('delete')} onChange={(e) => handleMenuChange("Products", "delete", e.target.checked)} />
                                                                    <label htmlFor="delete" style={{ marginLeft: '0.5rem' }}>Delete</label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div className="card" style={{ height: "14rem", overflow: "auto" }}>
                                                    <div style={{ display: "flex", justifyContent: "center" }}><span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>Reports</span></div>
                                                    <div className="card-body">
                                                        <div className="d-sm-flex flex-row text-start align-items-center">
                                                            <div className="ms-sm-3 ms-md-0 ms-xl-3 mt-2 mt-sm-0 mt-md-2 mt-xl-0 form-group" style={{ marginBottom: "0rem" }} >
                                                                <div class="col">
                                                                    <input type="checkbox" id="predictive_analysis" name="predictive_analysis" checked={selectedOptions['Reports']?.includes('predictive_analysis')} onChange={(e) => handleMenuChange("Reports", "predictive_analysis", e.target.checked)} />
                                                                    <label htmlFor="predictive_analysis" style={{ marginLeft: '0.5rem' }}>Predictive Analysis</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="lowcreditreport" name="lowcreditreport" checked={selectedOptions['Reports']?.includes('lowcreditreport')} onChange={(e) => handleMenuChange("Reports", "lowcreditreport", e.target.checked)} />
                                                                    <label htmlFor="lowcreditreport" style={{ marginLeft: '0.5rem' }}>Low Credit Report</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="newonboardcustomer" name="newonboardcustomer" checked={selectedOptions['Reports']?.includes('newonboardcustomer')} onChange={(e) => handleMenuChange("Reports", "newonboardcustomer", e.target.checked)} />
                                                                    <label htmlFor="newonboardcustomer" style={{ marginLeft: '0.5rem' }}>New Onboard  Customer</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="feedback_report" name="feedback_report" checked={selectedOptions['Reports']?.includes('feedback_report')} onChange={(e) => handleMenuChange("Reports", "feedback_report", e.target.checked)} />
                                                                    <label htmlFor="feedback_report" style={{ marginLeft: '0.5rem' }}>Feedback Report</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="order_report" name="order_report" checked={selectedOptions['Reports']?.includes('order_report')} onChange={(e) => handleMenuChange("Reports", "order_report", e.target.checked)} />
                                                                    <label htmlFor="order_report" style={{ marginLeft: '0.5rem' }}>Order Report</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="hubdeliveryreport" name="hubdeliveryreport" checked={selectedOptions['Reports']?.includes('hubdeliveryreport')} onChange={(e) => handleMenuChange("Reports", "hubdeliveryreport", e.target.checked)} />
                                                                    <label htmlFor="hubdeliveryreport" style={{ marginLeft: '0.5rem' }}>Hub Deliveries</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="orderSheet" name="orderSheet" checked={selectedOptions['Reports']?.includes('orderSheet')} onChange={(e) => handleMenuChange("Reports", "orderSheet", e.target.checked)} />
                                                                    <label htmlFor="orderSheet" style={{ marginLeft: '0.5rem' }}>Order Sheets</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="cumulative_sales" name="cumulative_sales" checked={selectedOptions['Reports']?.includes('cumulative_sales')} onChange={(e) => handleMenuChange("Reports", "cumulative_sales", e.target.checked)} />
                                                                    <label htmlFor="cumulative_sales" style={{ marginLeft: '0.5rem' }}>Cumulative Sales</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="cumulative_sales" name="subscriptionreport" checked={selectedOptions['Reports']?.includes('subscriptionreport')} onChange={(e) => handleMenuChange("Reports", "subscriptionreport", e.target.checked)} />
                                                                    <label htmlFor="subscriptionreport" style={{ marginLeft: '0.5rem' }}>Subscription Report</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="customers_sales" name="customers_sales" checked={selectedOptions['Reports']?.includes('customers_sales')} onChange={(e) => handleMenuChange("Reports", "customers_sales", e.target.checked)} />
                                                                    <label htmlFor="customers_sales" style={{ marginLeft: '0.5rem' }}>Customer Sales</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="wallet_transaction" name="wallet_transaction" checked={selectedOptions['Reports']?.includes('wallet_transaction')} onChange={(e) => handleMenuChange("Reports", "wallet_transaction", e.target.checked)} />
                                                                    <label htmlFor="wallet_transaction" style={{ marginLeft: '0.5rem' }}>Wallet Transaction</label>
                                                                </div>

                                                                <div class="col">
                                                                    <input type="checkbox" id="ordersorting" name="ordersorting" checked={selectedOptions['Reports']?.includes('ordersorting')} onChange={(e) => handleMenuChange("Reports", "ordersorting", e.target.checked)} />
                                                                    <label htmlFor="ordersorting" style={{ marginLeft: '0.5rem' }}>Order Sorting</label>
                                                                </div>

                                                                <div class="col">
                                                                    <input type="checkbox" id="activity_log" name="activity_log" checked={selectedOptions['Reports']?.includes('activity_log')} onChange={(e) => handleMenuChange("Reports", "activity_log", e.target.checked)} />
                                                                    <label htmlFor="activity_log" style={{ marginLeft: '0.5rem' }}>Activity Log</label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div className="card" style={{ height: "14rem", overflow: "auto" }}>
                                                    <div style={{ display: "flex", justifyContent: "center" }}><span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>Tickets</span></div>
                                                    <div className="card-body">
                                                        <div className="d-sm-flex flex-row text-start align-items-center">
                                                            <div className="ms-sm-3 ms-md-0 ms-xl-3 mt-2 mt-sm-0 mt-md-2 mt-xl-0 form-group" style={{ marginBottom: "0rem" }} >
                                                                <div class="col">
                                                                    <input type="checkbox" id="read" name="read" checked={selectedOptions['Tickets']?.includes('read')} onChange={(e) => handleMenuChange("Tickets", "read", e.target.checked)} />
                                                                    <label htmlFor="read" style={{ marginLeft: '0.5rem' }}>Read</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="write" name="write" checked={selectedOptions['Tickets']?.includes('write')} onChange={(e) => handleMenuChange("Tickets", "write", e.target.checked)} />
                                                                    <label htmlFor="write" style={{ marginLeft: '0.5rem' }}>Write</label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="checkbox" id="delete" name="delete" checked={selectedOptions['Tickets']?.includes('delete')} onChange={(e) => handleMenuChange("Tickets", "delete", e.target.checked)} />
                                                                    <label htmlFor="delete" style={{ marginLeft: '0.5rem' }}>Delete</label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>



                        <Footer />

                    </div>

                </div>
            </div>
        </>
    );
}

export default UsersLevel;
