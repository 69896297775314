import React, { useState, useEffect, } from "react";
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import { useCookies } from "react-cookie";

function Sidebar() {
    const [cookies] = useCookies(["permissions"]);
    const rolePermissions = cookies.permissions ? cookies.permissions.Menu || [] : [];

    const [isActive, setIsActive] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const location = useLocation();

    useEffect(() => {
        // Extract the current route from the location object
        const currentRoute = location.pathname;

        // Determine the active item based on the current route
        setActiveItem(currentRoute);
    }, [location]);

    const handleMenuClick = (itemName) => {
        setActiveItem(itemName);
    };

    return (
        <>
            <nav class="sidebar sidebar-offcanvas" id="sidebar">
                <ul class="nav" style={{ marginTop: '50px' }}>
                    {rolePermissions.includes('') ? <>
                        <li className={`nav-item ${activeItem === '/' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/" onClick={() => handleMenuClick('/')}>
                                <i className="mdi mdi-grid-large menu-icon"></i>
                                <span className="menu-title">Dashboard</span>
                            </Link>
                        </li>

                    </> : <></>}
                    {rolePermissions.includes('vendors_data') ? <>
                        <li className={`nav-item ${activeItem === '/vendors_data' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/vendors_data" onClick={() => handleMenuClick('/vendors_data')}>
                                <i className="menu-icon mdi mdi-account-multiple"></i>
                                <span className="menu-title">Hubs/Distributors</span>
                            </Link>
                        </li>
                    </> : <></>}

                    {rolePermissions.includes('location') ? <>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="collapse" href="#locations" aria-expanded="false"
                                aria-controls="locations">
                                <i class="menu-icon mdi mdi-map-marker"></i>
                                <span class="menu-title">Locations</span>
                                <i class="menu-arrow"></i>
                            </a>
                            <div class="collapse" id="locations">
                                <ul class="nav flex-column sub-menu">
                                    {rolePermissions.includes('location') ? <><li className={`nav-item ${activeItem === '/location' ? 'active' : ''}`}> <Link class="nav-link" to="/location" onClick={() => handleMenuClick('/location')}>Locations</Link></li></> : <></>}
                                    {rolePermissions.includes('routes') ? <><li class="nav-item"> <Link class="nav-link" to="/routes">Routes</Link></li></> : <></>}
                                </ul>
                            </div>
                        </li>
                    </> : <></>}


                    {rolePermissions.includes('customers') ? <>
                        <li className={`nav-item ${activeItem === '/customers' ? 'active' : ''}`}>
                            <Link class="nav-link" to="/customers" onClick={() => handleMenuClick('/customers')}>
                                <i class="menu-icon mdi mdi-file-document"></i>
                                <span class="menu-title">Customers</span>
                            </Link>
                        </li>
                    </> : <></>}


                    {rolePermissions.includes('banners') ? <>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="collapse" href="#marketing" aria-expanded="false"
                                aria-controls="marketing">
                                <i class="menu-icon mdi mdi-account-convert"></i>
                                <span class="menu-title">Marketing</span>
                                <i class="menu-arrow"></i>
                            </a>
                            <div class="collapse" id="marketing">
                                <ul class="nav flex-column sub-menu">
                                    <li class="nav-item"> <Link class="nav-link" to="/banners">Banners</Link></li>
                                    {/* <li class="nav-item"> <Link class="nav-link" to={isActive ? '/coupons' : '#'}>Coupons</Link></li>
                                    <li class="nav-item"> <Link class="nav-link" to={isActive ? '/offers' : '#'}>Offers</Link></li>
                                    <li class="nav-item"> <Link class="nav-link" to={isActive ? "/communication" : '#'}>Communication</Link></li> */}
                                </ul>
                            </div>
                        </li>
                    </> : <></>}

                    {rolePermissions.includes('reports') ? <>
                        <li className={`nav-item ${activeItem === '/reports' ? 'active' : ''}`}>
                            <Link class="nav-link" to="/reports" onClick={() => handleMenuClick('/reports')}>
                                <i class="menu-icon mdi mdi-amplifier"></i>
                                <span class="menu-title">Reports</span>
                            </Link>
                        </li>
                    </> : <></>}

                    {rolePermissions.includes('products') ? <>
                        <li className={`nav-item ${activeItem === '/products' ? 'active' : ''}`}>
                            <Link class="nav-link" to="/products" onClick={() => handleMenuClick('/products')}>
                                <i class="menu-icon mdi mdi-amplifier"></i>
                                <span class="menu-title">Products</span>
                            </Link>
                        </li>
                    </> : <></>}

                    {rolePermissions.includes('tickets') ? <>
                        <li className={`nav-item ${activeItem === '/tickets' ? 'active' : ''}`}>
                            <Link class="nav-link" to="/tickets" onClick={() => handleMenuClick('/tickets')}>
                                <i class="menu-icon mdi mdi-amplifier"></i>
                                <span class="menu-title">Tickets</span>
                            </Link>
                        </li>
                    </> : <></>}
                    {rolePermissions.includes('cafeManagement') ? <>
                        {/* <li className={`nav-item ${activeItem === '/tickets' ? 'active' : ''}`}>
                            <Link class="nav-link" to="/cafe-management" onClick={() => handleMenuClick('/cafe-management')}>
                                <i class="menu-icon mdi mdi-amplifier"></i>
                                <span class="menu-title">B2B</span>
                            </Link>
                        </li> */}
                    </> : <></>}
                </ul>
            </nav>
        </>
    );
};

export default Sidebar;