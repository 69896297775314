import React, { useState, useEffect } from "react";
import db from "./Firebase";
import { Button, Spinner, Form, Modal } from "react-bootstrap";
import "./Report.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./OnBoardCustomer.css";
import { toast } from "react-toastify";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { useNavigate, Link, useParams } from 'react-router-dom';
import "./Report.css";
import ExportTableToExcel from "./ExportTableToExcel";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { fetch_records, create_record ,generate_random_id } from './helpers';
import {TASK_TYPE} from './constants';
import { getUserInfo } from "./Utility";
function NewOnboardCustomers() {
const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    console.log(loggedIn);
    if (loggedIn) {
      // navigate("/");
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const { loggedIn, userId, username, loggedIn_user } = getUserInfo();
  const moment = extendMoment(Moment);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isValidRange, setIsValidRange] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [OnboardedCustomer, setOnboardedCustomer] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [customerFeedbackData, setCustomerFeedbackData] = useState([]);
  const [showAddFeedbackModal, setShowAddFeedbackModal] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [show, setShow] = useState(false);
  const [initialValue, setInitialvalue] = useState("");

  const handleFromDateChange = (date) => {
    setShow(false);
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setShow(false);
    const updatedDate = new Date(date);
    updatedDate.setHours(23, 59, 59, 999);
    setToDate(updatedDate);
  };

  const handleSearch = async () => {
    setShow(false);
    if (!fromDate || !toDate) {
      if (!fromDate) {
        setIsValidRange(false);
        toast.error("Please enter from date");
        return;
      }
      if (!toDate) {
        setIsValidRange(false);
        toast.error("Please enter To Date");
        return;
      }

    } else {
      setShowSpinner(true);
      try {

        const query = await db
          .collection("customers_data")
          .where("registered_date", ">=", fromDate)
          .where("registered_date", "<", toDate);

        // Execute the query
        query.get().then((querySnapshot) => {
          const customersData = [];
          querySnapshot.forEach((doc) => {
            // Retrieve and store customer data
            const customerData = doc.data();
            customersData.push(customerData);
          });

          if (customersData.length > 0) {
            setDataLoaded(true);
            setOnboardedCustomer(customersData);
          } else {
            setDataLoaded(false);
            setInitialvalue("No data found")
          }
          setShowSpinner(false);
          renderPageButtons();
        });
      } catch (error) {
        console.error("Error fetching customers data:", error);
      }
    }
  };

  const handleReset = () => {
    setFromDate(null);
    setToDate(null);
    setShow(false);
    setInitialvalue("");
    setOnboardedCustomer([]);
  };

  const SpinnerOverlay = () => (
    <div className="spinner-overlay">
      <div className="spinner"></div>
    </div>
  );

  // Determine the range of page numbers to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = OnboardedCustomer.slice(indexOfFirstItem, indexOfLastItem);


  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const total = Math.ceil(OnboardedCustomer.length / itemsPerPage);
    setTotalPages(total);
  }, [OnboardedCustomer.length, itemsPerPage]);


  const renderPageButtons = () => {

    const pageButtons = [];
    // Determine the range of page numbers to display
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, startPage + 9);

    // If the total number of pages is less than 10, adjust the endPage
    if (totalPages <= 10) {
      endPage = totalPages;
    } else {
      // If the current page is near the start, display the first 10 pages
      if (currentPage <= 5) {
        startPage = 1;
        endPage = 10;
      }
      // If the current page is near the end, display the last 10 pages
      else if (currentPage >= totalPages - 4) {
        endPage = totalPages;
        startPage = endPage - 9;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button
            onClick={() => paginate(i)}
            className="page-link"
            style={{ color: "black" }}
          >
            {i}
          </button>
        </li>
      );
    }

    return pageButtons;
  };

  const [feedbackDate, setfeedbackDate] = useState(null);
  const [remark, setRemark] = useState("");
  const [showFeecback, setShowFeedback] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loadFeedbackData, setLoadFeedbackData] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();


  const handleSubmit = async () => {
    try {
      const feedbackRef = db.collection("customer_feedback");
      const feedbackDoc = feedbackRef.doc();

      await db.collection("customer_feedback").add({
        customer_id: selectedCustomer.customer_id,
        feedback_id: feedbackDoc.id,
        feedback_date: feedbackDate,
        remarks: remark,
      });
      closeAddFeedback();
      retrieveData(selectedCustomer.customer_id);
      toast.success("Feedback added Successfully");
    } catch (error) {
      console.error("Error adding feedback:", error);
      toast.error("There is error while adding feedback");
    }
  };

  const feedbackInfodata = async (customerId) => {
    try {

      const querySnapshot = await db
        .collection("customer_feedback")
        .where("customer_id", "==", customerId)
        .get();
      if (!querySnapshot.empty) {
        const fetchedData = querySnapshot.docs.map((doc) => doc.data());

        return fetchedData;
      } else {

      }
    } catch (error) {
      console.error("Error fetching documents:", error);
      return [];
    }

    return [];
  };

  const retrieveData = async (customerId) => {
    const data = await feedbackInfodata(customerId);

    if (data.length >= 1) {
      setCustomerFeedbackData(data);
      setLoadFeedbackData(true);
    } else {
      setCustomerFeedbackData([]);
      setLoadFeedbackData(true);
    }
  };

  const handleFeedback = async (selectedCustomer) => {


    setSelectedCustomer(selectedCustomer);
    await retrieveData(selectedCustomer.customer_id);
    setShowFeedback(true);
  };

  const handleClose = () => {
    setShowFeedback(false);
    setShowAddFeedbackModal(false);
  };

  const closeAddFeedback = () => {
    setShowAddFeedbackModal(false);
  };

  const handleAddFeedback = () => {
    setfeedbackDate(null);
    setRemark("");
    setShowAddFeedbackModal(true);
  };


  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = [
      "Sr No",
      "Customer ID",
      "Customer Name",
      "Phone Number",
      "Customer Email",
      "Hub Name	",
      "Wallet Balance",
      "Registered On",
    ];
    const tableRows = OnboardedCustomer.map((customer ,index) => [
      index + 1,
      customer.customer_id,
      customer.customer_name,
      customer.customer_phone,
      customer.customer_email,
      customer.hub_name,
      customer.wallet_balance,
      customer.created_date &&
      moment(
        customer.created_date.toDate().toISOString()
      ).format("DD/MM/YY, h:mm a")
    ]);
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });

    doc.save("OnBoardCustomer.pdf");
  };


  const exportToCSV = () => {
    // Define the CSV columns
    const csvColumns = [
        "Sr No",
        "Customer ID",
        "Customer Name",
        "Phone Number",
        "Customer Email",
        "Hub Name",
        "Wallet Balance",
        "Registered On",
    ];

    // Create CSV rows
    const csvRows = OnboardedCustomer.map((customer, index) => [
        index + 1,
        customer.customer_id,
        customer.customer_name,
        customer.customer_phone,
        customer.customer_email,
        customer.hub_name,
        customer.wallet_balance,
        customer.created_date && moment(customer.created_date.toDate()).format("DD/MM/YY, h:mm a")
    ]);

    // Combine columns and rows into a single CSV content
    const csvContent = [
        csvColumns.join(","), // Join the header row
        ...csvRows.map(row => row.join(",")) // Join each data row
    ].join("\n"); // Join all rows with a newline character

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element for downloading
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "OnBoardCustomer.csv");
    link.style.visibility = 'hidden';

    // Append the link to the body
    document.body.appendChild(link);
    link.click(); // Simulate a click on the link
    document.body.removeChild(link); // Remove the link after download
};



const checklead = (customer_id, customer_name, customer_phone) => {
  let task_id = null;
  console.log("customer_id", customer_id);
  fetch_records("tasks", [
      {
          "key": "customer_id",
          "value": customer_id,
          "operator": "=="
      },
      {
          "key": "task_type",
          "value": TASK_TYPE.ONBOARD,
          "operator": "=="
      }
  ]).then((data) => {
      console.log("data", data);

      if (data.length > 0) {
          console.log(data);
          task_id = data[0].task_id; 
          console.log('task_id', task_id);
      } else {
          task_id = generate_random_id(8);
          create_record("tasks", {
              "task_id": task_id,
              "customer_id": customer_id,
              "customer_name": customer_name,
              "customer_phone": customer_phone,
              "attempts": 0,
              "assign_to": username,
              "status": "LEAD"
          });
      }
      
      // Navigate to the leads page with task_id
      navigate(`/leads/${task_id}`);
  });
};

  return (
    <>
      <div className="panel" style={{ marginTop: "10px", marginBottom: "10px" }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700" }}>NEW ONBOARD CUSTOMER</span>
          <div>
            {dataLoaded &&
              <div>
                <ExportTableToExcel
                  tableId="new_onboard_customers"
                  fileName="new_onboard_customers"
                />
                <button className="btn btn-success btn-rounded btn-sm mt-1" onClick= {exportToPDF} >Export to PDF</button>
                <button className="btn btn-success btn-rounded btn-sm mt-1" onClick= {exportToCSV} >Export to CSV</button>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="panel">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '60%' }}>
          <div className="datepicker-container">
            <label className="datepicker-label">From Date:</label>
            <DatePicker
              selected={fromDate}
              maxDate={toDate}
              onChange={handleFromDateChange}
              dateFormat="dd/MM/yyyy" // Format for displaying the date
              className="datepicker-input"
              placeholderText="Select date"
            />
          </div>
          <div className="datepicker-container">
            <label className="datepicker-label">To Date:</label>
            <DatePicker
              selected={toDate}
              minDate={fromDate}
              onChange={handleToDateChange}
              dateFormat="dd/MM/yyyy" // Format for displaying the date
              className="datepicker-input"
              placeholderText="Select date"
            />
          </div>
          <div>
            <Button variant="outline-success" size="sm" onClick={handleSearch}>
              Search
            </Button>
            <Button
              variant="outline-success"
              size="sm"
              onClick={handleReset}
              style={{ marginLeft: "10px" }}
            >
              Reset
            </Button>
          </div>

        </div>
        <br />
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          class="table-responsive"
        >
          {showSpinner && (
            <div className="spinner-container">
              <SpinnerOverlay />
            </div>
          )}
          <table class="table table-striped" id="new_onboard_customers">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Customer Id</th>
                <th>Customer Name</th>
                <th>Phone Number</th>
                <th>Customer Email</th>
                <th>Hub Name</th>
                <th>Wallet balance</th>
                <th>Registered On</th>
               
                <th>Feedback</th>
                <th>Assign Details</th>
              </tr>
            </thead>
            <tbody>
              {dataLoaded ? (OnboardedCustomer.map((customer, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{customer.customer_id}</td>
                  <td>{customer.customer_name}</td>
                  <td>{customer.customer_phone}</td>
                  <td>{customer.customer_email}</td>
                  <td>{customer.hub_name}</td>
                  <td>{customer.wallet_balance}</td>
                  <td>
                    {customer.created_date &&
                      moment(
                        customer.created_date.toDate().toISOString()
                      ).format("DD/MM/YY, h:mm a")}
                  </td>
                  <td>
                    <Button
                      variant="outline-success"
                      size="sm"
                      onClick={() => handleFeedback(customer)}
                    >
                      Feedback Info
                    </Button>{" "}
                  </td>
                  <td> <button   variant="outline-success" className='btn btn-dark btn-sm' onClick={()=>{checklead(customer.customer_id,customer.customer_name,customer.customer_phone)}} style={ { padding: "0.2rem 0.85rem"} } ><i className="menu-icon mdi mdi-eye" style={ {color: "white"} }></i></button></td>
                </tr>
              ))) : (
                <tr>
                  <td colSpan="10" style={{ textAlign: "center" }}>{initialValue}</td>
                </tr>
              )
              }
            </tbody>
          </table>
        </div>
      </div>

      <ul className="pagination">
        <li className="page-item">
          <button
            onClick={() => paginate(currentPage - 1)}
            className="page-link"
            disabled={currentPage === 1}
          >
            Previous
          </button>
        </li>
        {renderPageButtons()}
        <li className="page-item">
          <button
            onClick={() => paginate(currentPage + 1)}
            className="page-link"
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </li>
      </ul>
      {showFeecback && (
        <Modal show={showFeecback} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedCustomer.customer_name}'s Feedback
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p class="card-description" style={{ marginLeft: "90%" }}>
              <button
                type="button"
                class="btn btn-success btn-rounded btn-sm"
                data-toggle="modal"
                data-target="#exampleModal-2"
                onClick={handleAddFeedback}
              >
                {customerFeedbackData.length >= 1
                  ? "Add Follow Up"
                  : "Add Feedback"}
              </button>
            </p>
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              class="table-responsive"
            >
              {showSpinner && (
                <div className="spinner-container">
                  <SpinnerOverlay />
                </div>
              )}
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Feedback Date</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {customerFeedbackData &&
                    customerFeedbackData.map((data, index) => (
                      <tr key={index}>
                        <td>
                          {moment(
                            data.feedback_date.toDate().toISOString()
                          ).format("DD/MM/YY, h:mm a")}
                        </td>
                        <td>{data.remarks}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Modal show={showAddFeedbackModal} onHide={closeAddFeedback} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Customer's Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="datePicker">
              <Form.Label>Date</Form.Label>
              <br />
              <DatePicker
                selected={feedbackDate}
                onChange={(date) => setfeedbackDate(date)}
                dateFormat="dd/MM/yyyy"
                className="form-control"
                minDate={new Date()}
                maxDate={new Date()}
              />
            </Form.Group>
            <Form.Group controlId="remark">
              <Form.Label>Remark</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                placeholder="Enter remark..."
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeAddFeedback}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewOnboardCustomers;