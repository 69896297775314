import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import db from "./Firebase";
import { useNavigate, Link, useParams } from 'react-router-dom';
import { toast } from "react-toastify";

const ManageRoles = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        console.log(loggedIn);
        if (loggedIn) {
            // navigate("/");
        } else {
            navigate("/login");
        }
    }, [navigate]);

    const [screens, setScreens] = useState([
        {
            name: "Dashboard",
            read: true,
            update: true,
            delete: false,
        },
    ]);


    const [showCreateRole, setShowCreateRole] = useState(false);
    const [submitRole, setSubmitRole] = useState({
        role_type: "",
        user_role: "",
    });
    const [errRoleType, setErrRoleType] = useState("");
    const [errRoleName, serErrRoleName] = useState("");
    const [rolesData, setRolesData] = useState([]);

    const handleCreateRole = () => {
        setShowCreateRole(true);
    };

    const handleClose = () => {
        setShowCreateRole(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "role_type") {
            setSubmitRole((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === "user_role") {
            setSubmitRole((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    useEffect(() => {
        const unsubscribe = db.collection("user_roles").onSnapshot((snapshot) => {
            const rolesData = [];
            snapshot.forEach((doc) => {
                rolesData.push({ id: doc.id, ...doc.data() });
            });
            setRolesData(rolesData);
        });

        return () => unsubscribe();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const newRole = { ...submitRole, index: rolesData.length + 1 };
            await db.collection("user_roles").add(newRole);
            toast.success("Roles added successfully");
            handleClose();
        } catch (error) {
            toast.error("Product not added successfully");
            console.error("Error updating document: ", error);
        }
    };

    return (
        <>
            <button
                type="button"
                class="btn btn-success btn-rounded btn-sm"
                style={{ color: "white" }}
                onClick={handleCreateRole}
            >
                Create New Role
            </button>

            <Modal show={showCreateRole} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Create New Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form class="myForm" onSubmit={handleSubmit}>
                        <div className="panel-group">
                            <div className="panel">
                                <h3>Role Type</h3>
                                <select
                                    name="role_type"
                                    value={submitRole.roleType}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value={""}>Select Role</option>
                                    <option value="global">Global</option>
                                    <option value="hublevel">Hub Level</option>
                                </select>
                                {errRoleType && (
                                    <span
                                        style={{
                                            color: "red",
                                            fontSize: "12px",
                                            display: "block",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        {errRoleType}
                                    </span>
                                )}
                            </div>
                            <div className="panel">
                                <h3>User Role</h3>
                                <input
                                    type="text"
                                    name="user_role"
                                    value={submitRole.roleName}
                                    placeholder="Enter user role"
                                    onChange={handleChange}
                                    required
                                    autoComplete="off"
                                />
                                {errRoleName && (
                                    <span
                                        style={{
                                            color: "red",
                                            fontSize: "12px",
                                            display: "block",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        {errRoleName}
                                    </span>
                                )}
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>User Role</th>
                            <th>Role Type</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rolesData.map((data, index) => {
                            return (
                                <tr key={index}>
                                    <td>{data.index}</td>
                                    <td>{data.user_role}</td>
                                    <td>{data.role_type}</td>

                                    <td>
                                        {/* <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} onClick={() => changeStatusFormLocation(data, id)} class="btn btn-dark btn-sm"><i class="menu-icon mdi mdi-map-marker" style={{ color: "white" }}></i></button>
                                                                                            <button style={{ marginRight: "1rem", padding: "0.2rem 0.85rem" }} class="btn btn-dark btn-sm" onClick={() => deleteDataLocation(id)}><i class="menu-icon mdi mdi-delete" style={{ color: "white" }}></i></button> */}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ManageRoles;
