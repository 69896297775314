import React, { useEffect, useState } from "react";
import db from "../../Firebase";
import Swal from 'sweetalert2'

const PlaceOrderModal = ({ orderFormData }) => {
  const Toast = Swal.mixin({
    toast: true,
    background: '#69aba6',
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  const [errors, setErrors] = useState({});
  const [productList, setProductList] = useState([])
  const [productsLoading, setProductsLoading] = useState(false)
  const [quantityOptions, setQuantityOptions] = useState([])
  const [productPrice, setProductPrice] = useState('');
  const [selectedProductData, setSelectedProductData] = useState(null);
  const [loading, setLoading] = useState(false)
  const initialOrderFormData = {
    product_name: "",
    package_unit: "",
    quantity: "",
    delivery_date: ""
  };
  const [formData, setFormData] = useState({...initialOrderFormData});

  const resetForm = () => {
    setFormData({ ...initialOrderFormData });
  };

  const handleClose = () => {
    resetForm();
  };

  const generateOrderId = () => {
    const timestamp = Date.now().toString();
    const randomComponent = Math.floor(Math.random() * 100);
    const uniqueOrderId =
      (timestamp % 100000000).toString().padStart(6, "0") +
      randomComponent.toString().padStart(2, "0");
    return uniqueOrderId;
  };
//   await db.collection("order_history").add(orderDetails);
  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    const updatedData = {
        ...orderFormData,
        formData
    }
    const orderDetails = {
        cancelled_reason: "",
        cancelled_time: "",
        created_date: new Date(),
        customer_id: '',
        customer_name: '',
        customer_phone: '',
        delivering_to: updatedData.cafe_name,
        delivery_date: updatedData.formData.delivery_date,
        delivery_exe_id: "",
        delivery_time: "00:00:00",
        delivery_timestamp: updatedData.formData.delivery_date,
        handling_charges: 0,
        hub_name: updatedData.delivery_hub,
        marked_delivered_by: "",
        order_id: generateOrderId(),
        order_type: updatedData.type,
        package_unit: updatedData.formData.package_unit,
        price: productPrice,
        product_name: updatedData.formData.product_name,
        quantity: updatedData.formData.quantity,
        status: "0",
        subscription_id: "0",
        tax: 0,
        total_amount: productPrice * updatedData.formData.quantity,
        updated_date: new Date(),
      };
      await db.collection("order_history").add(orderDetails).then(() => {
        resetForm()
        window.modelhidePlaceOrder()
        setLoading(false)
        Toast.fire({
            icon: 'success',
            title: 'Order Created'
        });
    })
  };

  const handleChange = async (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));

    if (id === "product_name") {
        const selectedProduct = productList.find(product => product.value === value);
        if (selectedProduct) {
            const productDoc = await db.collection("products_data").doc(selectedProduct.id).get();
            const productData = productDoc.data();
            setSelectedProductData(productData);
            const packageOptions = productData.packagingOptions.map(option => ({
                value: `${option.packaging} ${option.pkgUnit}`,
                label: `${option.packaging} ${option.pkgUnit}`,
                price: option.price
            }));
            setQuantityOptions(packageOptions);
        }
    }
     if (id === "package_unit") {
        const selectedOption = quantityOptions.find(option => option.value === value);
        if (selectedOption) {
            const selectedPackaging = selectedProductData.packagingOptions.find(option => 
                `${option.packaging} ${option.pkgUnit}` === value
            );
            if (selectedPackaging) {
                setProductPrice(selectedPackaging.price);
            }
        }
    }
  };

  const fetchProductList = async() => {
    setProductsLoading(true)
    try {
        const snapshot = await db.collection("products_data").get();
        const products = snapshot.docs.map(doc => {
            const data = doc.data()
            return {id:doc.id, value: `${data.productName}`, label: `${data.productName}`}
        })
        setProductList(products)
    } catch (error) {
        console.error('Error fetching products: ', error)
    } finally {
        setProductsLoading(false)
    }
  } 

  useEffect(() => {
    fetchProductList();
  },[])

  return (
    <>
    {loading && (
        <div className="loader-overlay">
          <div className="">
            <img
              alt="loader"
              style={{
                height: "6rem",
              }}
              src="images/loader.gif"
            ></img>
          </div>
        </div>
      )}
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Place Order
        </h5>
        <button
          type="button"
          class="close"
          aria-label="Close"
          data-bs-dismiss="modal"
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="myForm" onSubmit={handleSubmit}>
          <div class="form-group row">
            <div class="flex mb-3">
              <label>Product</label>
              <select
                className={`form-control ${errors.product_name ? "is-invalid" : ""}`}
                onChange={handleChange}
                id="product_name"
                value={formData.product_name}
                required
                disabled={productsLoading}
              >
                <option value="">Select Product</option>
                {productList.map((product) => (
                  <option key={product.value} value={product.value}>
                    {product.label}
                  </option>
                ))}
              </select>
            </div>
            <div class="flex flex-row items-center gap-2">
              <div>
                <label>Package Unit</label>
                <select
                className={`form-control ${errors.package_unit ? "is-invalid" : ""}`}
                onChange={handleChange}
                id="package_unit"
                value={formData.package_unit}
                required
              >
                <option value="">Select Packaging</option>
                {quantityOptions.map((quantityOptions) => (
                  <option key={quantityOptions.value} value={quantityOptions.value}>
                    {quantityOptions.label}
                  </option>
                ))}
              </select>
              </div>
            </div>
          </div>

          <div class="form-group row">
              <div>
                <label>Quantity</label>
                <input
                  className={`form-control ${
                    errors.quantity ? "is-invalid" : ""
                  }`}
                  type="text"
                  onChange={handleChange}
                  id="quantity"
                  value={formData.quantity}
                  required
                  autoComplete="off"
                />
              </div>
            </div>

          <div class="form-group row">
            <div class="flex flex-row">
                <label>Select Delivery Date: </label>
                <input
                  className={`form-control ${
                    errors.delivery_date ? "is-invalid" : ""
                  }`}
                  type="date"
                  onChange={handleChange}
                  id="delivery_date"
                  value={formData.delivery_date}
                  required
                  autoComplete="off"
                  min={new Date().toISOString().split("T")[0]}
                  onKeyDown={(e) => e.preventDefault()}
                />
            </div>
          </div>

          <div class="modal-footer">
            <button type="submit" value="submit" class="btn btn-success">
              Submit
            </button>
            <button type="button" class="btn btn-light" data-bs-dismiss="modal" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PlaceOrderModal;
