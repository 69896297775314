import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import db from "./Firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storage } from "./Firebase";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { useNavigate, Link, useParams } from 'react-router-dom';

const AddUsers = ({ show, handleClose, userState, editUser, userDetails }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    console.log(loggedIn);
    if (loggedIn) {
      // navigate("/");
    } else {
      navigate("/login");
    }
  }, [navigate]);
  const [formData, setFormData] = useState(userState);
  const [errFirstName, setErrFirstName] = useState("");
  const [errLastName, setErrLastName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errUsername, setErrUsername] = useState("");
  const [errRole, setErrRole] = useState("");
  const [errPass, setErrPass] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const fileInputRef = useRef(null);
  const phoneNumberPattern = /^\d{10}$/;
  const emailPattern = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "firstName" && value === "") {
      setErrFirstName("Please enter the first Name.");
    } else {
      setErrFirstName("");
    }

    if (name === "lastName" && value === "") {
      setErrLastName("Please enter the Last Name.");
    } else {
      setErrLastName("");
    }

    if (name === "email") {
      if (value === "") {
        setErrEmail("Please enter the email.");
      } else if (!emailPattern.test(value)) {
        setErrEmail("Please enter a valid email address.");
      } else {
        setErrEmail("");
      }
    }

    if (name === "role" && value === "") {
      setErrRole("Please select role for the user.");
    } else {
      setErrRole("");
    }

    if (name === "username" && value === "") {
      setErrUsername("Please enter username.");
    } else if (
      name === "username" &&
      userDetails.some((user) => user.username === value)
    ) {
      setErrUsername(
        "This username is already in use. Please enter different username"
      );
    } else {
      setErrUsername("");
    }

    if (name === "password" && value === "") {
      setErrPass("Please enter password.");
    } else {
      setErrPass("");
    }

    if (name === "phoneNumber") {

      if (value === "") {
        setErrPhone("Please enter phone number.");
      } else if (userDetails.some((user) => user.phoneNumber === value)) {
        setErrPhone(
          "Phone number is already in use. Please enter a different phone number."
        );
      } else if (!phoneNumberPattern.test(value)) {
        setErrPhone("Please enter a valid 10-digit phone number.");
      } else {
        setErrPhone("");
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {

    if (
      errFirstName ||
      errLastName ||
      errEmail ||
      errRole ||
      errPhone ||
      errUsername ||
      errPass
    ) {
      toast.error("Please resolve all the errors displayed on the screen.");
      return;
    }

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.username ||
      !formData.password ||
      !formData.role ||
      !formData.phoneNumber
    ) {
      toast.error("Please enter all the mandatory values");
      return;
    }

    const imageFile = formData.userImage;
    if (
      imageFile &&
      !["image/jpeg", "image/png", "image/jpg"].includes(imageFile.type)
    ) {
      alert("Please upload an image file (JPG , JPEG or PNG).");
      return;
    }

    addUsersToDb(formData);
    handleClose();
  };

  const addUsersToDb = async (userDetails) => {
    try {
      if (!editUser) {
        const newUser = { ...userDetails, userId: generateUniqueId() };
        await db.collection("userDetails").add(newUser);
        toast.success("User added successfully");
      } else {
        await db.collection("userDetails").doc(formData.id).update(userDetails);
        toast.info("User updated successfully");
      }
    } catch (error) {
      toast.error("Product not added successfully");
      console.error("Error updating document: ", error);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const newImageName = `${e.target.files[0].name}_${Date.now()}`;
      const storageRef = ref(storage, `users/${newImageName}`);

      uploadBytes(storageRef, e.target.files[0]).then((data) => {
        getDownloadURL(data.ref).then((val) => {
          setFormData((prevState) => ({
            ...prevState,
            userImage: val,
          }));
        });
      });
    }
  };

  function generateUniqueId() {
    const now = new Date();
    const timestamp = now.getTime();
    const random4Digits = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0");
    const customerId =
      (timestamp % 10000).toString().padStart(4, "0") + random4Digits;

    return customerId;
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="firstName">
              <Form.Label>First Name *</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                pattern="[A-Za-z]+"
                required
              />
              {errFirstName && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    display: "block",
                    marginBottom: "10px",
                  }}
                >
                  {errFirstName}
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name *</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                pattern="[A-Za-z]+"
                required
              />
              {errLastName && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    display: "block",
                    marginBottom: "10px",
                  }}
                >
                  {errLastName}
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email *</Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errEmail && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    display: "block",
                    marginBottom: "10px",
                  }}
                >
                  {errEmail}
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="role">
              <Form.Label>Select Role</Form.Label>
              <Form.Control
                as="select"
                name="role"
                value={formData.role}
                onChange={handleChange}
              >
                <option>Select Role</option>
                <option value="Accounts">Accounts</option>
                <option value="Admin">Admin</option>
                <option value="Bdm">Bdm</option>
                <option value="CS Lead">Cs Lead</option>
                <option value="Customer Care Agent">Customer Care Agent</option>
                <option value="Dev">Dev</option>
                <option value="Help Desk QA">Help Desk QA</option>
                <option value="Help Desk TL">Help Desk TL</option>
                <option value="Marketing Associate">Marketing Associate</option>
                <option value="Operation Team">Operation Team</option>
                <option value="Plant Manager">Plant Manager</option>
                <option value="Report Team">Report Team</option>
              </Form.Control>
              {errRole && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    display: "block",
                    marginBottom: "10px",
                  }}
                >
                  {errRole}
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="username">
              <Form.Label>Username *</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
              />
              {errUsername && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    display: "block",
                    marginBottom: "10px",
                  }}
                >
                  {errUsername}
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Password *</Form.Label>
              <Form.Control
                type="text"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              {errPass && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    display: "block",
                    marginBottom: "10px",
                  }}
                >
                  {errPass}
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="phonenumber">
              <Form.Label>Phone Number *</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
              {errPhone && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    display: "block",
                    marginBottom: "10px",
                  }}
                >
                  {errPhone}
                </span>
              )}
            </Form.Group>
            {formData.userImage ? (
              <>
                <div style={{}}>
                  <img
                    src={formData.userImage}
                    alt="Preview"
                    style={{
                      height: "50px",
                      marginTop: "10px",
                      display: "block",
                    }}
                  />

                  <input
                    type="file"
                    accept="image/*"
                    id="image"
                    className="form-control"
                    style={{
                      height: "34px",
                      padding: "6px 10px",
                      boxSizing: "border-box",
                      border: "2px solid #ccc",
                      borderRadius: "4px",
                      backgroundColor: "#f8f8f8",
                      fontSize: "16px",
                      resize: "none",
                    }}
                    onChange={handleImageChange}
                    ref={fileInputRef}
                  />
                </div>
              </>
            ) : (
              <input
                type="file"
                accept="image/*"
                id="image"
                className="form-control"
                style={{
                  height: "34px",
                  padding: "6px 10px",
                  boxSizing: "border-box",
                  border: "2px solid #ccc",
                  borderRadius: "4px",
                  backgroundColor: "#f8f8f8",
                  fontSize: "16px",
                  resize: "none",
                }}
                onChange={handleImageChange}
                ref={fileInputRef}
              />
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUsers;
