import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import AddUsers from "./AddUsers";
import db from "./Firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import ManageRoles from "./ManageRoles";
import { useNavigate, Link, useParams } from 'react-router-dom';

function ManageUserDetails() {
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    if (loggedIn) {
      // navigate("/");
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const [showAddUser, setShowAddUser] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [initialUserState, setInitialUserState] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    username: "",
    password: "",
    phoneNumber: "",
    status: true,
    userImage: null,
    createdDate: new Date(),
    updatedDate: new Date(),
  });
  const [selectedUser, setSelectedUser] = useState("");
  const [editUser, setEditUser] = useState("");
  const [showChangePassword, setShowChangePassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passErr, setPassErr] = useState("");
  const [showManageRolesScreen, setShowManageRolesScreen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleUserStatus = async (idToUpdate) => {
    const updatedUserDetails = userDetails.map((user) => {
      if (user.id === idToUpdate) {
        return {
          ...user,
          status: !user.status,
        };
      }
      return user;
    });
    setUserDetails(updatedUserDetails);

    await db
      .collection("userDetails")
      .doc(idToUpdate.toString())
      .update({
        status: updatedUserDetails.find((user) => user.id === idToUpdate)
          .status,
      });
    toast.info("User status is updated successfully.");
  };

  const fetchData = async () => {
    const snapshot = await db.collection("userDetails").get();
    const usersData = [];
    snapshot.forEach((doc) => {
      usersData.push({ id: doc.id, ...doc.data() });
    });
    setUserDetails(usersData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (selectedUser) => {
    setEditUser(true);
    setSelectedUser(selectedUser);
    setShowAddUser(true);
  };

  const handleDelete = (userId) => {
    if (userId === "") {
      toast.error("There is some error. Delete Unsuccessful.");
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        className: "p-5",
      }).then((result) => {
        if (result.isConfirmed) {
          confirmDelete(userId);
        }
      });
    }
  };

  const confirmDelete = async (userId) => {
    try {
      await db
        .collection("userDetails")
        .where("userId", "==", userId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs[0].ref.delete().then(() => {
            //toast.error("Product Deleted Successfully");
          });
        });
      toast.error("User Deleted Successfully");
      setUserDetails(userDetails.filter((user) => user.userId !== userId));
    } catch (error) {
      toast.error("User not Deleted Successfully");
      console.error("Error deleting document: ", error);
    }
  };

  const handleAddUsers = () => {
    setEditUser(false);
    setSelectedUser(initialUserState);
    setShowAddUser(true);
  };



  const handleCloseModal = () => {
    fetchData();
    setShowAddUser(false);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChange = (e) => {
    if (newPassword !== e.target.value) {
      setPassErr("Password is not matching");
      setConfirmNewPassword(e.target.value);
    } else {
      setConfirmNewPassword(e.target.value);
      setPassErr("");
    }
  };

  const handleClose = () => {
    setShowChangePassword(false);
  };

  const handleChangePassword = (selectedUser) => {

    setShowChangePassword(true);
    setSelectedUser(selectedUser);
  };

  const UpdatePassword = async (e) => {

    e.preventDefault();
    if (passErr) {
      toast.info("PLease resolve the error displayed on screen");
      return;
    } else {
      await db
        .collection("userDetails")
        .doc(selectedUser.id.toString())
        .update({
          password: confirmNewPassword,
        });
      setShowChangePassword(false);
      toast.info("User updated successfully");
    }
  };

  return (
    <>

      <button
        type="button"
        class="btn btn-success btn-rounded btn-sm"
        style={{ color: "white" }}
        onClick={handleAddUsers}
      >
        Add User
      </button>


      <div
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {!showManageRolesScreen && (
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Username</th>
                <th>Role</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {userDetails.map((user, index) => (
                <tr key={index}>
                  <td className="product-image-cell">
                    <div className="product-image-wrapper">
                      <img
                        className="product-image"
                        src={user.userImage}
                        alt="userImage"
                      />
                    </div>
                  </td>
                  <td className="product-name-cell">
                    <p className="product-name">
                      {user.firstName + " " + user.lastName}
                    </p>
                  </td>
                  <td>{user.username}</td>
                  <td>{user.role}</td>
                  <td>{user.email}</td>
                  <td>{user.phoneNumber}</td>
                  <td>
                    <Form.Switch
                      checked={user.status}
                      onChange={() => handleUserStatus(user.id)}
                    />
                  </td>
                  <td>
                    <button
                      style={{
                        marginRight: "1rem",
                        padding: "0.2rem 0.85rem",
                      }}
                      class="btn btn-dark btn-sm"
                      onClick={() => handleEdit(user)}
                    >
                      <i
                        class="menu-icon mdi mdi-pencil"
                        style={{ color: "white" }}
                      ></i>
                    </button>
                    <button
                      style={{
                        marginRight: "1rem",
                        padding: "0.2rem 0.85rem",
                      }}
                      class="btn btn-dark btn-sm"
                      onClick={() => handleDelete(user.userId)}
                    >
                      <i
                        class="menu-icon mdi mdi-delete"
                        style={{ color: "white" }}
                      ></i>
                    </button>
                    <button
                      style={{
                        marginRight: "1rem",
                        padding: "0.2rem 0.85rem",
                      }}
                      onClick={() => handleChangePassword(user)}
                    >
                      <img
                        src="images/changePassword.png"
                        alt="Icon"
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {showAddUser && (
          <AddUsers
            userState={selectedUser}
            show={showAddUser}
            handleClose={handleCloseModal}
            editUser={editUser}
            userDetails={userDetails}
          />
        )}
        <Modal show={showChangePassword} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={UpdatePassword}>
              <Form.Group controlId="newPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                />
              </Form.Group>
              <Form.Group controlId="confirmNewPassword">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  value={confirmNewPassword}
                  onChange={handleConfirmNewPasswordChange}
                />
                {passErr && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      display: "block",
                      marginBottom: "10px",
                    }}
                  >
                    {passErr}
                  </span>
                )}
              </Form.Group>
              <Button variant="primary" type="submit">
                Change Password
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        {showManageRolesScreen && <ManageRoles></ManageRoles>}
      </div>
    </>
  );
}

export default ManageUserDetails;
