import React from 'react'

function Offers() {
  return (
    <div>
      Offers
    </div>
  )
}

export default Offers
