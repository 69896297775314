import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { collection, getDocs, query, where, getCountFromServer } from 'firebase/firestore';
import db from "./Firebase";
import Sidebar from "./Sidebar";
import TopPanel from "./TopPanel";
import Footer from "./Footer";
import { extendMoment } from 'moment-range';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';

const Dashboard = () => {
    const [cookies] = useCookies(["permissions"]);
    const rolePermissions = cookies.permissions ? cookies.permissions.Menu || [] : [];
    const [loading, setLoading] = useState(false);
    const canvasRef = useRef(null);
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [selectedRange, setSelectedRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });
    const [showPicker, setShowPicker] = useState(false);
    const [totalCustomers, setTotalCustomers] = useState(0);
    const [todayCustomers, setTodayCustomers] = useState(0);
    const [weekCustomers, setWeekCustomers] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const [averageOrderValue, setAverageOrderValue] = useState(0);
    const [averageOrdersPerExecutive, setAverageOrdersPerExecutive] = useState(0);
    const [oneTimeOrderCount, setOneTimeOrderCount] = useState(0);
    const [android, setAndroid] = useState(0);
    const [ios, setIos] = useState(0);
    const [website, setWebsite] = useState(0);
    const [backend, setBackend] = useState(0);
    const [productNames, setProductNames] = useState([]);
    const [productQuantities, setProductQuantities] = useState([]);
    const dateRangeOptions = [
        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "Last 7 days", value: "last7days" },
        { label: "Custom", value: "custom" },
    ];
    const [selectedDateOptions, setSelectedDateOptions] = useState(dateRangeOptions[0]);
    const [showDate, setShowDate] = useState(true);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dateOption, setDateOption] = useState('today');
    const [customerCollectionSize, setCustomerCollectionSize] = useState(null);

    useEffect(() => {
        const checkLoginStatus = () => {
            setLoading(true);
            const loggedIn = localStorage.getItem("loggedIn") === "true";
            if (!loggedIn) {
                navigate("/login");
            }
            setLoading(false);
        };
        checkLoginStatus();
    }, [navigate]);

    useEffect(() => {
        const initializeCanvas = () => {
            const canvas = canvasRef.current;
            if (canvas) {
                canvas.getContext('2d');
            } else {
                console.error('Canvas element not found');
            }
        };
        initializeCanvas();
    }, []);

    // useEffect(() => {
    //     fetchDataForChart()
    // }, []);

    const openOneTimeOrders = () => {
        const url = '/onetimeorders';
        navigate('onetimeorders');
    }

    const openOrdersReport = () => {
        const url = '/orderreport';
        navigate('/orderreport');
    }

    const handleSelect = (ranges) => {
        setSelectedRange([ranges.selection]);
    };

    const togglePicker = () => {
        setShowPicker(!showPicker);
    };

    const handleSearchRange = () => {
        setStartDate(selectedRange[0].startDate);
        setEndDate(selectedRange[0].endDate);
        fetchDataBasedOnDateRange(selectedRange[0].startDate, selectedRange[0].endDate);
        togglePicker();

    };

    const fetchCollectionSize = async () => {
        const coll = collection(db, 'customers_data');
        const snapshot = await getCountFromServer(query(coll));
        setCustomerCollectionSize(snapshot.data().count);
    };

    const fetchPlatformCount = async (platform, setter) => {
        const collRef = collection(db, 'customers_data');
        const q = query(collRef, where('platform', '==', platform));
        const snapshot = await getCountFromServer(q);
        setter(snapshot.data().count);
    };

    const fetchDailyCustomerCount = async () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        const collRef = collection(db, 'customers_data');
        const q = query(collRef,
            where('registered_date', '>=', today),
            where('registered_date', '<', tomorrow)
        );
        const snapshot = await getCountFromServer(q);
        setTodayCustomers(snapshot.data().count);
    };

    const [newUserCount, setNewUserCount] = useState(0);
    /** show new user count */
    useEffect(() => {
      
        const fetchNewUserCount = async () => {
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Set the time to the start of the day

            const q = query(collection(db, "customers_data"), where("registered_date", ">=", today));
            console.log(q);
         
            const snapshot = await getDocs(q);
            setNewUserCount(snapshot.size);
       
        };
       
        fetchNewUserCount();

     
        const intervalId = setInterval(fetchNewUserCount, 86400000); // 86400000ms = 24 hours

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const fetchWeeklyCustomerCount = async () => {
        const today = new Date();
        const lastweek = new Date(today);
        lastweek.setDate(lastweek.getDate() - 7);

        const collRef = collection(db, 'customers_data');
        const q = query(collRef,
            where('registered_date', '>=', lastweek),
            where('registered_date', '<=', today)
        );
        const snapshot = await getCountFromServer(q);
        setWeekCustomers(snapshot.data().count);
    };

    const fetchOnetimeOrdersCount = async () => {
        // console.log("fetchOnetimeOrdersCount called");
        // console.log("startDate:", startDate);

        const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
        const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
        // console.log("formattedStartDate:", formattedStartDate, "formattedEndDate:", formattedEndDate);

        const collRef = collection(db, 'order_history');
        const q = query(collRef,
            where('order_type', '==', 'OT'),
            where('delivery_date', '==', formattedStartDate),
            // where('delivery_date', '<=', formattedEndDate)
        );
        const snapshot = await getCountFromServer(q);
        setOneTimeOrderCount(snapshot.data().count);
    };
    const formatDateC = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const fetchDataForChart = async () => {
        setLoading(true);

        const orderHistoryCollection = db.collection('order_history');
        const tempProductQuantities = {};
        const tempProductNames = new Set();

        const startDate = new Date();
        startDate.setDate(1);  // Set to the first day of the month
        const endDate = new Date();
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0);  // Set to the last day of the month

        const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
        const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

        // console.log(`Fetching data from ${formattedStartDate} to ${formattedEndDate}`);

        try {
            const querySnapshot = await orderHistoryCollection
                .where('delivery_date', '>=', formattedStartDate)
                .where('delivery_date', '<=', formattedEndDate)
                .get();

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                const productName = data.product_name.trim();
                const quantity = data.quantity;
                const deliveryDate = new Date(data.delivery_date);
                const formattedDate = formatDateC(deliveryDate);

                tempProductNames.add(productName);

                if (!tempProductQuantities[formattedDate]) {
                    tempProductQuantities[formattedDate] = {};
                    tempProductNames.forEach((name) => {
                        tempProductQuantities[formattedDate][name] = 0;
                    });
                }

                tempProductQuantities[formattedDate][productName] += quantity;
            });

            const sortedDates = Object.keys(tempProductQuantities).sort();
            const sortedQuantities = sortedDates.map((date) => {
                // console.log(date)
                return Array.from(tempProductNames).map((productName) => {
                    return tempProductQuantities[date][productName] || 0;
                });
            });

            setProductNames(Array.from(tempProductNames));
            setProductQuantities(sortedQuantities);
            // console.log(sortedQuantities)
            window.orderData(Array.from(tempProductNames), sortedQuantities);
        } catch (error) {
            console.error("Error fetching chart data: ", error);
        }

        setLoading(false);
    };
    const fetchDataBasedOnDateRange = async (startDate, endDate) => {
        setLoading(true);
        await Promise.all([
            fetchCollectionSize(),
            fetchPlatformCount('android', setAndroid),
            fetchPlatformCount('ios', setIos),
            fetchPlatformCount('website', setWebsite),
            fetchPlatformCount('backend', setBackend),
            fetchDailyCustomerCount(),
            fetchWeeklyCustomerCount(),
            fetchOnetimeOrdersCount(),
            // fetchDataForChart()
        ]);
        await calculateDashboardData(startDate, endDate);
        setLoading(false);
    };

    useEffect(() => {
        fetchDataBasedOnDateRange(new Date(), new Date());
    }, []);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const calculateDashboardData = async (startDate, endDate) => {
        let totalRevenue = 0;
        const uniqueOrderIds = new Set();
        const executives = new Set();
        const batchSize = 1000;
        let lastVisible = null;

        while (true) {
            let query = db.collection('order_history')
                .where('delivery_date', '>=', moment(startDate).format('YYYY-MM-DD'))
                .where('delivery_date', '<=', moment(endDate).format('YYYY-MM-DD'))
                .where('status', '!=', "2")
                .limit(batchSize);

            if (lastVisible) {
                query = query.startAfter(lastVisible);
            }

            const snapshot = await query.get();

            if (snapshot.empty) {
                break;
            }

            snapshot.forEach(doc => {
                const data = doc.data();
                totalRevenue += data.total_amount;
                uniqueOrderIds.add(data.order_id);
                executives.add(data.delivery_exe_id);
            });

            lastVisible = snapshot.docs[snapshot.docs.length - 1];
        }

        setTotalRevenue(totalRevenue);
        setTotalOrders(uniqueOrderIds.size);
        const averageValue = totalRevenue / (uniqueOrderIds.size);
        setAverageOrderValue(averageValue);
        const numExecutives = executives.size;
        if (totalOrders > 0 && numExecutives > 0) {
            const averageExecutives = totalOrders / numExecutives;
            setAverageOrdersPerExecutive(averageExecutives.toFixed(1));
        } else {
            setAverageOrdersPerExecutive(0);
        }
    };

    const handleDateOptions = (selectedOption) => {
        if (selectedOption.value === 'today') {
            setShowDate(true);
            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(today.getDate() + 1);
            setStartDate(today);
            setEndDate(tomorrow);
        } else if (selectedOption.value === 'yesterday') {
            setShowDate(true);
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            setStartDate(yesterday);
            setEndDate(today);
        } else if (selectedOption.value === 'last7days') {
            setShowDate(false);
            const today = new Date();
            const sevenDaysBefore = new Date(today);
            sevenDaysBefore.setDate(today.getDate() - 7);
            setStartDate(sevenDaysBefore);
            setEndDate(today);
        } else {
            setShowDate(false);
        }
        setSelectedDateOptions(selectedOption);
    };

    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <div>
                        <img style={{ height: "6rem" }} src="images/loader.gif" alt="Loading..." />
                    </div>
                </div>
            )}
            <div className="container-scroller">
                <TopPanel />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        {rolePermissions.includes('') ? (
                            <div className="content-wrapper">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <div>
                                            <div
                                                id="reportrange"
                                                style={{
                                                    background: '#fff',
                                                    cursor: 'pointer',
                                                    padding: '5px 10px',
                                                    border: '1px solid #ccc',
                                                    width: '50%',
                                                }}
                                                onClick={togglePicker}
                                            >
                                                <i className="fa fa-calendar" />&nbsp;
                                                <span>{`${selectedRange[0].startDate.toDateString()} - ${selectedRange[0].endDate.toDateString()}`}</span> <i className="fa fa-caret-down" />
                                            </div>
                                            {showPicker && (
                                                <div style={{ background: 'rgb(221 213 255)', padding: '15px', width: "37rem", position: 'absolute', zIndex: 2 }}>
                                                    <DateRangePicker
                                                        ranges={selectedRange}
                                                        onChange={handleSelect}
                                                        moveRangeOnFirstSelection={false}
                                                        showDateDisplay={false}
                                                        direction="vertical"
                                                    />
                                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                                        <button type="button" className="btn btn-success btn-rounded btn-sm" style={{ background: 'rgb(222 57 41)', marginTop: "15px", marginRight: "10px" }} onClick={togglePicker}>Cancel</button>
                                                        <button type="button" className="btn btn-success btn-rounded btn-sm" style={{ background: '#4a54ba', marginTop: "15px" }} onClick={handleSearchRange}>Search</button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto">
                                        <button onClick={openOneTimeOrders} className="btn btn-success mx-2">One Time Order</button>
                                        <button onClick={openOrdersReport} className="btn btn-success mx-2">Order Report</button>
                                    </div>
                                </div>

                                <div>
                                    <div className="col-sm-12">
                                        <div className="home-tab">
                                            <div className="tab-content tab-content-basic">
                                                <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="statistics-details d-flex align-items-center justify-content-between">
                                                                <div className="card d-flex align-items-start">
                                                                    <div className="card-body">
                                                                        <div className="d-flex flex-row align-items-start">
                                                                            <div>
                                                                                <p className="statistics-title">Total Customers</p>
                                                                                <h3 className="rate-percentage">{customerCollectionSize}</h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card d-flex align-items-start">
                                                                    <div className="card-body">
                                                                        <div className="d-flex flex-row align-items-start">
                                                                            <div className="d-none d-md-block">
                                                                                <p className="statistics-title">Total Revenue</p>
                                                                                <h3 className="rate-percentage">₹ {totalRevenue}</h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card d-flex align-items-start">
                                                                    <div className="card-body">
                                                                        <div className="d-flex flex-row align-items-start">
                                                                            <div className="d-none d-md-block">
                                                                                <p className="statistics-title">Total Orders</p>
                                                                                <h3 className="rate-percentage">{totalOrders}</h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card d-flex align-items-start">
                                                                    <div className="card-body">
                                                                        <div className="d-flex flex-row align-items-start">
                                                                            <div>
                                                                                <p className="statistics-title">One-Time Delivery</p>
                                                                                <h3 className="rate-percentage">{oneTimeOrderCount}</h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card d-flex align-items-start">
                                                                    <div className="card-body">
                                                                        <div className="d-flex flex-row align-items-start">
                                                                            <div className="d-none d-md-block">
                                                                                <p className="statistics-title">Avg. Order Value</p>
                                                                                <h3 className="rate-percentage">₹ {averageOrderValue ? averageOrderValue.toFixed(2) : '0'}</h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card d-flex align-items-start">
                                                                    <div className="card-body">
                                                                        <div className="d-flex flex-row align-items-start">
                                                                            <div>
                                                                                <p className="statistics-title">Avg. Orders per DE</p>
                                                                                <h3 className="rate-percentage">{averageOrdersPerExecutive}</h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="statistics-details d-flex align-items-center justify-content-between">
                                                                <div>
                                                                    <p className="statistics-title">Customers From Android</p>
                                                                    <h3 className="rate-percentage">{android}</h3>
                                                                </div>
                                                                <div>
                                                                    <p className="statistics-title">Customers From IOS</p>
                                                                    <h3 className="rate-percentage">{ios}</h3>
                                                                </div>
                                                                <div>
                                                                    <p className="statistics-title">From Webapps/Website</p>
                                                                    <h3 className="rate-percentage">{website}</h3>
                                                                </div>
                                                                <div className="d-none d-md-block">
                                                                    <p className="statistics-title">Backend</p>
                                                                    <h3 className="rate-percentage">{backend}</h3>
                                                                </div>
                                                                <div className="d-none d-md-block">
                                                                    <p className="statistics-title">Current Week customer</p>
                                                                    <h3 className="rate-percentage">{weekCustomers}</h3>
                                                                </div>
                                                                <div className="d-none d-md-block">
                                                                    <p className="statistics-title">Today's Customer</p>
                                                                    <h3 className="rate-percentage">{newUserCount}</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-12 d-flex flex-column">
                                                            <div className="row flex-grow">

                                                                <div className="col-12 col-lg-8 col-lg-12 grid-margin stretch-card" style={{ height: "100%", paddingBottom: "2rem" }}>
                                                                    <div className="card card-rounded">
                                                                        <div className="card-body">
                                                                            <div className="d-sm-flex justify-content-between align-items-start">
                                                                                <div>
                                                                                    <h4 className="card-title card-title-dash">Monthly ORDER REPORT</h4>
                                                                                    <button onClick={fetchDataForChart} className="btn btn-success mx-2">View Graph Data</button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-sm-flex align-items-center mt-1 justify-content-between" style={{ height: "22rem" }}>
                                                                                <canvas id="monthlyOrderChart" ref={canvasRef}></canvas>
                                                                            </div>
                                                                            <div className="chartjs-bar-wrapper mt-3">
                                                                                <div id="monthly-order-legend" className="legend-horizontal"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-12 d-flex flex-column" style={{ height: "100%", paddingBottom: "2rem" }}>
                                                            <div className="row flex-grow">
                                                                <div className="col-12 grid-margin stretch-card">
                                                                    <div className="card card-rounded">
                                                                        <div className="card-body">
                                                                            <div className="d-sm-flex justify-content-between align-items-start">
                                                                                <div></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row flex-grow">
                                                                <div className="col-12 grid-margin stretch-card"></div>
                                                            </div>
                                                            <div className="row flex-grow"></div>
                                                        </div>
                                                        <div className="col-lg-4 d-flex flex-column">
                                                            <div className="row flex-grow"></div>
                                                            <div className="row flex-grow">
                                                                <div className="col-12 grid-margin stretch-card"></div>
                                                            </div>
                                                            <div className="row flex-grow"></div>
                                                            <div className="row flex-grow">
                                                                <div className="col-12 grid-margin stretch-card"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <h2 style={{ padding: "15rem" }}>You are not authorised to view this page</h2>
                        )}
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Dashboard;


